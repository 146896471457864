<div class="modal fade show" [ngClass]="{'expanded':isExpanded}" tabindex="-1"
  aria-labelledby="service-order-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}">
  <div class="modal-dialog modal-xl">
    <app-loader *ngIf="showLoader"></app-loader>
    <div class="modal-content">
      <!-- check needed here if order id is already created or not -->
      <app-popup-header 
        [title]="popupTitle" 
        [isExpanded]="isExpanded" 
        (onClose)="close()"
        (onExpandCollapse)="isExpanded = $event"
        [createdDate]="purchaseOrderData ? purchaseOrderData.createdDate : null"
      ></app-popup-header>
      <div class="modal-body pt-0">
        <div class="row header-section">
          <div class="col-md-7">
            <div class="row header-left-section">
              <div class="col resource-wrapper">
                <div class="row field no-border">
                  <label for="" class="control-label col-md-auto mt-2">Technician<span
                      class="required-asterik">*</span></label> <span class="mt-2"> {{purchaseOrderData?.partsOrderId ? purchaseOrderData?.technicianName: userDetails?.employeeName}}</span>
                </div>
              </div>
              <div class="col">
                <div class="row field no-border">
                  <label for="" class="control-label col-md-auto mt-2">Status<b>:</b></label> <span
                    class="mt-2">{{purchaseOrderData?.status}}</span>
                </div>
              </div>
              <div class="col-auto" *ngIf="purchaseOrderData?.partsOrderId">
                  <div class="comments-section position-relative">
                      <a class="comments-opener" href="javascript:" #commentsButton
                          (click)="showComments = !showComments"><i class="fas fa-comment mr-2"
                              [ngClass]="{'color-orange': commentCount}"></i>
                          <span>{{commentCount}}</span> Comment<span *ngIf="commentCount > 1">s</span></a>
                      <div class="comments-container position-absolute" #commentsPopup
                          *ngIf="showComments">
                          <app-comments
                              [enableAddNew]="showComments"
                              [moduleId]="purchaseOrderData?.partsOrderId"
                              module="PurchaseOrder"
                              [comments]="purchaseOrderData?.comments"
                              (commentUpdated)="onCommentUpdate($event)"
                              (onClose)="showComments = false;"
                          ></app-comments>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col tabbable-line pt-1">
                <ul class="nav nav-tabs float-right" id="tabs">
                  <li class="nav-item">
                    <a class="nav-link position-relative" [ngClass]="{'active': tab === 'details'}" data-toggle="tab"
                      href="#cart-details-tab" title="Details">Details
                    </a>
                  </li>

                  <li class="nav-item" *ngIf="purchaseOrderData?.partsOrderId">
                    <a class="nav-link position-relative" [ngClass]="{'active': tab === 'tracking'}" data-toggle="tab"
                      href="#cart-tracking-tab" title="Tracking">Tracking
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': tab === 'links'}" 
                      href="#cart-links-tab" title="Links" aria-expanded="true"><i class="fas fa-link"></i></a>
                  </li>

                  <li class="nav-item" *ngIf="purchaseOrderData?.partsOrderId">
                    <a class="nav-link" [ngClass]="{'active': tab === 'attachments'}" data-toggle="tab"
                      href="#cart-attachments-tab" title="Attachments" aria-expanded="true"><i class="fas fa-file-upload"
                        [ngClass]="{'color-green': attachmentCount}"></i></a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': tab === 'history'}" data-toggle="tab" 
                      href="#cart-history-tab" title="History"><i class="fas fa-history"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="container-fluid w-100">
            <div class="tab-content custom-scrollbar flex-fill">
              <div class="tab-pane" [ngClass]="{'active show': tab === 'details'}" id="cart-details-tab">
                <div class="row">
                  <div class="col">
                    <div class="elements-container container-fluid p-0">

                      <div class="row container-fluid mt-3">
                        <div class="w-100">
                          <ejs-grid *ngIf="showGrid" 
                            #POCheckoutGrid 
                            id="po-checkout-grid" 
                            [height]="270"
                            [dataSource]="recievedItems" 
                            [allowPaging]="false" 
                            [allowFiltering]="true"
                            [allowSorting]="true" 
                            [allowResizing]="true" 
                            [allowReordering]="true"
                            [allowExcelExport]="true" 
                            [enablePersistence]="false" 
                            [filterSettings]="{ type: 'Menu' }"
                            [query]="query"
                            (commandClick)="onCommandClick($event)"
                            (rowDataBound)="onRowDataBound($event)">
                          <e-columns>
                           <e-column *ngFor="let column of columns;" [field]="column.field"
                              [headerText]="column.headerText" [clipMode]="'EllipsisWithTooltip'" [type]="column.type"
                              [edit]="column.edit" [width]="column.width"
                              [showInColumnChooser]="column.showInColumnChooser ?? true"
                              [filter]="column.filter ? column.filter : { type: 'Menu' }"
                              [format]="column.format ? column.format : null" [textAlign]="column.textAlign"
                              [visible]="column.visible" [isPrimaryKey]="column.isPrimaryKey || false"
                              [validationRules]="column?.validationRules || null"
                              [commands]="column?.commands ? column.commands : null"
                              [allowEditing]="column.allowEditing"
                              [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                              <ng-template *ngIf="column.field === 'totalPrice'" #template let-data>
                                {{((data.quantity * data.itemPrice) - data.itemDiscount).toFixed(2)}}
                              </ng-template>
                              <ng-template  *ngIf="column.field === 'item'" headerText="Item" #headerTemplate let-data>
                                <span>Item.<span class="required-asterik">*</span></span>
                               </ng-template>
                            </e-column>
                          </e-columns>
                        </ejs-grid>
                        </div>
                      </div>

                      <div class="row mt-3 container-fluid p-0"
                        [class.disabled]="purchaseOrderData?.status === 'Submitted'">

                        <div class="col-lg-6 col-md-4 col-sm-12">
                          <div class="row">
                            <div class="col-12">
                              <div class="field mb-2 position-relative">
                                <label for="" for="">Shipment Method<span class="required-asterik">*</span></label>
                                <ejs-dropdownlist #shipmentMethodDDLRef
                                  id="shipment-method" 
                                  [placeholder]="'Select Shipment Method'" 
                                  [dataSource]="shipmentMethodsList"
                                  [allowFiltering]="true" 
                                  [fields]="{text: 'text', value: 'value'}"
                                  [value]="purchaseOrderData?.shipmentMethodCode"
                                  [enabled]="purchaseOrderData?.status === 'Open'"
                                  (change)="onShipmentMethodChange($event)">
                                </ejs-dropdownlist>
                              </div>
                              <div class="clearfix"></div>
                            </div>


                            <div class="col-12" *ngIf="recievedItems[0]?.assignmentId"> <!-- Coming from service order -->
                              <div class="field mb-3">
                                <ejs-radiobutton 
                                  checked 
                                  name="addressType" 
                                  label="My Address"
                                  id="userAddress"
                                  value="userAddress"
                                  [(ngModel)]="checkType"
                                  [checked]="userDetails?.addressType === 'userAddress'" 
                                  (change)="userDetails.addressType = $event.value;
                                  radioButtonChanged($event)"
                                >
                                </ejs-radiobutton>
                              </div>
                              <div class="field mb-3">
                                <ejs-radiobutton 
                                  name="addressType" 
                                  label="Site Address"
                                  id="onSiteAdress"
                                  value="onSiteAdress"
                                  [(ngModel)]="checkType"
                                  [checked]="onsiteAddress?.addressType === 'onSiteAdress'" 
                                  (change)="onsiteAddress.addressType = $event.value;
                                  radioButtonChanged($event)"
                                >
                                </ejs-radiobutton>
                              </div>
                            </div>

                            <div class="col-12" *ngIf="!onsiteFlag">
                              <div class="row">
                                <div class="field mb-1 col-11">
                                  <label for="">{{customerAddress? 'Customer ': ''}}Address:</label>
                                  <div  *ngIf="!purchaseOrderData?.partsOrderId">
                                    <ejs-dropdownlist *ngIf="purchaseOrderData?.addressCode"  
                                    #addressesDropdown
                                    [placeholder]="'Select Address'"
                                    [fields]="{text: 'address', value: 'addressCode'}" [dataSource]="customerAddress ? [{address: customerAddress.addressName, addressCode: customerAddress.addressCode }]:technicianAddresses"
                                    [allowFiltering]="true" [value]="purchaseOrderData?.addressCode"
                                    [enabled]="purchaseOrderData?.status === 'Open' && !customerAddress"
                                    (change)="onAddressChange($event)"></ejs-dropdownlist>
                                  </div>
                                  @if(!customerAddress) {
                                    <a class="advanced-search-button pos-abs-r--20-b-10" style="cursor: pointer;" *ngIf="!purchaseOrderData?.partsOrderId">
                                      <i class="fas fa-info-circle adv-search-icon" (click)="this.showCustomerAdvancedSearchPopup = true;" onkeypress=""></i>
                                    </a>
                                  }
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="col-12">
                              <div class="mb-2 mt-1" *ngIf="purchaseOrderData?.addressCode">
                                <div class="custom-border p-1">
                                  {{purchaseOrderData?.addressName}}<br>
                                  {{purchaseOrderData?.addressCode}}<br>
                                  {{purchaseOrderData?.address}}<br>
                                  {{purchaseOrderData?.city}}, {{purchaseOrderData?.state}}, {{purchaseOrderData?.zipCode}}<br>
                                  {{purchaseOrderData?.country}}<br>
                                  {{purchaseOrderData?.phoneNumber}}<br>
                                  {{purchaseOrderData?.email}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-12">
                          <div class="field mb-2 position-relative">
                            <label for="" class="m-8px">PO Reference:</label><br>
                            <div style="height: 22px; margin-bottom: 4px;">
                              <input *ngIf="!poRefFlag" [(ngModel)]="purchaseOrderData.alternatePORef" maxlength="30" class="input-mod">
                              <span *ngIf="poRefFlag" class="mt-2">{{purchaseOrderData?.alternatePORef}}</span>
                            </div>
                            <div class="border-line"></div>
                            <label for="" class="mt-3">Payment Method</label><span class="required-asterik">*</span>
                            <ejs-dropdownlist #configurationsDDL 
                                [placeholder]="'Select payment method'"
                                [dataSource]="paymentOptionsDDL" 
                                [value]="purchaseOrderData?.paymentMethod"
                                [allowFiltering]="true" 
                                [enabled]="(purchaseOrderData?.status === 'In progress' || purchaseOrderData?.status === 'Payment Pending' || purchaseOrderData?.status === 'Open')"
                                (change)="onPaymentMethodChange($event)">
                              <!--"purchaseOrderData['paymentMethod']=$event.value;"-->
                            </ejs-dropdownlist>
                          </div><br>
                          <label for="">Payment Status</label><br>
                          <span class="mt-2">{{purchaseOrderData?.paymentStatus}}</span>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-12 p-0">
                          <div class="custom-border p-3">
                            <label for="" class="mb-1 mt-1">Amount</label> <span class="float-right mb-1 mt-1">{{totalNetPrice?.toFixed(2)}}</span><br>
                            <label for="" class="mb-1 mt-2">Shipping cost</label><span class="float-right mb-1 mt-1">{{purchaseOrderData?.shippingCost?.toFixed(2)}}</span><br>
                            <label for="" class="mb-1 mt-2">Tax</label><label for="" *ngIf="noTaxFlag" class="ml-1 color-red">(No Tax Record found)</label> 
                            <span class="float-right mb-1 mt-1">{{tax?.toFixed(2)}}</span>
                            <br>
                            <label for="" class="mb-1 mt-2">Total amount</label><span class="float-right mb-1 mt-1">{{(totalNetPrice + tax + purchaseOrderData?.shippingCost).toFixed(2)}}</span><br>
                            <label for="" class="mb-1 mt-2"><strong>Currency</strong></label><span class="float-right mb-1 mt-1"><strong>{{currency}}</strong></span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div id="cart-tracking-tab" [ngClass]="{'active show': tab === 'tracking'}" class="tab-pane active show">
                <div class="card">
                  <div class="card-header d-flex pl-0" data-toggle="collapse" href="#ordered-items-collapse-details">
                    <a class="card-link flex-fill">Ordered Items</a>
                    <i class="fas fa-chevron-up"></i>
                  </div>
                  <div id="ordered-items-collapse-details" class="collapse show container-fluid min-height">
                    <!-- ordered item grid here -->
                    <div class="row container-fluid mt-3 m-0 p-0">
                      <div class="w-100">
                        <ejs-grid
                          #OrderedItemsGrid 
                          id="ordered-items-grid" 
                          [height]="trackingGridHeight"
                          [dataSource]="orderedTabDataSource" 
                          [allowPaging]="false" 
                          [allowFiltering]="true"
                          [allowSorting]="true" 
                          [allowResizing]="true" 
                          [allowReordering]="true"
                          [showColumnChooser]="true" 
                          [allowExcelExport]="true"
                          [enablePersistence]="false"
                          [filterSettings]="{ type: 'Menu' }" 
                          (commandClick)="onCommandColumnClickOrderedItems($event)"
                          (rowDataBound)="onRowDataBoundOrderedItems($event)">
                          <e-columns>
                            <e-column *ngFor="let column of orderedGridcolumns;" [field]="column.field"
                              [headerText]="column.headerText" [clipMode]="'EllipsisWithTooltip'" [type]="column.type"
                              [edit]="column.edit" [width]="column.width"
                              [showInColumnChooser]="column.showInColumnChooser ?? true"
                              [filter]="column.filter ? column.filter : { type: 'Menu' }"
                              [format]="column.format ? column.format : null" [textAlign]="column.textAlign"
                              [visible]="column.visible" [isPrimaryKey]="column.isPrimaryKey || false"
                              [validationRules]="column?.validationRules || null"
                              [commands]="column?.commands ? column.commands : null" [allowEditing]="column.allowEditing"
                              [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
  
                              <ng-template *ngIf="column.field === 'status'" #template let-data>
                                <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                              </ng-template>
  
                            </e-column>
                          </e-columns>
                        </ejs-grid>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" *ngIf="purchaseOrderData?.status !== 'Submitted'">
                  <div class="card-header d-flex pl-0" data-toggle="collapse" href="#tracking-collapse-items">
                    <a class="card-link flex-fill">Tracking Updates</a>
                    <i class="fas fa-chevron-up"></i>
                  </div>
                  <div id="tracking-collapse-items" class="collapse show container-fluid min-height">
                    <!-- Tracking item grid here -->
                    <div class="row container-fluid mt-3 m-0 p-0">
                      <div class="w-100">
                        <ejs-grid
                            #TrackingUpdatesGrid 
                            id="tracking-updates-grid" 
                            [height]="trackingGridHeight"
                            [dataSource]="trackingTabDataSource" 
                            [allowPaging]="false" 
                            [allowFiltering]="true"
                            [allowSorting]="true" 
                            [allowResizing]="true" 
                            [allowReordering]="true"
                            [showColumnChooser]="true" 
                            [allowExcelExport]="true"
                            [enablePersistence]="false" 
                            [filterSettings]="{ type: 'Menu' }" 
                            (rowDataBound)="onRowDataBoundTracking($event)"
                            (commandClick)="onCommandColumnClickTracking($event)"
                          >
                          <e-columns>
                            <e-column *ngFor="let column of trackingUpdatesColumns;" 
                              [field]="column.field"
                              [headerText]="column.headerText" 
                              [clipMode]="'EllipsisWithTooltip'"
                              [type]="column.type"
                              [edit]="column.edit" 
                              [width]="column.width"
                              [showInColumnChooser]="column.showInColumnChooser ?? true"
                              [filter]="column.filter ? column.filter : { type: 'Menu' }"
                              [format]="column.format ? column.format : null" [textAlign]="column.textAlign"
                              [visible]="column.visible" 
                              [isPrimaryKey]="column.isPrimaryKey || false"
                              [commands]="column?.commands ? column.commands : null" 
                              [allowEditing]="column.allowEditing"
                              [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                              <ng-template *ngIf="column.field === 'status'" #template let-data>
                                <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                              </ng-template>
                              <ng-template *ngIf="column.field === 'trackingId'" #template let-data>
                                <a href="javascript:" (click)="redirectToUPS(data.trackingId)">{{data.trackingId}}<i *ngIf="data.trackingId" class="fas fa-share-square pl-1 custom-icon" aria-hidden="true"></i></a>
                              </ng-template>
                            </e-column>
                          </e-columns>
                        </ejs-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="cart-links-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'links'}">
                <app-links-tab-shared
                    [links]="links"
                    (onLinkIdClick)="onLinkIdClick.emit($event)"
                ></app-links-tab-shared>
              </div>
              
              <div id="cart-attachments-tab" class="tab-pane active show"
                  [ngClass]="{'active show': tab === 'attachments'}">
                  <app-attachments-tab 
                      [moduleId]="purchaseOrderData?.partsOrderId"
                      module="PurchaseOrder"
                      [isEditable]="true"
                      [attachments]="purchaseOrderData?.attachments"
                      (attachmentsUpdated)="onAttachmentsUpdate($event)"
                  ></app-attachments-tab>
              </div>

              <div id="cart-history-tab" class="tab-pane active show" [ngClass]="{'active show': tab === 'history'}">
                <app-history-tab-shared 
                  [history]="historyData"
                ></app-history-tab-shared>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
        <button [disabled]="purchaseOrderData?.paymentMethod === 'On Account'"
        *ngIf="purchaseOrderData?.paymentStatus !== 'Paid' && purchaseOrderData?.status !== 'Canceled'" type="button"
          class="btn btn-sm btn-success close-modal" (click)="payAndSubmit()">Pay & Submit</button>
        <button [disabled]="purchaseOrderData?.paymentMethod ===  'Credit Card'"
        *ngIf="purchaseOrderData?.paymentStatus !== 'Paid'"
          type="button" class="btn btn-sm btn-success close-modal"  (click)="submitOnly()">Submit</button>
      </div>
    </div>
  </div>
</div>

<ng-template #columnChooser>
  <app-column-chooser [columns]="columns" [grid]="grid" [showColumnChooser]="showColumnChooser"
    (onClose)="showColumnChooser = false"></app-column-chooser>
</ng-template>

<app-config-advanced-search
  *ngIf="showCustomerAdvancedSearchPopup"
  [serviceDepartment]=""
  (onClose)="onCustomerAdvancedSearchPopupClose($event)"
></app-config-advanced-search>

<!--conway-chase payment Tech-->
<app-iframe-popup *ngIf="showChasePaymentIframe"
  [serviceOrderId]="purchaseOrderId"
  [serviceOrderData]="data"
  [isPurchaseOrder]="true"
  (ccPaymentCompleted)="ccPaymentCompleted($event)"
  (ccPaymentAuthcode)="ccPaymentAuthcode($event)"
  (ccPaymentCancelForPurchaseOrder)="ccPaymentCancel($event)"
  (ccPaymentCancel)="ccPaymentCancel($event)">
</app-iframe-popup>


