import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    ActionEventArgs,
    CommandClickEventArgs,
    CommandColumnService,
    EditService,
    ExcelExportProperties,
    FilterService,
    GridComponent,
    ResizeService,
    RowDataBoundEventArgs,
    RowDDService,
    SortService,
    ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { CommonService } from '../../services/common.service';
import { PageService } from '../../services/page.service';
import { Query } from '@syncfusion/ej2-data';
import { getCurrentDate } from '../../utils/date-functions';
import { CustomAdaptor, excelQueryCellInfo, getExcelDataSource, handleClearFilter, handleGridExcelExport, updateFilterIcon } from '../../utils/grid-functions';
import { DataManager } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { ChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ApiResponse, UserRoles } from '../../models/app.model';
import { OrderTypes } from 'src/app/shared/models/app.model';
import { GridElement } from 'src/app/modules/builder/models/grid.model';
import { GridPage } from 'src/app/modules/builder/models/main.model';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

const BASE_URL = environment.apiUrl;

@Component({
    selector: 'app-syncfusion-grid',
    templateUrl: './syncfusion-grid.component.html',
    styleUrls: ['./syncfusion-grid.component.scss'],
    providers: [
        EditService,
        FilterService,
        ResizeService,
        SortService,
        CommandColumnService,
        ToolbarService,
        RowDDService
    ]
})
export class SyncfusionGridComponent implements OnInit {

    @Input() pageId: string;
    @Input() gridProps: GridElement;
    page: GridPage;
    grid: GridComponent;
    showColumnChooser: boolean = false;
    showAdjustmentOrderPopup: boolean = false;
    showAdjustmentOrderListPopup: boolean = false;
    tab: string = 'details';
    @Input()  gridHeight: number = window.innerHeight - 330;
    showComments: boolean = false;
    showLoader: boolean = false;
    gridData: any;

    gridQuery = null;

    USER: any;
    isManager: boolean;
    isCsa: boolean;

    popupData = {};

    @Input() resourceList: any;
    @Input() showAdjustmentOrdersList: boolean = false;
    @Input() selectedResource: any = [];
    @Input() adjustmentOrdersList: any = [];

    @ViewChild('columnChooser') columnChooser: TemplateRef<any>;
    @ViewChild('clickableId') clickableColumnTemplate: TemplateRef<any>;
    @ViewChild('resourceDropdown') resourceDropdown;
    @ViewChild('resourceDropdownElement') resourceDropdownElement: DropDownListComponent;
    @ViewChild('statusColumn') statusColumn: TemplateRef<any>;
    linkedPopupData: { id: string; type: string; } = null;
    listUpdate: boolean = false;
    isAdmin: boolean;

    @ViewChild('msGrid') set msGrid(gridComponent: GridComponent) {
        if ( gridComponent ) {
            this.grid = gridComponent;
            this.setGridToolbar();
        }
    }

    constructor(
        private pageService: PageService,
        public commonService: CommonService
    ) {
        this.USER = commonService.USER;
        this.isManager = [UserRoles.conwayServiceManager, UserRoles.klNationalMGR, UserRoles.klServiceManager].includes(this.USER.role);
        this.isAdmin = this.USER.role === UserRoles.administratorRole;
        this.isCsa= commonService.USER.role === UserRoles.csa;
    }

    ngOnInit(): void {
        if ( this.gridProps ) {
            if ( this.gridProps.elementLabel === 'Adjustment Orders' ) {
                this.gridQuery = new Query().addParams('company', this.USER.company);
            } else {
                this.gridQuery = new Query().where('isDeleted', 'notequal', true);
            }
            this.setGridColumns(this.gridProps);
            this.gridData = this.gridProps.dataSource;
        } else {
            this.getPopup();
        }
    }

    getPopup() {
        this.showLoader = true;
        this.pageService.getPageById(this.pageId)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful ) {
                const returnedPage = res.result;
                this.page = {
                    ...returnedPage,
                    elements: typeof returnedPage.elements === 'string' ? JSON.parse(returnedPage.elements) : returnedPage.elements
                };
                const grid: GridElement = this.page.elements[0];
                if ( grid.elementLabel === 'Adjustment Orders' ) {
                    this.gridQuery = new Query().addParams('company', this.USER.company);
                } else {
                    this.gridQuery = new Query().where('isDeleted', 'notequal', true);
                }
                if ( grid.api ) {
                    if ( grid.allowPaging ) {
                        grid.pageSettings = { pageSize: grid.pageSize };
                        this.gridProps = grid;
                        this.gridData = new DataManager({
                            url: `${BASE_URL+grid.api}`,
                            adaptor: new CustomAdaptor()
                        });
                        this.showLoader = false;
                    } else {
                        grid.dataSource = [];
                        if(this.showAdjustmentOrdersList){
                            this.adjustmentOrdersList.map( (item) => {
                                if ( item.createdDate ) { item.createdDate = new Date(item.createdDate) }
                                if ( item.orderDate ) { item.orderDate = new Date(item.orderDate) }
                                if ( item.lastUpdatedDate ) { item.lastUpdatedDate = new Date(item.lastUpdatedDate) }
                            });
                            this.gridData = this.adjustmentOrdersList;
                            this.gridProps = grid;
                            this.showLoader = false;
                        } else{
                            this.getPageData(grid);
                        }
                    }
                } else {
                    this.showLoader = false;
                }
                this.setGridColumns(grid);
            } else {
                this.showLoader = false;
            }
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    getPageData(grid: GridElement) {
        const filterData = {
            requiresCounts: true,
            onPage: {
                pageIndex: 1,
                pageSize: 20
            },
            skip: 0,
            take: 20,
            onWhere: {
                ignoreAccent: false,
                isComplex: true,
                condition: "and",
                predicates: []
            }
        };
        // if ( searchString ) {
        //     filterData['onWhere'] = {
        //         ignoreAccent: false,
        //         isComplex: true,
        //         condition: "and",
        //         predicates: [{
        //             condition: 'or',
        //             ignoreAccent: false,
        //             isComplex: false,
        //             field: columnName,
        //             operator: "contains",
        //             value: searchString,
        //             ignoreCase: true,
        //             oprtr: "contains"
        //         }]
        //     };
        // }
        let apiType = 'ShipmentOrder/GetPendingApprovalExceptions?status=Pending Approval' === grid.api ? 'get' : 'post';
        this.commonService.callApi(grid.api, apiType, filterData)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful) {
                if(res.result && res.result.length ){
                    res.result.map( (item) => {
                        if ( item.createdDate ) { item.createdDate = new Date(item.createdDate) }
                        if ( item.orderDate ) { item.orderDate = new Date(item.orderDate) }
                        if ( item.lastUpdatedDate ) { item.lastUpdatedDate = new Date(item.lastUpdatedDate) }
                    });
                    this.gridData = res.result;
                } else {
                    this.gridData = [];
                }
            }
            this.gridProps = grid;
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    setGridColumns(grid: GridElement) {
        grid.columns.forEach(column => {
            column.clipMode = column.field === 'Actions' ? '' : 'EllipsisWithTooltip';
            if ( column.columnType ) {
                if ( column.columnType.includes('ClickableTemplate') ) {
                    column.template = this.clickableColumnTemplate;
                } else {
                    column.template = this.statusColumn;
                }
            }
            if ( column.type === 'date' ) {
                column.format = { type: 'date', format: 'd MMM, y'}
            } else if ( column.commands && column.commands.length ) {
                column.allowFiltering = false;
                column.allowSorting = false;
                column.width = column.commands.length*35 + 40;
            }
            column.disableHtmlEncode = false;

            if(column.field === 'technicianName' && !this.isManager && !this.isCsa && !this.isAdmin){
                column.visible = false;
                column.showInColumnChooser = false;
            }

            if(column.field === 'Actions' && !this.isManager){
                grid.columns.pop();
            }
        });
    }

    onClickableClick(data: any) {
        this.showLoader = true;
        if(data.column.field === "shipmentOrderId"){
            this.openShipmentOrderPopup(data);
        } else if(data.column.field === "shipmentOrderLN"){
            this.openShipmentOrderPopup(data);
        } else if(data.column.field === "adjustmentOrderNumber"){
            this.openAdjustmentOrderPopup(data[data.column.field]);
        }
    }

    openShipmentOrderPopup(data){
        this.linkedPopupData = {
            id: data['shipmentOrderId'],
            type: OrderTypes.shipmentOrder
        };
        this.showLoader = false;
    }

    openAdjustmentOrderPopup(orderId){
        this.pageService.getAdjustmentOrderById(orderId)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful && res.result ) {
                this.popupData = res.result;
                this.showAdjustmentOrderPopup = true;
            } else {
                this.commonService.showNotification('error', res.message || 'There was a problem getting the record, please try again!');
            }
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    setGridToolbar() {
        if ( this.gridProps.showToolbar ) {
            if ( this.gridProps.toolbarItems && this.gridProps.toolbarItems.length ) {
                this.gridProps.toolbarItems.map(item => {
                    if ( item.options ) {
                        item.htmlAttributes = {
                            options: item.options
                        };
                    }
                });
            }
            const toolbar = [];
            toolbar.unshift({ id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns'});
            if ( this.gridProps.toolbarItems ) {
                if( this.pageId === "PG0000148")    {
                    if(this.isManager){ toolbar.push(...this.gridProps.toolbarItems); }
                } else{
                    toolbar.push(...this.gridProps.toolbarItems);
                }
            }
            // toolbar.push( { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' });
            toolbar.push('Search');
            if ( this.gridProps.allowExcelExport && this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
                toolbar.push({ text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' })
            }

            toolbar.push({ text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' })
            this.grid.toolbar = toolbar;
        }
    }

    onGridCreated() {
        if (!this.grid.searchSettings['properties'].key && this.grid.filterSettings.columns && this.grid.filterSettings.columns.length && this.grid.filterSettings.columns.findIndex(col => col['properties'].field !== 'warehouse') === -1) {
            document.getElementById(this.grid.element.id).getElementsByClassName('grid-filter-icon')[0]?.classList.remove('filtered');
        } else {
            updateFilterIcon({ requestType: 'filtering' }, this.grid);
        }
    }

    onGridActionComplete(args: ActionEventArgs, grid: GridComponent) {
        if (!grid.searchSettings['properties'].key && grid.filterSettings.columns && grid.filterSettings.columns.length && grid.filterSettings.columns.findIndex(col => col['properties'].field !== 'warehouse') === -1) {
            document.getElementById(grid.element.id).getElementsByClassName('grid-filter-icon')[0]?.classList.remove('filtered');
        } else {
            updateFilterIcon(args, grid);
        }
    }

    onExcelQueryCellInfo(args: any) {
        excelQueryCellInfo(args);
    }

    onToolbarClick(args: any, grid: GridComponent) {
        if ( args.item.id === 'excel-export' ) {
            handleGridExcelExport(grid, `${this.gridProps.elementLabel}}`);
        } else if ( args.item.id === 'clear-filter' ) {
            handleClearFilter(grid);
        } else if( args.item.id === "add-adjustment-order" && this.grid.dataSource ){
            let selectedExceptions : any = this.grid.getSelectedRecords();
            if(selectedExceptions.findIndex(x => x.exceptionStatus === 'Adjusted') > -1){
                this.commonService.showNotification('warning', 'Adjusted exception is added in the selected list. Kindly remove adjusted exceptions from the selection.');
            } else {
                if(selectedExceptions.findIndex(x => x.exceptionStatus !== 'Approved') > -1){
                    this.commonService.showConfirmation(`Do you want to Approve all selected exceptions?`, 'Yes', 'No', 'Approve All Exceptions?')
                    .then( result => {
                        if ( result.isConfirmed ) {
                            this.showLoader = true;
                            this.approveAllExceptions(selectedExceptions)
                        }
                    });
                } else {
                    this.showLoader = true;
                    this.approveAllExceptions(selectedExceptions)
                }
            }
        } else if ( args.item.options ) {
            const selectedExceptions = this.grid.getSelectedRecords();
            if ( selectedExceptions && selectedExceptions.length ) {
                this.popupData = {
                    status: 'Open',
                    warehouse: this.USER.warehouse,
                    createdDate: new Date(),
                    fields: [
                        { key: 'warehouse', value: this.USER.warehouse },
                        { key: 'status', value: 'Open' },
                        { key: 'electronicAdjustmentOrderLines', value: this.grid.getSelectedRecords() }
                    ]
                };
                this.showAdjustmentOrderPopup = true;
            } else {
                this.commonService.showNotification('info', 'Please select atleast one exception to continue');
            }
        } else if (args.item.id === 'refresh') {
            if ( this.gridProps ) {
                if ( this.gridProps.elementLabel === 'Adjustment Orders' ) {
                    this.gridQuery = new Query().addParams('company', this.USER.company);
                } else {
                    this.gridQuery = new Query().where('isDeleted', 'notequal', true);
                }
                this.setGridColumns(this.gridProps);
                this.gridData = this.gridProps.dataSource;
            } else {
                this.getPopup();
            }
        }
    }

    approveAllExceptions(selectedExceptions){
        this.commonService.callApi('ShipmentOrder/UpdateExceptionStatus?status=Approved', 'post', selectedExceptions)
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful ) {
                this.commonService.callApi('ShipmentOrder/CreateAdjustmentOrder', 'post', selectedExceptions)
                .subscribe( (response: ApiResponse) => {
                    if ( response.isSuccessful ) {
                        this.adjustmentOrdersList = response.result;
                        setTimeout(() => {
                            this.showAdjustmentOrderListPopup = true;
                        }, 500);
                        this.commonService.showNotification('success', response.message || 'Status updated! successfully. Adjustment orders created');
                    } else {
                        this.commonService.showNotification('error', response.message || 'Something went wrong. Kindly, try again.');
                        this.showLoader = false;
                    }
                }, (error: HttpErrorResponse) => {
                    this.showLoader = false;
                });
            } else {
                this.commonService.showNotification('error', res.message || 'Something went wrong. Kindly, try again.');
                this.showLoader = false;
            }
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    onCommandClick(args: CommandClickEventArgs) {
        if ( args && args.commandColumn && args.commandColumn['options'] ) {
            const options = typeof args.commandColumn['options'] === 'string' ? JSON.parse(args.commandColumn['options']) : args.commandColumn['options']; //JSON.parse(args.commandColumn['options']);
            if ( options.function ) {
                this[options.function](options, args.rowData);
            } else if ( options.action && options.action === 'popup' ) {
                this.tab = options.tab;
                this.showComments = options.isCommentButton !== undefined ? options.isCommentButton : false;
                this.showAdjustmentOrderPopup = true;
            }

        }
    }

    onRowDataBound(args: RowDataBoundEventArgs) {
        const data = args.data;
        const $row = args.row;

        if ( this.gridProps.allowActions ) {
            const commentsIcon = $row.getElementsByClassName('comments');
            const attachmentsIcon = $row.getElementsByClassName('attachments');
            if ( commentsIcon && commentsIcon.length ) {
                if ( data['commentCount'] ) {
                    commentsIcon[0].classList.add('color-orange');
                } else {
                    commentsIcon[0].classList.remove('color-orange');
                }
            }
            if ( attachmentsIcon && attachmentsIcon.length ) {
                if ( data['attachmentCount'] ) {
                    attachmentsIcon[0].classList.add('color-green');
                } else {
                    attachmentsIcon[0].classList.remove('color-green');
                }
            }

            if($row.querySelector('[title="On-Hold"]') && $row.querySelector('[title="Approve"]')){
                if(['Adjusted', 'Approved'].includes(data['exceptionStatus'])){
                    $row.querySelector('[title="On-Hold"]').classList.add('disabled');
                    $row.querySelector('[title="Approve"]').classList.add('disabled');
                } else if(data['exceptionStatus'] === 'Onhold'){
                    $row.querySelector('[title="On-Hold"]').classList.add('disabled');
                    $row.querySelector('[title="Approve"]').classList.remove('disabled');
                }else {
                    $row.querySelector('[title="On-Hold"]').classList.remove('disabled');
                    $row.querySelector('[title="Approve"]').classList.remove('disabled');
                }
            }

        }
    }

    onResourceDropdownCreated() {
        this.grid.filterByColumn('warehouse', 'equal', this.selectedResource, 'and', false, false);
    }

    onResourceChanged(args: ChangeEventArgs, grid: GridComponent) {
        this.selectedResource = args.value;
        this.grid.filterByColumn('warehouse', 'equal', this.selectedResource, 'and', false, false);
    }

    approveFunction(options, rowData) {
        this.showLoader = true;
        this.commonService.callApi(options.api+'?status=Approved', options.type, [rowData])
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful ) {
                this.adjustmentOrdersList = res.result;
                this.commonService.showNotification('success', res.message || 'Status updated! successfully.');
                const grid: GridElement = this.page.elements[0];
                this.getPageData(grid);
            } else {
                this.commonService.showNotification('error', res.message || 'Something went wrong. Kindly, try again.');
                this.showLoader = false;
            }
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    onholdFunction(options, rowData) {
        this.showLoader = true;
        this.commonService.callApi(options.api+'?status=Onhold', options.type, [rowData])
        .subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful ) {
                this.commonService.showNotification('success', res.message || 'Status updated! successfully.');
                const grid: GridElement = this.page.elements[0];
                this.getPageData(grid);
            } else {
                this.commonService.showNotification('error', res.message || 'Something went wrong. Kindly, try again.');
                this.showLoader = false;
            }
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    deleteFunction(options, rowData) {
        if ( options.showConfirmation ) {
            this.commonService.showConfirmation('Are you sure? This cannot be undone')
            .then( result => {
                if ( result.isConfirmed ) {
                    this.delete(options, rowData);
                }
            });
        } else {
            this.delete(options, rowData);
        }
    }

    delete(options, rowData) {
        if ( options.api ) {
            this.showLoader = true;
            this.commonService.callApi(options.api, options.type, [rowData])
            .subscribe( (res: ApiResponse) => {
                if ( res.isSuccessful ) {
                    this.getPageData(this.gridProps);
                } else {
                    this.showLoader = false;
                }
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
            });
        } else {
            const dataSource = this.gridData.slice();
            const itemToBeDeleted = dataSource.find(item => item[options.uniqueId] === rowData[options.uniqueId]);
            if ( itemToBeDeleted ) {
                itemToBeDeleted.isDeleted = true;
            }
            this.gridData = dataSource;
        }
    }

    adjustmentOrderListPopupClosed(event){
        this.showLoader = true;
        this.showAdjustmentOrderListPopup = false;
        this.adjustmentOrdersList = [];
        const grid: GridElement = this.page.elements[0];
        this.listUpdate = true;
        this.getPageData(grid);
    }

}
