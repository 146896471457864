import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ApiResponse, LinksEnum } from '../../models/app.model';
import { CommonService } from '../../services/common.service';
import { handleClearFilter, updateFilterIcon } from '../../utils/grid-functions';

@Component({
    selector: 'app-links-tab-shared',
    templateUrl: './links-tab.component.html',
    styleUrls: ['./links-tab.component.scss']
})
export class LinksTabSharedComponent implements OnInit {

    gridColumns: any;
    id: any;

    showColumnChooser: boolean;
    showLoader: boolean;

    @ViewChild('linksTabGrid') linksTabGrid: GridComponent;
    @ViewChild('linksTabGridColumnChooser') columnChooser;

    @Input() serviceOrderId: string = '';
    @Input() links;
    @Output() onLinkIdClick: EventEmitter<any> = new EventEmitter();
    showPartsOrderPopup: boolean = false;

    partsOrderId: string = "";
    partsOrderData: object = {};
    itemsData: any = [];

    constructor(
        private commonService: CommonService,
        private inventoryService: InventoryService
    ) { }

    ngOnInit(): void {
        this.gridColumns = this.getColumns();
    }

    onGridCreated() {
        this.linksTabGrid.toolbar = [
            { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
            'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = this.linksTabGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.linksTabGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
        }
        updateFilterIcon({requestType: 'filtering'}, this.linksTabGrid);
    }

    toolbarClick(args: ClickEventArgs, grid: GridComponent) {
        if (args.item.id === 'grid_excelexport') {
            grid.excelExport();
        } else if (args.item.id === 'grid_pdfexport') {
            grid.pdfExport();
        } else if (args.item.id === 'clear-filter') {
            handleClearFilter(grid);
        } 
    }

    getColumns() {
        return [
            { field: 'srNo', headerText: 'Sr No.', type: 'number', format: 'n', textAlign: 'Left', width: 120 },
            { field: 'orderId', headerText: 'Order ID', type: 'number', format: 'n',textAlign: 'Left', width: 128},
            { field: 'customer', headerText: 'Customer', type: 'string', textAlign: 'Left', width: 160 , filter: { type: 'Excel' }},
            { field: 'lnOrderId', headerText: 'LN Order ID', type: 'number',format: 'n', textAlign: 'Left', width: 128},
            { field: 'orderType', headerText: 'Order Type', type: 'string', textAlign: 'Left', width: 160 , filter: { type: 'Excel' }},
            { field: 'origin', headerText: 'Order Origin', type: 'string', textAlign: 'Left', width: 160 , filter: { type: 'Excel' }},
            { field: 'quantity', headerText: 'Quantity', type: 'string', textAlign: 'Left', width: 128, filter: { type: 'Excel' } },
            { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left', filter: { type: 'Excel' } },
            { field: 'createdDate', headerText: 'Order Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250 },
            { field: 'lastUpdatedDate', headerText: 'Last Updated Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, visible: false },
            { field: 'estimatedArrival', headerText: 'Estimated Arrival', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, visible: false }
        ];
    }

    getStatusClass(status: string) {
        return this.commonService.getStatusClass(status);
    }

    actionComplete(args,) {
        updateFilterIcon(args, this.linksTabGrid);
    }

    openPartsOrder(data){
        this.partsOrderId = data.orderId;
        this.inventoryService.getPartsOrderById(this.partsOrderId)
        .subscribe((res: ApiResponse) => {
            this.partsOrderData = res.result;
            this.itemsData = res.result.itemList;
            this.showPartsOrderPopup = true;
        });
    }

    partsOrderPopupClose() {
        this.showPartsOrderPopup = false;
        this.partsOrderData = null
    }

    partsOrderClose(){
        this.showLoader = true;
        this.showPartsOrderPopup = false;
        this.partsOrderData = null
        this.commonService.getLinkedRecords(LinksEnum.serviceOrder, this.serviceOrderId)
        .subscribe((res: ApiResponse) => {
            if(res.isSuccessful){
                this.links = res.result;
                this.linksTabGrid.refresh();
                this.commonService.showNotification('success', res.message);
            } else {
                this.commonService.showNotification('error', res.message);
            }
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
            this.commonService.showNotification('error', error.message);
        });
    }
}
