import { Component, ViewChild, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { gridNames } from 'src/app/shared/models/app.model';
import { GridComponent, ExcelExportProperties, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { CycleCountService } from '../../../cycle-counts.service';
import { getValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cycle-counts-upload-detail',
  templateUrl: './cycle-counts-upload-detail.component.html',
  styleUrls: ['./cycle-counts-upload-detail.component.scss']
})
export class CycleCountUploadDetailPopupComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  @Output() refreshMainGrid = new EventEmitter();

  @Input() showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountUploadDetailGrid;
  columns: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 420;
  showPopup: boolean = false;
  object;
  query: Query;
  public pageSettings: Object
  rowCycleCountData = null
  selectedTab: string = 'details';
  startDateElement: HTMLInputElement;
  startDateObject: DatePicker;
  showAddPopup: boolean = false;
  isExpanded = true
  aggreagtes
  @Output() browseFile: EventEmitter<any> = new EventEmitter();
  @Output() reloadPopupData: EventEmitter<any> = new EventEmitter();
  @Input() uploadDetailData
  @Input() cycleCountId

  public selectionOptions: SelectionSettingsModel;

  @ViewChild('cycleCountUploadDetailGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  USER: any;
  preference;
  showWareHouseGrid: boolean = false;
  @Input() fileInvalid: boolean = false;

  constructor(
    private commonService: CommonService,
    private cycleCountService: CycleCountService,
  ) {
    this.USER = this.commonService.USER;
  }

  ngOnInit(): void {
    this.selectionOptions = { type: 'Multiple', persistSelection: true, checkboxOnly: true };
    this.pageSettings = { pageSizes: [20, 50, 100, 500], pageSize: 20 };
    this.grid = null;
    this.getColumns();
    this.aggreagtes = [{
      columns: [
        {
          type: 'Sum',
          field: 'warehouse',
          format: 'N2',
          footerTemplate: 'Total'
        },
        {
          type: 'Count',
          field: 'itemCode',
          format: 'N',
          footerTemplate: '${Count}'
        },
        {
          type: 'Sum',
          field: 'count',
          format: 'N',
          footerTemplate: '${Sum}'
        }
      ]
    }];
    this.showLoader = false;
  }

  excelQueryCellInfo(args) {
    // applying the color and border color for  
    if (getValue('itemCodeIsValid', args.data) == false || getValue('countIsValid', args.data) == false || getValue('warehouseIsValid', args.data) == false) {
      args.style = { backColor: '#FFFF00' };
    }
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { field: 'warehouse', headerText: 'Warehouse', type: 'String', textAlign: 'Left', width: 100, isPrimaryKey: true },
        { field: 'itemCode', headerText: 'Item', type: 'String', allowEditing: false, textAlign: 'Left', width: 100, visible: true },
        { field: 'count', headerText: 'Count', type: 'String', textAlign: 'Left', width: 100 },
      ]
    };
  }

  setGridToolbar() {
    this.grid.toolbar = [
      { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
      { id: 'refresh', align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
      'Search',
      { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  queryCellInfo(args) {
    if (args.column.field) {
      if (getValue('itemCodeIsValid', args.data) == false || getValue('countIsValid', args.data) == false || getValue('warehouseIsValid', args.data) == false) {
        args.cell.bgColor = '#FFFF00';
      }
    }
  }

  expand() {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded) {
      this.gridHeight = window.innerHeight - 220;
    } else {
      this.gridHeight = window.innerHeight - 420;
    }
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'excel-export') {
      handleGridExcelExport(grid, 'Upload Detail Data');
    } else if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    } else if (args.item.id === 'refresh') {
      this.grid.refresh();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountUploadDetailGrid, value: '' }).subscribe((response) => {
            handleClearFilter(grid);
            this.showLoader = false;
          });
        }
        else {
          this.showLoader = false;
          return
        }
      });
    }
  }

  fileUpload() {
    if (this.fileInvalid) {
      Swal.fire({
        icon: 'error',
        title: 'File Invalid',
        text: 'Please fix the invalid fields before uploading the file!',
      })
    } else {
      this.showLoader = true
      this.cycleCountService.uploadCycleCountDetail(this.cycleCountId, this.uploadDetailData).subscribe((res: any) => {
        this.reloadPopupData.emit()
        this.onClose.emit();
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.commonService.showNotification('error', error.message)
        throw error;
      });
    }
  }

  close() {
    this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
      .then(result => {
        if (result.isConfirmed) {
          this.onClose.emit();
        }
      })
  }

}
