import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    Input,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { SwitchComponent } from '@syncfusion/ej2-angular-buttons';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeService } from 'src/app/modules/home/modules/employees/services/employee.service';
import { ApiResponse, Companies, LinksEnum, UserRoles } from 'src/app/shared/models/app.model';
import { Attachment } from 'src/app/shared/models/attachment.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { getUTCDate } from 'src/app/shared/utils/date-functions';
import { Invoice, InvoiceStatuses, PaymentMethods } from '../../models/payment-invoicing.model';
import { PageElement } from '../../models/popup.model';
import { CallGroups, CostTypes, InvoicePopupOpenedFrom, IscEmployeeModel, ModeList, serviceOrderStatuses } from '../../models/service-order.model';
import { ExecutionTabService } from '../../services/execution-tab.service';
import { ServiceOrderService } from '../../services/service-order.service';

import { TechnicianDropdown, StatusDropdown } from '../../structure/structure';
import { IscSoDetailsTabComponent } from './isc-so-details-tab/isc-so-details-tab.component';
import { IscExecutionTabComponent } from '../../components/isc-execution-tab/isc-execution-tab.component';
import { ClaimsService } from 'src/app/shared/services/claims.service';

@Component({
    selector: 'app-isc-popup',
    templateUrl: './isc-popup.component.html',
    styleUrls: [
        './isc-popup.component.scss',
        '../../../../../assets/scss/service-order.scss',
        '../../../../../assets/scss/popup.scss'
    ]
})
export class IscPopupComponent implements OnInit, OnDestroy {

    private destroy$: Subject<null> = new Subject();

    @Input() tab: string = 'details';
    @Input() id: string = null;
    @Input() showComments: boolean = false;
    @Input() handlePayments: boolean = false;
    @Input() retryCCPayment: string = 'false';
    @Input() claim: any;
    customerCurrency: string = 'USD';

    USER;
    companies = Companies;
    userRoles = UserRoles;

    InvoicePopupOpened;
    modeList = ModeList;
    assignments; transactions;
    canNotPay: boolean = false;
    canEdit: boolean = true;
    canNotInvoice: boolean = false;
    invoiceAmountExceeded: number = 0;
    fieldRules: any;
    isEditable: boolean = true;
    isExecutionEditable: boolean = true;
    isStatusReadonly: boolean = false;
    validationApplied: boolean = false;
    showEstimatedInvoice: boolean = false;
    estimateInvoiceUri: '';
    serviceOrderStatuses = serviceOrderStatuses;
    statuses = [];
    costTypes = [];
    links = [];

    showPaymentSelectionPopup: boolean = false;
    showPaymentPopup: boolean = false;
    showPaymentFromCreditCardPopup: boolean = false;
    showInvoicePopup: boolean = false;
    disablePaymentButton: boolean = true;
    disableClaimsButton: boolean = true;
    isSigned: boolean = false;
    showClaimsPopup: boolean = false;
    claimId: string;

    loadedData = null;
    distanceZone: any;

    checkInTime: any;
    checkOutTime: any;

    technicianDropdown: PageElement = TechnicianDropdown;
    statusDropdown: PageElement = StatusDropdown;

    showLoader: boolean = false;
    isExpanded: boolean = true;
    invoiceStatuses = InvoiceStatuses;
    technicianIdClaim = false;

    commentCount = 0;
    attachmentCount = 0;
    confirmationBox = {
        show: false,
    };

    @Input() data = null;
    invoice: Invoice = null;

    taxRate: any = [];
    statusCancelPopup: boolean = false;
    generalSectionData: object = null;
    equipmentInfoData: object = null;
    warrantyContractInfo: object = null;
    notesData: object = null;
    executionTabData: any = null;
    @ViewChild('commentsButton') commentsButton: ElementRef;
    @ViewChild('commentsPopup') commentsPopup: ElementRef;
    @ViewChild('toggler') toggler: SwitchComponent;
    @ViewChild('statusDDL') statusDDL;

    // @ViewChild('general') general;
    // @ViewChild('equipmentInfo') equipmentInfo;
    // @ViewChild('notes') notes;
    @ViewChild('detailsTab') detailsTab: IscSoDetailsTabComponent;

    @ViewChild('executionTab') executionTab: IscExecutionTabComponent;

    @Output() showPartsOrderPopup: EventEmitter<any> = new EventEmitter();
    @Output() onClose = new EventEmitter();
    @Output() onSave = new EventEmitter();
    @Output() onNeedRefresh = new EventEmitter();
    @Output() onLinkIdClick = new EventEmitter();
    @Output() showChecklist = new EventEmitter();

    statusChangeData: any;
    mentionUsersList: any;
    cancelReasonsList: any;
    hasCheckedInServiceOrder: boolean = false;
    hasEnrouteServiceOrder: boolean = false;
    isManager: boolean = false;
    isCsa: boolean = false;
    isIsc: boolean = false;
    isIscManager: boolean = false;
    isIscSupervisor: boolean = false;
    isKingslynn: boolean = false;
    showcheckpopup: boolean = false;
    serviceOrderId: string;
    technicianId: string;
    hasChecklist: string;
    checklistId: string;
    checklistID: string;
    increment : boolean = false;
    showChecklistButton : boolean = false;
    isTechnician: boolean = false;
    offlineBillableOnly: boolean;
    showChasePaymentIframe: boolean = false;
    showKingslynnPaymentPopup: boolean = false;
    estimateInvoiceFileId: any;
    estimateInvoiceFileAttachment: any;
    paymentButtonClicked: boolean = false;
    callGroups;
    iscEmployeeDetails: IscEmployeeModel;
    showActivateEquipmentCheckbox: boolean;

    constructor(
        private commonService: CommonService,
        private serviceOrderService: ServiceOrderService,
        private executionTabService: ExecutionTabService,
        private employeeService: EmployeeService,
        private cdr: ChangeDetectorRef,
        private claimService: ClaimsService
    ) {
        this.USER = commonService.USER;
        this.isIsc = commonService.USER.role === UserRoles.isc;
        this.isIscManager = commonService.USER.role === UserRoles.iscManager;
        this.isIscSupervisor = commonService.USER.role === UserRoles.IscSupervisor
        this.isTechnician = this.USER.role === UserRoles.fst;
        this.InvoicePopupOpened = InvoicePopupOpenedFrom;
        this.callGroups = CallGroups;
        this.canNotPay = this.commonService.roleClaims['SO_Popup_Fields_payment']?.readOnly;
        this.canNotInvoice = this.commonService.roleClaims['SO_Popup_Fields_invoice']?.readOnly;
        this.technicianIdClaim = this.commonService.roleClaims['SO_Popup_Fields_technicianId']?.readOnly;
    }

    ngOnChanges() {
        this.serviceOrderService.popupLoader.pipe(
            takeUntil(this.destroy$),
        ).subscribe((showLoader: any) => {
            setTimeout(() => {
                this.showLoader = showLoader;
            }, 0);
        });
    }

    ngOnInit(): void {
        this.isKingslynn = this.USER.company === this.companies.kingslynn
        this.serviceOrderService.configurationChange.pipe(
            takeUntil(this.destroy$),
        ).subscribe((res: any) => {
            this.getTaxRateByConfiguration();
            this.data.isNationalAccount = res.isNationalAccount;
        });
        this.isManager = [UserRoles.conwayServiceManager, UserRoles.klNationalMGR, UserRoles.klServiceManager].includes(this.USER.role);
        this.isCsa = this.USER.role === UserRoles.csa;

        this.showChecklistButton = this.data?.serviceOrderId && this.commonService.roleClaims['App_Popup_ShowChecklistBtn']?.visible && this.USER.company === Companies.kingslynn;
        this.hasChecklist= this.data?.hasChecklist ?? null;
        if (this.data?.assignments?.length) {
            this.assignments = this.data.assignments;
            this.transactions = this.data.assignmentDetails;
            this.disablePaymentButton = false;
            this.checkOtherCostForKingslynn();
            this.customerCurrency = this.data.customerCurrency;
            this.transactions.forEach(element => {
                element.customerCurrency = this.data.customerCurrency;
            });
        }

        this.serviceOrderService.equipmentInfoSubject.pipe(
            takeUntil(this.destroy$),
        ).subscribe(equipmentInfo => {
            this.warrantyContractInfo = equipmentInfo;
        });

        this.fieldRules = {};
        this.setPageData();
        this.loadAPIs();
        this.setClaimButtonDisable();
        this.checkOfflineBillable();
    }

    checkOfflineBillable() {
        this.offlineBillableOnly = this.data?.offlineOrder && this.assignments?.length === 1 && this.assignments[0].referenceActivityDescription?.indexOf('Billable') > -1;
    }

    setClaimButtonDisable() {
        this.disableClaimsButton = this.isCompanyKingslynn() || this.data?.status !== serviceOrderStatuses.completed
                                || this.assignments?.length === 1 && this.assignments[0].referenceActivityDescription?.indexOf('Billable') > -1;
    }

    isCompanyKingslynn(){
        return this.USER.company === this.companies.kingslynn;
    }

    configurationChanged(configId) {
        this.data.configuration = configId;
    }

    setStatusPendingApproval() {
        let pendingApprovalStatuses = [serviceOrderStatuses.pendingApproval];
        this.isManager && pendingApprovalStatuses.push(serviceOrderStatuses.estimateApproved, serviceOrderStatuses.estimateRejected);
        this.statusDropdown.dataSource = this.statuses.filter(status => pendingApprovalStatuses.includes(status.text));
        this.data.status = serviceOrderStatuses.pendingApproval;
    }

    switchClicked(event) {
        if ( this.isEditable ) {
            if (this.data.mode === this.modeList.estimate) {// If mode is estimate and changed to Actual
                event.stopImmediatePropagation();
                if ([serviceOrderStatuses.estimateApproved, serviceOrderStatuses.estimateRejected].includes(this.data?.status)) {
                    this.commonService.showNotification('warning', 'Service Order should be checked-in before you can proceed with Payment flow!')
                    return;
                }
                if (this.data.invoiceAmount > this.data.approvedAmount) {
                    if (this.data?.status !== serviceOrderStatuses.pendingApproval) {
                        this.commonService.showConfirmation('The total invoice amount is exceeding the approved amount. Do you want to submit for approval?', 'Yes', 'No', 'Can not switch to actual mode!').then(res => {
                            if (res.isConfirmed) {
                                this.setStatusPendingApproval();
                                this.setEditable();
                                this.serviceOrderService.popupLoader.next(true);
                                setTimeout(() => {
                                    this.save();
                                }, 1000);
                            }
                        });
                    } else {
                        this.commonService.showNotification('warning', 'Already requested for approval!');
                    }
                } else { //if invoice amount is less than approved amount
                    const filteredData = this.data?.assignmentDetails.filter(x => !x.isDeleted);
                    if (!filteredData?.length) { // If no transactions
                        this.commonService.showNotification('warning', 'There should be atleast one transaction to change the mode');
                    } else if (filteredData.findIndex(x => x.costType === 'Material' && x.qty > 0) > -1) { // if atleast one material found
                        this.commonService.showConfirmation('Selected material(s) will be issued for this service order!').then(res => {
                            if (res.isConfirmed) {
                                filteredData.map(x => {
                                    if (x.costType === 'Material') {
                                        x.issued = true;
                                    }
                                    return x;
                                });
                                this.modeChanged();
                                this.toggler.checked = false; // To toggle the switch programmatically
                                this.save(); // call the API to update the data
                            }
                        });
                    } else { // if there's no material but other transactions and invoice <= nte/approved amount
                        this.modeChanged();
                        this.toggler.checked = false; // To toggle the switch programmatically
                        this.save(); // Call the API to update service order
                    }
                }
            } else { // if the mode is actual and changed to Estimate
                // check payment and invoice details and prompt to clear details if no payment has been made but details have been added
                if (this.invoice && this.invoice.invoiceId && !(this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.invoice.checkAuthCode) && this.data.status !== serviceOrderStatuses.completed) {
                    event.stopImmediatePropagation();
                    this.commonService.showConfirmation('Payment and invoice details will be cleared. Do you want to proceed?').then(res => {
                        if (res.isConfirmed) {
                            this.invoice = null;
                            this.modeChanged();
                            this.toggler.checked = true; // To toggle the switch programmatically
                            this.save();
                        }
                    });
                } else {
                    event.stopImmediatePropagation();
                    this.commonService.showConfirmation('Service Order will be updated. Do you want to proceed?').then(res => {
                        if (res.isConfirmed) {
                            this.modeChanged();
                            this.toggler.checked = true; // To toggle the switch programmatically
                            this.save();
                        }
                    });
                }
            }
        }
    }

    modeChanged(args?) {
        this.data.mode = this.detailsTab.gsData.mode = this.data.mode === this.modeList.estimate ? this.modeList.actual : this.modeList.estimate;
        this.setEditable();
    }

    getTaxRateByConfiguration() {
        this.serviceOrderService.getTaxRateByConfiguration(this.data.configuration)
            .subscribe((res: ApiResponse) => {
                if (res && res.isSuccessful && res.result) {
                    this.taxRate = res.result;
                } else {
                    this.taxRate = [];
                }
            })
    }

    loadAPIs() {
        this.serviceOrderService.popupLoader.next(true);
        const apis = [
            this.commonService.getUsers(),
            this.commonService.getServiceOrderStatuses(),
            this.executionTabService.getIscCostTypes(),
            this.commonService.getUsersForMention(),
            this.serviceOrderService.getReasonsForCancelStatus(this.USER.company),
            this.serviceOrderService.GetInProgressAndEnrouteSOCount(this.data?.technicianId || this.USER.employeeId, true),
            this.serviceOrderService.getIscServiceEmployeeDetailsById(this.data?.technicianId || this.USER.employeeId)
        ];
        this.data?.serviceOrderId && apis.push(this.commonService.getLinkedRecords(LinksEnum.iscServiceOrder, this.id));
        (this.isIscManager || this.isIscSupervisor) && apis.push(this.employeeService.getIscTechnicianManagerList());
        this.data?.invoiceId && apis.push(this.serviceOrderService.getIscInvoice(this.data.invoiceId));

        forkJoin(apis).subscribe((res: any) => {
            this.technicianDropdown.dataSource = [];
            if(this.data?.serviceOrderId && !this.isIscManager && !this.isIscSupervisor){
                if(this.technicianDropdown.dataSource.findIndex( x => x.value === this.data?.technicianId) === -1){
                    this.technicianDropdown.dataSource.unshift({ text: this.data?.technicianName, value: this.data?.technicianId });
                }
            } else {
                if(this.technicianDropdown.dataSource.findIndex( x => x.value === this.USER.userId) === -1){
                    this.technicianDropdown.dataSource.unshift({ text: this.USER.firstName + ' ' + this.USER.lastName, value: this.USER.userId });
                }
            }
            this.statuses = res[1].result;
            this.hasCheckedInServiceOrder = res[5].result[0].count > 0;
            this.hasEnrouteServiceOrder = res[5].result[1].count > 0;
            this.statusDropdown.dataSource = this.getServiceOrderStatusList();
            this.data.status = this.data?.status ? this.data.status : serviceOrderStatuses.open;
            this.data.parentStatus = this.data?.parentStatus ? this.data.parentStatus : serviceOrderStatuses.open;
            const userData = this.USER;
            this.data.assignments.map((assignment, index) => {
                assignment.tempId = assignment.assignmentId;
                assignment.rowId = index + 1;
            });
            this.claimId = this.data.claimId;
            this.data.technicianId = this.data.serviceOrderId ? this.data.technicianId : userData.userId;
            this.data.technicianName = this.data.serviceOrderId ? this.data?.technicianName : userData.firstName;
            this.data.serviceDepartment = this.data.serviceOrderId ? this.data?.serviceDepartment : userData.serviceDepartment;
            this.data.serviceDepartmentDescription = this.data.serviceOrderId ? this.data?.serviceDepartmentDescription : userData.serviceDepartmentDescription;
            this.costTypes = res[2].result;
            this.mentionUsersList = res[3].result;
            this.cancelReasonsList = res[4].result;
            this.iscEmployeeDetails = res[6].result;
            this.links = this.data?.serviceOrderId ? res[7].result : []; //res[5].data; sqltodo
            if (this.isIscManager || this.isIscSupervisor) {
                let result = this.data && this.data.serviceOrderId === '' ? res[7].result : res[8].result;
                const resourceDDL = result.filter(isc => {
                    isc.text = isc.name;
                    isc.value = isc.employeeId;
                    return !isc.isDisabled
                });
                this.technicianDropdown.dataSource = [...this.technicianDropdown.dataSource, ...resourceDDL];
                if (apis.length === 10) {
                    this.invoice = res[9].result;
                    this.invoice.paymentType = this.invoice.paymentType + '';
                    this.disablePaymentButton = true;
                }
            } else {
                if (apis.length === 9) {
                    this.invoice = res[8].result;
                    this.invoice.paymentType = this.invoice.paymentType + '';
                    this.disablePaymentButton = !!this.invoice?.invoiceAttachment;
                }
            }

            if (this.handlePayments) {
                this.validationApplied = true;
                this.paymentButtonClicked = true;
                const hasOtherCost = this.data.assignmentDetails?.findIndex(row => row.costType === CostTypes.otherCost) > -1;
                const validateForActivateEquipment = this.data.callGroup = this.callGroups.installation && this.data.isChargeBeeModel && this.data.isEquipmentActivated;
                if ( this.isKingslynn && hasOtherCost || this.data.fstCommentsToCustomer && validateForActivateEquipment) {
                    if (this.creditCardPaymentDone()) {
                        this.disablePaymentButton = true;
                        if (this.data?.assignmentDetails?.findIndex(x => !x.price) > -1) {
                            this.commonService.showConfirmation('This service order contains transactions where price equals 0.\n Do you still want to proceed to invoicing?')
                            .then(response => {
                                if (response.isConfirmed) {
                                    this.showInvoicePopup = true;
                                }
                            })
                        } else {
                            this.showInvoicePopup = true;
                        }
                    } else if (this.invoice?.paymentType) {
                        if(this.invoice.paymentType === PaymentMethods.creditCard.paymentType){
                            this.check0PriceForInvoicing();
                        } else {
                            if (this.data?.assignmentDetails?.findIndex(x => !x.price) > -1) {
                                this.commonService.showConfirmation('This service order contains transactions where price equals 0.\n Do you still want to proceed to Payment?')
                                .then(response => {
                                    if (response.isConfirmed) {
                                        this.showPaymentPopup = true;
                                    }
                                })
                            } else {
                                this.showPaymentPopup = true;
                            }
                        }
                    } else {
                        if (this.data?.assignmentDetails?.findIndex(x => !x.price) > -1) {
                        this.commonService.showConfirmation('This service order contains transactions where price equals 0.\n Do you still want to proceed to payment?')
                            .then(response => {
                                if (response.isConfirmed) {
                                    this.showPaymentSelectionPopup = true;
                                }
                            })
                        } else {
                            this.showPaymentSelectionPopup = true;
                        }
                    }
                } else {
                    this.commonService.showNotification('error', 'Please provide all the required information to proceed to payment/invoice!');
                }
            } else if(this.retryCCPayment === 'true'){
                this.check0PriceForInvoicing();
            }

            this.setEditable();
            this.loadPage();
            this.setData();
            this.checkOtherCostForKingslynn();
            this.loadedData = JSON.stringify(this.data);
            this.serviceOrderService.popupLoader.next(false);
        }, (error: HttpErrorResponse) => {
            this.serviceOrderService.popupLoader.next(false);
        });
    }

    loadPage() {
        this.setGeneralSectionData();
        this.setEquipInfoSectionData();
        this.setNotesSectionData();
        this.setExecutionTabData(); 
    }

    setEditable() {
        this.isEditable = this.canEdit && this.isPopupEditable();
        this.isExecutionEditable = this.isEditable && this.data.status === serviceOrderStatuses.inProgress;// && this.data.mode === this.modeList.estimate;
        this.isStatusReadonly = this.checkIsStatusReadonly();
    }

    checkIsStatusReadonly(): boolean {
        const { serviceOrderId, status } = this.data;
        const { roleClaims } = this.commonService;
        const { role } = this.USER;
        const { paymentType, checkAuthCode } = this.invoice || {};

        const isReadOnly = roleClaims['SO_Popup_Field_status']?.readOnly;
        const isCompleted = status === serviceOrderStatuses.completed;
        const isImportPublishError = status === serviceOrderStatuses.importPublishError;
        const isExportPublishError = status === serviceOrderStatuses.exportPublishError;
        const isCancelled = status === serviceOrderStatuses.cancelled;
        const isPendingApprovalFst = status === serviceOrderStatuses.pendingApproval && role === UserRoles.fst;
        const isNotPendingApprovalCsa = status !== serviceOrderStatuses.pendingApproval && role === UserRoles.csa;
        const isCreditCardAuthCode = paymentType === PaymentMethods.creditCard.paymentType && checkAuthCode;
        const isInvoiceSigned = this.invoice?.status === this.invoiceStatuses.signed;

        return !serviceOrderId ||
            isReadOnly ||
            isCompleted ||
            isImportPublishError ||
            isExportPublishError ||
            isCancelled ||
            isPendingApprovalFst ||
            // isNotPendingApprovalCsa ||
            isCreditCardAuthCode ||
            isInvoiceSigned;
    }

    isPopupEditable() {
        const noEditStatuses = [
            serviceOrderStatuses.completed,
            serviceOrderStatuses.pendingApproval,
            serviceOrderStatuses.cancelled,
            serviceOrderStatuses.importPublishError,
            serviceOrderStatuses.exportPublishError,
            serviceOrderStatuses.costed
        ];
        if (this.data?.status && noEditStatuses.includes(this.data.status)) {
            return false;
        } else if (this.invoice) {
            if (this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.invoice.checkAuthCode) {
                return false;
            } else if (this.invoice.status !== InvoiceStatuses.signed) {
                return true;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    setPageData() {
        if (!this.data) {
            this.data = {
                pageId: 112,
                serviceOrderId: '',
                technicianId: null,
                technicianName: null,
                offlineOrder: true,
                mode: ModeList.estimate,
                status: serviceOrderStatuses.open,
                parentStatus: serviceOrderStatuses.open,
                assignments: [],
                assignmentDetails: [],
                comments: [],
                attachments: [],
                calibrations: []
            };
        } else {
            this.commentCount = this.data.comments.length;
            this.attachmentCount = this.data.attachments.length;
            // this.getTaxRateByConfiguration();
            this.compareInvoiceAndApprovedAmount(this.data);
        }
    }

    setGeneralSectionData() {
        this.generalSectionData = {
            serviceDepartment: this.data.serviceDepartment,
            serviceDepartmentDescription: this.data.serviceDepartmentDescription,
            configuration: this.data.configuration,
            customerDescription: this.data.customerDescription,
            bpDescription: this.data.customerDescription?.split('-')[1]?.trim() || '',
            customerCurrency: this.data.customerCurrency,
            customerCode: this.data.customerCode,
            customerPhoneNumber: this.data.customerPhoneNumber,
            address: this.data.address,
            addressCode: this.data.addressCode,
            city: this.data.city,
            elapsedTime: this.data.elapsedTime,
            country: this.data.country,
            state: this.data.state,
            zipCode: this.data.zipCode,
            priority: this.data.priority || '2',
            priorityDescription: this.data.priorityDescription || 'Normal',
            email: this.data.customerEmail,
            termsOfPayment: this.data.termsOfPayment,
            termCodeDescription: this.data.termCodeDescription,
            businessPartnerStatus: this.data.businessPartnerStatus,
            taxable: this.data.taxable,
            status: this.data.status,
            signal: this.data.signal,
            checkInDate: this.data.checkInDate,
            checkOutDate: this.data.checkOutDate,
            reference: this.data.reference,
            reasonCode: this.data.reasonCode,
            reasonDescription: this.data.reasonDescription,
            startDate: this.data.startDate,
            activityText: this.data.activityText,
            callGroup: this.data.callGroup,
            mode: this.data.mode,
            isNationalAccount: this.data.isNationalAccount,
            nteAmount: this.data.nteAmount,
            approvedAmount: this.data.approvedAmount,
            serviceOrderIdLN: this.data.serviceOrderIdLN,
            offlineOrder: this.data.offlineOrder !== undefined ? this.data.offlineOrder : true,
            orderDateLN: this.data.orderDateLN
        }
    }

    setEquipInfoSectionData() {
        let serviceTypeCode;
        if ( this.USER.company === Companies.kingslynn && this.data && this.data.assignments && this.data.assignments.length ) {
            serviceTypeCode = this.data.assignments[0].serviceTypeCode;
        } else {
            serviceTypeCode = '';
        }
        this.equipmentInfoData = {
            itemCode: this.data.itemCode,
            itemDescription: this.data.itemDescription,
            soCreatedDate: this.data.createdDate || new Date().toISOString(),
            serializedItemGroupCode: this.data.serializedItemGroupCode,
            serializedItemGroupDescription: this.data.serializedItemGroupDescription,
            serialNumber: this.data.serialNumber,
            category: this.data.category,
            installationDate: this.data.installationDate,
            warrantyDescription: this.data.warrantyDescription,
            warrantyCode: this.data.warrantyCode,
            serviceContractCode: this.data.serviceContractCode,
            serviceContractDescription: this.data.serviceContractDescription,
            calibrationNextCertificateDate: this.data.calibrationNextCertificateDate,
            equipmentStatus: this.data.equipmentStatus,
            offlineOrder: this.data.offlineOrder !== undefined ? this.data.offlineOrder : true,
            equipmentStatusDescription: this.data.equipmentStatusDescription,
            serviceTypeCode,
            status: this.data.status,
            warrantyCoverageLines: this.data.warrantyCoverageLines,
            contractCoverageLines: this.data.contractCoverageLines,
            isChargeBeeModel: this.data.isChargeBeeModel,
            isEquipmentActivated: this.data.isEquipmentActivated
        }
    }

    setNotesSectionData() {
        this.notesData = {
            customerComplaint: this.data.customerComplaint,
            fstCommentsToCustomer: this.data.fstCommentsToCustomer,
            notesToCustomerCare: this.data.notesToCustomerCare,
            cancellationNotes: this.data.cancellationNotes,
            cancellationDate: this.data.cancellationDate,
            offlineOrder: this.data.offlineOrder !== undefined ? this.data.offlineOrder : true,
            // reasonCode: this.data.reasonCode !== "undefined" ? this.data.reasonCode : null,
            // reasonDescription: this.data.reasonDescription
        }
    }

    setExecutionTabData() {
        this.executionTabData = {
            approvedAmount: this.data.approvedAmount || null,
            configuration: this.data.configuration,
            callGroup: this.data.callGroup,
            contractCoverage: this.data.contractCoverage || '',
            customerCode: this.data.customerCode,
            discountAmount: this.data.discountAmount,
            estimateApproved: this.data.estimateApproved,
            invoiceAmount: this.data.invoiceAmount,
            offlineOrder: this.data.offlineOrder !== undefined ? this.data.offlineOrder : true,
            serializedItemGroupCode: this.data.serializedItemGroupCode || '',
            serviceOrderId: this.data.serviceOrderId,
            serviceType: this.data.serviceType,
            salesAmount: this.data.salesAmount,
            soCreatedDate: this.data.createdDate || null,
            tax: this.data.tax,
            taxable: this.data.taxable !== undefined ? this.data.taxable : false,
            totalAmount: this.data.totalAmount,
            mode: this.data.mode,
            technicianId: this.data.technicianId,
            technicianWarehouse: this.data.technicianWarehouse,
            warrantyDescription: this.data.warrantyDescription || '',
            warrantyCoverage: this.data.warrantyCoverage || '',
            isNationalAccount: this.data?.isNationalAccount,
            warrantyCoverageLines: this.data.warrantyCoverageLines || '',
            contractCoverageLines: this.data.contractCoverageLines || '',
            orderDateLN: this.data.orderDateLN
        }
    }

    creditCardPaymentDone(){
        return this.invoice && (this.invoice.status === InvoiceStatuses.signed || (this.invoice.paymentType === PaymentMethods.creditCard.paymentType && this.invoice.checkAuthCode));
    }

    onCommentUpdate(data: any) {
        this.commentCount = data.filter(comment => !comment.isDeleted).length;
        this.onNeedRefresh.emit();
    }

    onAttachmentsUpdate(data: any) {
        const loadedData = JSON.parse(this.loadedData);
        loadedData.attachments = data;
        this.loadedData = JSON.stringify(loadedData);
        this.data.attachments = data;
        this.attachmentCount = data.filter(attachment => !attachment.isDeleted).length;
    }

    getExecutionData(data: any) {
        this.compareInvoiceAndApprovedAmount(data);
        this.data = {
            ...this.data,
            ...data
        };
        if (this.invoice) {
            this.invoice.paymentAmount = this.data.invoiceAmount;
            this.setPaymentType();
        }

        if (!this.data.assignments.filter(x => !x.isDeleted).length) {
            this.disablePaymentButton = true;
        } else {
            this.disablePaymentButton = false;
        }
        this.checkOtherCostForKingslynn();
        this.statusDropdown.dataSource = this.getServiceOrderStatusList();
        this.detailsTab.setAssignments(data && data.assignments && data.assignments.length);
        // this.save(); // save on assignment and transaction update
    }

    checkOtherCostForKingslynn() {
        if ( this.USER.company === Companies.kingslynn && !this.creditCardPaymentDone()) {
            if ( this.data?.assignmentDetails?.length && this.data.assignmentDetails.findIndex(detail => detail.costType === CostTypes.otherCost && !detail.isDeleted ) > -1 ) {
                this.disablePaymentButton = false;
            } else {
                this.disablePaymentButton = true;
            }
        }
    }

    compareInvoiceAndApprovedAmount(data) {
        this.invoiceAmountExceeded = data.approvedAmount && data.invoiceAmount &&
            data.invoiceAmount > data.approvedAmount ? data.invoiceAmount - data.approvedAmount : 0;
    }

    setPaymentType() {
        if ((this.invoice && this.invoice.paymentAmount !== undefined && this.invoice.paymentAmount === 0)) {
            this.invoice.paymentType = this.USER.company === Companies.conway ? PaymentMethods.notApplicable.paymentType : PaymentMethods.invoice.paymentType;
            this.invoice.paymentTypeDescription = this.USER.company === Companies.conway ? PaymentMethods.notApplicable.description : PaymentMethods.invoice.description;
            this.invoice.checkAuthCode = null;
            this.invoice.bankName = null;
        }
    }

    /**
     *
     * @param validateNotesOnPaymentInvoice Optional Parameter:Boolean (true if clicked on the payment or invoice button)
     * @returns Boolean
     */
    validate() {
        this.validationApplied = true;
        const validateNotes = this.detailsTab.validateCommentsNotesData(this.paymentButtonClicked);
        const loadedDataStatus = JSON.parse(this.loadedData)?.status;
        const validateExecutionTab = this.data.status !== serviceOrderStatuses.open && loadedDataStatus !== serviceOrderStatuses.open ? this.executionTab.validate() : true;
        return this.detailsTab.validateGeneralSectionData() && this.detailsTab.validateEquipmentSectionData(this.paymentButtonClicked) && validateNotes && validateExecutionTab;
    }

    setData(save?: boolean) {
        if (this.data.serviceOrderId) {
            this.data.lastUpdatedBy = save ? this.USER.employeeId:this.data.technicianId;
            this.data.lastUpdatedByName = save ? this.USER.employeeName:this.data.technicianName;
        } else {
            this.data.createdBy = this.data.technicianId;
            this.data.createdByName = this.data.technicianName;
        }

        const status = this.data.status;
        this.data.assignments.forEach(row => {
            if ( !row.assignmentId ) {
                row.assignmentId = 0;
            }
        });

        this.data.assignmentDetails.forEach(transaction => {
            if ( !transaction.assignmentDetailId ) {
                transaction.assignmentDetailId = 0;
            }
            delete transaction.assignment;
        });
        this.data = {
            company: this.USER.company,
            ...this.data,
            ...this.detailsTab?.getGeneralSectionData() || this.generalSectionData,
            ...this.detailsTab?.getEquipmentSectionData() || this.equipmentInfoData,
            ...this.detailsTab?.getCommentsNotesData() || this.notesData,
            status
        };

        if ( save ) {
            if ( !this.data.completedDate && this.data.status === serviceOrderStatuses.completed ) {
                this.data.completedDate = getUTCDate()
            }

            if ( [serviceOrderStatuses.completed, serviceOrderStatuses.cancelled].includes(this.data.status) && this.data.callGroup !== CallGroups.initialCalibration ) {
                this.data.warrantyCoverageLines = JSON.stringify(this.warrantyContractInfo['warrantyInfo']);
                this.data.contractCoverageLines = JSON.stringify(this.warrantyContractInfo['contractInfo']);
            }

            if ( this.data.status === serviceOrderStatuses.open ) {
                this.data.mode = this.data.nteAmount ? this.modeList.estimate : this.modeList.actual;
            }
            this.data.checkInDate = this.data.checkInDate && new Date(this.data.checkInDate).toISOString();
            this.data.checkOutDate = this.data.checkOutDate && new Date(this.data.checkOutDate).toISOString();
            this.data.startDate = this.data.startDate && new Date(this.data.startDate).toISOString();
        }
        this.data.priority = this.data.priority + '';
        this.data.attachments.map(attachment => {
            delete attachment.serialNumber;
            delete attachment.tempId;
            delete attachment.fileType;
            delete attachment.lastUpdatedDate;
        });
    }


    setUTCDates() {
        // const data = JSON.parse(JSON.stringify(this.data));
        // data.startDate = new Date(data.startDate).toISOString();
        // data.checkInDate = new Date(data.checkInDate).toISOString();
        // data.checkOutDate = new Date(data.checkOutDate).toISOString();
        // data.attachments.forEach( (attachment: Attachment) => {
        //     attachment.lastUpdatedDate = new Date(attachment.lastUpdatedDate).toISOString();
        // });
        // data.assignments.forEach((assignment: any) => {
        //     assignment.startDate = new Date(assignment.startDate).toISOString();
        //     assignment.endDate = new Date(assignment.endDate).toISOString();
        // });
        // data.assignmentDetails.forEach((transaction: any) => {
        //     transaction.startDate = new Date(transaction.startDate).toISOString();
        //     transaction.endDate = new Date(transaction.endDate).toISOString();
        // });
        return this.data;
    }

    onPaymentButtonClick() {
        if (this.validate()) {
            if ( this.data?.assignmentDetails?.filter(x => !x.isDeleted).length )  {
                if ( this.data?.assignmentDetails?.findIndex(x => !x.qty && !x.isDeleted) > -1 ) {
                    this.commonService.showNotification('error','Cannot proceed to Payment/Invoice with 0 quantity line. Please increase the quantity or remove the line.', 'center', 5000);
                } else if (this.data?.assignmentDetails?.findIndex(x => !x.price) > -1 ) {
                    this.commonService.showConfirmation('This service order contains transactions where price equals 0.\n Do you still want to proceed to payment?')
                        .then(response => {
                            if (response.isConfirmed) {
                                this.save(false, false, false, true);
                            }
                        })
                } else {
                    this.save(false, false, false, true);
                }
            } else {
                this.commonService.showNotification('error','Cannot proceed to Payment/Invoice with 0 Transactions.', 'center', 5000);
            }
        } else {
            this.commonService.showNotification('error', 'Please provide all the required information to proceed');
        }
    }

    save(close?: boolean, isInvoicing?: boolean, isEstimating?: boolean, paymentBtnClicked?: boolean) {
        if (this.validate()) {
            this.setData(true); // true: if called from the save method
            // const data = this.setUTCDates();
            this.data.reasonCode = [serviceOrderStatuses.paused, serviceOrderStatuses.cancelled].includes(this.data.status) ? this.data.reasonCode : null;
            this.data.reasonDescription = [serviceOrderStatuses.paused, serviceOrderStatuses.cancelled].includes(this.data.status) ? this.data.reasonDescription : null;
            this.serviceOrderService.popupLoader.next(true);
            this.serviceOrderService.saveIscServiceOrder(this.data)
                .subscribe((res: ApiResponse) => {
                    this.afterUpdateServiceOrder(res, close, isInvoicing, isEstimating, paymentBtnClicked);
                }, (error: HttpErrorResponse) => {
                    this.serviceOrderService.popupLoader.next(false);
                    this.commonService.showNotification('error', error.message);
                });
        } else {
            this.commonService.showNotification('error', 'Please provide all the required information to proceed');
            this.serviceOrderService.popupLoader.next(false);
        }
    }

    afterUpdateServiceOrder(res: ApiResponse, close?: boolean, isInvoicing?: boolean, isEstimating?: boolean, paymentBtnClicked?: boolean){
        if (res.isSuccessful) {
            res.result.assignments.map((assignment, index) => {
                assignment.priority = assignment.priority.toString();
                assignment.tempId = assignment.assignmentId;
                assignment.rowId = index + 1;
            });
            res.result.assignmentDetails.map((detail, index) => {
                detail.rowId = index + 1;
            });
            this.data = res.result;
            this.data.serviceOrderId = res.result.serviceOrderId;
            this.setExecutionTabData();
            this.data.status !== serviceOrderStatuses.open && this.executionTab.showEstimatedQtyCol(this.data.mode === ModeList.actual && this.data.estimateApproved);
            this.setGeneralSectionData();
            this.setEquipInfoSectionData();
            this.showActivateEquipmentCheckbox = this.data.callGroup === this.callGroups.installation && this.data.isChargeBeeModel;

            this.id = res.result.serviceOrderId;
            this.assignments = this.data.assignments;
            this.transactions = this.data.assignmentDetails;
            this.data.status !== serviceOrderStatuses.open && this.executionTab.setLoadedAssignmentItemDetails(this.transactions);
            this.setData();
            this.loadedData = JSON.stringify(this.data);
            this.setEditable();
            this.setClaimButtonDisable();
            this.disablePaymentButton = !this.data.assignments.length;
            this.serviceOrderService.equipmentInfoSubject.next(this.warrantyContractInfo);
            this.checkOtherCostForKingslynn();
            this.showChecklistButton = this.data && this.data.serviceOrderId && this.commonService.roleClaims['App_Popup_ShowChecklistBtn']?.visible && this.USER.company === Companies.kingslynn;
            this.commonService.showNotification('success', `Service Order ${this.data.serviceOrderId ? 'updated' : 'created'} successfully!`);
            if(isEstimating){
                this.showEstimateInvoice(isEstimating);
            }
            if (this.invoice && this.invoice.status !== InvoiceStatuses.signed) {
                this.invoice.paymentAmount = this.data.invoiceAmount;
                this.serviceOrderService.updateInvoice(this.invoice).subscribe((res: any) => {
                    if (this.invoice && this.invoice.invoiceId) {
                        this.serviceOrderService.getIscInvoice(this.data.invoiceId)
                        .subscribe((res: ApiResponse) => {
                            if (res.isSuccessful) {
                                this.invoice = res.result;
                                if (isInvoicing) {
                                    this.check0PriceForInvoicing();
                                }
                            }
                        })
                    }
                });
            }
            if ( paymentBtnClicked) {
                this.showPaymentSelectionPopup = true;
            }

            this.validationApplied = false;
            this.statusDropdown.dataSource = this.getServiceOrderStatusList();
            this.onSave.emit(res.result);
            if (close) {
                this.invoiceAmountExceeded = undefined;
                const reloadDataOnClose = JSON.parse(this.loadedData).status !== this.data.status;
                const updatedSO = JSON.parse(this.loadedData);
                this.onClose.emit({reloadDataOnClose, updatedSO});
                localStorage.removeItem('currency');
            }
        } else {
            this.commonService.showNotification('error', res.message);
        }
        this.serviceOrderService.popupLoader.next(false);
    }

    saveAndClose() {
        this.save(true);
    }

    openEstimatedInvoicePDF() {
        this.serviceOrderService.popupLoader.next(true);
        this.save(false, false, true);
    }

    onClaimSave(claim) {
        this.claimId = claim.claimId;
        this.data.claimId = claim.claimId;
    }

    onTechnicianChange(args) {
        this.data.technicianName = args.itemData.text;
        this.data.technicianId = args.value;
    }

    onStatusSelect(args) {
        if ( this.isKingslynn ) {
            if ( args.itemData.value === serviceOrderStatuses.inProgress ) {
                if ( this.data.callGroup === CallGroups.commissionInstall && !(this.data.serialNumber && this.data.itemCode)) {
                    this.commonService.showNotification('error', 'Cannot check-in due to blank model/serial number.');
                    args.cancel = true;
                }
            }

            if ( args.itemData.value === serviceOrderStatuses.awaitingPayment ) {
                if ( !this.data.fstCommentsToCustomer && !this.notesData['fstCommentsToCustomer'] ) {
                    args.cancel = true;
                    this.commonService.showNotification('error', 'Please add Diagnosis Solution before proceeding to Awaiting Payment.');
                } else if ( this.data?.assignmentDetails?.length && this.data.assignmentDetails.findIndex(detail => detail.costType === CostTypes.otherCost && !detail.isDeleted ) === -1 ) {
                    args.cancel = true;
                    this.commonService.showNotification('error', 'Please add one Other Cost in transaction grid before proceeding to Awaiting Payment.');
                }
            }
        }
    }

    statusChanged(args) {
        if (args.itemData) {
        //     if ( this.isKingslynn && args.itemData.value === serviceOrderStatuses.awaitingPayment) {
        //         if(!this.data.fstCommentsToCustomer){
        //             this.commonService.showNotification('error', 'Please add Diagnosis Solution before proceeding to Awaiting Payment.');
        //             this.statusDDL.disabled = true;
        //             setTimeout(() => {
        //                 this.statusDDL.disabled = false;
        //                 this.statusDDL.value = args.previousItemData.value;
        //                 this.data.status = args.previousItemData.value;
        //                 this.data.parentStatus = args.previousItemData.parent;
        //                 this.statusDDL.refresh();
        //             }, 0);
        //         } else if (!this.data?.assignmentDetails?.length && this.data.assignmentDetails.findIndex(detail => detail.costType === CostTypes.otherCost && !detail.isDeleted ) === -1 ) {
        //             this.commonService.showNotification('error', 'Please add one Other Cost in transaction grid before proceeding to Awaiting Payment.');
        //             this.statusDDL.disabled = true;
        //             setTimeout(() => {
        //                 this.statusDDL.disabled = false;
        //                 this.statusDDL.value = args.previousItemData.value;
        //                 this.data.status = args.previousItemData.value;
        //                 this.data.parentStatus = args.previousItemData.parent;
        //                 this.statusDDL.refresh();
        //             }, 0);
        //         } else {
        //             this.data.status = args.itemData.value;
        //             this.data.parentStatus = args.itemData.parent;
        //         }
        //     } else
            if (args.itemData.value === serviceOrderStatuses.inProgress) {
                this.data.status = args.itemData.value;
                this.data.parentStatus = args.itemData.parent;
                this.hasCheckedInServiceOrder = true;
            } else if (args.itemData.value === serviceOrderStatuses.cancelled) {
                if (args.isInteracted) {
                    this.statusCancelPopup = true;
                    this.statusChangeData = args;
                } else {
                    this.data.status = args.itemData.value;
                    this.data.parentStatus = args.itemData.parent;
                }
            } else if (args.itemData.value === serviceOrderStatuses.scheduled) {
                if (args.isInteracted) {
                    this.data.status = args.itemData.value;
                    this.generalSectionData['startDate'] = new Date();
                }
            } else if (args.itemData.value === serviceOrderStatuses.completed && args.isInteracted) {
                this.commonService.showConfirmation(`Are you sure you want to change the status to Completed? This cannot be reverted.`).then(result => {
                    if (result.isConfirmed) {
                        this.data.status = args.itemData.value;
                        this.data.parentStatus = args.itemData.value;
                        this.save();
                    } else {
                        setTimeout(() => {
                            this.statusDDL.value = args.previousItemData.value;
                            this.data.status = args.previousItemData.value;
                            this.data.parentStatus = args.previousItemData.parent;
                            this.statusDDL.refresh();
                        }, 0);
                    }
                });
            } else {
                this.data.status = args.itemData.value;
                this.data.parentStatus = args.itemData.parent;
            }

            if(args.previousItemData.value === serviceOrderStatuses.inProgress){
                this.technicianCheckedout(args);
            }
        }
    }

    technicianCheckedout(args){
        this.hasCheckedInServiceOrder = false;
        this.setEditable();
    }

    cancellationPopupClosed(event) {
        this.statusCancelPopup = false;
        if (event.isConfirmed) {
            this.notesData['cancellationNotes'] = event.cancellationNote;
            this.notesData['cancellationDate'] = event.cancellationDate;
            this.generalSectionData['reasonCode'] = event.cancellationCode;
            this.generalSectionData['reasonDescription'] = event.cancellationText;
            this.notesData['reasonCode'] = event.cancellationCode,
            this.notesData['reasonDescription'] = event.cancellationText;
            this.data.status = this.statusChangeData.itemData.value;
            this.data.parentStatus = this.statusChangeData.itemData.parent;
            if (this.transactions && this.transactions.length) {
                for (let i = this.transactions.length - 1; i >= 0; i--) {
                    if (this.transactions[i].assignmentDetailId) {
                        this.transactions[i].isDeleted = true;
                    } else {
                        this.transactions.splice(i, 1);
                    }
                }
                this.executionTab.refreshGrids();
            }
            this.save(false);
        } else {
            this.data.status = this.statusChangeData.previousItemData.value;
            this.data.parentStatus = this.statusChangeData.previousItemData.parent;
            this.statusDDL.value = this.data.status;
            this.statusDDL.refresh();
        }
    }

    checkedIn(checkinDate) {
        this.data.checkInDate = checkinDate;
        if (this.assignments && this.assignments.length) {
            this.assignments.forEach(assignment => {
                if (!assignment.startDate && checkinDate) {
                    assignment.startDate = new Date(checkinDate);
                    assignment.endDate = null;
                }
            });
        }
        if (this.transactions && this.transactions.length) {
            this.transactions.forEach(transaction => {
                if (!transaction.startDate && checkinDate) {
                    transaction.startDate = new Date(checkinDate);
                    if (transaction.endDate) {
                        transaction.endDate = null;
                    }
                }
            });
        }
        this.executionTab.refreshGrids();
        this.save();
    }

    checkedOut(checkoutDate) {
        if (this.assignments && this.assignments.length) {
            this.assignments.forEach(assignment => {
                if (!assignment.endDate && checkoutDate) {
                    assignment.endDate = new Date(checkoutDate);
                }
            });
        }
        if (this.transactions && this.transactions.length) {
            this.transactions.forEach(transaction => {
                if (!transaction.endDate && checkoutDate) {
                    transaction.endDate = new Date(checkoutDate);
                }
            });
        }
        this.executionTab.refreshGrids();
    }

    notesSectionUpdated(event){
        this.notesData = event;
        this.data.fstCommentsToCustomer = event.fstCommentsToCustomer;
    }

    isChanged() {
        this.setData();
        const dataHistory = JSON.parse(this.loadedData);
        dataHistory.history = this.data.history;
        dataHistory.warrantyDescriptionObj = this.data.warrantyDescriptionObj;
        dataHistory.serviceContractDescriptionObj = this.data.serviceContractDescriptionObj;
        this.loadedData = JSON.stringify(dataHistory);
        // console.log(this.loadedData);
        // console.log(JSON.stringify(this.data));
        const currentAssignmentDetailsData = this.data.assignmentDetails.map(({tempId, ...keepAttrs}) => keepAttrs);
        const currentData = this.data;
        currentData.assignmentDetails = currentAssignmentDetailsData;
        return this.loadedData !== JSON.stringify(currentData);
    }

    checklistPopupClosed(event:any){
        this.checklistID= event;
        this.increment = true;
        this.data.hasChecklist = event;
        this.hasChecklist = event;
    }

    checklistClicked(){
        this.showcheckpopup= true;
        this.showChecklist.emit(true);
    }

    close() {
      const reloadDataOnClose = JSON.parse(this.loadedData).status !== this.data.status;
      const updatedSO = JSON.parse(this.loadedData);
        if ([serviceOrderStatuses.completed, serviceOrderStatuses.costed].includes(this.data.status) || !this.isChanged()) {
          this.onClose.emit({reloadDataOnClose, updatedSO});
            localStorage.removeItem('currency');
        } else {
            this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                .then(result => {
                    if (result.isConfirmed) {
                      this.onClose.emit({reloadDataOnClose, updatedSO});
                      localStorage.removeItem('currency');
                    }
                });
        }
    }

    reset() {
        if (this.isChanged()) {
            this.commonService.showConfirmation('Are you sure? All the unsaved changes will be lost')
                .then(result => {
                    if (result.isConfirmed) {
                        this.resetPopup();
                        this.loadPage();
                        this.data.status !== serviceOrderStatuses.open && this.executionTab.setLoadedAssignmentItemDetails(this.transactions);
                    }
                });
        } else {
            this.commonService.showNotification('info', 'Nothing to reset');
        }
    }

    resetPopup() {
        this.generalSectionData = null;
        this.equipmentInfoData = null;
        this.notesData = null;
        this.executionTabData = null;
        this.assignments = null;
        this.transactions = null;
        this.data = JSON.parse(this.loadedData);
        this.assignments = this.data.assignments;
        this.transactions = this.data.assignmentDetails;
    }

    expandCollapse(value: boolean) {
        this.isExpanded = value;
    }

    makeInvoice() {
        if (this.validate()) {
            if ( this.data?.assignmentDetails?.filter(x => !x.isDeleted).length )  {
                if ( this.data?.assignmentDetails?.findIndex(x => !x.qty && !x.isDeleted) > -1 ) {
                    this.commonService.showNotification('error','Cannot proceed to Payment/Invoice with 0 quantity line. Please increase the quantity or remove the line.', 'center', 5000);
                    return;
                }
            } else {
                this.commonService.showNotification('error','Cannot proceed to Payment/Invoice with 0 Transactions.', 'center', 5000);
                return;
            }

            if (this.invoice.paymentAmount > 0 && this.invoice.paymentType === PaymentMethods.notApplicable.paymentType) {
                // this.commonService.showNotification('warning', 'Amount is greater than zero with payment type NA!')
            }

            if (this.isChanged()) {
                this.commonService.showConfirmation('Changes you have made will be saved! Do you want to proceed to invoicing?')
                    .then(result => {
                        if (result.isConfirmed) {
                            this.save(false, true);
                        }
                    });
            } else {
                this.check0PriceForInvoicing();
            }

        } else {
            this.commonService.showNotification('error', 'Please provide all the required information to proceed');
        }
    }

    check0PriceForInvoicing() {
        if (this.data?.assignmentDetails?.findIndex(x => !x.price) > -1) {
            this.commonService.showConfirmation('This service order contains transactions where price equals 0.\n Do you still want to proceed to invoicing?')
            .then(response => {
                if (response.isConfirmed) {
                    this.continueInvoicing();
                }
            })
        } else {
            this.continueInvoicing();
        }
    }

    continueInvoicing() {
        if (this.invoice.status === InvoiceStatuses.signed) {
            this.isSigned = true;
            this.showInvoicePopup = true;
        } else if (this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.invoice.checkAuthCode) {
            this.isSigned = this.invoice.status === this.invoiceStatuses.signed;
            this.disablePaymentButton = true;
            this.showInvoicePopup = true;
        } else if (this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.USER.company === this.companies.conway) {
            this.showChasePaymentIframe = true;
        } else if (this.invoice.paymentType == PaymentMethods.creditCard.paymentType && this.USER.company === this.companies.kingslynn) {
            this.showKingslynnPaymentPopup = true;
        } else {
            this.showPaymentPopup = true;
        }
    }

    onPaymentSelectionPopupClosed(invoice: Invoice) {
        if (invoice) {
            invoice.paymentType = invoice.paymentType + '';
            this.invoice = invoice;
        }
        this.showPaymentSelectionPopup = false;
    }

    onPaymentTypePopupClose() {
        this.showPaymentPopup = false;
    }

    ccPaymentAuthcode(eventArgs){
        this.invoice.checkAuthCode = eventArgs;
        this.disablePaymentButton = true;
    }

    ccPaymentCompleted(){
        this.serviceOrderService.popupLoader.next(true);
        this.commonService.showNotification('info', 'Generating invoice');
        this.serviceOrderService.getIscInvoice(this.invoice.invoiceId).subscribe((result: ApiResponse) => {
            if (result.isSuccessful) {
                this.showChasePaymentIframe = false;
                this.cdr.detectChanges();
                this.invoice = result.result;
                this.check0PriceForInvoicing();
                this.serviceOrderService.popupLoader.next(false);
            } else {
                this.commonService.showNotification('error', "Something went wrong. Kindly, try again.")
            }
        }, (error: HttpErrorResponse) => {
            this.commonService.showNotification('error', error.message);
        });
    }

    ccPaymentCancel(){
        this.showChasePaymentIframe = false;
        this.cdr.detectChanges();
    }

    createClaimFromServiceOrder() {
        this.showLoader = true;
        this.claimService.createClaimFromSO(this.data.serviceOrderId)
        .subscribe((res: ApiResponse) => {
            if (res.isSuccessful) {
                const hasServiceOrderIdLN = res.result.serviceOrderIdLN ? ' - ' + res.result.serviceOrderIdLN : '';
                this.commonService.showNotification('success', `Claim ${res.result.claimId} has been created for service order ${res.result.serviceOrderId}${hasServiceOrderIdLN} Successfully!`);
                this.showLoader = false;
            } else {
                this.commonService.showNotification('error', res.message);
                this.showLoader = false;
            }
        }, error => {
            this.commonService.showNotification('error', error.message);
            this.showLoader = false;
            throw error;
        });
    }
    
    onInvoiceSigned(invoice: Invoice, closeInvoicePopup: boolean) {
        this.invoice = invoice;
        this.statusDropdown.dataSource = this.statuses.filter(status => status.text === serviceOrderStatuses.completed);
        if(closeInvoicePopup){
            this.showInvoicePopup = false;
        }
        this.reload();
        setTimeout(() => {
            this.createClaimFromServiceOrder();
        }, 1000);

        this.data.warrantyCoverageLines = JSON.stringify(this.warrantyContractInfo['warrantyInfo']);
        this.data.contractCoverageLines = JSON.stringify(this.warrantyContractInfo['contractInfo']);

        this.serviceOrderService.updateISCServiceOrderContractAndWarranty({
            serviceOrderId: this.id,
            warrantyCoverageLines: this.data.warrantyCoverageLines,
            contractCoverageLines: this.data.contractCoverageLines
        }).subscribe(res => {
            this.onNeedRefresh.emit();
        });
    }

    onInvoicePopupClosed(invoice: Invoice) {
        if(invoice){
            this.invoice = invoice;
            if (this.invoice.status === InvoiceStatuses.signed && this.data.status !== serviceOrderStatuses.completed) {
                this.data.status = serviceOrderStatuses.completed;
                this.save();
            }
            this.showInvoicePopup = false;
        } else {
            this.showInvoicePopup = false;
            this.commonService.showNotification('error', "Something went wrong. Please, contact support.")
        }
    }

    onPaymentTypePopupNext(invoice: Invoice) {
        this.invoice = invoice;
        if (this.invoice.paymentType == PaymentMethods.creditCard.paymentType) {
            this.showPaymentFromCreditCardPopup = true;
        } else {
            this.showPaymentPopup = false;
            this.showInvoicePopup = true;
        }
    }

    onCancel() {
        // this.showPaymentFromCreditCardPopup = false;
        this.showKingslynnPaymentPopup = false;
    }

    onPay(invoice: Invoice) {
        this.invoice = invoice;
        this.showPaymentFromCreditCardPopup = false;
        this.showPaymentPopup = false;
        this.showInvoicePopup = true;
        this.setEditable();
    }

    getServiceOrderStatusList() {
        const statuses = this.statuses;
        const { role } = this.USER;
        const { serviceOrderId, status, assignments, calibrations, callGroup } = this.data;
        const { open, inProgress, completed, costed, awaitingPayment, paused, enroute, scheduled, cancelled, pendingApproval, estimateApproved, estimateRejected, importPublishError, exportPublishError } = serviceOrderStatuses;

        let validStatusesList = [];
        const addStatuses = (...statusesToAdd) => validStatusesList.push(...statusesToAdd);

        if (this.isCsa && ![completed, costed, pendingApproval ].includes(status)) {
            return statuses.filter(s => s.text === status || s.text === cancelled);
        }

        if(!this.hasCheckedInServiceOrder && status !== pendingApproval){
            addStatuses(inProgress);
        }
        if(!this.hasEnrouteServiceOrder && status !== pendingApproval){
            addStatuses(enroute);
        }

        if (!this.data) return;
        
        if ([importPublishError, exportPublishError].includes(status)) {
            return statuses.filter(s => s.text === status);
        } 
    
        if (this.hasCheckedInServiceOrder && role === UserRoles.fst) {
            if (status !== inProgress && status !== open) {
                return statuses.filter(s => [status, cancelled].includes(s.text));
            }
            if (status === open) {
                return statuses.filter(s => [cancelled, open, scheduled].includes(s.text));
            }
        }

        switch(status) {
            case open:
                if (serviceOrderId) 
                    addStatuses(open, scheduled, paused, cancelled);
                else 
                    return statuses.filter(s => s.text === open);
                break;
            case scheduled: 
                addStatuses(scheduled);
                if(assignments?.length){
                    addStatuses(awaitingPayment);
                }
                addStatuses(paused, cancelled);
                break;
            case inProgress:
                addStatuses(inProgress, scheduled);
                if(assignments?.length){
                    addStatuses(awaitingPayment);
                }
                addStatuses(paused, cancelled);
                break;
    
            case paused:
                addStatuses(paused, scheduled, cancelled);
                if (callGroup === CallGroups.initialCalibration && calibrations?.length) {
                    addStatuses(completed);
                }
                break;
    
            case awaitingPayment:
                addStatuses(awaitingPayment, scheduled, cancelled);
                break;
    
            case enroute:
                addStatuses(enroute, scheduled, paused, awaitingPayment, cancelled);
                break;
    
            case cancelled:
                return statuses.filter(s => s.text === cancelled);
    
            case completed:
                return statuses.filter(s => s.text === completed);
    
            case costed:
                return statuses.filter(s => s.text === costed);
    
            case pendingApproval:
                addStatuses(pendingApproval);
                (this.isManager || this.isCsa) && addStatuses(estimateApproved, estimateRejected );
                break;
    
            case estimateApproved:
                case estimateRejected:
                    addStatuses(status, scheduled, paused, awaitingPayment, cancelled);
                    break;
    
            default:
                return statuses.filter(s => s.text === status);
        }

        return statuses.filter(s => validStatusesList.includes(s.text));
    }

    reload(isEstimating?) {
        this.serviceOrderService.popupLoader.next(true);
        this.serviceOrderService.getServiceOrderById(this.data.serviceOrderId, true)
            .subscribe((res: ApiResponse) => {
                this.data = res.result;
                this.assignments = this.data.assignments;
                this.transactions = this.data.assignmentDetails;
                this.attachmentCount = this.data.attachments?.length || 0;
                if(isEstimating){
                    this.estimateInvoiceFileAttachment = this.data.attachments.find( att => att.fileId === this.estimateInvoiceFileId)
                    this.showEstimatedInvoice=true;
                }
                this.loadPage();
                this.setData();
                this.loadedData = JSON.stringify(this.data);
                this.setEditable();
                this.serviceOrderService.popupLoader.next(false);
            }, (error: HttpErrorResponse) => {
                this.serviceOrderService.popupLoader.next(false);
            });
    }

    showEstimateInvoice(isEstimating){
        this.serviceOrderService.generateIscEstimateInvoice(this.data.serviceOrderId).subscribe((res: any) => {
            this.estimateInvoiceUri = res.uri;
            this.estimateInvoiceFileId = res.fileId;
            this.reload(isEstimating);
            this.serviceOrderService.popupLoader.next(false);
        });
    }

    ngOnDestroy() {
        this.technicianDropdown
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    setChecklistId(event: any)
    {
        this.checklistId=event;
    }

    updateAttachments(args: any){
        this.reload();
        this.onNeedRefresh.emit();
    }
}
