import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommandColumnService, ExcelExportProperties, ExcelExportService, FilterService, GridComponent, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { ApiResponse, UserRoles } from 'src/app/shared/models/app.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { getExcelDataSource, gridActionBeginForFiltering, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from '../../../../shared/services/common.service';
import { ServiceOrderService } from '../../services/service-order.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
    selector: 'app-history-tab',
    templateUrl: './history-tab.component.html',
    styleUrls: ['./history-tab.component.scss'],
    providers: [
        ToolbarService,
        FilterService,
        SortService,
        ExcelExportService,
        CommandColumnService
    ]
})
export class ServiceOrderHistoryTabComponent implements OnInit, OnChanges {
    private destroy$: Subject<null> = new Subject();

    @Input() serviceOrder = null;

    modelDescription: any;
    serialNumber: any;
    equipmentInfoData: any;

    equipmentGrid = {
        toolbar: [
            { id: 'column-chooser', tooltipText: 'Show/Hide Columns' }, 'Search',
            { text: '', id: 'clear-filter', align: 'right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ],
        filterSettings: { type: 'Menu' },
        columns: [],
        dataSource: []
    }

    serviceOrderGrid = {
        toolbar: [
            { id: 'column-chooser', tooltipText: 'Show/Hide Columns' }, 'Search',
            { text: '', id: 'clear-filter', align: 'right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ],
        filterSettings: { type: 'Menu' },
        columns: [],
        dataSource: []
    }

    @ViewChild('equipmentsGrid') equipmentsGrid: GridComponent;
    @ViewChild('serviceOrdersGrid') serviceOrdersGrid: GridComponent;
    showPopup: boolean = false;
    showLoader: boolean = false;
    serviceOrderData: any = {};
    serviceOrderID: any = null;
    isIsc: boolean = false;
    isIscManager: boolean = false;
    isIscSupervisor: boolean;

    constructor(
        private commonService: CommonService,
        private serviceOrderService: ServiceOrderService
    ) { 
        this.isIsc = commonService.USER.role === UserRoles.isc;
        this.isIscManager = commonService.USER.role === UserRoles.iscManager;
        this.isIscSupervisor = commonService.USER.role === UserRoles.IscSupervisor;
        let columnChooserIndex = this.equipmentGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.equipmentGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
        columnChooserIndex = this.serviceOrderGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.serviceOrderGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
    }

    ngOnInit(): void {
        if ( this.serviceOrder?.serviceOrderId ) { this.getEquipmentHistory(); }
        this.serviceOrder?.history?.map(item => {
            item.CreatedDate = new Date(item.CreatedDate);
        });

        this.serviceOrderService.modelDescriptionChange.subscribe(res => {
            this.modelDescription = res;
        });

        this.serviceOrderService.serialNumberChange.pipe(
            takeUntil(this.destroy$),
        ).subscribe((args: any) => {
            this.serialNumber = args.itemData ? args.itemData.serialNumber : null;
        });

        this.serviceOrderService.equipmentInfoSubject.pipe(
            takeUntil(this.destroy$),
        ).subscribe(equipmentInfo => {
            this.equipmentInfoData = equipmentInfo;
            // this.modelDescription = equipmentInfo.description;
            // this.serialNumber = equipmentInfo.value;
        });

        this.serviceOrderGrid.dataSource = this.serviceOrder.history;
        this.initServiceOrderGrid();
    }

    ngOnChanges(change: SimpleChanges) {
        if (change && change.serviceOrder) {
            this.serviceOrder = change.serviceOrder.currentValue;
            this.serviceOrderGrid.dataSource = this.serviceOrder.history;
        }
    }

    getEquipmentHistory() {
        this.serviceOrderService.getEquipmentHistory(
            this.serviceOrder.serviceOrderId,
            this.serviceOrder.itemCode,
            this.serviceOrder.serialNumber,
            this.isIsc || this.isIscManager || this.isIscSupervisor
        ).subscribe( (res: ApiResponse) => {
            if ( res.isSuccessful ) {
                res.result.map( row => {
                    row.createdDate = new Date(row.createdDate);
                });
                this.equipmentGrid.dataSource = res.result;
                this.initEquipmentGrid();
            } else {
                this.initEquipmentGrid();
            }
        }, (error: HttpErrorResponse) => {
            this.initEquipmentGrid();
        });
    }

    initEquipmentGrid() {
        this.equipmentGrid.columns = this.getEquipmentsGridColumns();
    }

    initServiceOrderGrid() {
        this.serviceOrderGrid.columns = this.getServiceOrdersGridColumns();
    }

    getEquipmentsGridColumns() {
        return [
            { field: 'serviceOrderId', headerText: 'Order No.', textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
            { field: 'technicianName', headerText: 'Technician Name', type: 'String', textAlign: 'Left', width: 180, visible: true , filter: { type: 'Excel' }},
            { field: 'createdDate', headerText: 'Start Date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 140, visible: true },
            { field: 'callGroup', headerText: 'Call Group', type: 'String', textAlign: 'Left', visible: true , filter: { type: 'Excel' }},
            { field: 'totalAmount', headerText: 'Total Amount', type: 'Number', textAlign: 'Right', width: 200 , filter: { type: 'Excel' }}
        ]
    }

    getServiceOrdersGridColumns() {
        return [
            { field: 'createdByName', headerText: 'Updated By', width: 200, textAlign: 'Left' , filter: { type: 'Excel' } },
            { field: 'text', headerText: 'Description', textAlign: 'Left' },
            { field: 'createdDate', headerText: 'Created Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y hh:mm' }, width: 180 },
        ]
    }

    onGridActionBegin(args, grid?) {
        gridActionBeginForFiltering(args, grid);
    }

    onGridActionComplete(args, grid) {
        updateFilterIcon(args, grid);
    }


    onToolbarClick(args: ToolbarClickEventArgs, grid: GridComponent) {
        if (args.item.id === 'excel-export') {
            handleGridExcelExport(grid, 'Equipment History');
        }  else if (args.item.id === 'clear-filter') {
            handleClearFilter(grid);
        }
    }

    onHistoryToolbarClick(args: ToolbarClickEventArgs,  grid: GridComponent) {
        if (args.item.id === 'excel-export') {
            handleGridExcelExport(grid, 'Service Orders History');
        }  else if (args.item.id === 'clear-filter') {
            handleClearFilter(grid);
        }
    }

    onServiceOrderClick(serviceOrderID) {
        this.serviceOrderService.getServiceOrderById(serviceOrderID)
            .subscribe((res: any) => {
                this.serviceOrderID = serviceOrderID;
                this.serviceOrderData = res.result;
                this.showLoader = false;
                this.showPopup = true;
            }, (error: HttpErrorResponse) => {
                this.showLoader = false;
                throw error;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
