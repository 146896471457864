import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { GridComponent, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { CommonService } from 'src/app/shared/services/common.service';
import { Query } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { getExcelDataSource, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { OrderTypes, UserRoles } from 'src/app/shared/models/app.model';
import { shipmentOrderStatuses } from '../../../models/orders.model';
import { MouseEventArgs } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-shipment-order-items-tab',
  templateUrl: './shipment-order-items-tab.component.html',
  styleUrls: ['./shipment-order-items-tab.component.scss']
})
export class ShipmentOrderItemsTabComponent implements OnInit, OnChanges {

  showColumnChooser: boolean;
  columns: Object[];
  validationApplied: boolean = false;
  canEditItemGrid: boolean = false;
  itemsField;
  itemsObject: DropDownList;

  itemGroupField;
  itemGroupObject: any;

  itemDescriptionField;
  itemDescriptionObject: any;

  itemQuantityReceivedField: any;
  itemQuantityReceivedObject: any;

  itemServiceableField: any;
  itemServiceableObject: any;

  itemOverShippedField: any;
  itemOverShippedObject: any;

  itemShortShippedField: any;
  itemShortShippedObject: any;

  itemDamagedField: any;
  itemDamagedObject: any;

  unknownPartField: any;
  unknownPartObject: any;

  wrongPartField: any;
  wrongPartObject: any;

  itemsQuery: any;

  linkedPopupData: any = null;

  @Input() items = [];
  @Input() itemsDDL: any;
  @Input() shipmentOrderId: any;
  @Input() inventoryItems: any;
  @Input() reasonDDL: any;
  @Input() status;
  @Input() updatedException: any;

  @Output() onQuantityReceivedUpdate = new EventEmitter();
  @Output() onQuantityReceivedReset = new EventEmitter();
  @Output() onUpdate = new EventEmitter();

  @ViewChild('shipmentOrderItemsGrid') itemsGrid: GridComponent;
  @ViewChild('shipmentOrderItemsGridColumnChooser') columnChooser;
  selectedRowId: any;
  showWrongItemPopup: boolean = false;
  wrongPartsList: any = [];
  wrongPartsQuantity: number = 0;
  currentRowData: any;
  gridHeight: number;
  wronPartPopupClosed: boolean = false;

  USER;
  isManager: boolean = false;
  isCSA: boolean = false;

  constructor(private commonService: CommonService, private eRef: ElementRef) {
    this.USER = commonService.USER;
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.itemsGrid.isEdit && !this.showWrongItemPopup) {
      if (Boolean(!this.eRef.nativeElement.contains(event.target))) {
        this.itemsGrid.endEdit();
      }
    }
  }

  ngOnInit(): void {
    this.isCSA = this.USER.role === UserRoles.csa;
    this.isManager = this.USER.role === UserRoles.manager;
    this.canEditItemGrid = this.status === shipmentOrderStatuses.deliveryAcknowledged && !(this.isManager || this.isCSA);
    this.columns = this.getGridColumns();
    this.gridHeight = document.getElementsByClassName('modal-body')[0].scrollHeight - 170;
    this.setItemsData();
  }

  ngOnChanges() {

  }

  load(args) {
    this.itemsGrid.element.addEventListener('mouseup', (e: MouseEventArgs) => {
      if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
        const cellIndex = +(e.target as HTMLElement).getAttribute('data-colindex');
        this.itemsGrid.isEdit && this.itemsGrid.endEdit();
        let rowIndex: number = parseInt((e.target as HTMLElement).getAttribute("Index"));
        this.itemsGrid.selectRow(rowIndex);
        this.itemsGrid.startEdit();
        const clickedColumnName = this.itemsGrid.getColumnByIndex(cellIndex)?.field;
        clickedColumnName && document.getElementById('itemsGrid' + clickedColumnName).focus();
      };
    });
  }

  setItemsData() {
    this.items?.map((item, index) => {
      item.rowId = index;
      return this.setItemsRow(item, index);
    });
  }

  setItemsRow(item, index) {
    item.shoDetailId = item.shoDetailId || '',
      item.lineNumber = index + 1;
    item.itemCode = item.itemCode || item.item || '';
    item.itemDescription = item.itemDescription || item.description || '';
    item.itemGroup = item.itemGroup || '';
    item.quantityShippedInInventoryUnit = item.quantityShippedInInventoryUnit || null;
    item.quantityReceived = item.quantityReceived ?? null;
    item.quantityServiceable = item.quantityServiceable ?? null;
    item.createdBy = item.createdBy || null;
    item.createdByName = item.createdByName || '';
    item.lastUpdatedBy = item.lastUpdatedBy || null;
    item.lastUpdatedByName = item.lastUpdatedByName || '';
    item.wrongPartsList = item.wrongPartsList || [];

    return item;
  }

  onGridCreated() {
    this.itemsQuery = new Query().where('isDeleted', 'notequal', true);
    this.itemsGrid.dataSource = this.items;
    this.itemsGrid.toolbar = [
      { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.itemsGrid.toolbar.findIndex(x => x === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.itemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
    updateFilterIcon({ requestType: 'filtering' }, this.itemsGrid);
  }

  getData() {
    return {
      itemList: (this.itemsGrid.dataSource as any).map((item, index) => {
        item.warehouseOrderLN = item.warehouseOrderLN || item.partsOrderRef;
        return this.setItemsRow(item, index);
      })
    }
  }

  getGridColumns() {
    return [
      { field: 'shipmentLine', headerText: 'Sr. No.', visible: false },
      { field: 'partsOrderRef', headerText: 'Part Order', type: 'string', textAlign: 'Left', width: 100 },
      // { field: 'lineNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, visible: false },
      { field: 'msOrderLine', headerText: 'Ord Line#', type: 'number', format: 'n', textAlign: 'Left', width: 100, visible: false, },
      { field: 'itemCode', headerText: 'Item', type: 'string', textAlign: 'Left', width: 130, filter: { type: 'Excel', operator: 'contains' } },
      { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left', visible: false },
      { field: 'warehouseOrderLN', headerText: 'LN Warehouse Order No', type: 'string', textAlign: 'Left', width: 130, filter: { type: 'Menu', operator: 'contains' } },
      { field: 'warehouseOrderLNLine', headerText: 'LN WH Line#', type: 'number', format: 'n', textAlign: 'Left', width: 130, visible: false },
      { field: 'warehouseOrderLNSequence', headerText: 'LN WH Line Seq.', type: 'number', format: 'n', textAlign: 'Left', width: 130, visible: false },
      { field: 'itemGroup', headerText: 'Item Group', type: 'string', textAlign: 'Left', filter: { type: 'CheckBox' }, width: 150, visible: false },
      { field: 'orderQty', headerText: 'Ord Qty', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      { field: 'quantityShippedInInventoryUnit', headerText: 'Ship Qty', type: 'number', format: 'n', textAlign: 'right', width: 157 },
      { field: 'quantityReceived', headerText: 'Rec Qty', type: 'number', format: 'n', textAlign: 'right', width: 184, edit: this.initItemQuantityReceived() }, //, validationRules: { required: true } },
      { field: 'quantityServiceable', headerText: 'Serviceable', type: 'number', format: 'n', textAlign: 'right', width: 184, edit: this.initItemServiceable() }, //validationRules: { required: true } },
      { field: 'overShipment', headerText: 'Over Shipment', type: 'number', format: 'n', textAlign: 'right', width: 142, edit: this.initOverShippedItem() },
      { field: 'shortShipment', headerText: 'Short Shipment', type: 'number', format: 'n', textAlign: 'right', width: 142, edit: this.initShortShippedItem() },
      { field: 'damagedPart', headerText: 'Damaged Part', type: 'number', format: 'n', textAlign: 'right', width: 142, edit: this.initDamagedPart() },
      { field: 'wrongPart', headerText: 'Wrong Part', type: 'number', format: 'n', textAlign: 'right', width: 142, edit: this.initWrongPart() },
      { field: 'unknownPart', headerText: 'Unknown Part', type: 'number', format: 'n', textAlign: 'right', width: 142, edit: this.initUnknownPart() },
      { field: 'backOrder', headerText: 'Back Order', type: 'number', format: 'n', textAlign: 'right', width: 142, allowEditing: false, visible: false },
    ];
  }


  actionBegin(args) {
    if (args.requestType === 'sorting') {
      if (this.itemsGrid.isEdit) {
        args.cancel = true;
      }
    } else if (args.requestType === 'save') {
      let totalQuantity = 0;
      if (this.wrongPartObject.value > 0) {
        this.wrongPartsList.forEach(wrongPart => {
          totalQuantity += +wrongPart.quantity;
        });
        if (totalQuantity !== this.wrongPartObject.value) {
          args.cancel = true;
          this.commonService.showNotification('error', 'You have not added ' + this.wrongPartObject.value + ' wrong parts.');
          if (!this.wronPartPopupClosed) {
            setTimeout(() => {
              this.onShowWrongItemPopup();
            }, 500);
          }
        }
      }
    }
  }

  actionComplete(args) {
    if (args.requestType === 'save') {
      this.itemsGrid.dataSource[this.selectedRowId]['quantityReceived'] = this.itemQuantityReceivedObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['quantityServiceable'] = this.itemServiceableObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['overShipment'] = this.itemOverShippedObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['shortShipment'] = this.itemShortShippedObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['damagedPart'] = this.itemDamagedObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['wrongPart'] = this.wrongPartObject.value;
      this.itemsGrid.dataSource[this.selectedRowId]['unknownPart'] = this.unknownPartObject.value;

      this.itemsGrid.dataSource[this.selectedRowId]['wrongPartsList'] = this.wrongPartsList;
      let exceptionsList = this.generateExceptions(this.itemsGrid.dataSource);
      this.onUpdate.emit(exceptionsList);
      // if (args.data.quantityReceived !== args.data.quantityShippedInInventoryUnit) {
      //     this.onQuantityReceivedUpdate.emit(args.data);
      // } else {
      //     this.onQuantityReceivedReset.emit(args.data.itemCode);
      // }
    }
    updateFilterIcon({ requestType: 'filtering' }, this.itemsGrid);
  }

  generateExceptions(itemsList) {
    let exceptionsList = [];
    let count = 1;
    itemsList.forEach(row => {
      if (row.overShipment) {
        exceptionsList.push(this.addExceptionRow(count++, row, this.reasonDDL.find(x => x.text === 'Excess shipment'), row.overShipment));
      }
      if (row.shortShipment) {
        exceptionsList.push(this.addExceptionRow(count++, row, this.reasonDDL.find(x => x.text === 'Short Shipment '), row.shortShipment));
      }
      if (row.damagedPart) {
        exceptionsList.push(this.addExceptionRow(count++, row, this.reasonDDL.find(x => x.text === 'Damaged'), row.damagedPart));
      }
      if (row.unknownPart) {
        exceptionsList.push(this.addExceptionRow(count++, row, this.reasonDDL.find(x => x.text === 'Unknown'), row.unknownPart));
      }
      if (row.wrongPart) {
        if (row.wrongPartsList && row.wrongPartsList.length) {
          row.wrongPartsList.forEach(wrongPart => {
            if (wrongPart.quantity > 0) {
              exceptionsList.push(this.addExceptionRow(count++, row, this.reasonDDL.find(x => x.text === 'Wrong part'), wrongPart.quantity, wrongPart));
            }
          });
        }
      }
    });

    return exceptionsList;
  }

  addExceptionRow(lineNumber, exception, reasonData, quantity, wrongPart?) {
    return {
      shoExceptionId: 0,
      lineNumber: lineNumber,
      itemCode: wrongPart ? wrongPart.itemCode : reasonData.value === this.reasonDDL.find(x => x.text === 'Unknown').value ? this.commonService.unknownPart.value : exception?.itemCode || '',
      itemDescription: wrongPart ? wrongPart.itemDescription : reasonData.value === this.reasonDDL.find(x => x.text === 'Unknown').value ? this.commonService.unknownPart.text : exception?.itemDescription || '',
      itemGroup: wrongPart ? wrongPart.itemGroup : exception?.itemGroup || null,
      quantity: quantity || 0,
      reasonCode: reasonData.value,
      reasonDescription: reasonData.description,
      shipmentOrderDetailId: exception?.shipmentOrderDetailId || '',
      isDeleted: false,
      createdBy: '',
      createdDate: new Date(),
      createdByName: '',
      lastUpdatedDate: new Date(),
      lastUpdatedBy: '',
      lastUpdatedByName: '',
      isEditable: false,
      isManual: false
    }
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'grid_excelexport') {
      handleGridExcelExport(grid, 'Shipment Orders Items');
    } else if (args.item.id === 'grid_pdfexport') {
      this.itemsGrid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    }
  }

  validate(skipValidation) {
    if (skipValidation) {
      return true;
    }
    this.validationApplied = true;
    this.itemsGrid.endEdit();
    let quantityReceived = null, quantityServiceable = null;
    quantityReceived = (this.itemsGrid.dataSource as any).findIndex(element => element.quantityReceived === null);
    quantityServiceable = (this.itemsGrid.dataSource as any).findIndex(element => element.quantityServiceable === null);
    if (Boolean(this.itemsGrid.dataSource) && !this.itemsGrid.isEdit && quantityReceived === -1 && quantityServiceable === -1) {
      return true;
    } else {
      this.itemsGrid.selectRow(quantityReceived !== -1 ? quantityReceived : quantityServiceable);
      setTimeout(() => {
        this.itemsGrid.startEdit();
      }, 300);
      document.getElementById('items-tab-button').click();
      this.commonService.showNotification('error', 'Mandatory fields in items are missing!');
      return false;
    }
  }

  updateGridRecords(isReceived) {
    if (isReceived) {

    }
  }

  initItemQuantityReceived() {
    return {
      create: () => {
        this.itemQuantityReceivedField = document.createElement('input');
        return this.itemQuantityReceivedField;
      },
      read: () => {
        if (this.itemQuantityReceivedObject) {
          return this.itemQuantityReceivedObject.value;
        }
      },
      destroy: () => {
        this.itemQuantityReceivedObject.destroy();
      },
      write: (argsData) => {
        this.itemQuantityReceivedObject = new NumericTextBox({
          value: argsData.rowData.quantityReceived,
          showSpinButton: false,
          decimals: 0,
          min: 0,
          change: args => {
            if (args.value != null) {
              this.itemServiceableObject.max = args.value;
              this.itemQuantityReceivedObject.value = args.value;
              this.itemServiceableObject.value = this.itemQuantityReceivedObject.value;

              if (args.value > argsData.rowData.quantityShippedInInventoryUnit) {
                this.itemOverShippedObject.value = args.value - argsData.rowData.quantityShippedInInventoryUnit;
                this.itemShortShippedObject.value = 0;
              } else if (args.value < argsData.rowData.quantityShippedInInventoryUnit) {
                this.itemShortShippedObject.value = argsData.rowData.quantityShippedInInventoryUnit - args.value;
                this.itemOverShippedObject.value = 0;
              } else {
                this.itemShortShippedObject.value = this.itemOverShippedObject.value = 0;
              }
            } else {
              this.itemShortShippedObject.value = this.itemServiceableObject.value = this.itemOverShippedObject.value = this.itemDamagedObject.value = this.wrongPartObject.value = this.unknownPartObject.value = null;
            }
          },
          created: () => {
            this.itemQuantityReceivedField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.itemQuantityReceivedObject.value = +event.target.value;
              }
              if (event.keyCode === 8) {
                this.itemQuantityReceivedObject.value = event.target.value ? +event.target.value : null;
              }
            }
          }
        });
        this.itemQuantityReceivedObject.appendTo(this.itemQuantityReceivedField);
      }
    }
  }

  initItemServiceable() {
    return {
      create: () => {
        this.itemServiceableField = document.createElement('input');
        return this.itemServiceableField;
      },
      read: () => {
        if (this.itemServiceableObject) {
          return this.itemServiceableObject.value;
        }
      },
      destroy: () => {
        this.itemServiceableObject.destroy();
      },
      write: (argsData) => {
        this.itemServiceableObject = new NumericTextBox({
          value: argsData.rowData.quantityServiceable,
          showSpinButton: false,
          decimals: 0,
          min: 0,
          change: args => {
            this.itemServiceableObject.value = args.value;
            if (!args.value || args.value === 0) {
              this.itemDamagedObject.value = this.itemQuantityReceivedObject.value;
              this.itemDamagedObject.max = this.itemQuantityReceivedObject.value;
            } else if (args.value < this.itemQuantityReceivedObject.value) {
              this.itemDamagedObject.value = this.itemQuantityReceivedObject.value - args.value;
              this.itemDamagedObject.max = this.itemQuantityReceivedObject.value - args.value
            } else if (args.value === this.itemQuantityReceivedObject.value) {
              this.itemDamagedObject.value = 0;
              this.itemDamagedObject.max = 0;
            }
          },
          created: () => {
            this.itemServiceableField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.itemServiceableObject.value = +event.target.value;
              }
            }
          }
        });
        this.itemServiceableObject.appendTo(this.itemServiceableField);
      }
    }
  }

  initOverShippedItem() {
    return {
      create: () => {
        this.itemOverShippedField = document.createElement('input');
        return this.itemOverShippedField;
      },
      read: () => {
        if (this.itemOverShippedObject) {
          return this.itemOverShippedObject.value;
        }
      },
      destroy: () => {
        this.itemOverShippedObject.destroy();
      },
      write: (row) => {
        this.itemOverShippedObject = new NumericTextBox({
          value: row.rowData.overShipment,
          showSpinButton: false,
          enabled: false,
          decimals: 0,
          min: 0,
          change: args => {
          },
          created: args => {
          }
        });
        this.itemOverShippedObject.appendTo(this.itemOverShippedField);
      }
    }
  }

  initShortShippedItem() {
    return {
      create: () => {
        this.itemShortShippedField = document.createElement('input');
        return this.itemShortShippedField;
      },
      read: () => {
        if (this.itemShortShippedObject) {
          return this.itemShortShippedObject.value;
        }
      },
      destroy: () => {
        this.itemShortShippedObject.destroy();
      },
      write: (row) => {
        this.itemShortShippedObject = new NumericTextBox({
          value: row.rowData.shortShipment,
          showSpinButton: false,
          enabled: false,
          decimals: 0,
          min: 0,
          change: args => {
          },
          created: args => {
          }
        });
        this.itemShortShippedObject.appendTo(this.itemShortShippedField);
      }
    }
  }

  initDamagedPart() {
    return {
      create: () => {
        this.itemDamagedField = document.createElement('input');
        return this.itemDamagedField;
      },
      read: () => {
        if (this.itemDamagedObject) {
          return this.itemDamagedObject.value;
        }
      },
      destroy: () => {
        this.itemDamagedObject.destroy();
      },
      write: (row) => {
        this.itemDamagedObject = new NumericTextBox({
          value: row.rowData.damagedPart,
          showSpinButton: false,
          decimals: 0,
          min: 0,
          change: args => {
            // this.wrongPartObject.max = args.value;
            // this.unknownPartObject.max = args.value;
          },
          created: () => {
            this.itemDamagedField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.itemDamagedObject.value = +event.target.value;
              }
            }
          }
        });
        this.itemDamagedObject.appendTo(this.itemDamagedField);
      }
    }
  }

  initWrongPart() {
    return {
      create: (args) => {
        this.selectedRowId = args.data.rowId;
        this.wrongPartField = document.createElement('input');
        return this.wrongPartField;
      },
      read: () => {
        if (this.wrongPartObject) {
          return this.wrongPartObject.value;
        }
      },
      destroy: () => {
        this.wrongPartObject.destroy();
      },
      write: (row) => {
        this.wrongPartObject = new NumericTextBox({
          value: row.rowData.wrongPart,
          cssClass: 'generic-items-dropdown-list',
          showSpinButton: false,
          decimals: 0,
          min: 0,
          change: args => {
            this.wrongPartObject.value = args.value;
          },
          focus: args => {
            this.commonService.showNotification('info', 'Click on info icon to add wrong parts');
          },
          created: () => {
            this.wrongPartField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.wrongPartObject.value = +event.target.value;
              }
            }
          }
        });
        this.wrongPartObject.appendTo(this.wrongPartField);
        // this.currentRowData = row.rowData;
        this.addAdvancedSearchIcon();
      }
    }
  }

  addAdvancedSearchIcon() {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Add wrong items');
    itemAnchor.addEventListener('click', this.onShowWrongItemPopup.bind(this));
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.wrongPartField.parentElement.parentElement.append(itemAnchor);
  }

  onShowWrongItemPopup() {
    this.currentRowData = this.items[this.selectedRowId];
    this.showWrongItemPopup = true;
  }

  onWrongItemPopupClose(event: any) {
    this.wronPartPopupClosed = event.justClose;
    this.showWrongItemPopup = false;
    this.currentRowData = null;
    // const rowIndex = this.itemsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
    if (!event.justClose) {
      this.wrongPartsList = event.data;
      this.wrongPartObject.value = event.totalQuantity;
    }
    // let exceptionsList = this.generateExceptions(this.itemsGrid.dataSource);
    // this.onUpdate.emit(exceptionsList);
    // this.itemsGrid.endEdit();
  }

  initUnknownPart() {
    return {
      create: () => {
        this.unknownPartField = document.createElement('input');
        return this.unknownPartField;
      },
      read: () => {
        if (this.unknownPartObject) {
          return this.unknownPartObject.value;
        }
      },
      destroy: () => {
        this.unknownPartObject.destroy();
      },
      write: (row) => {
        this.unknownPartObject = new NumericTextBox({
          value: row.rowData.unknownPart,
          showSpinButton: false,
          decimals: 0,
          min: 0,
          change: args => {
            this.unknownPartObject.value = args.value;
          },
          created: () => {
            this.unknownPartField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.unknownPartObject.value = +event.target.value;
              }
            }
          }
        });
        this.unknownPartObject.appendTo(this.unknownPartField);
      }
    }
  }


  onPartOrderClick(data) {
    this.linkedPopupData = {
      id: data.partsOrderRef,
      type: OrderTypes.partsOrder
    };
  }
}
