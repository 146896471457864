<app-loader *ngIf="loading"></app-loader>
<div class="tab-content-container mt-3 mb-2">
    <h4 class="border-bottom">Attachments</h4>
    <div class="row">
        <div class="col">
            <div class="border-bottom pb-1">
                <div class="add-button float-left" *ngIf="!addingAttachment && isEditable">
                    <a href="javascript:" (click)="addingAttachment = true"><i class="fas fa-plus"></i> New
                        Attachment</a>
                </div>
                <div class="attachments-buttons-container float-right">
                    <div class="icon d-inline-block mr-1" [ngClass]="{'active': isGridView && !addingAttachment}"
                        title="Show list view" (click)="isGridView = true; addingAttachment = false;">
                        <i class="fas fa-bars"></i>
                    </div>
                    <div class="icon d-inline-block" [ngClass]="{'active': !isGridView && !addingAttachment}"
                        title="Show grid view" (click)="isGridView = false; addingAttachment = false;">
                        <i class="fas fa-border-all"></i>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!addingAttachment">
        <div class="col" *ngIf="isGridView">
            <div class="attachments-grid" *ngIf="attachmentGrid.columns && attachmentGrid.columns.length">
                <ejs-grid #attachmentsGrid 
                    [id]='"attachmentsList"'
                    [dataSource]="attachments" 
                    [allowFiltering]="true" 
                    [allowSorting]="true"
                    [allowResizing]="true" 
                    [allowReordering]="true"
                    [showColumnChooser]="true" 
                    [allowExcelExport]="true"
                    [editSettings]="{ allowEditing: true, mode: 'Normal' }" 
                    [toolbar]="attachmentGrid.toolbar"
                    [filterSettings]="{ type: 'Menu' }" 
                    (commandClick)="onCommandClick($event)"
                    (rowDataBound)="onRowDataBound($event)" 
                    (created)="onGridCreated($event)"
                    (excelQueryCellInfo)="onExcelQueryCellInfo($event)" 
                    (toolbarClick)="onToolbarClick($event, attachmentGrid)"
                    (actionBegin)="onGridActionBegin($event)"
                    (actionComplete)="onGridActionComplete($event, attachmentsGrid)"
                    ><e-columns>
                        <e-column *ngFor="let column of attachmentGrid.columns;" 
                            [field]="column.field"
                            [headerText]="column.headerText" 
                            [clipMode]="'EllipsisWithTooltip'" 
                            [type]="column.type"
                            [width]="column.width" 
                            [format]="column.format ? column.format : ''"
                            [textAlign]="column.textAlign" 
                            [visible]="column.visible"
                            [filter]="column.filter ? column.filter : { type: 'Menu'}"
                            [commands]="column?.commands ? column.commands : null" 
                            [allowEditing]="column.allowEditing"
                            [allowSorting]="column.allowSorting"
                            [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                            <ng-template *ngIf="column.field === 'fileSize'" #template let-data>
                                <span class="">{{getFileSize(data.fileSize)}}</span>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
        <div class="col" *ngIf="!isGridView">
            <div class="row attachments-grid-view pt-3">
                <div class="col-2" *ngFor="let attachment of attachments">
                    <div class="attachment-container">
                        <div class="attachments-overlay">
                            <div class="overlay-button">
                                <a href="javascript:" (click)="downloadFile(attachment.uri, attachment.fileName)"
                                    title="Download Attachment"><i class="fas fa-download"></i></a>
                            </div>
                            <div class="overlay-button ml-3" *ngIf="!attachment.readOnly">
                                <a href="javascript:" (click)="deleteAttachment(attachment)"
                                    title="Delete Attachment"><i class="fas fa-trash"></i></a>
                            </div>
                        </div>
                        <div class="image-icon-container text-center">
                            <i class="far fa-file-pdf" *ngIf="attachment.fileType === 'pdf'"></i>
                            <i class="far fa-file-excel"
                                *ngIf="attachment.fileType === 'xls' || attachment.fileType === 'xlsx'"></i>
                            <i class="far fa-file-powerpoint"
                                *ngIf="attachment.fileType === 'ppt' || attachment.fileType === 'pptx'"></i>
                            <i class="far fa-file-word"
                                *ngIf="attachment.fileType === 'doc' || attachment.fileType === 'docx'"></i>
                            <img *ngIf="attachment.fileType === 'jpg' || attachment.fileType === 'png' || attachment.fileType === 'svg' || attachment.fileType === 'jpeg'"
                                [src]="attachment.uri" class="img img-responsive grid-view-img">
                        </div>
                        <div class="file-name mt-1 text-truncate">{{attachment.fileName}}</div>
                        <div class="user-info mt-1 d-flex align-items-center">
                            <div class="user-icon mr-2"><i class="fas fa-user"></i></div>
                            <div class="user-name">{{attachment.uploadedByName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="addingAttachment && isEditable">
        <div class="col uploader-section">
            <ejs-uploader #attachmentUpload 
                id='attachmentUpload' 
                [asyncSettings]='path' 
                [dropArea]='dropElement'
                [maxFileSize]="3.2e+7"
                [allowedExtensions]="allowedExtensions"
                (created)="onUploaderCreated(attachmentUpload)"
                (uploading)="beforeFileUpload($event)" 
                (success)="onUploadSuccess($event)"
                (actionComplete)="onActionComplete($event)"
            ></ejs-uploader>
        </div>
    </div>
</div>

<ng-template #columnChooser>
    <app-column-chooser [columns]="attachmentGrid.columns" [grid]="grid" [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"></app-column-chooser>
</ng-template>

<app-email-popup *ngIf="showEmailPopup"
    [receiverEmail]="customerData?.customerEmail"
    [emailSubject]="emailSubject"
    [isAttachment]="true"
    [urlLink]="link"
    [attachment]="emailAttachment"
    [isReturnOrderStatement]="[returnOrderTypes.coreReturnOrder, returnOrderTypes.damagedReturnOrder].includes(module)"
    [returnOrderId]="[returnOrderTypes.coreReturnOrder, returnOrderTypes.damagedReturnOrder].includes(module) ? moduleId : null"
    [module]="[returnOrderTypes.coreReturnOrder, returnOrderTypes.damagedReturnOrder].includes(module) ? module : null"
    [technicianName]="employeeName"
    [isReturnOrderStatement]="[returnOrderTypes.coreReturnOrder, returnOrderTypes.damagedReturnOrder].includes(module) ? true : false"
    (onClose)="onClose()"
></app-email-popup>