import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AuthGuardService } from './shared/services/auth-guard.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './modules/auth/services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BuilderComponent } from './modules/builder/builder.component';
import { ViewerComponent } from './modules/viewer/viewer.component';
import { PdfViewerModule } from '@syncfusion/ej2-angular-pdfviewer';
import { HttpConfigInterceptor } from './shared/interceptor/httpconfig.interceptor';
import { HomeService } from './modules/home/services/home.service';
import { SharedModule } from './shared/shared.module';
import { CommonService } from './shared/services/common.service';
import { CycleCountService } from './modules/home/modules/cycle-counts/cycle-counts.service';
import { MessagingService } from './shared/services/messaging.service';

@NgModule({
    declarations: [
        AppComponent,
        BuilderComponent,
        ViewerComponent,
    ],
    imports: [
        BrowserModule,
        PdfViewerModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // NgxTributeModule,
        SharedModule,
        // AccumulationChartModule
    ],
    providers: [
        AuthService,
        AuthGuardService,
        MessagingService,
        HomeService,
        AsyncPipe,
        CommonService,
        CycleCountService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
