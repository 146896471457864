
<div class="row">
    <div class="col mt-4">
        <ejs-grid #returnOrderItemsGrid
            id="return-order-items-grid"
            [dataSource]="items"
            [height]="gridHeight"
            [allowPaging]="false"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [showColumnChooser]="true"
            [enablePersistence]="false"
            [allowExcelExport]="true"
            [query]="itemsQuery"
            [editSettings]="{ allowEditing: status === 'Open' && !isCSA && !isManager && !isReadOnly,  allowAdding: true, allowDeleting: true , newRowPosition: 'Top' }"
            [filterSettings]="{ type: 'Menu' }"
            (dataBound)="dataBound($event)"
            (rowDataBound)="rowDataBound($event)"
            (toolbarClick)="toolbarClick($event, returnOrderItemsGrid)"
            (created)="onGridCreated()"
            (actionBegin)="actionBegin($event)"
            (rowSelected)="rowSelected($event, returnOrderItemsGrid)"
            (rowDeselected)="rowDeselected($event, returnOrderItemsGrid)"
            (actionComplete)="actionComplete($event,returnOrderItemsGrid)"
            (commandClick)="commandColumnClick($event)"
            (load)="load($event)"
            >
            <e-columns>
                <e-column
                    *ngFor="let column of columns;"
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [clipMode]="'EllipsisWithTooltip'"
                    [type]="column.type"
                    [edit]="column.edit"
                    [width]="column.width"
                    [filter]="column.filter ? column.filter : { type: 'Menu' }"
                    [format]="column.format ? column.format : null"
                    [textAlign]="column.textAlign"
                    [visible]="column.visible"
                    [foreignKeyValue]="column.foreignKeyValue"
                    [foreignKeyField]="column.foreignKeyField"
                    [dataSource]="column.dataSource"
                    [isPrimaryKey]="column.isPrimaryKey || false"
                    [validationRules]="column?.validationRules || null"
                    [commands]="column?.commands ? column.commands : null"
                    [allowEditing]="column.allowEditing"
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.headerText === 'Item'" #headerTemplate let-data>
                        <div>
                            Item <span class="required-asterik">*</span>
                        </div>
                    </ng-template>
                    <ng-template *ngIf="column.headerText === 'Quantity'" #headerTemplate let-data>
                        <div>
                            Quantity <span class="required-asterik">*</span>
                        </div>
                    </ng-template>
                    <ng-template *ngIf="column.headerText === 'Return Quantity'" #headerTemplate let-data>
                        <div>
                            Return Quantity <span class="required-asterik">*</span>
                        </div>
                    </ng-template>
                    <ng-template *ngIf="column.field === 'serviceOrderIdLN'" #template let-data>
                        <a href="javascript:" (click)="onServiceOrderClick(data.serviceOrderId)">{{data.serviceOrderIdLN}}</a>
                    </ng-template>
                    <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                        <a href="javascript:" (click)="onServiceOrderClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #returnOrderItemsColumnChooser>
    <app-column-chooser
        [grid]="returnOrderItemsGrid"
        [columns]="columns"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>

<app-items-advanced-search
    *ngIf="showItemAdvancedSearchPopup"
    [category]="category"
    [warehouse]="warehouse"
    [module]="'ReturnOrder'"
    (onClose)="onItemAdvancedSearchPopupClose($event)"
></app-items-advanced-search>

<app-popup
    *ngIf="showServiceOrderPopup"
    [id]="serviceOrderId"
    [data]="serviceOrderData"
    (showPartsOrderPopup)="partsOrderClicked($event)"
    (onLinkIdClick)="onLinkIdClick.emit($event)"
    (onClose)="showServiceOrderPopup = false; serviceOrderId = null; serviceOrderData = null"
></app-popup>
