import { Component, Input, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { ClaimStatuses } from 'src/app/shared/models/claim.model';
import { ClaimsService } from 'src/app/shared/services/claims.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserRoles } from 'src/app/shared/models/app.model';

@Component({
  selector: 'app-isc-claims-customer-rules-tab',
  templateUrl: './isc-claims-customer-rules.component.html',
  styleUrls: ['./isc-claims-customer-rules.component.scss']
})
export class IscClaimsRulesCustomerComponent {

  private destroy$: Subject<null> = new Subject();

  showColumnChooser: boolean;
  claimsQuery: any;

  columns: object = []
  toolbar = [];
  
  @ViewChild('customerRulesGridColumnChooser') columnChooser;
  @ViewChild('customerRulesGrid') customerRulesGrid: GridComponent;

  @Input() referenceActivities: any;
  @Input() claimForm: any;

  constructor(
    private commonService: CommonService,
    private claimService: ClaimsService
  ) { }

  ngOnInit(): void {
      this.columns = this.getGridColumns();
  }
  
  getGridColumns() {
      let columns: object = [
          { field: 'id', headerText: 'ID', type: 'number', format: 'n', textAlign: 'Left', width: 100, isPrimaryKey: true, showInColumnChooser: false, visible: false },
          { field: 'ruleLine', headerText: 'Description', type: 'string', textAlign: 'Left', filter: { type: 'Menu' }, allowFiltering: true, allowSorting: true},
          { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left',  width: 150, filter: { type: 'Excel' }, allowFiltering: true, allowSorting: true},
          { field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 70,
            commands: [
                { title: 'Passed', buttonOption: { iconCss: 'fas fa-check', cssClass: `e-flat text-success approve-btn` } },
                { title: 'Failed', buttonOption: { iconCss: 'fas fa-times', cssClass: `e-flat text-danger reject-btn` } }
            ]
          }
      ];
      return columns;
  }

  onCommandClick(args: any) {
    switch (args.commandColumn.title) {
      case 'Passed':
        this.customerRulesGrid.setCellValue(args.rowData.id, 'status', 'Passed')
        break;

      case 'Failed':
        this.customerRulesGrid.setCellValue(args.rowData.id, 'status', 'Failed')
        break;

      default:
        break;
    }
  }

  getStatusClass(status: string) {
    return this.commonService.getClaimsRuleStatus(status);
  }

  rowDataBound(args: any) {
    const $row = args.row;
    if (this.commonService.USER.role === UserRoles.csa && this.claimForm?.value?.status === ClaimStatuses.inProgress) {
      $row.getElementsByClassName('approve-btn')[0]?.classList.remove('disabled-grid-button');
      $row.getElementsByClassName('reject-btn')[0]?.classList.remove('disabled-grid-button');
    } else {
      $row.getElementsByClassName('approve-btn')[0]?.classList.add('disabled-grid-button');
      $row.getElementsByClassName('reject-btn')[0]?.classList.add('disabled-grid-button');
    }
  }

  onGridCreated() {
      this.toolbar = [
          'Search',
          { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
      ];
      const columnChooserIndex = this.toolbar.findIndex(item => item === 'Search');
      if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
          this.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
      }
      updateFilterIcon({ requestType: 'filtering' }, this.customerRulesGrid);
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
      if (args.item.id === 'grid_excelexport') {
        handleGridExcelExport(grid, 'Claims Rules');
      } else if (args.item.id === 'grid_pdfexport') {
          grid.pdfExport();
      } else if (args.item.id === 'clear-filter') {
        handleClearFilter(grid);
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
