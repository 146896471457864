<div class="tab-content-container mt-3 mb-2">
    <app-loader *ngIf="showLoader"></app-loader>
    <h4 class="border-bottom">Calibration</h4>
    <div class="so-calibrations-tab">
        <ejs-grid *ngIf="showGrid"
            #calibrationsGrid
            [id]="'calibration-types-grid'"
            [dataSource]="calibrationTypes"
            [allowFiltering]="true"
            [allowSorting]="true"
            [allowResizing]="true"
            [allowReordering]="true"
            [showColumnChooser]="true"
            [allowExcelExport]="true"
            [filterSettings]="{ type: 'Menu' }"
            (actionBegin)="onGridActionBegin($event)"
            (actionComplete)="onGridActionComplete($event, calibrationsGrid)"
            (toolbarClick)="onToolbarClick($event, calibrationsGrid)"
            (commandClick)="onCommandClick($event)"
            (rowDataBound)="onRowDataBound($event)"
            >
            <e-columns>
                <e-column
                    *ngFor="let column of gridColumns;"
                    [field]="column.field"
                    [headerText]="column.headerText"
                    [type]="column.type"
                    [width]="column.width"
                    [format]="column.format ? column.format : ''"
                    [textAlign]="column.textAlign"
                    [visible]="column.visible"
                    [filter]="column.filter ? column.filter : { type: 'Menu'}"
                    [commands]="column?.commands ? column.commands : null"
                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                    <ng-template *ngIf="column.field === 'calibrationType'" #template let-data>
                        <div>
                          <div *ngIf="isInProgress; else elseBlock">
                            <a href="javascript:" (click)="onCalibrationClicked(data)">{{data.calibrationType}}</a>
                          </div>
                          <ng-template #elseBlock>
                            {{ data.calibrationType }}
                          </ng-template>
                        </div>
                      </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</div>

<ng-template #calibrationTabColumnChooser>
    <app-column-chooser
        [columns]="grid.columns"
        [grid]="grid"
        [showColumnChooser]="showColumnChooser"
        (onClose)="showColumnChooser = false"
    ></app-column-chooser>
</ng-template>



<app-ms-dynamic-popup
    *ngIf="showCalibrationPopup"
    [tabName]="tab"
    [pageType]="pageType"
    [calibrationType]="calibrationType"
    module="Calibration"
    [orderId]="serviceOrderData?.serviceOrderId || null"
    [data]="calibrationData"
    [serviceOrderData]="serviceOrderData"
    [showComments]="false"
    (onClose)="onCalibrationClose()"
    (onSave)="getPageData($event)"
></app-ms-dynamic-popup>

<app-pdf-viwer *ngIf="showCertificatePopup"
    [title]="'Calibration Certificate'"
    [serviceOrderId]="serviceOrderData.serviceOrderId"
    [serviceOrderIdLN]="serviceOrderData.serviceOrderIdLN"
    [certificateUrl]="certificateUrl"
    [isCalibrationCertificate]="true"
    [isAlreadySigned]="isAlreadySigned"
    [allowSignature]="true"
    (onClose)="onCertificateViewerClose($event)"
></app-pdf-viwer>
