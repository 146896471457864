<div class="modal fade show" [ngClass]="{'expanded':isExpanded}" tabindex="-1"
  aria-labelledby="service-order-modalLabel" aria-hidden="true" [ngStyle]="{'display': 'block'}"
  *ngIf="this.hidePopup == false;">
  <div class="modal-dialog modal-xl">
    <app-loader *ngIf="showLoader"></app-loader>
    <div class="modal-content">
      <div class="modal-header" (onExpandCollapse)="isExpanded = $event">
        <div class="d-flex w-100">
          <div class="mr-2 id flex-fill">
            <span class="custom-icon">
              <i id="header-cart" class="fa fa-shopping-cart rotation" aria-hidden="true"></i>
            </span>
            <span class="modal-title mt-2 item-id">MY CART</span>
          </div>
          <div class="btns-header">
            <button class="header-icon close p-0 m-0" (click)="onCloseCart()" aria-label="Close">
              <i class="fas fa-times" title="Close Popup"></i>
            </button>
            <button class="close header-icon expand-modal p-0 m-0" (click)="expandCollapse()">
              <i class="fas ml-2 mr-2" [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}"
                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col mt-4">
            <ejs-grid #ISCPartsOrderItemsGrid
              [id]="gridName"
              [height]="gridHeight"
              [dataSource]="data"
              [allowPaging]="false"
              [allowFiltering]="true"
              [allowSorting]="true"
              [allowResizing]="true"
              [allowReordering]="true"
              [showColumnChooser]="true"
              [allowExcelExport]="true"
              [selectionSettings]="{ mode: 'Both', checkboxOnly: true, persistSelection: false }"
              [filterSettings]="{ type: 'Menu' }"
              [query]="query"
              [editSettings]="{ allowEditing: false, mode: 'Batch' }"
              (commandClick)="onCommandClick($event,ISCPartsOrderItemsGrid)"
              (checkBoxChange)="onCheckboxChange($event)"
              (rowSelected)='onRowSelected($event)' 
              (rowDeselected)='onRowDeselected($event)'
              (created)="onGridCreated(args,grid)"
              (actionBegin)="actionBegin($event)"
              (actionComplete)="actionComplete($event, ISCPartsOrderItemsGrid)"
            ><e-columns>
              <e-column *ngFor="let column of columns;"
                [isPrimaryKey]='column.isPrimaryKey'
                [rowHeight]="30"
                [height]="215" [field]="column.field"
                [headerText]="column.headerText"
                [type]="column.type"
                [width]="column.width"
                [filter]="column.filter ? column.filter : { type: 'Menu' }"
                [format]="column.format ? column.format : null"
                [textAlign]="column.textAlign"
                [showInColumnChooser]="column.showInColumnChooser ?? true"
                [visible]="column.visible"
                [commands]="column?.commands ? column.commands : null"
                [edit]="column.edit"
                [allowReordering]="column.allowReordering ?? true"
                [allowEditing]="column.allowEditing"
                [allowSorting]="column.allowSorting"
                [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true"
                [freeze]="column.freeze ? column.freeze: null"
                >
                <!--[enableImmutableMode]='true'-->

                <ng-template *ngIf="column.field === 'quantity'" #template let-data>

                  <div id="qty">
                    <div class="row">
                      <div class="col-3 text-left mt-1">
                        <button class="qty-gen qty-sub" id="qty-sub-{{data.id}}"
                          (click)="sub(data, $event)">-</button>
                      </div>
                      <div class="col-6 p-0">
                        <ejs-numerictextbox #quantityColumnTemplate
                          format="#"
                          validateDecimalOnType="true" 
                          decimals="0"
                          style="text-align: center !important;"
                          floatLabelType='Always'
                          min="1" [showSpinButton]="false"
                          [value]="data.quantity"
                          [required]="true"
                          (blur)="onQuantityBlur($event, data)"
                          (change)="onChangeQuantity($event, data)"
                        ></ejs-numerictextbox>
                      </div>
                      <div class="col-3 text-right mt-1">
                        <button class="qty-gen qty-add" id="qty-add-{{data.id}}"
                          (click)="add(data, $event)">+</button>
                      </div>
                    </div>
                  </div>

                </ng-template>

                <ng-template *ngIf="column.field === 'stock'" #template let-data>
                  <span>{{userDetails?.orderCurrency === "USD" ? data.stock : data.stockCan}}</span>
                </ng-template>

                <ng-template *ngIf="column.field === 'itemPrice'" #template let-data>
                  <span>${{data.itemPrice.toFixed(2)}}</span>
                </ng-template>

                <ng-template *ngIf="column.field === 'itemDiscount'" #template let-data>
                  <span>${{data.itemDiscount.toFixed(2)}}</span>
                </ng-template>

                <ng-template *ngIf="column.field === 'netPrice'" #template let-data>
                  <span>${{data.netPrice.toFixed(2)}}</span>
                </ng-template>

                <ng-template *ngIf="column.field === 'grossAmount'" #template let-data>
                  <span>${{data.grossAmount.toFixed(2)}}</span>
                </ng-template>
              </e-column>
            </e-columns>
            </ejs-grid>
            <div class="row container fluid mt-2">
              <div class="col-3"></div>
              <div class="col-2 item-id">Total quantity: {{totalQuantity}}</div>
              <div class="col-3 item-id">Total amount: {{totalPrice.toFixed(2)}}</div>
              <div class="col-2" style="color:green"><b class="item-id">Savings: {{totalSavings.toFixed(2)}}</b>
              </div>
              <div class="col-2"><b class="item-id">Currency: {{currency}}</b></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-danger close-modal" (click)="onCloseCart()">Close</button>
        <button type="button" class="btn btn-sm btn-primary close-modal" (click)="clearCart()"><i
            class="fas fa-trash mr-2"></i>Clear Cart</button>
        <button type="button" class="btn btn-sm btn-warning close-modal" style="color: white;" (click)="checkAvailibility()">Check Availibility</button>
        <button type="button" class="btn btn-sm btn-success close-modal" (click)="openCheckout()"
          [disabled]="isCheckoutButtonDisabled"><i class="fas fa-share-square mx-1"></i>Checkout</button>
      </div>
    </div>
  </div>
</div>

<app-cart-checkout-popup
  *ngIf="showCartCheckoutPopup"
  [customerAddress]="customerAddress"
  [recievedItems]="selectedItemsToCheckout"
  (onClose)="onCloseFromCheckout()">
</app-cart-checkout-popup>

<ng-template #columnChooser>
  <app-column-chooser
    [columns]="columns"
    [grid]="grid"
    [showColumnChooser]="showColumnChooser"
    (onClose)="showColumnChooser = false"></app-column-chooser>
</ng-template>
