import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CommandColumnService, ExcelExportProperties, ExcelExportService, FilterService, GridComponent, RecordDoubleClickEventArgs, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { CalibrationData, CalibrationTypes } from 'src/app/modules/home/modules/calibrations/models/calibration.model';
import { CalibrationService } from 'src/app/modules/home/modules/calibrations/services/calibration.service';
import { UserRoles } from 'src/app/shared/models/app.model';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { getExcelDataSource, gridActionBeginForFiltering, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { forkJoin } from 'rxjs';
import { PageService } from 'src/app/shared/services/page.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CallGroups, serviceOrderStatuses } from '../../models/service-order.model';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-calibration-tab',
  templateUrl: './calibration-tab.component.html',
  styleUrls: ['./calibration-tab.component.scss'],
  providers: [
    ToolbarService,
    FilterService,
    SortService,
    ExcelExportService,
    CommandColumnService
]
})
export class CalibrationTabComponent implements OnChanges, OnInit {

  showCalibrationPopup: boolean = false;
  showCertificatePopup: boolean = false;
  showLoader: boolean = false;
  gridColumns: any;
  toolbar: any;
  showGrid: boolean = false;
  showCalibrationSelectionPopup: boolean = false;
  showColumnChooser: boolean = false;
  gridButtonClass: string = '';

  tab: string = 'details';

  pageType: string;
  calibrationType: string;
  calibrationData: CalibrationData;
  certificateUrl: string = null;
  calibrationTypes: any = [];
  calibrationTypesForNew: any = [];
  USER: any;
  isManager: boolean = false;
  isNew: boolean = false;

  @Input() isEditable: boolean;
  @Input() serviceOrderData;
  @Input() calibrations:any = [];

  @Output() reloadPopup = new EventEmitter();
  grid: GridComponent

  @ViewChild('calibrationTabColumnChooser') columnChooser;
  isInProgress: boolean = false;
  isAlreadySigned: boolean = false;
  @ViewChild('calibrationsGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.grid = gridComponent;
      this.setGridToolbar();
    }
  }

  constructor(
    private calibrationService: CalibrationService,
    private commonService: CommonService,
    private pageService: PageService
  ) {
    this.USER = commonService.USER;
  }

  ngOnChanges(change: SimpleChanges) {
    if (change && change.calibrations && change.calibrations.currentValue && change.calibrations.previousValue) {
      this.calibrations = change.calibrations.currentValue;
      this.calibrations.map((calibration, index) => {
        const rowIndex = this.calibrationTypes.findIndex( cal => cal.value === calibration.calibrationType );
        if(rowIndex > -1){
          this.calibrationTypes[rowIndex].hasCertificate = true;
          this.calibrationTypes[rowIndex].certificateUrl = calibration.certificateUrl;
          this.calibrationTypes[rowIndex].createdDate = calibration.createdDate;
        }
      });
      if (this.grid) {
          this.grid.dataSource = this.calibrationTypes;
          this.grid.refresh();
      }
    }

    if (change && change.serviceOrderData && change.serviceOrderData.currentValue  && change.serviceOrderData.previousValue) {
      this.isInProgress = change.serviceOrderData.currentValue.status === serviceOrderStatuses.inProgress;
      this.grid?.refresh();
    }

    if (change && change.isEditable) {
      this.isInProgress = change.isEditable.currentValue;
      this.grid?.refresh();
    }
  }

  ngOnInit(): void {
    this.isInProgress = this.serviceOrderData?.status === serviceOrderStatuses.inProgress;
    this.setGridColumns();
    this.isManager= this.commonService.USER.role === UserRoles.conwayServiceManager;
    this.gridButtonClass = this.isEditable ? '' : 'disabled-grid-button';
    this.showCalibrationPopup = false;
    this.getCalibrationTypesList();
  }

  getCalibrationTypesList(){
    let itemGroup = '0';
    itemGroup = this.serviceOrderData.serializedItemGroupCode;
    //if( [CallGroups.initialCalibration, CallGroups.calibration].includes(this.serviceOrderData.callGroup) ){
    //}
    this.calibrationService.getCalibrationTypes(itemGroup).subscribe((res: any) => {
      if(res.isSuccessful){
        res.result.map( x => {
          x.calibrationType = x.text;
          x.certificateUrl = '';
          x.hasCertificate = this.calibrations.findIndex( calibration => calibration.calibrationType === x.value ) > -1;
          if(x.hasCertificate){
            const rowData = this.calibrations.find( calibration => calibration.calibrationType === x.value );
            x.certificateUrl = rowData.certificateUrl;
            x.createdDate = rowData.createdDate;
          }
        });
        this.calibrationTypes = res.result;
        this.showGrid = true;
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    }, (error: HttpErrorResponse) => {
        this.showLoader = false;
    });
  }

  setGridToolbar() {
    this.grid.toolbar = [
      { id: 'column-chooser', tooltipText: 'Show/Hide Columns', template: this.columnChooser },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.grid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        this.grid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
  }

  setGridColumns() {
    this.gridColumns = [
      { field: 'pageType', headerText: 'ID', type: 'string', isPrimaryKey: true, visible: false, showInColumnChooser: false },
      { field: 'calibrationType', headerText: 'Calibration Type', type: 'string', textAlign: 'Left' },
      { field: 'createdDate', headerText: 'Created Date', type: 'date', textAlign: 'Left', width: 150, format: { type: 'datetime', format: 'd MMM, y  hh:mm' } },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', allowFiltering: false, allowSorting: false, allowReordering: false, allowEditing: false, width: 150,
        commands: [
            { type: 'View', title: 'View certificate', buttonOption: { iconCss: `fa fa-eye`, cssClass: 'e-flat view-certificate' } }
        ]
      }
    ];
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'add-new-calibartion') {
        this.showCalibrationSelectionPopup = true;
    } else if (args.item.id === 'excel-export') {
      handleGridExcelExport(grid, `Service Order ${this.serviceOrderData.serviceOrderId} - Calibrations`);
    } else if (args.item.id === 'clear-filter') {
      document.getElementById('calibration-types-grid').getElementsByClassName('grid-filter-icon')[0]?.classList.remove('filtered');
      handleClearFilter(grid);
      this.grid.refresh();
    }
  }

  onRowDataBound(args: any) {
    const data = args.data;
    const $row = args.row;
    if (data.hasCertificate) {
      $row.getElementsByClassName('view-certificate')[0]?.classList.remove('disabled');
      $row.getElementsByClassName('view-certificate')[0]?.classList.add('color-green');
    } else {
      $row.getElementsByClassName('view-certificate')[0]?.classList.remove('color-green');
      $row.getElementsByClassName('view-certificate')[0]?.classList.add('disabled');
    }
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid) {
    updateFilterIcon(args, grid);
}

  onCalibrationClicked(data){
    const so = this.serviceOrderData;
    this.pageType = data.pageType;
    this.calibrationType = data.value;
    const [ item, serialNumber, customerCode ] = [so.itemCode, so.serialNumber, so.customerCode ];
    data.createdDate = new Date();
    const apis = [
      this.calibrationService.getCalibrationRefEquipment(data.value),
      this.calibrationService.getCalibrationEquipmentAndDatesData(item, serialNumber, customerCode, data.value)
    ]
    this.showLoader = true;
    forkJoin(apis).subscribe((res: any) => {
      if ( res.length ) {
        let updatedRefEquipmentData;
          let [refEquipment, equipmentAndDates] = [res[0].result, res[1].result];
          if ( equipmentAndDates )
            equipmentAndDates.manufacturer = equipmentAndDates.manufacturer || "Snap-on";
            equipmentAndDates.procedureValue = equipmentAndDates.procedureNumber || '';
            equipmentAndDates.previousCalibrationDate = so.calibrationNextCertificateDate ? new Date(so.calibrationNextCertificateDate) : new Date();
          if ( refEquipment ) {
            updatedRefEquipmentData = this.setRefEquipmentData(refEquipment);
          }

          this.calibrationData = {
            serviceOrderId: so.serviceOrderIdLN ? so.serviceOrderIdLN : so.serviceOrderId,
            serialNo: serialNumber,
            model: equipmentAndDates.modelDescription ? equipmentAndDates.modelDescription : so.itemDescription.split(' - ')[1],
            previousCalibrationDate: so.calibrationNextCertificateDate ? new Date(so.calibrationNextCertificateDate) : new Date(),
            ...updatedRefEquipmentData,
            ...equipmentAndDates
          }
          this.isNew = false;
          this.showCalibrationSelectionPopup = false;
          this.showCalibrationPopup = true;
          this.showLoader = false;;
      }
    }, error => {
      this.showLoader = false;
      this.showCalibrationSelectionPopup = false;
      throw error;
    });
  }

  onCommandClick(args: any) {
    if (args.commandColumn.type === 'View') {
      this.isAlreadySigned = true;
      this.showCertificatePopup = true;
      this.certificateUrl = args.rowData.certificateUrl;
    }
  }

  onCalibrationSelectionPopupClosed(newCalibrationSelected) {
    if(newCalibrationSelected){
      const so = this.serviceOrderData;
      this.pageType = newCalibrationSelected.pageType;
      this.calibrationType = newCalibrationSelected.value;
      const [ item, serialNumber, customerCode ] = [so.itemCode, so.serialNumber, so.customerCode ];
      newCalibrationSelected.createdDate = new Date();
      const apis = [
        this.calibrationService.getCalibrationRefEquipment(newCalibrationSelected.value),
        this.calibrationService.getCalibrationEquipmentAndDatesData(item, serialNumber, customerCode, newCalibrationSelected.value)
      ]
      this.showLoader = true;
      forkJoin(apis).subscribe((res: any) => {
        if ( res.length ) {
          let updatedRefEquipmentData;
          let [refEquipment, equipmentAndDates] = [res[0].result, res[1].result];
          if ( equipmentAndDates )
            equipmentAndDates.manufacturer = equipmentAndDates.manufacturer || "Snap-on";
            equipmentAndDates.procedureValue = equipmentAndDates.procedureNumber || '';
            equipmentAndDates.previousCalibrationDate = so.calibrationNextCertificateDate ? new Date(so.calibrationNextCertificateDate) : new Date();
          if ( refEquipment ) {
            updatedRefEquipmentData = this.setRefEquipmentData(refEquipment);

          }

          this.calibrationData = {
            serviceOrderId: so.serviceOrderIdLN ? so.serviceOrderIdLN : so.serviceOrderId,
            serialNo: serialNumber,
            model: equipmentAndDates.modelDescription ? equipmentAndDates.modelDescription : so.itemDescription.split(' - ')[1],
            previousCalibrationDate: so.calibrationNextCertificateDate ? new Date(so.calibrationNextCertificateDate) : new Date(),
            ...updatedRefEquipmentData,
            ...equipmentAndDates,
            createdDate: new Date()
          }
          this.isNew = true;
          this.showCalibrationSelectionPopup = false;
          this.showCalibrationPopup = true;
          this.showLoader = false;
        }
      }, error => {
        this.showLoader = false;
        this.showCalibrationSelectionPopup = false;
        throw error;
      });
    } else {
      this.showLoader = false;
      this.showCalibrationSelectionPopup = false;
    }
  }

  setRefEquipmentData(data) {
    let props = {};
    data.forEach(refEquipment => {
        props = {
          props,
          ...this.setRefEquipmentProps(refEquipment, refEquipment.asset, props)
        };
        props['manufacturer'] = refEquipment.manufacturer || "Snap-on";
        return refEquipment;
    });
    return props;
  }

  setRefEquipmentProps(data, asset, props) {
    switch(asset) {
        case 'Thermometer':
            props['assetNumberThermometer']  = data.assetNumber;
            props['certificateNumberThermometer'] = data.certificateNumber;
            props['certificateExpiryThermometer'] = data.certiExpiryDate;
            break;
        case 'Multimeter':
            props['assetNumberMultimeter'] = data.assetNumber;
            props['certificateNumberMultimeter']  = data.certificateNumber;
            props['certificateExpiryMultimeter'] = data.certiExpiryDate;
            break;
        case 'WOTI Temp. Simulator':
            props['assetNumberWOTITempSim'] = data.assetNumber;
            props['certificateNumberWOTITempSim'] = data.certificateNumber;
            props['certificateExpiryWOTITempSim'] = data.certiExpiryDate;
            props['tempWirelessWOTI'] = data.tempWireless;
            break;
        case 'Smoke Temp. Simulator':
            props['certificateExpiryDate'] = data.certiExpiryDate;
            props['assetNumber'] = data.assetNumber;
            props['certificateNumber']  = data.certificateNumber;
            props['wirelessTemperature'] = data.tempWireless;
            props['standardTemperature'] = data.tempStd;
            props['certificateExpiry'] = data.certiExpiryDate;
            break;
        case 'Scale':
            props['scalesSetAssetNumber'] = data.assetNumber;
            props['scalesSetCertificateNumber']  = data.certificateNumber;
            props['scalesSetExpiryDate'] = data.certiExpiryDate;
            props['equipmentType'] = data.isSelected ? "Electronic" : props['equipmentType'];
            break;
        case 'Weights':
            props['weightsAssetNumber'] = data.assetNumber;
            props['weightsCertificateNumber'] = data.certificateNumber;
            props['weightsExpiryDate'] = data.certiExpiryDate;
            props['equipmentType'] = data.isSelected ? "Mechanical" : props['equipmentType'];
            break;
        case 'Axle Weight':
            props['brakeCalAxleWeight'] = data.refWeight;
            props['axelCertificateNumber'] = data.certificateNumber;
            props['axelExpiryDate'] = data.certiExpiryDate;
            break;
        case 'Flowmeter':
            props['assetNumberFlowmeter'] = data.assetNumber;
            props['certificateNumberFlowmeter']  = data.certificateNumber;
            props['certificateExpiryFlowmeter'] = data.certiExpiryDate;
            break;
        case 'Regulator':
            props['assetNumberRegulator'] = data.assetNumber;
            props['certificateNumberRegulator']  = data.certificateNumber;
            props['certificateExpiryRegulator'] = data.certiExpiryDate;
            break;
        case 'Barometer':
            props['assetNumberBarometer'] = data.assetNumber;
            props['certificateNumberBarometer']  = data.certificateNumber;
            props['certificateExpiryBarometer'] = data.certiExpiryDate;
            break;
        case 'Gas Bottle':
            props['referenceEquipmentCertificateNumber'] = data.certificateNumber;
            props['referenceEquipmentCertificateExpiryDate'] = data.certiExpiryDate;
            props['coValue'] = data.coVal;
            props['co2Value'] = data.co2Val;
            props['propaneValue'] = data.propaneVal;
            props['referenceEquipmentSerialNumber'] = data.assetSerialNumber;
            break;
        default:
          if ( this.pageType === CalibrationTypes.liftLoad ) {
            props['referenceWeight'] = data.refWeight;
          }
          props['certificateExpiryDate'] = data.certiExpiryDate;
          props['assetNumber'] = data.assetNumber;
          props['certificateNumber']  = data.certificateNumber;
          break;
    }
    return props;
  }

  onCalibrationClose() {
    this.showCalibrationPopup = false;

    // this.calibrations.push({
    //   id: 0,
    //   calibrationId: '',
    //   calibrationType: newCalibrationSelected.value,
    //   company: this.USER.company,
    //   serviceOrderId: this.serviceOrderData.serviceOrderId,
    //   serialNumber : this.serviceOrderData.serialNumber,
    //   item : this.serviceOrderData.itemCode,
    //   itemDescription : this.serviceOrderData.itemDescription,
    //   createdDate: new Date(),
    //   certificateUrl: "google.com"
    // });

    // this.grid.refresh();
  }
  calibrationSaved() {}
  getCalibrations() {}

  getPageData(event) {
    const { calibrationType, uri, refEquipment, calibSerialNumber, expiryDate } = event;

    const rowIndex = this.calibrations.findIndex(row => row.calibrationType === calibrationType);

    const calibration = {
      id: 0,
      calibrationId: '',
      calibrationType,
      company: this.USER.company,
      serviceOrderId: this.serviceOrderData.serviceOrderId,
      serialNumber: this.serviceOrderData.serialNumber || calibSerialNumber,
      expiryDate,
      item: this.serviceOrderData.itemCode,
      itemDescription: this.serviceOrderData.itemDescription,
      isSigned: false,
      createdDate: new Date(),
      certificateUrl: uri,
      refEquipment
    };

    if (rowIndex === -1) {
      this.calibrations.push(calibration);
    } else {
      this.updateCalibrationValues(rowIndex, calibration);
    }

    this.isAlreadySigned = true;
    this.showCertificatePopup = true;
    this.certificateUrl = uri;
    this.grid.refresh();
    this.reloadPopup.emit();
  }

  updateCalibrationValues(index, calibration) {
    this.calibrations[index].calibrationId = '';
    this.calibrations[index].isSigned = false;
    this.calibrations[index].createdDate = new Date();
    this.calibrations[index].certificateUrl = calibration.certificateUrl;
    this.calibrations[index].refEquipment = calibration.refEquipment;
    this.calibrations[index].calibrationType = calibration.calibrationType;
    this.calibrations[index].expiryDate = calibration.expiryDate;
  }


  onCertificateViewerClose(event){
    if(this.calibrationData && event){
      let rowIndex = this.calibrationTypes.findIndex( row => row.value === this.calibrationData.calibrationType);
      this.calibrationTypes[rowIndex].certificateUrl = event;
    }
    this.grid.refresh();
    this.showCertificatePopup = false;
    this.certificateUrl = null;
    this.calibrationData = null;
    this.reloadPopup.emit();
  }

}
