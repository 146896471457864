import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { getExcelDataSource, gridActionBeginForFiltering, handleGridExcelExport, updateFilterIcon } from '../../utils/grid-functions';
import { gridNames } from '../../models/app.model';
import { CommandClickEventArgs, ExcelExportProperties, GridComponent, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { getCurrentDate } from '../../utils/date-functions';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
@Component({
  selector: 'app-link-service-orders-popup',
  templateUrl: './link-service-orders-popup.component.html',
  styleUrls: ['./link-service-orders-popup.component.scss']
})

export class LinkServiceOrdersPopupComponent implements OnInit {
  isExpanded: boolean = false;
  showLoader: boolean = false;
  title: string = "Link Service Orders";
  hideCreationDate: boolean = true;
  ServiceOrdersForTransferOrder: any =[];
  USER;

  gridHeight: number = window.innerHeight - 330;
  gridName = gridNames.linkServiceOrdersGrid;
  showColumnChooser: boolean;
  
  gridColumns: any;
  disabledAdd: boolean = true;
  checkboxCount: number=0;

  @ViewChild('linkServiceOrdersGrid') linkServiceOrdersGrid: GridComponent;
  @ViewChild('columnChooser') columnChooser;
  serviceOrderIdtosend=[];

  isSorting: boolean=false;
  ServiceOrdersForTransferOrderLenght: number;
  selection:boolean=false;
  temp=[];
  
  @Input() technicianId = '';
  @Input() selectedServiceOrderIDs = [];

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() serviceOrderIdArray: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private inventoryService: InventoryService
  ) {
      this.USER = commonService.USER;
  }
  ngOnInit(): void {
    this.showLoader = true;
    this.getData();
    this.gridColumns = this.getColumns();
  }

  getData() {
    if ( this.technicianId ) {
      this.inventoryService.getServiceOrdersForTransferOrder(this.technicianId).subscribe((res: any)=>{
        if ( res.isSuccessful ) {
          this.ServiceOrdersForTransferOrder = res.result;
          this.ServiceOrdersForTransferOrder.forEach((element)=>{
            this.ServiceOrdersForTransferOrderLenght+=1;
          })
        } else {
          this.commonService.showNotification('error', res.message);
        }
        this.showLoader = false;
       }, error => {
        this.showLoader=false;
        this.commonService.showNotification('error', error.message);
       });
    }
  }

  getColumns() {
    return [
        { field: "", type: 'checkbox', headerText: "", textAlign: 'Center', allowFiltering: false, allowSorting: false, allowSearching: false, width: 55, showInColumnChooser: false },
        { field: 'serviceOrderId', headerText: 'Order ID', type: 'string', visible: true, textAlign: 'Left', width: 120 },
        { field: 'serviceOrderIdLN', headerText: 'LN Order No', type: 'string', visible: true, textAlign: 'Left' },
        { field: 'customerDescription', headerText: 'Service Order Customer', textAlign: 'Left', type: 'string', visible: true},
        { field: 'status', headerText: 'Status', type: 'string', visible: true, textAlign: 'Left', width: 150 },
        { field: 'itemDescription', headerText: 'Item', type: 'string', visible: true, textAlign: 'Left', width: 350 },
    ];
  }

  actionBegin(args: any){
    if ( args.requestType === 'sorting') {
      this.updateSendArray();
      this.temp=this.serviceOrderIdtosend;
    } 
  }

  updateSendArray()
  {
    this.serviceOrderIdtosend=[];
    this.linkServiceOrdersGrid.getSelectedRecords().forEach((row: any) => {
      this.serviceOrderIdtosend.push(row.serviceOrderId);
    })
  }

  add(){
    this.updateSendArray();
    this.serviceOrderIdArray.emit(this.serviceOrderIdtosend);
    this.close();
  }

  rowSelected(args: any){
    this.updateSendArray();
  }

  rowDeselected(args: any){
    this.updateSendArray();
  }

  close() {
    this.onClose.emit();
  }

  expandCollapse(value: boolean) {
    this.isExpanded = value;
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'clear-filter') {
        grid.clearFiltering(['serviceOrderId', 'serviceOrderIdLN', 'customerDescription', 'status', 'itemCode']);
        grid.search('');
        grid.refresh();
    } else if (args.item.id === 'grid_excelexport') {
      handleGridExcelExport(grid, 'Link Service Order');
    }
  }

  onGridCreated() {
    const toolbarOptions: any = [
        { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        'Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = toolbarOptions.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
        toolbarOptions.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
    this.linkServiceOrdersGrid.toolbar = toolbarOptions;
    updateFilterIcon({ requestType: 'filtering' }, this.linkServiceOrdersGrid);

  }

  onCommandClick(args: CommandClickEventArgs) {
  }
  
  checkBoxChange(args: any){
      this.disabledAdd = !this.checkboxCount;
  }

  public dataBound(args): void {
    const  indexs: number[] = []; 
      (this.linkServiceOrdersGrid.dataSource as object[]).forEach((sdata, index) => { 
        if(this.selectedServiceOrderIDs.includes(sdata['serviceOrderId'])){
          indexs.push(index);
        }
    });
    this.linkServiceOrdersGrid.selectionModule.selectRows(indexs);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    if ( args.requestType === 'sorting' ) {
      this.serviceOrderIdtosend=[];
      this.serviceOrderIdtosend=this.temp;
        const  indexs: number[] = []; 
        
        args.rows.forEach((element)=>{
          if(this.serviceOrderIdtosend.includes(element.data.serviceOrderId)){
            indexs.push(element.index);              
          }
        })

        this.linkServiceOrdersGrid.clearCellSelection();
        this.linkServiceOrdersGrid.clearSelection();
        this.linkServiceOrdersGrid.selectionModule.selectRows(indexs);

    }
    updateFilterIcon(args, grid);
  }

}
