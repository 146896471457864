import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ApiResponse, gridNames, GridPersistRequests, PersistenceData, UserRoles } from 'src/app/shared/models/app.model';
import { GridComponent, SelectionSettingsModel, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { CustomAdaptor, excelQueryCellInfo, getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { IscService } from '../../../services/isc.service';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const BASE_URL = environment.apiUrl;
@Component({
  selector: 'app-parts-catalog-popup',
  templateUrl: './parts-catalog-popup.component.html',
  styleUrls: ['./parts-catalog-popup.component.scss']
})
export class PartsCatalogPopupComponent implements OnInit {
  query = new Query().where('isDeleted', 'equal', false);
  isExpanded: boolean = true;
  @Output() onClose = new EventEmitter();
  @Output() onExpandCollapse = new EventEmitter();
  // public selectionOptions: SelectionSettingsModel;
  grid: GridComponent;
  @ViewChild('columnChooser') columnChooser;
  @ViewChild('quantityColumnTemplate') quantityColumnTemplate: NumericTextBox;
  @ViewChild('ISCPartsOrderItemsGrid') set gridComponent(gridComponent: GridComponent) {
    if (gridComponent) {
      this.grid = gridComponent;
      // this.setGridToolbar();
    }
  }
  @Input() currency;
  @Input() materials = [];
  @Input() customerAddress;
  @Input() serviceOrderId: string = '';
  public pageSettings: Object;
  columns;
  data: any;
  showCartCheckoutPopup: boolean = false;
  showLoader: boolean = false;
  gridHeight: number = window.innerHeight - 500;
  gridName = gridNames.ISCPartsOrderItemsGrid;
  mainGrid: any;
  hidePopup: boolean = false;
  isCheckoutButtonDisabled: boolean = true;
  toSendQty = 0;
  userDetails;
  delFlag = false;
  technicianDetails: any;
  stats: any = [];
  totalQuantity = 0;
  totalNetPrice = 0;
  totalPrice = 0;
  totalSavings = 0;
  selectedItemsToCheckout: any;
  selected: boolean;
  rowSelectedFlag: boolean = false;
  onQtyUpdate = new Subject<string>();

  constructor(private commonService: CommonService, private iscService: IscService) { }

  ngOnInit(): void {
    // this.showLoader = true;
    console.log('parts catalog popup: soid',this.serviceOrderId)
    this.columns = this.getColumns();
    this.setGrid();
    !this.materials?.length && this.loadPage();
    this.data = this.materials;
    this.onQtyUpdate
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe((data: any) => {
        this.showLoader = true;
        this.iscService.refreshPrice(data.item, data.quantity).subscribe((res: ApiResponse) => {
          this.grid.setCellValue(data.id, "netPrice", res?.result?.result?.netAmount?.netAmountValue);
          this.grid.setCellValue(data.id, "itemDiscount", res?.result?.result?.discountAmount?.discountAmountValue);
          this.grid.setCellValue(data.id, "grossAmount", res?.result?.result?.grossAmount?.grossAmountValue);
          const rec: any = this.grid.getSelectedRecords();
          let totalDiscount = 0;
          let totalPrice = 0;
          let totalQuantity = 0;
          for (var r of rec) {
            totalQuantity += r.quantity;
            totalDiscount += r.itemDiscount;
            totalPrice += r.netPrice;
          }
          this.totalSavings = totalDiscount;
          this.totalPrice = totalPrice;
          this.totalQuantity = totalQuantity;
          this.showLoader = false;
        })
      });
  }

  loadPage() {
    this.showLoader = true;
    this.userDetails = this.commonService.USER;
    this.iscService.getUserCartItem().subscribe((res: any) => {
      res.result.map((row, index) => {
        row.lineNumber = index + 1;
        row.isSelected = false;
      });
      this.data = res?.result;
      this.stats = this.data;
      this.showLoader = false;
    })
  }

  onQuantityBlur(args, data) {
    if (!args.value) {
      this.grid.setCellValue(data.id, "quantity", 1);
      // this.onCheckboxChange();
    }
  }

  onChangeQuantity(args, data) {
    this.grid.setCellValue(data.id, "netPrice", data.netPrice)
    this.grid.setCellValue(data.id, "quantity", args.value);
    let records: any = this.grid.getSelectedRecords();
    this.totalQuantity = 0;
    this.totalPrice = 0;
    let total = 0;
    let totalPrice = 0;
    for (let rec of records) {
      this.totalQuantity += rec.quantity;
      total += rec.itemDiscount;
      totalPrice += rec.netPrice;
    }
    this.totalSavings = total;
    this.totalPrice = totalPrice;
    data.quantity = args.value;
    this.onQtyUpdate.next(data);
  }

  onRowSelected(args) {
    // const gridElem = document.getElementById(this.gridName);
    // if (Array.isArray(args.data)) {
    //   args.data.forEach(elem => {
    //     gridElem.querySelector(`#qty-sub-${elem.id}`).removeAttribute('disabled');
    //     gridElem.querySelector(`#qty-add-${elem.id}`).removeAttribute('disabled');
    //   });
    // } else {
    //   gridElem.querySelector(`#qty-sub-${args.data.id}`).removeAttribute('disabled');
    //   gridElem.querySelector(`#qty-add-${args.data.id}`).removeAttribute('disabled');
    // }
    args.data.isSelected = true;
    this.rowSelectedFlag = true;
    this.onCheckboxChange(args);
    const rec: any = this.grid.getSelectedRecords();

    let totalDiscount = 0;
    let totalPrice = 0;
    let totalQuantity = 0;
    for (var r of rec) {
      totalQuantity += r.quantity;
      totalDiscount += r.itemDiscount;
      totalPrice += r.netPrice;
    }
    this.totalQuantity = totalQuantity;
    this.totalSavings = totalDiscount;
    this.totalPrice = totalPrice;
  }

  onRowDeselected(args) {
    // const gridElem = document.getElementById(this.gridName);
    // if (Array.isArray(args.data)) {
    //   args.data.forEach(elem => {
    //     gridElem.querySelector(`#qty-sub-${elem.id}`).setAttribute('disabled', 'disabled');
    //     gridElem.querySelector(`#qty-add-${elem.id}`).setAttribute('disabled', 'disabled');
    //   });
    // } else {
    //   gridElem.querySelector(`#qty-sub-${args.data.id}`).setAttribute('disabled', 'disabled');
    //   gridElem.querySelector(`#qty-add-${args.data.id}`).setAttribute('disabled', 'disabled');
    // }
    args.data.isSelected = false;
    this.rowSelectedFlag = false;
    this.onCheckboxChange(args);
    const rec: any = this.grid.getSelectedRecords();

    let totalDiscount = 0;
    let totalPrice = 0;
    let totalQuantity = 0;
    for (var r of rec) {
      totalQuantity += r.quantity;
      totalDiscount += r.itemDiscount;
      totalPrice += r.netPrice;
    }
    this.totalQuantity = totalQuantity;
    this.totalSavings = totalDiscount;
    this.totalPrice = totalPrice;
  }

  dataBound(args): void {
    this.grid.selectionModule.selectRowsByRange(0, this.grid.getRows().length);
  }

  onGridCreated(args) { }
  actionBegin(args) { }
  actionComplete(args, grid) {
    updateFilterIcon({ requestType: 'filtering' }, grid);
  }

  onCheckboxChange(args?) {
    const selectedRecords = this.grid.getSelectedRecords().filter((x: any) => !x.isDeleted);
    this.isCheckoutButtonDisabled = !selectedRecords.length;
    // this.updateAmounts(selectedRecords);
  }

  // updateAmounts(selectedRecords) {
  //   this.resetStatsToZero();
  //   if (selectedRecords.length) {
  //     this.updateStats(selectedRecords);
  //   }
  // }

  // updateStats(selectedRecords) {
  //   selectedRecords.forEach((el: any) => {
  //     this.totalQuantity += el.quantity;
  //     // this.totalNetPrice += el.netPrice;
  //     this.totalPrice += el.netPrice // * el.quantity
  //     // this.totalSavings += el.itemDiscount; // * el.quantity
  //   });
  // }

  resetStatsToZero() {
     this.totalQuantity = 0;
     this.totalNetPrice = 0;
     this.totalPrice = 0;
     this.totalSavings = 0;
  }

  checkAvailability() {
    let recs = this.grid.getSelectedRecords();
    this.showLoader = true;
    if (this.data === null) {
      this.commonService.showNotification('warning', 'No data present at the moment!');
      this.showLoader = false;
    }
    else {
      this.iscService.checkAvailability(this.data).subscribe((res: ApiResponse) => {
        if (res.isSuccessful) {
          this.grid.dataSource = res.result;
          this.showLoader = false;
        }
        else {
          this.commonService.showNotification('warning', res.message);
          this.showLoader = false;
        }
      })
    }
  }

  onCommandClick(args, grid) {
    if (args.commandColumn.title === 'Delete Item') {
      if (this.serviceOrderId) {
        const rowIndex = this.data.findIndex(item => item.id === args.rowData.id);
        this.data.splice(rowIndex, 1);
        this.grid.refresh();
      } else {
        this.showLoader = true;
        this.commonService.showConfirmation('Are you sure want to delete this item from cart?')
        .then(result => {
          if (result.isConfirmed) {
            args.rowData.isDeleted = true;
            this.iscService.deleteItemFromCart(args.rowData).subscribe((response: ApiResponse) => { // delete an item from cart
              if (response.isSuccessful) {
                const rowIndex = this.data.findIndex(item => item.id === args.rowData.id);
                this.data.splice(rowIndex, 1);
                this.onCheckboxChange();
                this.resetStatsToZero();
                this.loadPage();
                this.showLoader = false;
              }
              else {
                this.commonService.showNotification('warning', 'Item ' + args.rowData.item + ' cannot deleted');
                this.showLoader = false;
              }
            });
          }
          else {
            this.showLoader = false;
            return;
          }
        });
      }
    }
  }

  clearCart() {
    if (this.data.length) {
      this.commonService.showConfirmation('This will empty your cart !')
      .then(result => {
        if (result.isConfirmed) {
          if (this.serviceOrderId) { // comming from service order execution tab - transaction grid for multiple parts ordering
            this.data = [];
          } else {
            this.showLoader = true;
            this.iscService.clearCart(this.userDetails.employeeId).subscribe((res: ApiResponse) => {
              this.data = [];
              this.showLoader = false;
            });
          }
        }
      });
    } else {
      this.commonService.showNotification('info', 'Your cart is empty !');
    }
  }

  add = (data) => {
    if (data) {
      data.quantity++;
      this.toSendQty = data.quantity;
      this.totalQuantity++;
    }
  }

  sub = (data) => {
    if (data) {
      if (data.quantity > 1) {
        data.quantity--;
        this.toSendQty = data.quantity;
        this.totalQuantity--;
      }
      else {
        this.commonService.showNotification('warning', 'Quantity cannot be less than 1');
      }
    }
  }

  setGridToolbar() {
    this.grid.toolbar = [
      { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
      { id: 'reset-persistence', align: 'Right', prefixIcon: 'fas fa-sync', cssClass: '', tooltipText: 'Reset Persistence' },
      { id: 'cart', prefixIcon: 'fa fa-shopping-cart', cssClass: '', tooltipText: 'Reset Persistence' },
      { id: 'refresh', align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
    ];
  }

  getColumns() {
    let columns = [
      { field: 'id', type: 'number', isPrimaryKey: true, visible: false, showIncolumnChooser: false },
      { field: "", type: 'checkbox', headerText: "", allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, width: 55, showInColumnChooser: false, },
      { field: 'item', headerText: 'Item.', allowEditing: false, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      { field: 'itemDescription', headerText: 'Description', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'stock', headerText: 'Stocks', allowEditing: false, textAlign: 'Center', width: 120, visible: true },
      { field: 'quantity', headerText: 'Order Qty', allowEditing: false, textAlign: 'Center', filter: { type: 'Menu' }, visible: true, disableHtmlEncode: false, },
      { field: 'itemPrice', type: 'string', headerText: 'Price', format: 'n2', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'grossAmount', type: 'string', headerText: 'Gross Amount', format: 'n2', allowEditing: false, textAlign: 'Left', width: 120, visible: true },
      { field: 'itemDiscount', type: 'string', headerText: 'Discount', format: 'n2', allowEditing: false, textAlign: 'Left', width: 120, filter: { type: 'Menu' }, visible: true },
      { field: 'netPrice', type: 'string', headerText: 'Net Amount', format: 'n2', allowEditing: false, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      { field: 'expectedDeliveryDate', headerText: 'Estimated Delivery Date', allowEditing: false, type: 'date', format: { type: 'date', format: 'd MMM, y' }, textAlign: 'Left', width: 120, visible: true, showInColumnChooser: false },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 130, allowEditing: false, textAlign: 'Center', allowFiltering: false, allowSorting: false, allowReordering: false,
        commands: [
          { title: 'Delete Item', buttonOption: { iconCss: `fas fa-trash`, cssClass: `e-flat delete-button` } },
        ]
      }
    ];
    return columns;
  }

  setGrid() {
    this.mainGrid = {
      toolbar: []
    };
  }

  onCloseCart() {
    this.commonService.showConfirmation('Are you sure want to close cart?')
      .then(result => {
        if (result.isConfirmed) {
          this.onClose.emit();
          this.showCartCheckoutPopup = false;
        }
      });
  }

  onCloseFromCheckout() {
    this.onClose.emit();
    this.showCartCheckoutPopup = false;
  }

  expandCollapse() {
    this.isExpanded = !this.isExpanded;
    this.onExpandCollapse.emit(this.isExpanded);
  }

  openCheckout() {
    this.selectedItemsToCheckout = this.grid.getSelectedRecords()
    this.commonService.showConfirmation('Proceed to checkout?') //Are you sure to checkout all selected items? and
      .then(result => {
        this.showLoader = true;
        if (result.isConfirmed) {
          this.showCartCheckoutPopup = true;
          this.showLoader = false;
          this.hidePopup = true;
          // this.onClose.emit();
        }
        else {
          this.showLoader = false;
          return;
        }
      });
  }
}

