<div 
    class="modal fade show" 
    [ngClass]="{'expanded': isExpanded}"
    id="payment-selection-modal" 
    tabindex="-1" 
    aria-labelledby="payment-selection-modalLabel" 
    aria-hidden="true"
    [ngStyle]="{'display': 'block'}"
>
    <div class="modal-dialog modal-lg">
        <app-loader *ngIf="showLoader"></app-loader>
        <div class="modal-content">
            <div class="modal-header pt-1 pb-2">
                <div class="d-flex w-100">
                    <div class="mr-2 id">
                        <h4 class="modal-title mb-0 item-id">Payment</h4>
                    </div>
                    <div class="btns-header ml-auto">
                        <button 
                            class="header-icon close p-0 m-0" 
                            (click)="close()" 
                            aria-label="Close"
                        >
                            <i 
                                class="fas fa-times" 
                                title="Close Popup"
                            ></i>
                        </button>
                        <button 
                            class="close header-icon expand-modal p-0 m-0"
                            (click)="isExpanded = !isExpanded"
                        >
                            <i 
                                class="fas ml-2 mr-2" 
                                [ngClass]="{'fa-expand-alt': !isExpanded, 'fa-compress-alt': isExpanded}" 
                                [title]="isExpanded ? 'Exit Full Screen' : 'Enter Full Screen'"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body custom-scrollbar pt-3">  
                <div class="row">
                    <div class="col">
                        <div class="field mb-3 position-relative"> 
                            <label>Payment Method:</label>
                            <ejs-dropdownlist 
                                #paymentTypeDD
                                [placeholder]="'Select Payment Type'" 
                                [dataSource]="paymentTypes"
                                [fields]="{ text: 'description', value: 'paymentType' }"
                                [value]="paymentMethod"
                                [filterType]="'contains'"
                                [readonly]="paymentReadOnly || (invoice && ((invoice.paymentAmount === 0) || (invoice.paymentType === creditCardPaymentType && invoice.checkAuthCode) || invoice.status === invoiceStatuses.signed ) || (serviceOrderData.totalAmount < 1))"
                                (change)="onPaymentMethodChange($event)"
                            ></ejs-dropdownlist>
                            <div class="field-error" *ngIf="validationApplied && !paymentTypeDD.value">Please select a payment method!</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="field mb-3" *ngIf="showConsolidateInvoice"> 
                            <label>Consolidate Invoices:</label>
                            <div class="w-100"></div>
                            <ejs-checkbox 
                                [disabled]="paymentReadOnly || (invoice && ((invoice.paymentType == creditCardPaymentType && invoice.checkAuthCode) || invoice.status === invoiceStatuses.signed ))"
                                [checked]="consolidateInvoices"
                                (checkedChange)="consolidateInvoices = $event;"
                            ></ejs-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="service-order-grid w-100" *ngIf="consolidateInvoices && serviceOrders && serviceOrders.length">
                            <ejs-grid 
                                #serviceOrderGrid
                                [dataSource]="serviceOrders" 
                                [allowFiltering]="true"
                                [allowSorting]="true" 
                                [allowResizing]="true" 
                                [allowReordering]="true" 
                                [allowExcelExport]="true"
                                [enablePersistence]="false"
                                [toolbar]="serviceOrderGrid?.toolbar"
                                [filterSettings]="{ type: 'Menu' }"
                                [allowSelection]="true"
                                [selectionSettings]="{ mode: 'Row', checkboxOnly: true, type: 'Multiple' }"
                                (dataBound)="onDataBound($event)"
                                (rowDataBound)="onRowDataBound($event)"
                                (rowDeselecting)="onRowDeselecting($event)"
                                (checkBoxChange)="onCheckBoxChange($event)"
                                (toolbarClick)="onToolbarClick($event, serviceOrderGrid)"
                                (actionBegin)="onGridActionBegin($event)"
                                (actionComplete)="onGridActionComplete($event, serviceOrderGrid)"
                            >
                                <e-columns>
                                    <e-column 
                                        *ngFor="let column of serviceOrderGridSettings?.columns;" 
                                        [field]="column.field" 
                                        [headerText]="column.headerText"
                                        [clipMode]="'EllipsisWithTooltip'"
                                        [type]="column.type"
                                        [width]="column.width"
                                        [filter]="column.filter ? column.filter : { type: 'Menu' }"
                                        [format]="column.format ? column.format : null"
                                        [textAlign]="column.textAlign" 
                                        [showInColumnChooser]="column.showInColumnChooser" 
                                        [visible]="column.visible"
                                        [commands]="column?.commands ? column.commands : null"
                                        [allowEditing]="column.allowEditing" 
                                        [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                        <ng-template *ngIf="column.field === 'status'" #template let-data>
                                            <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                                        </ng-template>
                                    </e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger close-modal" (click)="close()">Close</button>
                <button type="button" class="btn btn-sm btn-success mr-1" *ngIf="!paymentReadOnly" id="" (click)="save()">Save</button>
            </div>
        </div>
    </div>
</div>
