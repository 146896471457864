import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from '../../services/common.service';
import { CustomAdaptor, gridActionBeginForFiltering, gridActionsForFiltering, handleClearFilter, updateFilterIcon } from '../../utils/grid-functions';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
const BASE_URL = environment.apiUrl;
@Component({
  selector: 'app-resource-selection-popup',
  templateUrl: './resource-selection-popup.component.html',
  styles: [
  ]
})
export class ResourceSelectionPopupComponent implements OnInit {

  isExpanded: boolean = false;
  resourceList;
  gridColumns;
  gridToolbar;
  gridPageSettings;
  query: Query;

  @ViewChild('resourceGrid') resourceGrid;

  @Input() responder: string;

  @Output() onClose = new EventEmitter();
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.setGrid();
    this.resourceList = new DataManager({
      url: `${BASE_URL}ServiceEmployee/GetEmployeeSearchView`,
      adaptor: new CustomAdaptor()
    });
    // this.query = new Query().addParams('employeeId', this.responder).addParams('fromResourceSelectionPopup', '');
  }

  setGrid() {
    this.gridColumns = [
      { field: 'id', headerText: 'ID', width: 120, visible: false, nonHideable: true, showInColumnChooser: false, type: 'string' },
      { field: 'company', headerText: 'Company', visible: true, type: 'string' },
      { field: 'employeeId', headerText: 'Technician ID', visible: true, type: 'string' },
      { field: 'employeeName', headerText: 'Technician Name', visible: true, type: 'string' },
      { field: 'serviceDepartment', headerText: 'Service Department', visible: true, type: 'string' },
      {
        field: 'Actions', headerText: 'Actions', textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
        commands: [
          {
            title: 'Proceed',
            buttonOption: {
              iconCss: `fas fa-arrow-right`,
              cssClass: `e-flat action-button`
            }
          }
        ]
      }
    ];
    this.gridToolbar = ['Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
    this.gridPageSettings = { pageSize: 10 }

  }

  close() {
    this.onClose.emit();
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    }
  }

  onGridCreated(args) {
    updateFilterIcon({ requestType: 'filtering' }, this.resourceGrid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid) {
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Proceed') {
      args.rowData.technicianId = args.rowData.employeeId;
      args.rowData.technicianName = args.rowData.employeeName;
      this.onClose.emit(args.rowData);
    }
  }

}
