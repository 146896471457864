export interface ElementsList {
    structural: string[],
    form: string[],
    grids?: string[]
}

export const pageType = {
    page: 1,
    popup: 2
}

export interface LooseObject {
    [key: string]: any;
}

export interface HtmlElementObject {
    [key: string]: any;
}

export interface CheckboxObject {
    [key: string]: any;
}

export interface Notification {
    title: string;
    content: string;
    type: string;
}

export interface DropdownFields {
    text: string;
    value: string | number;
}

export interface PersistenceData {
    userId: string;
    name: string;
    value: string;
}

export const CRUDModes = {
    CREATE: 'CREATE',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
}

export const gridNames = {
    usersGrid: 'users-grid',
    missingSignaturesGrid: 'dashboard-missing-signatures-grid',
    claimsMainGrid: 'claims-main-grid',

    serviceOrderMainGrid: 'ServiceOrderMainGrid',
    iscServiceOrderMainGrid: 'IscServiceOrderMainGrid',
    serviceOrderTransactionsGrid: 'assignmentDetailsGrid',
    calibrationGrid: 'calibration-grid',
    mydayGrid: 'myday-grid',
    purchaseOrdersGrid: 'purchase-orders-grid',
    serviceablePartsGrid: 'serviceable-parts-grid',
    unknownPartsGrid: 'unknown-parts-grid',
    wrongPartsGrid: 'wrong-parts-grid',
    defectivePartsGrid: 'defective-parts-grid',
    coreReturnPartsGrid: 'core-return-parts-grid',
    quarantinedPartsGrid: 'quarantined-parts-grid',
    damagedPartsGrid: 'damaged-parts-grid',
    exceptionsGrid: 'exceptions-grid',
    partsOrderMainGrid: 'parts-order-main-grid',
    partsOrderItemsGrid: 'parts-order-items-grid',
    returnOrderMainGrid: 'return-order-main-grid',
    coreReturnOrderMainGrid: 'core-return-order-main-grid',
    damagedReturnOrderMainGrid: 'damaged-return-order-main-grid',
    returnOrderItemsGrid: 'return-order-items-grid',
    shipmentOrderMainGrid: 'shipmentOrderMainGrid',
    shipmentOrderReceiptsGrid: 'shipment-order-receipts-grid',
    shipmentOrderExceptionsGrid: 'shipment-order-exceptions-grid',
    employeesGrid: 'employees-grid',
    serviceOrdersOnholdForParts: 'serviceOrdersOnholdForParts-grid',
    serviceOrderWidgetGrid: 'serviceOrderWidget-Grid',
    partsOrderWidgetGrid: 'partsOrderWidget-Grid',
    refEquipmentGrid: 'refEquipment-Grid',
    OtherTechniciansInventoryGrid: 'other-technicians-inventory-grid',
    inventoryRequestedByMeGrid: 'inventory-requested-by-me-grid',
    inventoryOrdersForMeGrid: 'inventoryOrdersForMeGrid',
    linkServiceOrdersGrid: 'linkServiceOrdersGrid',
    invenotryAdjustmentOrdersGrid: 'invenotryAdjustmentOrdersGrid',
    priceBookGrid: 'priceBookGrid',
    cycleCountGrid: 'cycleCountGrid',
    cycleCountAddPopupGrid: 'cycleCountAddPopupGrid',
    cycleCountOpenExceptionGrid: 'cycleCountOpenExceptionGrid',
    cycleCountCostAnalysisGrid: 'cycleCountCostAnalysisGrid',
    cycleCountUploadDetailGrid: 'cycleCountUploadDetailGrid',
    cycleCountExceptionPopupGrid: 'cycleCountExceptionPopupGrid',
    serviceOrdersPodPopupGrid: 'serviceOrdersPodPopupGrid',
    costAnalysisInventoryGrid: 'costAnalysisInventoryGrid',
    itemsGrid: 'itemsGrid',
    invenotryTransactionHistoryGrid: 'invenotryTransactionHistoryGrid',

    masterDataAddressGrid: 'masterDataAddressGrid',
    masterBussinessPartnerGrid: 'masterBussinessPartnerGrid',
    masterCofigTopObjectsGrid: 'masterCofigTopObjectsGrid',
    masterConfigurationGrid: 'masterConfigurationGrid',
    masterContractCostTermsGrid: 'masterContractCostTermsGrid',
    masterConversionFactorsGrid: 'masterConversionFactorsGrid',
    masterContractCoverageLineGrid: 'masterContractCoverageLineGrid',
    masterCostComponetGrid: 'masterCostComponetGrid',
    masterCreditRatingGrid: 'masterCreditRatingGrid',
    masterCurrenciesGrid: 'masterCurrenciesGrid',
    masterCurrencyRatesGrid: 'masterCurrencyRatesGrid',
    masterDistanceZonesGrid: 'masterDistanceZonesGrid',
    masterEquipmentGrid: 'masterEquipmentGrid',
    masterERPParametersGrid: 'masterERPParametersGrid',
    masterGeneralTasksGrid: 'masterGeneralTasksGrid',
    masterLabourRatesGrid: 'masterLabourRatesGrid',
    masterObjectGrid: 'masterObjectGrid',
    masterObjectGroupGrid: 'masterObjectGroupGrid',
    masterPaymentMethodGrid: 'masterPaymentMethodGrid',
    masterPriorityGrid: 'masterPriorityGrid',
    masterProblemsObjectGrid: 'masterProblemsObjectGrid',
    masterReasonsGrid: 'masterReasonsGrid',
    masterProblemsGrid: 'masterProblemsGrid',
    masterReferenceActivitiesGrid: 'masterReferenceActivitiesGrid',
    masterServiceCarsGrid: 'masterServiceCarsGrid',
    masterServiceCentersGrid: 'masterServiceCentersGrid',
    masterServiceEmployeesGrid: 'masterServiceEmployeesGrid',
    masterServiceItemsGrid: 'masterServiceItemsGrid',
    masterServiceTypesGrid: 'masterServiceTypesGrid',
    masterSignalsGrid: 'masterSignalsGrid',
    masterSoldToBpGrid: 'masterSoldToBpGrid',
    masterSolutionsGrid: 'masterSolutionsGrid',
    masterSolObjectGroupGrid: 'masterSolObjectGroupGrid',
    masterTasksGrid: 'masterTasksGrid',
    masterTaxRateGrid: 'masterTaxRateGrid',
    masterTemplateGrid: 'masterTemplateGrid',
    masterUnitsGrid: 'masterUnitsGrid',
    masterWarehouseGrid: 'masterWarehouseGrid',
    masterWarrantyCoverageGrid: 'masterWarrantyCoverageGrid',
    masterZoneClassGrid: 'masterZoneClassGrid',
    inventoryOrdersRequestedGrid: 'inventoryOrdersRequestedGrid',
    inventoryOrderShippedGrid: 'inventoryOrderShippedGrid',
    adjustmentOrderMainGrid: 'adjustmentOrderMainGrid',
    partsCatalogGrid: 'partsCatalogGrid',
    cartGrid: 'cartGrid',
    ISCPartsOrderItemsGrid: 'ISCpartsOrderItemsGrid',
    iscRulesGrid: 'iscRulesGrid',
    iscRuleCheckListGrid: 'iscRuleCheckListGrid',
    selectBuyerGrid:'selectBuyerGrid',
    trackingUpdatesGrid:'trackingUpdatesGrid',
    orderedItemsGrid:'orderedItemsGrid',
    freightChargesGrid:'freightChargesGrid',
    POMultipleItemsCheckoutGrid:'POMultipleItemsCheckoutGrid',
    OrderedItemsGrid:'OrderedItemsGrid',
    TrackingUpdatesGrid:'TrackingUpdatesGrid',
    dataDiscrepancyGrid:'dataDiscrepancyGrid',
    exportErrorGrid:'exportErrorGrid',
    importErrorGrid:'importErrorGrid',
    uncostedOrdersPodGrid:'uncostedOrdersPodGrid',
    receiptsPopupShippedItemsGrid:"receiptsPopupShippedItemsGrid",
    receiptsPopupShippedExceptionsGrid:"receiptsPopupShippedExceptionsGrid",
    pendingApprovalGrid:"pending-approval-grid",

}

export enum UserRolesDescriptions {
    MS_SVCFST = "Field Service Technician",
    ISCManager = "ISC Manager",
    ISCSupervisor = "ISC Supervisor",
    KL_SVCMGR = "KingsLynn Service Manager",
    KL_NationalMGR = "KingsLynn National Manager",
    IndependantServiceContractor = "Independant Service Contractor"
}

export enum UserRoles {
    // admin = "Admin",
    manager = "Manager",
    conwayServiceManager = "MS_SVCMGR", //(Conway Service Manager Role)
    fst = "MS_SVCFST", //(Field Service Technician Role)
    csa = "MS_SVCCSA", // (Customer Service Agent Role)
    isc = "IndependantServiceContractor",
    administratorRole = "MS_SVCADM", // (Admin Role)
    // readOnlyRole = "MS_SVCRDO", //(Read Only)
    iscManager = "ISCManager", // ISC Manager
    IscSupervisor = "ISCSupervisor", // ISC Supervisor role created 13th June, 2024
    klServiceManager = "KL_SVCMGR", // (KL Service Manager Role)
    klNationalMGR = "KL_NationalMGR",
    readOnly = "ReadOnly"

    //Only following roles exist in the DB [provided by Sohaib]
    // ReadOnly
    // Manager
    // MS_SVCMGR
    // MS_SVCFST
    // MS_SVCCSA
    // IndependantServiceContractor
    // ServiceManager
    // ISCManager
    // KL_NationalMGR
    // KL_SVCMGR
    // MS_SVCADM

}

export enum ModuleNames {
    returnOrder = 'ReturnOrder',
    shipmentOrder = 'ShipmentOrder',
    calibration = 'Calibration',
    cycleCount = 'CycleCount',
    inventoryAdjustmentOrder = 'InventoryAdjustmentOrder',
    partsOrder = 'PartsOrder',
    purchaseOrder = 'PurchaseOrder',
    iscServiceOrder = 'IscServiceOrder',
    serviceOrder = 'ServiceOrder',
    claims = 'Claims',
    transferOrder = 'TransferOrder',
    adjustmentOrder = "AdjustmentOrder",
    coreReturnOrder = "CoreReturnOrder",
    damagedReturnOrder = "DamagedReturnOrder",
    shipmentAdjustmentOrder = 'AdjustmentOrder',
}

export enum ModuleFullNames {
    returnOrder = 'Return Order',
    shipmentOrder = 'Shipment Order',
    calibration = 'Calibration',
    cycleCount = 'Cycle Count',
    inventoryAdjustmentOrders = 'Inventory Adjustment Order',
    partsOrder = 'Parts Order',
    purchaseOrder = 'Purchase Order',
    iscServiceOrder = 'ISC Service Order',
    serviceOrder = 'Service Order',
    claims = 'Claims',
    transferOrder = 'Transfer Order',
}

export const GridPersistRequests = ['filtering', 'sorting', 'columnstate', 'searching', 'refresh', 'reorder', 'paging'];

export enum LinksEnum {
    serviceOrder = 1,
    partsOrder = 2,
    returnOrder = 3,
    shipmentOrder = 4,
    inventory = 5,
    claims = 6,
    iscServiceOrder = 7,
    purchaseOrder = 8
}

export enum OrderTypes {
    serviceOrder = 'serviceorder',
    iscServiceOrder = 'iscserviceorder',
    partsOrder = 'partsorder',
    returnOrder = 'returnorder',
    coreReturnOrder = 'corereturnorder',
    damagedReturnOrder = 'damagedreturnorder',
    shipmentOrder = 'shipmentorder',
    inventory = 'inventory',
    adjustmentorder = 'adjustmentorder',
    purchaseOrder = 'purchaseorder',
    cycleCountOrder = 'cyclecountorder',
    inventoryAdjustmentOrder = 'inventoryadjustmentorder',
    shipmentInventoryAdjustmentOrder = 'shipmentinventoryadjustmentorder',
    transferOrder = 'transferorder'
}

export enum Companies {
    kingslynn = '389',
    conway = '140'
}

export enum PreferencesType {
    Calibrations = 'Calibrations',
    Claims = 'Claims',
    ServiceOrder = 'ServiceOrder',
    InventoryAndOrders = 'Inventory & Orders',
    CommonMiscellaneous = 'Common/Miscellaneous',
    PurchaseOrder = 'PurchaseOrder',
}

export interface ApiResponse {
    apiException: string;
    errors: any;
    message: string;
    result: any;
    statusCode: number;
    success?: boolean;
    isSuccessful?: boolean;
    totalRecords?: number;
}

export const FilterTypes = {
    soMainGrid: {
        referenceActivityDescription: 'Excel',
        priorityDescription: 'Excel',
        technicianName: 'Excel',
        itemDescription: 'Menu',
        equipmentStatusDescription: 'Excel',
        customerCode: 'Excel',
        customerDescription: 'Menu',
        customerArea: 'Menu',
        serviceDepartmentDescription: 'Excel',
        customerPhoneNumber: 'Menu',
        city: 'Excel',
        state: 'Excel',
        status: 'Excel',
        reasonDescription: 'Excel',
        callGroup: 'Excel',
        callGroupDescription: 'Excel'
    }
}

export interface ISignal {
  action: string;
  subAction?: string;
  data: any;
  subData?: any;
}
export interface ISignal {
  action: string;
  subAction?: string;
  data: any;
  subData?: any;
}
export interface UserProfileData {
    smsPreferences?: any;
    id?: number | string;
    employeeName?: string;
    serviceDepartment?: string;
    serviceDepartmentDesc?: string;
    serviceCar?: string;
    addressCode?: string;
    name?: string;
    name2?: string;
    address?: string,
    address2?: string;
    houseNumber?: string;
    postBoxNumber?: string;
    city?: string;
    telephone?: string;
    fax?: string;
    country?: string;
    email?: string;
    stateProvince?: string;
    zipCode?: string;
    employeeId: string;
    parentEmployeeId?: string;
    laborRate?: string | number;
    role?: string;
    userId?: string;
    sso?: boolean;
    userName?: string;
    warehouse?: string;
    duration?: string | number;
    readDuration?: string | number;
    profilePic?: any;
    employeeAddress?: any;
    notificationPreferences?: any[];
    emailPreferences?: any[];
    businessProfileLogo?: any;
    isDisable?: boolean;
    notificationDuration?: string;
    readNotificationExp?: string;
    seEmail?: string;
    roleDesc?: string;
    roleId?: string;
    isSignatureCreated?: boolean;

    iscSupplierCode?: string,
    iscCode?: string;
    t1laborRate?: string;
    t2laborRate?: string;
    t1CalloutRate?: string;
    t2CalloutRate?: string;
    mileageThreshold?: string;
    mileageRate?: string;
    userType?: string;
    payVia?: string;
    iscManager?: string;
}

export enum ActionButtonTitles {
    complete = 'Complete',
    approve = 'Approve',
    reject = 'Reject',
    onhold = 'On-hold',
    email = 'Email',
    republish = 'Republish',
    setToOpen = 'Set to open',
    eye = 'Eye',
    comment = 'Comment',
    estimate = 'Estimate',
}

export enum PopupTabs {
    details = 'details',
    links = 'links',
    attachments = 'attachments',
    history = 'history',
}

export enum DashboardPods {
    pendingApproval = 'pendingApproval',
}

export enum ToolbarItemsId {
    gridExcelExport = 'grid_excelexport',
    gridPdfExport = 'grid_pdfexport',
    clearFilter = 'clear-filter',
    columnChooser = 'column-chooser',
    createAdjustmentOrder = 'add-adjustment-order',
}

export enum ActionRequestTypes {
    filtering = 'filtering',
}

export enum NotificationTypes {
    error = 'error',
    success = 'success',
}

export enum MaintenanceDashboardStatus {
    fixed = 'Fixed',
    open = 'Open',
    republished = 'Republished',
    pendingRepublish = 'Pending Republish'
}

export enum ToolbarClickTitles {
    refresh = 'refresh',
    excelExport = 'excel-export',
    resetPersistence = 'reset-persistence',
    clearFilter = 'clear-filter',
}

export enum PartsAvailabilityStatus {
    active = 'Active',
    inActive = 'Inactive',
}

export enum paymentMethodType {
    onAccount = 'On Account',
    creditCard = 'Credit Card'
}
