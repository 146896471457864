import { NgModule } from '@angular/core';

// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { NumericTextBoxModule, TextBoxModule, UploaderModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ComboBoxAllModule, DropDownListModule, MentionModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule, DateRangePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { PageService } from './services/page.service';
import { MasterDataService } from './services/master-data.service';
import { CommonService } from './services/common.service';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

import { LoaderComponent } from './components/loader/loader.component';
import { EjsGridComponent } from './components/grid/grid.component';
import { PageStructureComponent } from '../modules/builder/components/page-structure/page-structure.component';
import { CommonModule } from '@angular/common';
import { MobileServicePopupComponent } from './components/mobile-service-popup/mobile-service-popup.component';
import { CommentsComponent } from './components/comments/comments.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ColumnChooserComponent } from './components/column-chooser/column-chooser.component';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { ProgressBarAllModule } from '@syncfusion/ej2-angular-progressbar';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { HistoryTabComponent } from './components/history-tab/history-tab.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ServiceOrderService } from '../modules/service-order/services/service-order.service';
import { GeneralSectionService } from '../modules/service-order/services/general-section.service';
import { EquipmentInformationSectionService } from '../modules/service-order/services/equipment-information-section.service';
import { ExecutionTabService } from '../modules/service-order/services/execution-tab.service';
import { PopupComponent } from '../modules/service-order/pages/popup/popup.component';
import { PopupHeaderComponent } from '../modules/service-order/components/popup-header/popup-header.component';
import { PopupFooterComponent } from '../modules/service-order/components/popup-footer/popup-footer.component';
import { PopupLayoutComponent } from '../modules/service-order/components/popup-layout/popup-layout.component';
import { NotesCommentsSectionComponent } from '../modules/service-order/components/notes-comments-section/notes-comments-section.component';
import { ExecutionTabComponent } from '../modules/service-order/components/execution-tab/execution-tab.component';
import { AttachmentsTabComponent } from '../modules/service-order/components/attachments-tab/attachments-tab.component';
import { AssignmentsPopupComponent } from '../modules/service-order/components/assignments-popup/assignments-popup.component';
import { AssignmentDetailsPopupComponent } from '../modules/service-order/components/assignment-details-popup/assignment-details-popup.component';
import { ConfigAdvancedSearchComponent } from '../modules/service-order/components/config-advanced-search/config-advanced-search.component';
import { PaymentTypePopupComponent } from '../modules/service-order/components/payment-type-popup/payment-type-popup.component';
import { CreditCardPopupComponent } from '../modules/service-order/components/credit-card-popup/credit-card-popup.component';
import { InvoicePopupComponent } from '../modules/service-order/components/invoice-popup/invoice-popup.component';
import { PaymentSelectionPopupComponent } from '../modules/service-order/components/payment-selection-popup/payment-selection-popup.component';
import { CameraComponent } from '../modules/service-order/components/camera/camera.component';
import { PartsOrdersPopupComponent } from '../modules/home/modules/orders/components/parts-orders/parts-orders-popup/parts-orders-popup.component';
import { DetailsTabComponent } from '../modules/home/modules/orders/components/parts-orders/parts-orders-popup/details-tab/details-tab.component';
import { ItemsTabComponent } from '../modules/home/modules/orders/components/parts-orders/parts-orders-popup/items-tab/items-tab.component';
import { NotesComponent } from '../modules/home/components/notes/notes.component';
import { LinksTabComponent } from '../modules/service-order/components/links-tab/links-tab.component';
import { LinksTabSharedComponent } from './components/links-tab/links-tab.component';
import { ServiceOrderHistoryTabComponent } from '../modules/service-order/components/history-tab/history-tab.component';
import { ConfigAdvancedSearchForSerialNumberComponent } from '../modules/service-order/components/config-advanced-search-for-serial-number/config-advanced-search-for-serial-number.component';
import { CancelStatusPopupComponent } from '../modules/service-order/components/cancel-status-popup/cancel-status-popup.component';
import { LinkedPopupOpenerComponent } from './components/linked-popup-opener/linked-popup-opener.component';
import { SyncfusionGridComponent } from './components/syncfusion-grid/syncfusion-grid.component';
import { MsDynamicPopupComponent } from './components/ms-dynamic-popup/ms-dynamic-popup.component';
import { DynamicPopupLayoutComponent } from './components/dynamic-popup-layout/dynamic-popup-layout.component';
import { ItemsAdvancedSearchComponent } from './components/items-advanced-search/items-advanced-search.component';
import { EmailPopupComponent } from './components/email-popup/email-popup.component';
import { ServiceOrderListForClaimPopupComponent } from './components/service-order-list-for-claim-popup/service-order-list-for-claim-popup.component';
import { ClaimsService } from './services/claims.service';
import { ShipmentOrderPopupComponent } from '../modules/home/components/shipment-order-popup/shipment-order-popup.component';
import { ShipmentDetailsTabComponent } from '../modules/home/components/shipment-order-popup/shipment-details-tab/shipment-details-tab.component';
import { ShipmentOrderItemsTabComponent } from '../modules/home/components/shipment-order-popup/shipment-order-items-tab/shipment-order-items-tab.component';
import { ShipmentReceiptsTabComponent } from '../modules/home/components/shipment-order-popup/shipment-receipts-tab/shipment-receipts-tab.component';
import { CalibrationTabComponent } from '../modules/service-order/components/calibration-tab/calibration-tab.component';
import { CalibrationSelectionPopupComponent } from '../modules/service-order/components/calibration-selection-popup/calibration-selection-popup.component';
import { PdfViwerComponent } from './components/pdf-viwer/pdf-viwer.component';
import { MsDynamicModalComponent } from './components/ms-dynamic-modal/ms-dynamic-modal.component';
import { WrongPartsListComponent } from '../modules/home/components/shipment-order-popup/wrong-parts-list/wrong-parts-list.component';
import { ShowchecklistComponent } from './components/showchecklist/showchecklist.component';
import { IframePopupComponent } from './components/iframe-popup/iframe-popup.component';
import { InventoryTransferRequestPopupComponent } from './components/inventory-transfer-request-popup/inventory-transfer-request-popup.component';
import { InventoryTransferResponsePopupComponent } from './components/inventory-transfer-response-popup/inventory-transfer-response-popup.component';
import { ResourceSelectionPopupComponent } from './components/resource-selection-popup/resource-selection-popup.component';
import { LinkServiceOrdersPopupComponent } from './components/link-service-orders-popup/link-service-orders-popup.component';
import { SoDetailsTabComponent } from '../modules/service-order/pages/popup/so-details-tab/so-details-tab.component';
import { ServiceOrderNteUpdateModalComponent } from './components/service-order-nte-update-modal/service-order-nte-update-modal.component';
import { BarclayPaymentComponent } from '../modules/service-order/components/barclay-payment/barclay-payment.component';
import { MapsService } from './services/maps.service';
import { ChaseResponseSuccessComponent } from './components/iframe-popup/chase-response-success/chase-response-success.component';
import { ChaseResponseFailedComponent } from './components/iframe-popup/chase-response-failed/chase-response-failed.component';
import { BarclaySuccessComponent } from '../modules/service-order/components/barclay-response/response/barclay-success.component';
import { BarclayFailedComponent } from '../modules/service-order/components/barclay-response/response/barclay-failed.component';
import { TreeGridAllModule } from '@syncfusion/ej2-angular-treegrid';
import { UsersService } from './services/users.service';
import { ReturnOrderStatementViewerComponent } from './components/return-order-statement-viewer/return-order-statement-viewer.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { IscPopupComponent } from '../modules/service-order/pages/isc-popup/isc-popup.component';
import { IscSoDetailsTabComponent } from '../modules/service-order/pages/isc-popup/isc-so-details-tab/isc-so-details-tab.component';
import { IscExecutionTabComponent } from '../modules/service-order/components/isc-execution-tab/isc-execution-tab.component';
import { IscAssignmentsPopupComponent } from '../modules/service-order/components/isc-assignments-popup/isc-assignments-popup.component';
import { IscAssignmentDetailsPopupComponent } from '../modules/service-order/components/isc-assignment-details-popup/isc-assignment-details-popup.component';
import { IscClaimsPopupComponent } from './components/isc-claims-popup/isc-claims-popup.component';
import { IscClaimsRulesBusinessComponent } from './components/isc-claims-popup/isc-claims-business-rules/isc-claims-business-rules.component';
import { IscClaimsRulesCustomerComponent } from './components/isc-claims-popup/isc-claims-customer-rules/isc-claims-customer-rules.component';
import { IscSOClaimsComponent } from './components/isc-claims-popup/isc-so-claims/isc-so-claims.component';
import { AdvanceCommentsComponent } from './components/advance-comments/advance-comments.component';
import { CartCheckoutPopupComponent } from '../modules/home/modules/parts-catalog/cart-checkout-popup/cart-checkout-popup.component';
import { PoResponseFailedComponent } from './components/iframe-popup/po-response-failed/po-response-failed.component';
import { PoResponseSuccessComponent } from './components/iframe-popup/po-response-success/po-response-success.component';
import { ReasonPopupComponent } from './components/reason-popup/reason-popup.component';
import { ShipmentAdjustmentOrderPopupComponent } from './components/shipment-adjustment-order-popup/shipment-adjustment-order-popup.component';
import { ShipmentAdjustmentOrderPopupDetailsTabComponent } from './components/shipment-adjustment-order-popup/shipment-adjustment-order-popup-details-tab/shipment-adjustment-order-popup-details-tab.component';
import { ShipmentAdjustmentOrderPopupExceptionsTabComponent } from './components/shipment-adjustment-order-popup/shipment-adjustment-order-popup-exceptions-tab/shipment-adjustment-order-popup-exceptions-tab.component';
import { ShippedItemsComponent } from '../modules/home/components/shipment-order-popup/shipment-receipts-tab/shipped-items/shipped-items.component';
import { ShippedExceptionsComponent } from '../modules/home/components/shipment-order-popup/shipment-receipts-tab/shipped-exceptions/shipped-exceptions.component';
import { ReturnOrderPopupComponent } from '../modules/home/components/return-order-popup/return-order-popup.component';
import { ReturnOrderPopupDetailsTabComponent } from '../modules/home/components/return-order-popup/return-order-popup-details-tab/return-order-popup-details-tab.component';
import { ReturnOrderItemsTabComponent } from '../modules/home/components/return-order-popup/return-order-items-tab/return-order-items-tab.component';
import { CycleCountService } from '../modules/home/modules/cycle-counts/cycle-counts.service';
import { CycleCountComponent } from '../modules/home/modules/cycle-counts/cycle-counts.component';
import { CycleCountAddPopupComponent } from '../modules/home/modules/cycle-counts/cycle-counts-add-popup/cycle-counts-add-popup.component';
import { CycleCountPopupComponent } from '../modules/home/modules/cycle-counts/cycle-counts-popup/cycle-counts-popup.component';
import { CycleCountsResourceSelectionPopupComponent } from '../modules/home/modules/cycle-counts/cycle-counts-popup/cycle-counts-resource-selection-popup/cycle-counts-resource-selection-popup.component';
import { CycleCountUploadDetailPopupComponent } from '../modules/home/modules/cycle-counts/cycle-counts-popup/cycle-count-cost-analysis/cycle-count-upload-detail/cycle-counts-upload-detail.component';
import { CycleCountOpenExceptionComponent } from '../modules/home/modules/cycle-counts/cycle-counts-popup/cycle-count-open-exception/cycle-counts-open-exception.component';
import { CycleCountCostAnalysisComponent } from '../modules/home/modules/cycle-counts/cycle-counts-popup/cycle-count-cost-analysis/cycle-counts-cost-analysis.component';
import { CycleCountExceptionPopupComponent } from '../modules/home/modules/cycle-counts/cycle-counts-exception-popup/cycle-counts-exception-popup.component';
import { CycleCountsModule } from '../modules/home/modules/cycle-counts/cycle-counts.module';
import { CoreReturnOrderPopupComponent } from '../modules/home/components/core-return-order-popup/core-return-order-popup.component';
import { CoreReturnOrderPopupDetailsTabComponent } from '../modules/home/components/core-return-order-popup/core-return-order-popup-details-tab/core-return-order-popup-details-tab.component';
import { CoreReturnOrderPopupItemsTabComponent } from '../modules/home/components/core-return-order-popup/core-return-order-popup-items-tab/core-return-order-popup-items-tab.component';
import { TransferOrderPopupOpenerComponent } from './components/transfer-order-popup-opener/transfer-order-popup-opener.component';
import { EmailAddressInputComponent } from './components/email-popup/email-address-input/email-address-input.component';
import { CoreReturnOrderPopupCoreItemsTabComponent } from '../modules/home/components/core-return-order-popup/core-return-order-popup-core-items-tab/core-return-order-popup-core-items-tab.component';
import { PartsCatalogPopupComponent } from '../modules/home/modules/parts-catalog/parts-catalog-popup/parts-catalog-popup.component';

@NgModule({
    declarations: [
        PartsCatalogPopupComponent,
        ServiceOrderNteUpdateModalComponent,
        PopupComponent,
        IscPopupComponent,
        PopupHeaderComponent,
        PopupFooterComponent,
        PopupLayoutComponent,
        SoDetailsTabComponent,
        IscSoDetailsTabComponent,
        NotesCommentsSectionComponent,
        ExecutionTabComponent,
        IscExecutionTabComponent,
        CalibrationTabComponent,
        CalibrationSelectionPopupComponent,
        LinksTabComponent,
        LinksTabSharedComponent,
        AttachmentsTabComponent,
        AssignmentsPopupComponent,
        IscAssignmentsPopupComponent,
        AssignmentDetailsPopupComponent,
        IscAssignmentDetailsPopupComponent,
        ConfigAdvancedSearchComponent,
        ConfigAdvancedSearchForSerialNumberComponent,
        PaymentTypePopupComponent,
        CreditCardPopupComponent,
        InvoicePopupComponent,
        PaymentSelectionPopupComponent,
        CameraComponent,

        PartsOrdersPopupComponent,
        DetailsTabComponent,
        ItemsTabComponent,
        NotesComponent,
        CancelStatusPopupComponent,

        ShipmentOrderPopupComponent,
        ShipmentDetailsTabComponent,
        ShipmentOrderItemsTabComponent,
        ShipmentReceiptsTabComponent,
        ShippedItemsComponent,
        ShippedExceptionsComponent,

        LoaderComponent,
        EjsGridComponent,
        PageStructureComponent,
        MobileServicePopupComponent,
        CommentsComponent,
        AdvanceCommentsComponent,
        ShowchecklistComponent,
        ColumnChooserComponent,
        DropdownButtonComponent,
        HistoryTabComponent,
        ServiceOrderHistoryTabComponent,
        ConfirmationBoxComponent,
        ToastNotificationComponent,
        DateAgoPipe,
        SyncfusionGridComponent,
        MsDynamicPopupComponent,
        DynamicPopupLayoutComponent,
        LinkedPopupOpenerComponent,
        ItemsAdvancedSearchComponent,
        EmailPopupComponent,
        PdfViwerComponent,
        ServiceOrderListForClaimPopupComponent,
        MsDynamicModalComponent,
        WrongPartsListComponent,
        IframePopupComponent,
        InventoryTransferRequestPopupComponent,
        InventoryTransferResponsePopupComponent,
        ResourceSelectionPopupComponent,
        LinkServiceOrdersPopupComponent,
        BarclayPaymentComponent,
        ChaseResponseSuccessComponent,
        ChaseResponseFailedComponent,
        BarclaySuccessComponent,
        BarclayFailedComponent,
        ReturnOrderStatementViewerComponent,
        TimeAgoPipe,

        IscClaimsPopupComponent,
        IscClaimsRulesBusinessComponent,
        IscClaimsRulesCustomerComponent,
        IscSOClaimsComponent,
        CartCheckoutPopupComponent,
        PoResponseFailedComponent,
        PoResponseSuccessComponent,
        ReasonPopupComponent,
        ShipmentAdjustmentOrderPopupComponent,
        ShipmentAdjustmentOrderPopupDetailsTabComponent,
        ShipmentAdjustmentOrderPopupExceptionsTabComponent,
        EmailAddressInputComponent,

        // Return order popup
        ReturnOrderPopupComponent,
        ReturnOrderPopupDetailsTabComponent,
        ReturnOrderItemsTabComponent,

        // Cycle count popup
        CycleCountComponent,
        CycleCountAddPopupComponent,
        CycleCountPopupComponent,
        CycleCountsResourceSelectionPopupComponent,
        CycleCountUploadDetailPopupComponent,
        CycleCountOpenExceptionComponent,
        CycleCountCostAnalysisComponent,
        CycleCountExceptionPopupComponent,

        // Core return order popup
        CoreReturnOrderPopupComponent,
        CoreReturnOrderPopupDetailsTabComponent,
        CoreReturnOrderPopupItemsTabComponent,
        CoreReturnOrderPopupCoreItemsTabComponent,
        TransferOrderPopupOpenerComponent,

    ],
    imports: [
        // MatFormFieldModule,
        // MatInputModule,
        // MatIconModule,
        // MatButtonModule,
        // MatCheckboxModule,
        // MatProgressSpinnerModule,
        // DragDropModule,
        TextBoxModule,
        UploaderModule,
        NumericTextBoxModule,
        DateRangePickerModule,
        MaskedTextBoxModule,
        CheckBoxModule,
        CommonModule,
        DropDownListModule,
        MultiSelectModule,
        DatePickerModule,
        DateTimePickerModule,
        GridAllModule,
        ToastModule,
        DashboardLayoutModule,
        RichTextEditorAllModule,
        MentionModule,
        ComboBoxAllModule,
        ProgressBarAllModule,
        DropDownButtonModule,
        DialogModule,
        ToastModule,
        RadioButtonModule,
        FormsModule,
        ReactiveFormsModule,
        SwitchModule,
        TreeViewModule,
        DropDownButtonModule,
        CycleCountsModule
    ],
    exports: [
        PartsCatalogPopupComponent,
        ServiceOrderNteUpdateModalComponent,
        PopupComponent,
        IscPopupComponent,
        TreeGridAllModule,
        PopupHeaderComponent,
        PopupFooterComponent,
        PopupLayoutComponent,
        SoDetailsTabComponent,
        MentionModule,
        IscSoDetailsTabComponent,
        NotesCommentsSectionComponent,
        ExecutionTabComponent,
        IscExecutionTabComponent,
        CalibrationTabComponent,
        CalibrationSelectionPopupComponent,
        LinksTabComponent,
        LinksTabSharedComponent,
        AttachmentsTabComponent,
        AssignmentsPopupComponent,
        IscAssignmentsPopupComponent,
        AssignmentDetailsPopupComponent,
        IscAssignmentDetailsPopupComponent,
        ConfigAdvancedSearchComponent,
        PaymentTypePopupComponent,
        CreditCardPopupComponent,
        InvoicePopupComponent,
        PaymentSelectionPopupComponent,
        CameraComponent,
        DateRangePickerModule,
        PartsOrdersPopupComponent,
        DetailsTabComponent,
        ItemsTabComponent,
        NotesComponent,
        CancelStatusPopupComponent,
        TextBoxModule,
        UploaderModule,
        NumericTextBoxModule,
        MaskedTextBoxModule,
        CheckBoxModule,
        DropDownListModule,
        MultiSelectModule,
        DatePickerModule,
        DateTimePickerModule,
        GridAllModule,
        ToastModule,
        LoaderComponent,
        DashboardLayoutModule,
        EjsGridComponent,
        PageStructureComponent,
        MobileServicePopupComponent,
        CommentsComponent,
        AdvanceCommentsComponent,
        RichTextEditorAllModule,
        MentionModule,
        ColumnChooserComponent,
        ComboBoxAllModule,
        ProgressBarAllModule,
        DropDownButtonModule,
        HistoryTabComponent,
        ServiceOrderHistoryTabComponent,
        ConfirmationBoxComponent,
        ToastNotificationComponent,
        RadioButtonModule,
        DateAgoPipe,
        FormsModule,
        ReactiveFormsModule,
        TreeViewModule,
        DropDownButtonModule,
        SyncfusionGridComponent,
        MsDynamicPopupComponent,
        LinkedPopupOpenerComponent,
        ItemsAdvancedSearchComponent,
        EmailPopupComponent,
        ShipmentOrderPopupComponent,
        ShipmentDetailsTabComponent,
        ShippedItemsComponent,
        ShippedExceptionsComponent,
        ShipmentOrderItemsTabComponent,
        ShipmentReceiptsTabComponent,
        PdfViwerComponent,
        ServiceOrderListForClaimPopupComponent,
        MsDynamicModalComponent,
        WrongPartsListComponent,
        IframePopupComponent,
        LinkServiceOrdersPopupComponent,
        BarclayPaymentComponent,
        InventoryTransferRequestPopupComponent,
        InventoryTransferResponsePopupComponent,
        ResourceSelectionPopupComponent,
        BarclaySuccessComponent,
        BarclayFailedComponent,
        ReturnOrderStatementViewerComponent,

        IscClaimsPopupComponent,
        IscClaimsRulesBusinessComponent,
        IscClaimsRulesCustomerComponent,
        IscSOClaimsComponent,
        CartCheckoutPopupComponent,
        ReasonPopupComponent,
        ShipmentAdjustmentOrderPopupComponent,
        DialogModule,

        // Return order popup
        ReturnOrderPopupComponent,
        ReturnOrderPopupDetailsTabComponent,
        ReturnOrderItemsTabComponent,

        // Cycle count popup
        CycleCountComponent,
        CycleCountAddPopupComponent,
        CycleCountPopupComponent,
        CycleCountsResourceSelectionPopupComponent,
        CycleCountUploadDetailPopupComponent,
        CycleCountOpenExceptionComponent,
        CycleCountCostAnalysisComponent,
        CycleCountExceptionPopupComponent,

        // Core return order popup
        CoreReturnOrderPopupComponent,
        CoreReturnOrderPopupDetailsTabComponent,
        CoreReturnOrderPopupItemsTabComponent,
        CoreReturnOrderPopupCoreItemsTabComponent,
    ],
    providers: [
        ServiceOrderService,
        GeneralSectionService,
        EquipmentInformationSectionService,
        ExecutionTabService,
        PageService,
        MasterDataService,
        CommonService,
        UsersService,
        MapsService,
        ClaimsService,
        CycleCountService
    ]
})
export class SharedModule {}
