<div class="row mt-3" *ngIf="modelDescription">
    <div class="col-3">
        <div class="field mb-3"> 
            <label>Model:</label>
            <p class="pt-2">{{ modelDescription }}</p>
        </div>
    </div>
    <div class="col-3" *ngIf="serialNumber">
        <div class="field mb-3"> 
            <label>Serial Number:</label>
            <p class="pt-2">{{ serialNumber }}</p>
        </div>
    </div>
</div>

<div class="mb-2">
    <div class="row">
        <div class="col tabbable-line pt-1">
            <ul class="nav nav-tabs border-bottom" id="tabs">
                <li class="nav-item">
                    <a 
                        class="nav-link active position-relative" 
                        data-toggle="tab" 
                        href="#service-order-tab"
                        title="Service Order History"
                    >Service Order</a>
                </li>
                <li class="nav-item">
                    <a 
                        class="nav-link position-relative" 
                        data-toggle="tab" 
                        href="#equipments-tab"
                        title="Equipment History"
                    >Equipment</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="tab-content flex-fill mt-2">
                <div class="tab-pane active show" id="service-order-tab">
                    <div class="service-order-grid" *ngIf="serviceOrderGrid.dataSource && serviceOrderGrid.dataSource.length">
                        <ejs-grid 
                            #serviceOrdersGrid
                            [dataSource]="serviceOrderGrid.dataSource" 
                            [allowFiltering]="true"
                            [allowSorting]="true" 
                            [allowResizing]="true" 
                            [allowReordering]="true" 
                            [allowExcelExport]="true" 
                            [toolbar]="serviceOrderGrid.toolbar"
                            [filterSettings]="{ type: 'Menu' }"
                            (toolbarClick)="onHistoryToolbarClick($event, serviceOrdersGrid)"
                            (actionBegin)="onGridActionBegin($event)"
                            (actionComplete)="onGridActionComplete($event, serviceOrdersGrid)">
                            <e-columns>
                                <e-column 
                                    *ngFor="let column of serviceOrderGrid.columns;" 
                                    [field]="column.field" 
                                    [headerText]="column.headerText" 
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [format]="column.format ? column.format : null"
                                    [textAlign]="column.textAlign" 
                                    [visible]="column.visible"
                                    [filter]="column.filter ? column.filter : { type: 'Menu'}" 
                                    [allowEditing]="column.allowEditing" 
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                    <ng-template *ngIf="column.field === 'status'" #template let-data>
                                        <span class="grid-status-column {{getStatusClass(data.status)}}">{{data.status}}</span>
                                    </ng-template>
                                    <ng-template *ngIf="column.field === 'consignment'" #template let-data>
                                        <a href="javascript:" (click)="onConsignmentClick(data.consignment)">{{data.consignment}}</a>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
                <div class="tab-pane" id="equipments-tab">
                    <div class="equipments-grid" *ngIf="equipmentGrid.columns && equipmentGrid.columns.length">
                        <ejs-grid 
                            #equipmentsGrid
                            [dataSource]="equipmentGrid.dataSource" 
                            [allowFiltering]="true"
                            [allowExcelExport]="true" 
                            [allowSorting]="true" 
                            [allowResizing]="true" 
                            [allowReordering]="true" 
                            [toolbar]="equipmentGrid.toolbar"
                            [filterSettings]="{ type: 'Menu' }"
                            (toolbarClick)="onToolbarClick($event, equipmentsGrid)"
                            (actionBegin)="onGridActionBegin($event)"
                            (actionComplete)="onGridActionComplete($event, equipmentsGrid)"
                        >
                            <e-columns>
                                <e-column 
                                    *ngFor="let column of equipmentGrid.columns;" 
                                    [field]="column.field" 
                                    [headerText]="column.headerText" 
                                    [clipMode]="'EllipsisWithTooltip'"
                                    [type]="column.type"
                                    [width]="column.width"
                                    [format]="column.format ? column.format : null"
                                    [textAlign]="column.textAlign" 
                                    [visible]="column.visible"
                                    [filter]="column.filter ? column.filter : { type: 'Menu'}" 
                                    [commands]="column?.commands ? column.commands : null"
                                    [allowEditing]="column.allowEditing" 
                                    [allowFiltering]="column.allowFiltering !== undefined ? column.allowFiltering : true">
                                    <ng-template *ngIf="column.field === 'serviceOrderId'" #template let-data>
                                        <a href="javascript:" (click)="onServiceOrderClick(data.serviceOrderId)">{{data.serviceOrderId}}</a>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup
    *ngIf="showPopup"
    [id]="serviceOrderId"
    [data]="serviceOrderData"
    (onClose)="showPopup = false"
></app-popup>
