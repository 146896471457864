import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
    DataManager,
    DataUtil,
    QueryList,
    Query,
    ParamOption,
    ODataAdaptor,
    QueryOptions
} from '@syncfusion/ej2-data';
import { Ajax } from '@syncfusion/ej2-base';
import { GridComponent, PageService, SelectionService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { CustomAdaptor, gridActionBeginForFiltering, gridActionsForFiltering, updateFilterIcon } from '../../utils/grid-functions';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from '../../services/common.service';
import { UserRoles } from '../../models/app.model';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';

const BASE_URL = environment.apiUrl;
@Component({
    selector: 'app-items-advanced-search',
    templateUrl: './items-advanced-search.component.html',
    styleUrls: ['./items-advanced-search.component.scss'],
    providers: [
        ToolbarService,
        SortService,
        PageService,
        SelectionService
    ]
})
export class ItemsAdvancedSearchComponent implements OnInit {


    UserRoles: typeof UserRoles;
    USER: any;
    isExpanded: boolean = true;
    data: any;
    mainGrid: any;
    filterSettings: any;
    query: Query;
    selectedRowData: any;
    public pageSettings: Object

    @Input() warehouse: string;
    @Input() category: string = 'servieable';
    @Input() module: string;
    @Input() serviceItemGroup: string;
    @Input() technicianId: string;

    @Output() onClose = new EventEmitter();
    @ViewChild('columnChooser') columnChooser;
    @ViewChild('onHandCheckbox') onHandCheckbox: CheckBoxComponent;
    @ViewChild('itemsAdvancedSearchGrid') itemsAdvancedSearchGrid: GridComponent;
    gridHeight: number = window.innerHeight - 300;
  isOnHandChecked: boolean;

    constructor(
        private commonService: CommonService,
    ) {
        this.commonService.USER;
    }

    ngOnInit(): void {
      this.UserRoles = UserRoles;
      this.USER = this.commonService.USER;
      this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
        let url = `${BASE_URL}ServiceItem/GetServiceItemSearch?technicianId=${this.technicianId}`;

        if ( this.module === 'ReturnOrder' ) {
            url = `${BASE_URL}ReturnOrder/GetServiceItemsForReturnOrder?warehouse=${this.warehouse}&category=${this.category}`;
        }
        this.filterSettings = { type: 'Menu' };
        this.data = new DataManager({
            url,
            adaptor: new CustomAdaptor()
        });
        this.query = new Query().addParams('company', this.commonService.USER.company);
        if ( this.serviceItemGroup ) {
            this.query.addParams('serviceItemGroup', this.serviceItemGroup);
        }
    }

    ngAfterViewInit(): void {
        this.setGrid();
    }

    setGrid() {
        const columns : any = [
            { field: 'company', headerText: 'Company', width: 120, visible: true, nonHideable: true, type: 'string', filter: { type: 'Excel' } },
            { field: 'item', headerText: 'Item', visible: true, type: 'string', filter: {type: 'Excel'} },
            { field: 'itemDescription', headerText: 'Item Description', visible: true, type: 'string' },
            { field: 'priceUnit', headerText: 'Price Unit', visible: false, type: 'string' },
            { field: 'serviceItemGroup', headerText: 'Service Item Group', visible: true, type: 'string' , filter: { type: 'Excel' } },
            { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left', width: 120, filter: { type: 'Excel' } },
            { field: 'alternateItem', headerText: 'Alternate Item', type: 'string', format: 'n', textAlign: 'Left' },
            { field: 'costComponent', headerText: 'Cost Component', visible: true, type: 'string' },
            { field: 'itemGroup', headerText: 'Item Group', visible: true, type: 'string' , filter: { type: 'Excel' }},
            {
                field: 'Actions', headerText: 'Actions', textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
                commands: [
                    {
                        title: 'Proceed',
                        buttonOption: {
                            iconCss: `fas fa-arrow-right`,
                            cssClass: `e-flat action-button`
                        }
                    }
                ]
            }
        ]

        if ( ![UserRoles.isc, UserRoles.iscManager, UserRoles.IscSupervisor].includes(this.commonService.USER.role) ) {
            const index = columns.findIndex(x => x.field === 'itemDescription');
            columns.splice(index, 0 ,{ field: 'onHand', headerText: 'On Hand', type: 'number', format: 'n' });
        }

        this.mainGrid = {
            columns,
            toolbar: [
              { text: '', id: 'on-hand-checkbox', align: 'Right', template: this.onHandCheckbox, cssClass: 'mr-4', tooltipText: 'On Hand' },
              'Search',
              { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }

            ],
        };
    }

    toolbarClick(args: ClickEventArgs, grid: GridComponent) {
        if (args.item.id === 'clear-filter') {
            grid.clearFiltering();
            grid.search('');
            this.isOnHandChecked = false;
            this.onHandCheckbox.checked = false;
            this.itemsAdvancedSearchGrid.removeFilteredColsByField('onHand');
        }
    }

    onGridCreated() {
        updateFilterIcon({ requestType: 'filtering' }, this.itemsAdvancedSearchGrid);
    }

    getStatusClass(status) {
        return this.commonService.getStatusClass(status);
    }


    onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
        gridActionsForFiltering(args, grid);
        updateFilterIcon(args, grid);
    }

    onCommandClick(args: any) {
        if (args.commandColumn.title === 'Proceed') {
            this.onClose.emit(args.rowData);
        }
    }

    close() {
        this.onClose.emit(null);
    }

    proceed() {
        this.onClose.emit(this.selectedRowData);
    }

    rowSelected(args) {
        this.selectedRowData = args.data;
    }

    onHoldCheckboxChange(e) {
      if(e?.checked) {
        this.itemsAdvancedSearchGrid.filterByColumn('onHand', 'doesnotcontain' , 0);
      } else {
        this.itemsAdvancedSearchGrid.removeFilteredColsByField('onHand');
      }
      this.isOnHandChecked = e?.checked;
    }
}
