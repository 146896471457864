import { Component, Input, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from 'src/app/shared/services/common.service';
import { handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-isc-claims-business-rules',
  templateUrl: './isc-claims-business-rules.component.html',
  styleUrls: ['./isc-claims-business-rules.component.scss']
})
export class IscClaimsRulesBusinessComponent {

  private destroy$: Subject<null> = new Subject();

  showColumnChooser: boolean;
  claimsQuery: any;

  columns = []
  @Input() businessRules;

  @ViewChild('businessRulesGrid') grid: GridComponent;

  @Input() referenceActivities: any;
  @Input() ruleStatus: any;

  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.columns = this.getGridColumns();
  }

  getGridColumns() {
    const disabled = true ? 'e-flat' : 'e-flat disabled-grid-button';
    let columns: any = [
      { field: 'rule', headerText: 'Rule', type: 'string', textAlign: 'Left' },
      { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left', width: 150 }
    ];
    return columns;
  }

  ngAfterViewInit(): void {
    this.setGrid();
  }

  setGrid() {
    this.grid.toolbar = [
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.grid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.grid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
  }

  onGridActionComplete(args) {
    updateFilterIcon(args, this.grid);
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
  }

  getStatusClass(status: string) {
    return this.commonService.getClaimsRuleStatus(status);
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'grid_excelexport') {
      handleGridExcelExport(grid, 'Claims Business Rules');
    } else if (args.item.id === 'grid_pdfexport') {
      grid.pdfExport();
    } else if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
