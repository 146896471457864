import { Component } from '@angular/core';

@Component({
    selector: 'app-builder',
    templateUrl: './builder.component.html',
    styleUrls: []
})
export class BuilderComponent {

    constructor() {}
}
