import { HttpErrorResponse } from '@angular/common/http';

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {
    CommandColumnService,
    ExcelExportProperties,
    ExcelExportService,
    FilterService,
    GridComponent,
    SortService,
    ToolbarService,
    EditService,
    ForeignKeyService
} from '@syncfusion/ej2-angular-grids';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { Query } from '@syncfusion/ej2-data';
import { DropDownList, ComboBox } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { forkJoin, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ApiResponse, Companies, gridNames, GridPersistRequests, PartsAvailabilityStatus, PersistenceData, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { excelQueryCellInfo, getExcelDataSource, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { IscCostTypes, IscCostTypesList, ModeList, IscSubCostTypeList, SubCostTypes, IscRuleModel, IscEmployeeModel, CostTypes } from '../../models/service-order.model';
import { ExecutionTabService } from '../../services/execution-tab.service';
import { ServiceOrderService } from '../../services/service-order.service';
import { CANNOT_CLAIM_TRANSACTION_MESSAGE, UPLOAD_RECEIPT_MESSAGE } from 'src/app/shared/messages';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
    selector: 'app-isc-execution-tab',
    templateUrl: './isc-execution-tab.component.html',
    styleUrls: ['./isc-execution-tab.component.scss'],
    providers: [
        ToolbarService,
        FilterService,
        SortService,
        ExcelExportService,
        CommandColumnService,
        EditService,
        ForeignKeyService
    ]
})

export class IscExecutionTabComponent implements OnInit, OnChanges {

    private destroy$: Subject<null> = new Subject();

    serviceTypes = [];
    transactionGridName: string = gridNames.serviceOrderTransactionsGrid;
    @Input() data = null;
    @Input() equipmentInfoData = null;
    @Input() configurationId = null;
    @Input() distanceZone = null;
    @Input() customerCurrency = 'USD';
    @Input() isEditable: boolean;
    @Input() fieldRules: any;
    @Input() assignments = [];
    @Input() assignmentDetails = [];
    @Input() taxRates = [];
    @Input() checkinTime: any = null;
    @Input() costTypes = IscCostTypesList;
    @Input() iscEmployeeDetails: IscEmployeeModel;

    preference: any;
    isOffline: boolean;
    isIsc: boolean;
    isIscManager: boolean;
    ddlFetched: boolean = false;
    showAssignmentsPopup: boolean = false;
    showAssignmentsColumnChooser: boolean = false;
    getPriceForItem: boolean = true;
    itemChangedFromAdvanceSearch: boolean = false;
    assignmentGrid = null;
    selectedAssignment = null;
    gridButtonClass: string = '';
    warranty = '';
    contract = '';
    USER;

    coreItemGroup = ['450SVC'];

    isTabValid: boolean = true;
    showAssignmentDetailsPopup: boolean = false;
    showAssignmentDetailsColumnChooser: boolean = false;
    skipBeginEditEvent: boolean = false;
    assignmentDetailGrid = null;
    selectedAssignmentDetail = null;
    /**
     * Set this variable if there is only one assignment and reset to null once reference assignment value is populated.
     * We are using this to populate Reference Activity by default if there is only one assignment in assignmentsGrid
     */
    defaultReferenceActivity = null;

    @ViewChild('assignmentTemplate') assignmentTemplate: any;
    @ViewChild('assignmentsGrid') assignmentsGrid: GridComponent;
    @ViewChild('assignmentDetailsGrid') assignmentDetailsGrid: GridComponent;
    @ViewChild('columnChooserAssignments') assignmentsColumnChooser;
    @ViewChild('columnChooserAssignmentDetails') assignmentDetailsColumnChooser;

    query = new Query().where('isDeleted', 'equal', false);

    @Output() tabUpdated = new EventEmitter();
    @Output() showPartsOrderPopup: EventEmitter<any> = new EventEmitter();

    warehouse: string;

    referenceActivities = [];
    reportedProblems = [];
    solutions = [];
    priorities = [];
    units = [];
    serviceItems = [];
    returnReasons = [];
    reportedProblem: any;
    reportedProblemObject: DropDownList;
    reportedProblemDescription: any;

    description: any;
    descriptionObject: TextBox;

    solution: any;
    solutionObject: DropDownList;
    solutionDescription: any;

    priority: any;
    priorityObject: DropDownList;

    startDate: any;
    startDateObject: DateTimePicker;

    endDate: any;
    endDateObject: DateTimePicker;

    assignment: any;
    assignmentObject: any;

    assignmentDetailReferenceActivity: any;
    assignmentDetailReferenceActivityObject: TextBox;
    assignmentDetailReferenceActivityDescription: any;
    assignmentDetailReferenceActivityCode: any;

    assignmentDetailServiceType: any;
    assignmentDetailServiceTypeObject: TextBox;
    assignmentDetailServiceTypeCode: TextBox;
    assignmentDetailServiceTypDescription: any;

    comments: any;
    commentsObject: TextBox;

    costType: any;
    costTypeObject: DropDownList;

    subcostType: any;
    subcostTypeObject: any;

    itemGroup: any;
    serviceItemGroup: any;
    rowServiceItemGroup: any;

    itemCode: any;
    itemCodeObject;

    selectedRowId: string;
    showItemAdvancedSearchPopup: boolean = false;
    onItemUpdate = new Subject<string>();
    itemsList = [];

    itemDescription: any;
    itemDescriptionObject: TextBox;

    mileage: any;
    mileageObject: NumericTextBox;

    additionalMileage: any;
    additionalMileageObject: NumericTextBox;


    quantity: any;
    quantityObject: NumericTextBox;
    onHandQuantity;

    discountAmount: any;
    discountAmountObject: NumericTextBox;

    iscDiscountAmount: any;
    iscDiscountAmountObject: NumericTextBox;

    warrantyAmount: any;
    warrantyAmountObject: NumericTextBox;

    contractAmount: any;
    contractAmountObject: NumericTextBox;

    customerPriceValue: any;
    customerPriceElem: any;
    customerPriceObject: NumericTextBox;

    iscPriceElem: any;
    iscPriceObject: any;

    totalAmount: any = 0;
    totalAmountValue: any;
    totalAmountObject: NumericTextBox;

    customerTotalAmount: any = 0;
    customerTotalAmountValue: any;
    customerTotalAmountObject: NumericTextBox;

    tempUnit: string = '';
    unit: any;
    unitObject: DropDownList;
    unitCode: any;

    returnReason: any;
    returnReasonObject: DropDownList;

    currency: any;
    currencyObject: TextBox;

    assignmentDetailStartTime: any;
    assignmentDetailStartTimeValue: any;
    assignmentDetailStartTimeObject: DateTimePicker;

    assignmentDetailEndTime: any;
    assignmentDetailEndTimeObject: DateTimePicker;

    task: any;
    taskValue: string;
    taskObject: TextBox;

    tax: any;
    taxAmount: any;
    taxObject: NumericTextBox;

    taxPercent: any;
    taxPercentObject: NumericTextBox;

    laborType: any;
    laborTypeObject: TextBox;

    costComponent: any;
    costComponentValue: string;
    costComponentObject: TextBox;

    transactionWarehouse: any;
    transactionWarehouseObject: TextBox;

    activityId: any;
    refActivityCategory: any;
    assignmentId: any;
    warrantyInfo: any;
    contractInfo: any;

    referenceActivity: any;
    referenceActivityObject: DropDownList;
    referenceActivityDescription: any;

    serviceType: any;
    serviceTypeObject: TextBox;
    serviceTypeCode: any;
    priceValue: any = 0;
    quantityValue: any = 0;
    discountValue: any = 0;
    startTimeValue: any;
    laborRate: any = 0;
    calloutRate: any = 0;
    modelDescription: any;
    serialNumber: any;

    fromExecutionTab: any;
    showCartCheckoutPopup: boolean = false;

    addAssignmentRow: any = null;
    addTransactionRow: any = null;

    loadedAssignmentItemDetails: any = [];

    assignmentDetailAction: string = '';
    showLoader: boolean = false;
    assignmentsReadOnly: boolean = false;
    transactionsReadOnly: boolean = false;

    iscRulesForSO: IscRuleModel;
    iscRelatedFields = ['mileage', 'price', 'totalAmount', 'iscDiscountAmount', 'addtMileageCharged'];
    isIscSupervisor: boolean = false;

    partsCatalogPopupData: boolean = false;
    showPartsCatalogPopup: boolean = false;
    customerAddress: any = '';

    constructor(
        public commonService: CommonService,
        private executionTabService: ExecutionTabService,
        private serviceOrderService: ServiceOrderService,
        private inventoryService: InventoryService,
        private eRef: ElementRef
    ) {
        this.USER = this.commonService.USER;
        this.assignmentsReadOnly = this.commonService.roleClaims['SO_Popup_Field_assignments']?.readOnly;
        this.transactionsReadOnly = this.commonService.roleClaims['SO_Popup_Fields_transactions']?.readOnly;
    }

    // @HostListener('document:click', ['$event'])
    // onClick(event) {
    // if (this.assignmentsGrid?.isEdit) {
    //     if (!document.getElementById('assignmentsGrid')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridreferenceActivityCode_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridreportedProblem_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridsolutionCode_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridpriority_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridstartDate_datepopup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridstartDate_timepopup')?.contains(event.target)
    //         && !event.target.classList.contains('e-day')
    //         && !document.getElementById('assignmentsGridendDate_datepopup')?.contains(event.target)
    //         && !document.getElementById('assignmentsGridendDate_timepopup')?.contains(event.target)
    //     ) {
    //         this.assignmentsGrid?.endEdit();
    //     }
    // } else if (this.assignmentDetailsGrid?.isEdit) {
    //     if (!document.getElementById('assignmentDetailsGrid')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridassignmentId_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridcostType_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridsubcostType_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridsubCostType_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGriditemCode_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridunit_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridlaborType_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridreturnReasonCode_popup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridstartDate_datepopup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridstartDate_timepopup')?.contains(event.target)
    //         && !event.target.classList.contains('e-day')
    //         && !document.getElementById('assignmentDetailsGridendDate_datepopup')?.contains(event.target)
    //         && !document.getElementById('assignmentDetailsGridendDate_timepopup')?.contains(event.target)
    //         && !document.getElementById('config-modal')?.contains(event.target)
    //     ) {
    //         if ( !this.showLoader ) {
    //             this.assignmentDetailsGrid.endEdit();
    //         }
    //     }
    // }
    // }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        const assignmentGridEdited = this.assignmentsGrid?.isEdit;
        const assignmentDetailsGridEdited = this.assignmentDetailsGrid?.isEdit;
        const grid: GridComponent = assignmentGridEdited ? this.assignmentsGrid : assignmentDetailsGridEdited ? this.assignmentDetailsGrid : null;
        if (grid?.isEdit) {
            const excludedClasses = ['e-ddl', 'e-popup', 'e-lib', 'e-control', 'e-popup-close', 'e-day', 'loader-container'];
            let targetElement = event.target as HTMLElement;
            while (targetElement) {
                if (excludedClasses.some(className => targetElement.classList.contains(className))) {
                    event.stopPropagation();
                    return;
                }
                targetElement = targetElement.parentElement;
            }
            grid.endEdit();
        }
    }

    ngOnInit(): void {
        this.warehouse = this.data?.technicianWarehouse ?? this.USER.warehouse;
        this.isOffline = this.data?.offlineOrder;
        this.isIsc = this.USER.role === UserRoles.isc;
        this.isIscManager = this.USER.role === UserRoles.iscManager;
        this.isIscSupervisor = this.USER.role === UserRoles.IscSupervisor;
        this.data.warrantyCoverageLines && (this.warrantyInfo = JSON.parse(this.data.warrantyCoverageLines));
        this.data.contractCoverageLines && (this.contractInfo = JSON.parse(this.data.contractCoverageLines));

        this.subscriptions();

        this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
        this.setLoadedAssignmentItemDetails(this.assignmentDetails);
        this.gridButtonClass = this.isEditable ? '' : 'disabled-grid-button';
        this.setPageData();
        this.loadTab();
        this.warranty = localStorage.getItem('warranty') ?? '';
        this.contract = localStorage.getItem('contract') ?? '';
        this.assignmentDetailStartTimeValue = this.checkinTime;
        if (this.USER.company === Companies.conway) {
            this.getItemList();
        }
        this.onItemUpdate
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            ).subscribe(value => {
                if (this.itemCodeObject) {
                    this.itemCodeObject.showPopup();
                    this.itemCodeObject.showSpinner();
                }
                this.getItemList({ itemCode: value });
            });
    }

    subscriptions() {
        this.serviceOrderService.configurationChange.pipe(
            takeUntil(this.destroy$),
        ).subscribe((res: any) => {
            this.customerAddress = res.customerAddress;
            console.log('customer address:', this.customerAddress)
        });
        
        this.serviceOrderService.nteChange.pipe(
            takeUntil(this.destroy$),
        ).subscribe((args: any) => {
            if (args.value) {
                this.data.approvedAmount = args.value;
            }
        });
        this.serviceOrderService.modelDescriptionChange.subscribe(modelDescription => {
            this.modelDescription = modelDescription;
        });

        this.serviceOrderService.serialNumberChange.pipe(
            takeUntil(this.destroy$),
        ).subscribe((args: any) => {
            if (args.itemData && args.itemData.serialNumber) {
                this.serialNumber = args.itemData.serialNumber;
            }
        });

        this.serviceOrderService.equipmentInfoSubject.pipe(
            takeUntil(this.destroy$),
        ).subscribe(equipmentInfo => {
            if (equipmentInfo) {
                this.equipmentInfoData = equipmentInfo.data;
                this.warrantyInfo = equipmentInfo.warrantyInfo;
                this.contractInfo = equipmentInfo.contractInfo;
                this.equipmentInfoData.warrantyDescriptionObj = equipmentInfo.warrantyInfo;
                this.equipmentInfoData.serviceContractDescriptionObj = equipmentInfo.contractInfo;
            }
        });
    }

    setLoadedAssignmentItemDetails(transactions) {
        this.loadedAssignmentItemDetails = transactions.map(x => {
            return {
                itemCode: x.itemCode,
                issuedQty: x.issuedQty
            }
        });
    }

    getItemList(rowData?) {
        const value = rowData?.itemCode ?? '';
        this.showLoader = true;
        let serviceItemGroup = '';
        this.commonService.getItems(value, 30, this.USER.company, serviceItemGroup)
            .subscribe((res: ApiResponse) => {
                if (res && res.result && res.result.length) {
                    res.result.map(item => {
                        item.value = item.item;
                        item.text = `${item.item} | ${item.itemDescription}`;
                    });

                    const existingItems = [];
                    (this.assignmentDetailsGrid?.dataSource as any)?.forEach(transaction => {
                        if (transaction.itemCode !== this.itemCodeObject?.value) {
                            existingItems.push(transaction.itemCode);
                        }
                    });
                    this.itemsList = res.result.filter(item => !existingItems.includes(item.value));
                    if (this.itemCodeObject) {
                        this.itemCodeObject.hideSpinner();
                        this.itemCodeObject.dataSource = this.itemsList;
                    }
                    this.showLoader = false;
                } else {
                    this.itemsList = [];
                    if (this.itemCodeObject) {
                        this.itemCodeObject.hideSpinner();
                        this.itemCodeObject.dataSource = [];
                    }
                    this.showLoader = false;
                }
            });
    }

    ngOnChanges(change: SimpleChanges): void {
        if (change && change.isEditable) { // Calls if mode changed
            // Do nothing . . .
            // If we want to do anything other than enable/disable we do that here
        }

        if (change && change.assignments && change.assignments.currentValue) {
            this.assignments = change.assignments.currentValue;
            if (this.assignments && this.assignments.length) {
                this.assignments.map(assignment => {
                    assignment.tempId = parseInt(assignment.assignmentId);
                });
            }
            if (!change.assignments.firstChange) {
                if (this.assignmentsGrid) {
                    this.assignmentsGrid.dataSource = this.assignments;
                    this.assignmentsGrid.refresh();
                }
            }
        }

        if (change && change.taxRates && change.taxRates.currentValue) {
            this.taxRates = change.taxRates.currentValue;
        }

        if (change && change.distanceZone && change.distanceZone.currentValue) {
            this.distanceZone = change.distanceZone.currentValue;
            this.getCalloutChargeRate();
        }

        if (change && change.configurationId && change.configurationId.currentValue) {
            this.configurationId = change.configurationId.currentValue;
            this.getLaborRate();
        }

        if (change && change.assignmentDetails && change.assignmentDetails.currentValue) {
            this.assignmentDetails = change.assignmentDetails.currentValue;
            if (!change.assignmentDetails.firstChange) {
                this.assignmentDetailGrid.dataSource = change.assignmentDetails.currentValue;
                this.assignmentDetailsGrid.columns[2] = {
                    field: 'assignmentId', headerText: 'Reference Activity *', width: 148, filter: { type: 'Excel' }, textAlign: 'Left',
                    foreignKeyValue: 'referenceActivityDescription', foreignKeyField: 'tempId', showInColumnChooser: false, dataSource: this.assignments, edit: this.editAssignment()
                };
                this.assignmentDetailsGrid.refreshColumns();
                this.assignmentDetailsGrid.refresh();
            }
        }

        if (change && change.isEditable && change.isEditable.currentValue !== undefined && !change.isEditable.firstChange) {
            this.isEditable = change.isEditable.currentValue;
            if (this.isEditable) {
                document.getElementsByClassName('grid-add-button')[0].classList.remove('disabled-grid-button');
                document.getElementsByClassName('grid-add-button')[1].classList.remove('disabled-grid-button');
            } else {
                document.getElementsByClassName('grid-add-button')[0].classList.add('disabled-grid-button');
                document.getElementsByClassName('grid-add-button')[1].classList.add('disabled-grid-button');
            }
            // this.calculateAmounts(false);
            this.refreshGrids();
        }
    }

    refreshGrids() {
        this.assignmentsGrid && this.assignmentsGrid.refresh();
        this.assignmentDetailsGrid && this.assignmentDetailsGrid.refresh();
    }

    getLaborRate() {
        this.executionTabService.getLaborSalesRate(this.configurationId)
            .subscribe((response: ApiResponse) => {
                if (response && response.isSuccessful) {
                    this.laborRate = response.result;
                }
            });
    }

    getCalloutChargeRate() {
        this.executionTabService.getCallOutCharge(this.distanceZone, 0)
            .subscribe((response: ApiResponse) => {
                if (response && response.isSuccessful && response.result) {
                    this.calloutRate = response.result;
                }
            });
    }

    validate() {
        this.assignmentsGrid?.endEdit();
        this.assignmentDetailsGrid?.endEdit();
        if (this.assignmentsGrid?.isEdit || this.assignmentDetailsGrid?.isEdit) {
            this.isTabValid = false;
            return false;
        } else {
            this.isTabValid = true;
            return true;
        }
    }

    setPageData() {
        if (!this.data) {
            this.data = {
                serviceOrderId: null,
                serviceType: null,
                salesAmount: 0,
                discountAmount: 0,
                warrantyCoverage: 0,
                contractCoverage: 0,
                invoiceAmount: 0,
                tax: 0,
                taxable: false,
                totalAmount: 0,
                customerTotalAmount: 0
            }
        } else {
            if (this.assignmentDetails && this.assignmentDetails.length) {
                this.calculateAmounts(true);
            }
        }
    }

    onAssignmentsGridCreated(args) {
        const disableAddIcon = this.isEditable ? this.gridButtonClass : 'disabled-grid-button';

        this.assignmentsGrid.toolbar = [
            { id: 'column-chooser', tooltipText: 'Show/Hide Columns', template: this.assignmentsColumnChooser },
            { text: '', id: 'add-new-assignment', prefixIcon: 'e-add', cssClass: `grid-add-button ${disableAddIcon}`, tooltipText: 'Add Assignment' },
            'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = this.assignmentGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.assignmentGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
    }

    onTransactionsGridCreated(args) {
        this.assignmentDetailsGrid.toolbar = [
            { id: 'column-chooser-assignment-detail', tooltipText: 'Show/Hide Columns', template: this.assignmentDetailsColumnChooser },
            { text: '', id: 'add-new-assignment-detail', prefixIcon: 'e-add', cssClass: `grid-add-button ${this.gridButtonClass}`, tooltipText: 'Add Assignment Detail' },
            { text: '', id: 'cart-icon', prefixIcon: 'fa fa-shopping-cart', cssClass: `grid-add-button ${this.gridButtonClass}`, tooltipText: 'Add Assignment Detail' },
            'Search',
            { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = this.assignmentDetailGrid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.assignmentDetailGrid.toolbar.splice(columnChooserIndex, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
    }

    loadTab() {
        this.serviceOrderService.popupLoader.next(true);
        forkJoin([
            this.executionTabService.getServiceTypes(this.USER.company),
            this.executionTabService.getIscReferenceActivities(this.data.customerCode, this.data.offlineOrder),
            this.executionTabService.getReportedProblems(this.USER.company, this.data.serializedItemGroupCode),
            this.executionTabService.getSolutions(this.USER.company, this.data.serializedItemGroupCode),
            this.serviceOrderService.getPriorities(this.USER.company),
            this.executionTabService.getUnits(this.USER.company),
            this.executionTabService.getReturnReasons(this.USER.company),
            this.commonService.getUserPreference(gridNames.serviceOrderTransactionsGrid, this.USER.employeeId),
            this.serviceOrderService.getIscRuleForServiceOrder(this.data.serviceOrderId)
        ]).subscribe((res: any) => {
            if (res[8]?.result) {
                this.iscRulesForSO = res[8].result;
            }

            if (res[7] && res[7].result && res[7].result.length && res[7].result[0].value) {
                this.preference = res[7].result[0].value;
                localStorage.setItem(`grid${this.transactionGridName}`, this.preference);
            } else {
                localStorage.removeItem(`grid${this.transactionGridName}`);
            }

            this.serviceTypes = res[0].result;
            this.referenceActivities = res[1].result.map(activity => {
                activity.text = activity.description;
                return activity;
            });
            this.reportedProblems = res[2].result.map(problem => {
                problem.text = problem.description;
                return problem;
            });
            this.solutions = res[3].result;
            this.priorities = res[4].result.map(priority => {
                priority.text = priority.description;
                return priority;
            });

            this.units = res[5].result.map(row => {
                row.text = row.description;
                return row;
            });

            this.returnReasons = res[6].result.map(row => {
                row.text = row.description;
                return row;
            });

            this.ddlFetched = true;
            this.initGrids();
            // this.data.priority = this.data.priority ? this.data.priority : this.priorities.find( priority => priority.text === 'Normal').value;
            this.serviceOrderService.popupLoader.next(false);
            this.commonService.getUserDefaults(this.data.technicianId ?? this.USER.userId);
        }, (error: HttpErrorResponse) => {
            this.serviceOrderService.popupLoader.next(false);
        });
        // this.executionTabService.getServiceTypes()
        // .subscribe( (res: any) => {
        //     this.serviceTypes = res.result;
        // });
    }

    initGrids() {
        if (this.assignments && this.assignments.length) {
            this.assignments.map((assignment, index) => {
                assignment.tempId = parseInt(assignment.assignmentId);
                assignment.rowId = index + 1;
            });
        }
        if (this.assignmentDetails && this.assignmentDetails.length) {
            this.assignmentDetails.map((detail, index) => {
                detail.tempId = parseInt(detail.assignmentDetailId);
                detail.rowId = index + 1;
            });
        }
        this.initAssignmentsGrid();
        this.initAssignmentDetailsGrid()
    }

    initAssignmentsGrid() {
        this.assignmentGrid = {
            toolbar: [],
            filterSettings: { type: 'Menu' },
            columns: [
                { field: 'rowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
                { field: 'activityId', type: 'number', headerText: 'ID', width: 120, textAlign: 'Left', format: 'n', visible: false },
                {
                    field: 'referenceActivityCode', headerText: 'Reference Activity', textAlign: 'Left', showInColumnChooser: false, validationRules: { required: true }, filter: { type: 'Excel' },
                    foreignKeyValue: 'description', foreignKeyField: 'value', dataSource: this.referenceActivities, edit: this.editReferenceActivity()
                },
                { field: 'serviceTypeDescription', headerText: 'Service Type', textAlign: 'Left', type: 'string', showInColumnChooser: false, filter: { type: 'Excel' }, edit: this.editServiceType() },
                {
                    field: 'reportedProblem', headerText: 'Reported Problem', filter: { type: 'Excel' }, textAlign: 'Left',
                    foreignKeyValue: 'description', foreignKeyField: 'value', dataSource: this.reportedProblems, edit: this.editReportedProblem()
                },
                { field: 'description', headerText: 'Description', textAlign: 'Left', type: 'string', showInColumnChooser: false, allowEditing: true, validationRules: { required: false } },
                {
                    field: 'solutionCode', headerText: 'Solution', type: 'string', filter: { type: 'Excel' }, textAlign: 'Left',
                    foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.solutions, edit: this.editSolution()
                },
                {
                    field: 'priority', headerText: 'Priority', type: 'string', filter: { type: 'Excel' }, textAlign: 'Left', visible: false,
                    foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.priorities, edit: this.editPriority(), validationRules: { required: true }
                },
                { field: 'startDate', headerText: 'Start Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a ' }, width: 160, edit: this.editStartDate(), visible: false },
                { field: 'endDate', headerText: 'End Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 160, edit: this.editEndDate(), visible: false },
                {
                    field: 'Actions', headerText: 'Actions', showInColumnChooser: false, textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
                    commands: [
                        {
                            title: 'Edit Assignment',
                            type: 'Edit',
                            buttonOption: {
                                iconCss: `fas fa-pencil-alt`,
                                cssClass: `e-flat action-button`
                            }
                        },
                        {
                            title: 'Delete Assignment',
                            type: 'Delete',
                            buttonOption: {
                                iconCss: `fas fa-trash`,
                                cssClass: `e-flat action-button`
                            }
                        }
                    ]
                }
            ]
        }
    }

    assignmentActionBegin(args) {
        if (args.requestType === 'beginEdit') {
            if (this.assignmentDetailsGrid.isEdit) {
                args.cancel = true;
                this.commonService.showNotification('warning', 'Transactions grid is in edit mode! ');
            }
            if (this.assignmentsReadOnly) {
                args.cancel = true;
                this.commonService.showPermissionNotification();
            }
            this.referenceActivityDescription = args.rowData.referenceActivityDescription;
            this.serviceTypeCode = args.rowData.serviceTypeCode;
            this.reportedProblemDescription = args.rowData.reportedProblemDescription;
            this.solutionDescription = args.rowData.solutionDescription;
        } else if (args.requestType === 'save') {
            args.data.referenceActivityDescription = this.referenceActivityDescription;
            args.data.serviceTypeCode = this.serviceTypeCode;
            args.data.reportedProblemDescription = this.reportedProblemDescription;
            args.data.solutionDescription = this.solutionDescription;
            args.data['category'] = this.refActivityCategory;

            if (!this.assignmentsGrid.getColumnByField('startDate').visible) {
                args.data.startDate = this.checkinTime ? new Date(this.checkinTime) : null;
            }
        } else if (args.requestType === 'delete') {
            args.cancel = true;
            if (this.assignmentsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                if (!args.data[0].assignmentId || !args.data[0].activityIdLN || args.data[0].activityIdLN !== '10') {
                    this.deleteAssignment(args.data[0], this.assignmentsGrid);
                }
            }
        }
    }

    setTempValuesForTransaction(data) {
        // this.assignmentId = +data.tempId;
        this.activityId = data.activityId;
        this.assignmentDetailReferenceActivityDescription = data.referenceActivityDescription;
        this.assignmentDetailReferenceActivityCode = data.referenceActivityCode;
        this.assignmentDetailServiceTypeCode = data.serviceTypeCode;
    }

    onAssignmentDetailsActionBegin(args) {
        if (args.requestType === 'beginEdit') {
            this.taxAmount = 0;
            this.getPriceForItem = false;
            this.serviceItemGroup = args.rowData.serviceItemGroup;
            this.rowServiceItemGroup = args.rowData.serviceItemGroup;
            this.tempUnit = this.getUnit(args.rowData.costType);
            if (args.rowData.costType === IscCostTypes.material) {
                if (this.skipBeginEditEvent) {
                    this.skipBeginEditEvent = false;
                }
                this.getItemList(args.rowData);

            }
            this.setItemCodeValidation(args.rowData.costType);
            if (this.assignmentsGrid.isEdit) {
                args.cancel = true;
                this.commonService.showNotification('warning', 'Assignment is in edit mode! ');
            } else {
                this.assignmentDetailsGrid.getColumnByField('qty').validationRules = { required: true };
            }
            if (this.transactionsReadOnly) {
                args.cancel = true;
                this.commonService.showPermissionNotification();
            }
            this.setTempValuesForTransaction(args.rowData);
        } else if (args.requestType === 'delete') {
            args.cancel = true;
            if (this.transactionsReadOnly) {
                this.commonService.showPermissionNotification();
            } else if (this.isEditable) {
                this.deleteTransaction(args.data[0]);
            }
        }
    }

    setAssignmentRow(assignment) {
        const index = (this.assignmentsGrid.dataSource as any)?.filter(row => !row.isDeleted).length;
        const currentTime = this.checkinTime ? new Date(this.checkinTime) : null;
        const rowId = this.getMaxRowId(this.assignmentsGrid.dataSource) + 1;
        assignment.rowId = rowId;
        assignment.assignmentId = 0;
        assignment.activityId = rowId * 10;
        assignment.tempId = this.getTempId();
        assignment.description = "";
        assignment.serviceTypeCode = "";
        assignment.serviceTypeDescription = "";
        assignment.referenceActivityCode = "";
        assignment.referenceActivityDescription = "";
        assignment.reportedProblem = "";
        assignment.reportedProblemDescription = "";
        assignment.solutionCode = "";
        assignment.solutionDescription = "";
        assignment.priority = this.priorities?.find(priority => priority.text === 'Manual Action' || priority.text === 'Low')?.value;
        assignment.startDate = currentTime;
        assignment.endDate = null;
        assignment.isDeleted = false;
        return assignment;
    }

    getTempId() {
        const assignments = this.assignments.filter(assignment => !assignment.isDeleted);
        if (assignments.length) {
            let tempId = 1;
            assignments.forEach(assignment => {
                if (!isNaN(assignment.tempId) && +assignment.tempId > +tempId) {
                    tempId = +assignment.tempId;
                }
            });
            return tempId + 1;
        } else {
            return 1;
        }
    }

    initAssignmentDetailsGrid() {
        const laborFieldHeader = this.USER.company === Companies.kingslynn ? 'Labour Type' : 'Labor Type';
        this.assignmentDetailGrid = {
            toolbar: [],
            filterSettings: { type: 'Menu' },
            columns: [
                { field: 'rowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
                { field: 'assignmentDetailId', headerText: 'ID', width: 140, textAlign: 'Left', visible: false, showInColumnChooser: false },
                {
                    field: 'assignmentId', headerText: 'Reference Activity', width: 148, filter: { type: 'Excel' }, textAlign: 'Left', validationRules: { required: true },
                    foreignKeyValue: 'referenceActivityDescription', foreignKeyField: 'tempId', showInColumnChooser: false, dataSource: this.assignments, edit: this.editAssignment()
                },
                { field: 'referenceActivityCode', headerText: 'Reference Activity', width: 184, filter: { type: 'Excel' }, textAlign: 'Left', visible: false, showInColumnChooser: false, validationRules: { required: true } },
                { field: 'referenceActivityDescription', headerText: 'Term Description', clipMode: 'EllipsisWithTooltip', visible: false, showInColumnChooser: false },
                {
                    field: 'costType', headerText: 'Cost Type', width: 137, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false,
                    foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.costTypes, edit: this.editCostType(), validationRules: { required: true }
                },
                { field: 'subCostType', headerText: 'Subcost Type', width: 137, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: IscSubCostTypeList, edit: this.editSubCostType() },
                { field: 'comments', headerText: 'Comments', width: 150, textAlign: 'Left', filter: { type: 'Excel' }, allowEditing: true, showInColumnChooser: true, visible: true, validationRules: { required: false } },
                { field: 'itemCode', headerText: 'Item', width: 175, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, edit: this.editItem(), validationRules: { required: true } },
                { field: 'itemDescription', headerText: 'Description', width: 195, type: 'string', textAlign: 'Left', edit: this.editItemDescription(), showInColumnChooser: false },
                { field: 'estimateQty', headerText: 'Estimated Qty', textAlign: 'Right', width: 130, format: 'n2', showInColumnChooser: false, visible: this.data.mode === ModeList.actual && this.data.estimateApproved, type: 'number', allowEditing: false },
                { field: 'qty', headerText: 'Quantity', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 130, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, edit: this.editQuantity(), validationRules: { required: true } },
                { field: 'mileage', headerText: 'Distance (KM/MI)', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 166, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, edit: this.editMileage(), validationRules: { required: false } },
                // { field: 'addtMileageCharged', headerText: 'Addt Mile Chg', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 130, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, validationRules: { required: false }, allowEditing: false, edit: this.editAdditionalMileageCharge() },
                { field: 'price', headerText: 'Price', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 110, format: 'n2', edit: this.editPrice(), validationRules: { required: true } },
                { field: 'iscDiscountAmount', headerText: 'ISC Discount Amount', type: 'number', width: 160, showInColumnChooser: false, textAlign: 'Right', format: 'n2', edit: this.editIscDiscountAmount() },
                { field: 'totalAmount', headerText: 'Total Amount', width: 160, type: 'number', textAlign: 'Right', showInColumnChooser: false, format: 'n2', edit: this.editTotalAmount() },
                { field: 'warrantyCoverage', headerText: 'Warranty Amount', type: 'number', width: 160, format: 'n2', textAlign: 'Right', allowEditing: false, visible: true, edit: this.editWarrantyAmount() },
                { field: 'contractCoverage', headerText: 'Contract Amount', type: 'number', width: 160, format: 'n2', textAlign: 'Right', allowEditing: false, visible: true, edit: this.editContractAmount() },
                { field: 'customerPrice', headerText: 'Customer Price', type: 'number', showInColumnChooser: true, textAlign: 'Right', width: 150, format: 'n2', edit: this.editCustomerPrice(), validationRules: { required: true } },
                { field: 'discountAmount', headerText: 'Customer Discount Amount', type: 'number', width: 160, showInColumnChooser: false, textAlign: 'Right', format: 'n2', edit: this.editDiscountAmount() },
                { field: 'customerTotalAmount', headerText: 'Customer Total Amount', width: 196, type: 'number', textAlign: 'Right', showInColumnChooser: true, format: 'n2', edit: this.editCustomerTotalAmount() },
                {
                    field: 'unit', headerText: 'Unit', textAlign: 'Left', type: 'string', width: 150, visible: false, filter: { type: 'Excel' },
                    foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.units, edit: this.editUnit()
                },
                { field: 'laborType', headerText: laborFieldHeader, width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editLaborType(), visible: false, filter: { type: 'Excel' }, },
                { field: 'warehouse', headerText: 'Warehouse', width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editTransactionWarehouse(), visible: false, filter: { type: 'Excel' }, },
                {
                    field: 'returnReasonCode', headerText: 'Return Reason', width: 150, type: 'string', textAlign: 'Left',
                    foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.returnReasons, edit: this.editReturnReason(), visible: false, showInColumnChooser: false
                },
                { field: 'task', headerText: 'Task', type: 'string', width: 145, textAlign: 'Left', edit: this.editTask(), visible: false, filter: { type: 'Excel' }, },
                { field: 'startDate', headerText: 'Start Time', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, edit: this.editStartTime(), visible: false },
                { field: 'endDate', headerText: 'End Time', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, edit: this.editEndTime(), visible: false },
                { field: 'costComponent', headerText: 'Cost Component', width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editCostComponent(), visible: false, filter: { type: 'Excel' }, },
                { field: 'taxpercent', headerText: 'Tax %', width: 160, format: 'n3', type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editTaxPercent(), visible: false },
                { field: 'taxAmount', headerText: 'Tax Amount', width: 160, format: 'n3', type: 'string', textAlign: 'Right', allowEditing: false, edit: this.editTax(), visible: false, filter: { type: 'Excel' }, },
                { field: 'customerCurrency', headerText: 'Currency', type: 'string', width: 160, textAlign: 'Left', allowEditing: false, visible: false, filter: { type: 'Excel' }, },

                {
                    field: 'Actions', headerText: 'Actions', showInColumnChooser: false, textAlign: 'Center', width: 140, allowFiltering: false, allowSorting: false,
                    commands: [
                        {
                            title: 'Create Purchase Order',
                            buttonOption: {
                                iconCss: `e-btn-icon fas fa-cubes e-icons`,
                                cssClass: `e-flat action-button`
                            }
                        },
                        {
                            title: 'Edit Assignment Detail',
                            type: 'Edit',
                            buttonOption: {
                                iconCss: `fas fa-pencil-alt`,
                                cssClass: `e-flat action-button`
                            }
                        },
                        {
                            title: 'Delete Detail',
                            type: 'Delete',
                            buttonOption: {
                                iconCss: `fas fa-trash`,
                                cssClass: `e-flat action-button`
                            }
                        },
                    ]
                }
            ]
        };
    }

    getMaxRowId(datasource) {
        if (datasource.length) {
            return Math.max.apply(Math, datasource.map(row => row.rowId)) || 0;
        } else {
            return 0;
        }
    }

    setAssignmentDetailRow(detail) {
        let costType = this.costTypes.find(type => type.value === IscCostTypes.material);
        const currentTime = this.checkinTime ? new Date(this.checkinTime) : null;
        detail.rowId = this.getMaxRowId(this.assignmentDetailsGrid.dataSource) + 1;
        detail.assignmentId = '';
        detail.assignmentDetailId = '';
        detail.activityId = null;
        detail.comments = '';
        detail.referenceActivityDescription = '';
        detail.referenceActivityCode = '';
        detail.serviceTypeCode = '';
        detail.serviceTypeDescription = '';
        detail.costType = costType ? costType.value : this.costTypes[0].value;
        detail.subCostType = '';
        detail.itemCode = null;
        detail.itemDescription = this.getItemDescription(detail.costType);
        detail.customerCurrency = this.customerCurrency;
        detail.qty = 1;
        detail.price = detail.costType === IscCostTypes.labor ? this.laborRate : 0;
        detail.totalAmount = 0;
        detail.customerTotalAmount = 0;
        detail.unit = this.getUnit(detail.costType);
        this.unitCode = detail.unit;
        detail.startDate = currentTime;
        this.assignmentDetailStartTimeValue = currentTime;
        detail.endDate = null;
        detail.task = this.getTask(detail.costType);
        detail.laborType = this.getLaborType(detail.costType);
        detail.warehouse = this.getWarehouse(detail.costType);
        detail.costComponent = this.getCostComponent(detail.costType);
        detail.discountAmount = 0;
        detail.warrantyCoverage = 0;
        detail.contractCoverage = 0;
        detail.taxpercent = this.getTaxRate(detail.costType);
        detail.taxAmount = 0;
        detail.returnReasonCode = null;
        detail.serviceItem = null;
        detail.isDeleted = false;
        detail.warehouse = this.data?.technicianWarehouse;
        detail.mileage = 0;
        detail.customerPrice = 0;
        detail.iscDiscountAmount = 0;
        detail.addtMileageCharged = 0;

        // detail.itemGroup = '';
        // detail.serviceItemGroup = '';
        // detail.lineNumber = this.getMaxRowId(this.assignmentDetailsGrid) + 1;
        // detail.issuedQty
        // detail.estimateQty
        // detail.invoiceAmount
        return detail;
    }

    showEstimatedQtyCol(showColumn) {
        if (this.assignmentDetailsGrid)
            this.assignmentDetailsGrid.getColumnByField('estimateQty').visible = showColumn;
    }

    getItemDescription(costType, costTypeChanged = false) {
        if (costType === IscCostTypes.labor) {
            return "Labor charge";
        } else if ([IscCostTypes.material, IscCostTypes.other].includes(costType) || !costType) {
            return null;
        }
    }

    getTaxRate(costType) {
        if (this.taxRates) {
            const laborTypeKeys = {
                [IscCostTypes.labor]: 'LABMOBILE',
                [IscCostTypes.material]: 'MATERIAL',
                [IscCostTypes.other]: 'OTHER'
            }
            let taxRateItem = this.taxRates.find(taxRate => taxRate.productCategory === laborTypeKeys[costType]);
            if (taxRateItem && taxRateItem.taxRatePercentage) {
                return taxRateItem.taxRatePercentage;
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    getCostComponent(costType: string) {
        if (costType === IscCostTypes.labor) {
            return this.commonService.userDefaults && this.commonService.userDefaults.lbrCCMP ? this.commonService.userDefaults.lbrCCMP : null;
        } else if (costType === IscCostTypes.material) {
            return this.commonService.userDefaults && this.commonService.userDefaults.mtlCCMP ? this.commonService.userDefaults.mtlCCMP : null;
        } else if (costType === IscCostTypes.other) {
            return this.commonService.userDefaults && this.commonService.userDefaults.traCallCCMP ? this.commonService.userDefaults.traCallCCMP : null;
        }
    }

    getWarehouse(costType: string) {
        if (costType === IscCostTypes.material) {
            return this.warehouse;
        } else {
            return null;
        }
    }

    getLaborType(costType: string) {
        if (costType === IscCostTypes.labor) {
            return this.commonService.userDefaults?.lbrCHLT ? this.commonService.userDefaults.lbrCHLT : null;
        } else {
            return null;
        }
    }

    getWarrantyAmount(row?) { //row: if called from calculateAmounts()
        if (row) {
            return row.warrantyCoverage;
        } else {
            let warrantyPercent = 0, warrantyCoverageIndex;
            let costType = this.costTypeObject?.value && (this.costTypeObject?.value as string)?.toLowerCase();
            warrantyCoverageIndex = this.equipmentInfoData?.warrantyDescriptionObj?.findIndex(war => war.costType.toLowerCase() === costType || war.costType === 'all');
            if (warrantyCoverageIndex > -1) {
                warrantyPercent = this.equipmentInfoData.warrantyDescriptionObj[warrantyCoverageIndex].percentageCoverage;
            }
            let totalPrice = (this.quantityObject.value * this.customerPriceValue) - this.discountAmountObject.value;
            return totalPrice > 0 ? +(totalPrice * warrantyPercent / 100)?.toFixed(2) : 0;
        }
    }

    getContractAmount(row?) { //row: if called from calculateAmounts()
        if (row) {
            return row.contractCoverage;
        } else {
            let contractPercent = 0, contractCoverageIndex;
            let costType = this.costTypeObject.value && (this.costTypeObject.value as string).toLowerCase();
            contractCoverageIndex = this.equipmentInfoData?.serviceContractDescriptionObj?.findIndex(contract => contract.costType.toLowerCase() === costType || contract.costType === 'all');
            if (contractCoverageIndex > -1) {
                contractPercent = this.equipmentInfoData?.serviceContractDescriptionObj[contractCoverageIndex].percentageCoverage;
            }

            let totalPrice = (this.quantityObject.value * this.customerPriceValue) - this.discountAmountObject.value;
            return totalPrice > 0 ? +(totalPrice * contractPercent / 100)?.toFixed(2) : 0;
        }
    }

    getUnit(costType: string) {
        if (costType === IscCostTypes.labor) {
            return this.units.find(unit => unit.description === 'Hours').value;
        } else if (costType === IscCostTypes.material || costType === IscCostTypes.other) {
            const unit = this.subcostTypeObject?.value === SubCostTypes.mileage ? this.units.find(unit => unit.description === 'Miles') : this.units.find(unit => unit.description === 'Each');
            return unit ? unit.value : this.units[0].value;
        } else {
            return null;
        }
    }

    getTask(costType: string) {
        if (costType === IscCostTypes.labor) {
            return this.commonService.userDefaults && this.commonService.userDefaults.lbrCTSK ? this.commonService.userDefaults.lbrCTSK : null;
        } else {
            return null;
        }
    }

    addAssignment() {
        this.assignmentsGrid.allowReordering = false;
        this.assignmentsGrid.allowSorting = false;
        this.assignmentsGrid.allowFiltering = false;
        this.serviceOrderService.popupLoader.next(true);
        let newRow = this.setAssignmentRow({});
        (this.assignmentsGrid.dataSource as any).push(newRow);
        this.assignmentsGrid.refresh();
        setTimeout(() => {
            const index = this.assignmentsGrid.getRowIndexByPrimaryKey(newRow.rowId);
            this.assignmentsGrid.selectRow(index);
            this.assignmentsGrid.startEdit();
            this.serviceOrderService.popupLoader.next(false);
        }, 600);
    }

    onRowDataBound(args: any) {
        const data = args.data;
        const $row = args.row,
            actionButtons = $row.querySelectorAll('.action-button');
        if (this.isEditable && this.data.offlineOrder) {
            this.enableAssignmentsActionButton(actionButtons);
        } else {
            if (this.USER.company === Companies.kingslynn && args.data && args.data.rowId > 1) {
                this.enableAssignmentsActionButton(actionButtons);
            } else {
                if (this.isEditable && (!args.data.assignmentId || !args.data.activityIdLN || args.data.activityIdLN && args.data.activityIdLN !== '10')) {
                    this.enableAssignmentsActionButton(actionButtons);
                } else {
                    actionButtons[0].classList.add('disabled-grid-button');
                    if (actionButtons[1]) {
                        actionButtons[1].classList.add('disabled-grid-button');
                    }
                }
            }
        }
    }

    enableAssignmentsActionButton(actionButtons) {
        actionButtons[0].classList.remove('disabled-grid-button');
        if (actionButtons[1]) {
            actionButtons[1].classList.remove('disabled-grid-button');
        }
    }

    headerCellInfo(args) {
        if (this.iscRelatedFields.includes(args.cell.column.field)) {
            args.node.classList.add('bg-dodgerblue');
        }
    }

    queryCellInfo(args) {
        if (this.iscRelatedFields.includes(args.column.field)) {
            args.cell.bgColor = '#a5d2fe';
        }
    }

    onTransactionRowDataBound(args: any) {
        const data = args.data;
        const $row = args.row, actionButtons = $row.querySelectorAll('.action-button');
        if (args.data.costType === IscCostTypes.material) {
            actionButtons[0].style.visibility = 'visible';
        } else {
            actionButtons[0].style.visibility = 'hidden';
        }
        if (this.isEditable) {
            actionButtons[0].classList.remove('disabled-grid-button');
            if (actionButtons[1]) {
                actionButtons[1].classList.remove('disabled-grid-button');
            }
            if (actionButtons[2]) {
                actionButtons[2].classList.remove('disabled-grid-button');
            }
        } else {
            actionButtons[0].classList.add('disabled-grid-button');
            if (actionButtons[1]) {
                actionButtons[1].classList.add('disabled-grid-button');
            }
            if (actionButtons[2]) {
                actionButtons[2].classList.add('disabled-grid-button');
            }
        }
    }

    onAssignmentsToolbarClick(args: ToolbarClickEventArgs, grid: GridComponent) {
        if (args.item.id === 'add-new-assignment') {
            this.assignmentsGrid.endEdit();
            if (this.assignmentsGrid.isEdit) {
                this.commonService.showNotification('warning', 'There is a row in assignment grid, which is already in edit mode!', 'center', 5000);
            } else {
                if (this.assignmentsReadOnly) {
                    this.commonService.showPermissionNotification();
                } else {
                    this.assignmentDetailsGrid.endEdit();
                    if (this.assignmentDetailsGrid.isEdit) {
                        this.commonService.showNotification('warning', 'Can not add assignment if trasaction is being edited!', 'center', 5000);
                    } else {
                        this.addAssignment();
                    }
                }
            }
        } else if (args.item.id === 'column-chooser') {
            this.showAssignmentsColumnChooser = !this.showAssignmentsColumnChooser;
        } else if (args.item.id === 'clear-filter') {
            handleClearFilter(grid);
        } else if (args.item.id === 'excel-export') {
            handleGridExcelExport(grid, 'Assignments');
        }
    }

    onAssignmentCommandClick(args: any) {
        if (args.commandColumn.title === 'Delete Assignment') {
            if (this.assignmentsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                this.deleteAssignment(args.rowData, this.assignmentsGrid);
            }
        } else if (args.commandColumn.title === 'Edit Assignment') {
            if (this.assignmentsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                if (!this.assignmentDetailsGrid.isEdit && !this.assignmentsGrid.isEdit) {
                    args.rowData['callGroup'] = this.data.callGroup;
                    this.openEditAssignmentPopup(args.rowData);
                } else {
                    this.commonService.showNotification('warning', 'Assignment or Transaction is in edit mode!');
                }
            }
        }
    }

    openEditAssignmentPopup(rowData) {
        this.selectedAssignment = rowData;
        this.showAssignmentsPopup = true;
    }

    addAssignmentDetail() {
        this.assignmentDetailsGrid.allowReordering = false;
        this.assignmentDetailsGrid.allowSorting = false;
        this.assignmentDetailsGrid.allowFiltering = false;
        this.serviceOrderService.popupLoader.next(true);
        let newRow = this.setAssignmentDetailRow({});
        (this.assignmentDetailsGrid.dataSource as any).push(newRow);
        // this.addTransactionRow = newRow.rowId;
        this.assignmentDetailsGrid.refresh();
        this.serviceOrderService.hasTransaction.next(true);
        setTimeout(() => {
            const index = this.assignmentDetailsGrid.getRowIndexByPrimaryKey(newRow.rowId);
            const onlyOneAssignment = (this.assignmentsGrid.dataSource as any).filter(x => !x.isDeleted).length === 1;
            this.defaultReferenceActivity = onlyOneAssignment && (this.assignmentsGrid.dataSource as any).find(x => !x.isDeleted) || null;
            this.assignmentDetailsGrid.selectRow(index);
            this.assignmentDetailsGrid.startEdit();
            this.serviceOrderService.popupLoader.next(false);
        }, 600);
    }

    onAssignmentDetailsToolbarClick(args: ToolbarClickEventArgs, grid: GridComponent) {
        if (args.item.id === 'add-new-assignment-detail') {
            this.assignmentDetailsGrid.endEdit();
            if (!this.assignmentDetailsGrid.isEdit) {
                if (this.assignments && this.assignments.length) {
                    if (this.transactionsReadOnly) {
                        this.commonService.showPermissionNotification();
                    } else {
                        this.assignmentsGrid.endEdit();
                        if (!this.assignmentsGrid.isEdit) {
                            this.addAssignmentDetail();
                        }
                    }
                } else {
                    this.commonService.showNotification('warning', 'Please add an assignment first to add transaction!', 'center', 5000);
                }
            } else {
                this.commonService.showNotification('warning', 'There is a row in transaction grid, which is already in edit mode!', 'center', 5000);
            }
        } else if (args.item.id === 'cart-icon') {
            this.setPartsCatalogPopupData();
            this.showPartsCatalogPopup = true;
        } else if (args.item.id === 'column-chooser-assignment-detail') {
            this.showAssignmentDetailsColumnChooser = !this.showAssignmentDetailsColumnChooser;
        } else if (args.item.id === 'clear-filter') {
            handleClearFilter(grid);
            this.commonService.saveUserPreference({ userId: this.USER.userId, name: gridNames.serviceOrderTransactionsGrid, value: '' });
            localStorage.setItem('grid' + gridNames.serviceOrderTransactionsGrid, '');
            this.assignmentDetailGrid.refresh();
        } else if (args.item.id === 'excel-export') {
            handleGridExcelExport(grid, 'Transactions');
        }
    }

    setPartsCatalogPopupData() {
        this.partsCatalogPopupData = (this.assignmentDetailsGrid.dataSource as any)
            .filter(x => x.costType === CostTypes.material && !x.isDeleted)
            .map((x, index) => {
                return {
                    id: index + 1,
                    item: x.itemCode,
                    itemDescription: x.itemDescription,
                    // itemGroup: ,
                    unit: x.unit,
                    quantity: 1,
                    itemPrice: x.price,
                    itemDiscount: x.iscDiscountAmount,
                    grossAmount: x.price,
                    netPrice: x.price - x.iscDiscountAmount,
                    totalPrice: 0,
                    technicianId: this.data.technicianId ?? this.USER.userId,
                    technicianName: null,
                    isDeleted: false,
                    expectedDeliveryDate: null,
                    stock: null,
                    stockCan: null,
                    // createdBy: "W692CLB",
                    // createdDate: "2024-10-28T08:16:06.627Z",
                    // createdByName: "Cen Tex Automotive Eq(Paul Nielsen)",
                    // lastUpdatedDate: "2024-10-28T08:16:06.627Z",
                    // lastUpdatedBy: "W692CLB",
                    // cartTotal: 5
                }
            });
    }

    toSendSOId: any;

    onAssignmentDetailsCommandClick(args: any) {
        if (args.commandColumn.title === 'Create Purchase Order') {
            if (this.data.serviceOrderId && args.rowData.itemCode) {
                // this.serviceOrderService.popupLoader.next(true);
                this.toSendSOId = this.data.serviceOrderId;
                let data: any = args.rowData
                this.commonService.getItems(args.rowData.itemCode, 30, this.USER.company)
                    .subscribe((res: ApiResponse) => {
                        if (res.isSuccessful) {
                            if (res?.result[0]?.status === PartsAvailabilityStatus.inActive) {
                                this.commonService.showNotification('error', '\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete');
                                if (res?.result[0]?.alternateItem !== null) {
                                    this.commonService.showConfirmation('\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete.' + '\n\n Would you like to add the alternate item ' + res?.result[0]?.alternateItem)
                                        .then(result => {
                                            if (result.isConfirmed) { //if item is obelete, has alternate item and 'YES' clicked
                                                // this.commonService.getItems(res?.result[0]?.alternateItem, 30, this.USER.company)
                                                this.commonService.getAlternateItemData(res?.result[0]?.alternateItem)
                                                    .subscribe((res2: ApiResponse) => {
                                                        data.assignmentId = args.rowData.assignmentId,
                                                            data.itemCode = res2?.result?.item,
                                                            data.itemDescription = res2?.result?.itemDescription,
                                                            data.unit = args.rowData.unit,
                                                            data.qty = args.rowData.qty
                                                        this.fromExecutionTab = [data];
                                                        this.showCartCheckoutPopup = true;
                                                    })
                                            }
                                            else {
                                                // if item is obsolete, has alternate item and still 'NO' clicked
                                                return;
                                            }
                                        })
                                }
                                else {
                                    // if item is obsolete but alternateItem is null 
                                    this.commonService.showNotification('error', '\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete');
                                    args.cancel = true;
                                    return;
                                }
                            } else {
                                this.fromExecutionTab = [args.rowData];
                                this.showCartCheckoutPopup = true;
                            }

                        }
                        else {
                            this.commonService.showNotification('warning', res.message)
                        }
                    })

            } else {
                let warning = !this.data.serviceOrderId && 'Please save the service order before creating a purchase order!' || !args.rowData.itemCode && 'Select an item for this transaction first!';
                this.commonService.showNotification('warning', warning);
            }
        } else if (args.commandColumn.title === 'Edit Assignment Detail') {
            args.cancel = true;
            if (this.transactionsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                if (!this.assignmentDetailsGrid.isEdit && !this.assignmentsGrid.isEdit) {
                    this.showAssignmentDetailsPopup = true;
                    this.selectedAssignmentDetail = args.rowData;
                } else {
                    this.commonService.showNotification('warning', 'Assignment or Transaction is in edit mode!');
                }
            }
        } else if (args.commandColumn.title === 'Delete Detail') {
            this.assignmentDetailAction = 'Delete';
            if (this.transactionsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                this.deleteTransaction(args.rowData);
            }
        }
    }

    onAssignmentGridActionComplete(args, grid) {
        updateFilterIcon(args, grid);
        if (args.requestType === 'save') {
            this.assignmentsGrid.allowReordering = true;
            this.assignmentsGrid.allowSorting = true;
            this.assignmentsGrid.allowFiltering = true;

            this.calculateAmounts(false);
            this.isTabValid = true;
            this.resetTempValuesForAssignment();
            this.refreshGrids();
        } else if (args.requestType === 'cancel') {
            this.assignmentsGrid.allowReordering = true;
            this.assignmentsGrid.allowSorting = true;
            this.assignmentsGrid.allowFiltering = true;
            if (!args.rowData.assignmentId) {
                const index = grid.dataSource.findIndex(row => row.activityId === args.data.activityId);
                (grid.dataSource as any).splice(index, 1);
                grid.refresh();
            }
        } else if (args.requestType === 'refresh') {
            if (this.addAssignmentRow != null) {
                const index = grid.getRowIndexByPrimaryKey(this.addAssignmentRow);
                grid.selectRow(index);
            }
        }
    }

    onAssignmentSelected(args) {
        if (this.addAssignmentRow != null) {
            setTimeout(() => {
                this.assignmentsGrid.startEdit();
            }, 100);
            this.addAssignmentRow = null;
        }
    }

    onTransactionSelected(args) {
        if (this.addTransactionRow != null) {
            setTimeout(() => {
                this.assignmentDetailsGrid.startEdit();
            }, 200);
            this.addTransactionRow = null;
        }
    }

    checkBillable() {
        return this.assignments.length && this.assignments.find(assignment => this.getRefActivityCategory(assignment.referenceActivityCode) === 'Billable Service');
    }

    resetTempValuesForAssignment() {
        this.referenceActivityDescription = '';
        this.serviceTypeCode = '';
        this.reportedProblemDescription = '';
        this.solutionDescription = '';
    }

    onTransactionGridActionComplete(args, grid) {
        updateFilterIcon(args, grid);
        if (GridPersistRequests.includes(args.requestType)) {
            const persistenceData: PersistenceData = {
                userId: this.USER.userId,
                name: gridNames.serviceOrderTransactionsGrid,
                value: grid.getPersistData()
            }
            this.commonService.saveUserPreference(persistenceData);
            localStorage.setItem(`grid${this.transactionGridName}`, grid.getPersistData());
        }

        if (args.requestType === 'save') {
            this.assignmentDetailsGrid.allowReordering = true;
            this.assignmentDetailsGrid.allowSorting = true;
            this.assignmentDetailsGrid.allowFiltering = true;

            const rowData = grid.dataSource.find(row => row.rowId === args.data.rowId);
            rowData.assignmentId = +(this.assignmentId ?? rowData.assignmentId);
            rowData.activityId = this.activityId ?? rowData.activityId;

            if (!this.assignmentDetailsGrid.getColumnByField('unit').visible) {
                rowData.unit = this.getUnit(rowData.costType);
            }

            if (!this.assignmentDetailsGrid.getColumnByField('warrantyCoverage').visible) {
                rowData.warrantyCoverage = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
            }

            if (!this.assignmentDetailsGrid.getColumnByField('contractCoverage').visible) {
                rowData.contractCoverage = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;
            }

            if (!this.assignmentDetailsGrid.getColumnByField('totalAmount').visible) {
                rowData.totalAmount = this.totalAmountValue || 0;
            }

            if (!this.assignmentDetailsGrid.getColumnByField('taxpercent').visible) {
                rowData.taxpercent = this.getTaxRate(rowData.costType);
            }

            this.taxAmount = +(rowData.customerTotalAmount * (this.getTaxRate(rowData.costType) / 100)).toFixed(2);
            rowData.taxAmount = this.taxAmount || 0;

            if (!this.assignmentDetailsGrid.getColumnByField('costComponent').visible) {
                rowData.costComponent = this.getCostComponent(rowData.costType);
            }

            if (!this.assignmentDetailsGrid.getColumnByField('warehouse').visible) {
                rowData.warehouse = this.getWarehouse(rowData.costType);
            }

            if (!this.assignmentDetailsGrid.getColumnByField('laborType').visible) {
                rowData.laborType = this.getLaborType(rowData.costType);
            }

            if (!this.assignmentDetailsGrid.getColumnByField('task').visible) {
                rowData.task = this.getTask(rowData.costType);
            }

            if (!this.assignmentDetailsGrid.getColumnByField('startDate').visible) {
                rowData.startDate = rowData.startDate ? rowData.startDate : this.assignmentDetailStartTimeValue;
            }

            if (!this.assignmentDetailsGrid.getColumnByField('customerCurrency').visible) {
                rowData.customerCurrency = this.customerCurrency;
            }

            // if (!this.assignmentDetailsGrid.getColumnByField('customerPrice').visible) {
            //     rowData.customerPrice = this.customerPriceValue;
            // }

            // if (!this.assignmentDetailsGrid.getColumnByField('customerTotalAmount').visible) {
            //     rowData.customerTotalAmount = this.customerTotalAmountValue || 0;
            // }

            rowData.customerPrice = this.customerPriceValue ?? rowData.customerPrice;
            rowData.customerTotalAmount = this.customerTotalAmountValue ?? rowData.customerTotalAmountValue;
            rowData.serviceTypeDescription = this.assignmentDetailServiceTypDescription ?? rowData.serviceTypeDescription;
            rowData.itemGroup = this.itemGroup ?? rowData.itemGroup;
            rowData.serviceItemGroup = this.serviceItemGroup;
            rowData.referenceActivityCode = this.assignmentDetailReferenceActivityCode ?? rowData.referenceActivityCode;
            rowData.referenceActivityDescription = this.assignmentDetailReferenceActivityDescription ?? rowData.referenceActivityDescription;
            rowData.serviceTypeCode = this.assignmentDetailServiceTypeCode ?? rowData.serviceTypeCode;
            // rowData.estQ
            rowData.transactionDescription = rowData.description;
            rowData.discountAmount = rowData.discountAmount ?? 0;
            rowData.iscDiscountAmount = rowData.iscDiscountAmount ?? 0;
            rowData.isDeleted = false;
            this.calculateAmounts(false);
            this.isTabValid = true;
            grid.refreshColumns();
            this.tempUnit = '';
            this.resetTempValuesForTransaction();
            this.serviceItemGroup = '';
            this.rowServiceItemGroup = '';
            (this.isIsc || this.isIscManager || this.isIscSupervisor) && this.showUploadReceiptMessage();
        } else if (args.requestType === 'cancel') {
            this.assignmentDetailsGrid.allowReordering = true;
            this.assignmentDetailsGrid.allowSorting = true;
            this.assignmentDetailsGrid.allowFiltering = true;
            if (!args.rowData.assignmentDetailId) {
                const index = grid.dataSource.findIndex(row => row.rowId === args.data.rowId);
                (grid.dataSource as any).splice(index, 1);
                grid.refresh();
            }
            this.resetTempValuesForTransaction();
        } else if (args.requestType === 'beginEdit') {
            if (args.rowData && args.rowData.referenceActivityDescription) {
                this.updateCostTypeDataSource({ itemData: args.rowData });
            }
            if (args.rowData.costType === IscCostTypes.material) {
                const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
                anchorElement.classList.remove('d-none');
            }
            this.customerPriceValue = args.rowData.customerPrice;
            this.customerTotalAmountValue = args.rowData.customerTotalAmount;
            this.mandatoryCommentsForISC(args);
            if (this.defaultReferenceActivity) {
                this.assignmentObject.value = this.defaultReferenceActivity.tempId;
                this.defaultReferenceActivity = null;
            }
        } else if (args.requestType === 'refresh') {
            if (this.addTransactionRow != null) {
                const index = grid.getRowIndexByPrimaryKey(this.addTransactionRow);
                grid.selectRow(index);
            }
        }
    }

    mandatoryCommentsForISC(args) {
        this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = args.rowData && args.rowData.subCostType && args.rowData.subCostType !== SubCostTypes.travelCalloutCharge;
    }

    showUploadReceiptMessage() {
        if (this.subcostTypeObject?.value && ![SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value)) {
            this.commonService.showNotification('info', UPLOAD_RECEIPT_MESSAGE);
        }
    }

    resetTempValuesForTransaction() {
        this.assignmentDetailServiceTypDescription = this.itemGroup = this.serviceItemGroup = this.rowServiceItemGroup
            = this.assignmentDetailReferenceActivityCode = this.assignmentDetailReferenceActivityDescription
            = this.assignmentDetailServiceTypeCode = this.costTypeObject = this.itemCodeObject = this.itemDescriptionObject
            = this.commentsObject = this.quantityObject = this.customerPriceObject = this.discountAmountObject = this.contractAmountObject
            = this.warrantyAmountObject = this.customerTotalAmountObject = this.returnReasonObject = this.unitObject = this.assignmentObject
            = this.assignmentDetailStartTimeObject = this.assignmentDetailEndTimeObject = this.taskObject = this.taxObject = this.iscPriceObject
            = this.taxPercentObject = this.transactionWarehouseObject = this.laborTypeObject = this.iscDiscountAmountObject = null;
        this.customerPriceValue = this.customerTotalAmountValue = 0;
    }

    onExcelQueryCellInfo(args: any) {
        excelQueryCellInfo(args);
    }

    onAssignmentDoubleClick(args: any) {
        if (this.isEditable) {
            if (this.assignmentsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                this.selectedAssignment = args.rowData;
                this.showAssignmentsPopup = true;
            }
        }
    }

    onAssignmentDetailDoubleClick(args: any) {
        if (this.isEditable) {
            if (this.transactionsReadOnly) {
                this.commonService.showPermissionNotification();
            } else {
                this.selectedAssignmentDetail = args.rowData;
                this.showAssignmentDetailsPopup = true;
            }
        }
    }

    saveAssignment(args: any) {
        const index = this.assignments.findIndex(assign => (args.data.assignmentId && assign.assignmentId === args.data.assignmentId) || assign.tempId === args.data.tempId);
        const assignment = {
            ...args.data,
            activityId: args.data.activityId || (this.assignments.length + 1) * 10,
            assignmentId: args.data.assignmentId || 0,
            isDeleted: false
        };
        if (index > -1) {
            this.assignments.splice(index, 1, assignment);
        } else {
            this.assignments.push(assignment);
        }
        if (args.deleteTransactions) {
            this.deleteAssignmentDetailsByAssignment(args.data);
        }
        this.assignmentsGrid.dataSource = this.assignments;
        this.assignmentsGrid.refresh();
        this.emitData();
    }

    saveAssignmentDetail(data: any) {
        this.costComponentValue = data.costComponent;
        data.transactionDescription = data.description;
        data.isDeleted = false;
        if (data.costType !== IscCostTypes.material) {
            data.itemDescription = this.getItemDescription(data.costType);
        }
        const existingTransactionIndex = this.assignmentDetails.findIndex(tran => (tran.assignmentDetailId && tran.assignmentDetailId === data.assignmentDetailId) || (data.rowId && tran.rowId === data.rowId));
        if (existingTransactionIndex > -1) {
            this.assignmentDetails[existingTransactionIndex] = data;
        } else {
            // data.tempId = this.assignmentDetails.length + 1;
            this.assignmentDetails?.push(data);
        }
        this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
        this.assignmentDetailsGrid.refresh();
        this.calculateAmounts(false);
        // this.emitData();
        this.serviceOrderService.popupLoader.next(false);
    }

    calculateAmounts(isInitialLoad: boolean) {
        this.data.salesAmount = 0;
        this.data.discountAmount = 0;
        this.data.warrantyCoverage = 0;
        this.data.contractCoverage = 0;
        this.data.tax = 0;
        this.assignmentDetails.filter(detail => !detail.isDeleted && detail.qty).forEach(row => {
            this.data.salesAmount += row.qty * row.customerPrice;
            this.data.discountAmount += row.discountAmount;
            this.data.warrantyCoverage += row.warrantyCoverage;
            this.data.contractCoverage += row.contractCoverage;
            this.data.tax += row.taxAmount;
        });
        this.data.salesAmount = +this.data.salesAmount?.toFixed(2);
        this.data.discountAmount = +this.data.discountAmount?.toFixed(2);
        this.data.warrantyCoverage = +this.data.warrantyCoverage?.toFixed(2);
        this.data.contractCoverage = +this.data.contractCoverage?.toFixed(2);
        this.data.tax = +this.data.tax?.toFixed(2);

        this.data.totalAmount = this.data.salesAmount - this.data.warrantyCoverage - this.data.contractCoverage - this.data.discountAmount;
        this.data.invoiceAmount = +(this.data.totalAmount + this.data.tax)?.toFixed(2);

        if (!isInitialLoad) {
            this.emitData();
        }
    }

    emitData() {
        this.tabUpdated.emit({
            ...this.data,
            assignments: this.assignments,
            assignmentDetails: this.assignmentDetails
        });
    }

    deleteAssignment(data: any, grid) {
        this.commonService.showConfirmation('Are you sure you want to delete this assignment? Corresponding transactions will also be deleted!')
            .then(result => {
                if (result.isConfirmed) {
                    const index = grid.dataSource.findIndex(assgn => assgn.rowId === data.rowId);
                    if (index > -1) {
                        this.deleteAssignmentDetailsByAssignment(data);
                        if (grid.dataSource[index].assignmentId) {
                            grid.dataSource[index].isDeleted = true;
                        } else {
                            grid.dataSource.splice(index, 1);
                        }
                        this.updateActivityId();
                        this.emitData();
                        grid.refresh();
                    }
                }
            });
    }

    deleteAssignmentDetailsByAssignment(assignment: any) {
        const details = [...this.assignmentDetails];
        this.assignmentDetails.forEach((detail, index) => {
            if (detail.assignmentId === assignment.tempId || detail.assignmentId === assignment.assignmentId) {
                if (detail.assignmentDetailId) {
                    details[index].isDeleted = true;
                } else {
                    details.splice(index, 1);
                }
            }
        });
        this.assignmentDetails = details;
        this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
        this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
        this.calculateAmounts(false);
        this.assignmentDetailsGrid.refresh();
    }

    updateActivityId() {
        this.assignments.filter(assign => !assign.isDeleted).forEach((row, index) => {
            row.activityId = (index + 1) * 10;
            this.updateDetailsOnAssignmentDeletion(row);
            return row;
        });
    }

    updateDetailsOnAssignmentDeletion(assignment) {
        this.assignmentDetails.map(det => {
            if (assignment.tempId === det.assignmentId) {
                det.activityId = assignment.activityId;
            }
        });
    }

    deleteTransaction(data) {
        this.commonService.showConfirmation('Are you sure you want to delete this transaction?')
            .then(result => {
                if (result.isConfirmed) {
                    const index = this.assignmentDetails.findIndex(assgnDet => assgnDet.rowId === data.rowId);
                    if (index > -1) {
                        if (this.assignmentDetails[index].assignmentDetailId) {
                            this.assignmentDetails[index].isDeleted = true;
                        } else {
                            this.assignmentDetails.splice(index, 1);
                        }
                        this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
                        this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
                        this.assignmentDetailsGrid.refresh();
                        this.calculateAmounts(false);
                    }
                }
            })
    }

    onClose() {
        this.showLoader = false;
    }

    partsOrderSaved(updatedData) {
    }

    editReferenceActivity() {
        return {
            create: () => {
                this.referenceActivity = document.createElement('input');
                return this.referenceActivity;
            },
            read: () => {
                if (this.referenceActivityObject) {
                    return this.referenceActivityObject.value;
                }
            },
            destroy: () => {
                this.referenceActivityObject.destroy();
            },
            write: (args) => {
                const existingRefActivities = [];
                (this.assignmentsGrid.dataSource as any).forEach(assignment => {
                    if (assignment.referenceActivityCode !== args.rowData.referenceActivityCode && !assignment.isDeleted) {
                        existingRefActivities.push(assignment.referenceActivityCode);
                    }
                });
                let refActivitiesDDL = this.updateRefActivityDS(existingRefActivities);
                this.referenceActivityObject = new DropDownList({
                    dataSource: refActivitiesDDL,
                    fields: { value: 'value', text: 'description' },
                    value: args.rowData.referenceActivityCode,
                    allowFiltering: true,
                    filterType: 'Contains',
                    popupWidth: '250px',
                    change: event => {
                        if (this.hasTransaction(args.rowData) && event.isInteracted) {
                            this.commonService.showConfirmation('Are you sure? The transactions added for this assignment will be deleted').then(res => {
                                if (res.isConfirmed) {
                                    this.setTempValuesForAssignment(event);
                                    this.deleteAssignmentDetailsByAssignment(args.rowData);
                                } else {
                                    this.referenceActivityObject.value = event.previousItemData.value;
                                }
                            });
                        } else {
                            this.setTempValuesForAssignment(event);
                        }
                    }
                });
                this.referenceActivityObject.appendTo(this.referenceActivity);
            }
        }
    }

    setTempValuesForAssignment(event) {
        this.serviceTypeObject.value = event.itemData.serviceTypeDescription;
        this.serviceTypeCode = event.itemData.serviceTypeCode;
        this.referenceActivityDescription = event.itemData.description;
        this.refActivityCategory = event.itemData.category;
    }

    hasTransaction(assignment) {
        const index = (this.assignmentDetailsGrid.dataSource as any).findIndex(ad => ad.assignmentId === assignment.tempId);
        return index > -1;
    }

    updateRefActivityDS(existingRefActivities) {
        const createdDate = new Date(this.data?.orderDateLN ? this.data?.orderDateLN : this.data?.soCreatedDate).setHours(0,0,0,0);
        return this.referenceActivities.filter(activity => {
            if (activity.description === 'Contract Service' && this.equipmentInfoData) {
                if (this.equipmentInfoData?.serviceContractCode && this.equipmentInfoData.serviceContractDescriptionObj && this.equipmentInfoData.serviceContractDescriptionObj.length) {
                    const index = this.equipmentInfoData.serviceContractDescriptionObj.findIndex(contract => new Date(contract.expiryDate) >= new Date(createdDate));
                    return index > -1 ? !existingRefActivities.includes(activity.value) : false;
                } else {
                    return false;
                }
            } else if (activity.description === 'Warranty Service' && this.equipmentInfoData) {
                if (this.equipmentInfoData.warrantyCode && this.equipmentInfoData.warrantyDescriptionObj && this.equipmentInfoData.warrantyDescriptionObj.length) {
                    const index = this.equipmentInfoData.warrantyDescriptionObj.findIndex(warranty => new Date(warranty.expiryDate) >= new Date(createdDate));
                    return index > -1 ? !existingRefActivities.includes(activity.value) : false;
                } else {
                    return false;
                }
            } else {
                return !existingRefActivities.includes(activity.value);
            }
        });
    }

    editServiceType() {
        return {
            create: () => {
                this.serviceType = document.createElement('input');
                return this.serviceType;
            },
            read: () => {
                if (this.serviceTypeObject) {
                    return this.serviceTypeObject.value;
                }
            },
            destroy: () => {
                this.serviceTypeObject.destroy();
            },
            write: (args) => {
                this.serviceTypeObject = new TextBox({
                    value: args.rowData.serviceTypeDescription,
                    enabled: false
                });
                this.serviceTypeObject.appendTo(this.serviceType);
            }
        }
    }

    editReportedProblem() {
        return {
            create: () => {
                this.reportedProblem = document.createElement('input');
                return this.reportedProblem;
            },
            read: () => {
                if (this.reportedProblemObject) {
                    return this.reportedProblemObject.value;
                }
            },
            destroy: () => {
                this.reportedProblemObject.destroy();
            },
            write: (args) => {
                this.reportedProblemObject = new DropDownList({
                    dataSource: this.reportedProblems,
                    fields: { value: 'value', text: 'description' },
                    value: args.rowData.reportedProblem,
                    allowFiltering: true,
                    filterType: 'Contains',
                    popupWidth: '250px',
                    change: event => {
                        this.reportedProblemDescription = event.itemData.description;
                    }
                });
                this.reportedProblemObject.appendTo(this.reportedProblem);
            }
        }
    }

    editSolution() {
        return {
            create: () => {
                this.solution = document.createElement('input');
                return this.solution;
            },
            read: () => {
                if (this.solutionObject) {
                    return this.solutionObject.value;
                }
            },
            destroy: () => {
                this.solutionObject.destroy();
            },
            write: (args) => {
                this.solutionObject = new DropDownList({
                    dataSource: this.solutions,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.solutionCode,
                    allowFiltering: true,
                    filterType: 'Contains',
                    popupWidth: '250px',
                    change: event => {
                        this.solutionDescription = event.itemData.text;
                    }
                });
                this.solutionObject.appendTo(this.solution);
            }
        }
    }

    editPriority() {
        return {
            create: () => {
                this.priority = document.createElement('input');
                return this.priority;
            },
            read: () => {
                if (this.priorityObject) {
                    return this.priorityObject.value;
                }
            },
            destroy: () => {
                this.priorityObject.destroy();
            },
            write: (args) => {
                this.priorityObject = new DropDownList({
                    dataSource: this.priorities,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.priority,
                    allowFiltering: true,
                    filterType: 'Contains',
                    popupWidth: '250px',
                });
                this.priorityObject.appendTo(this.priority);
            }
        }
    }

    editStartDate() {
        return {
            create: () => {
                this.startDate = document.createElement('input');
                return this.startDate;
            },
            read: () => {
                if (this.startDateObject) {
                    return this.startDateObject.value;
                }
            },
            destroy: () => {
                this.startDateObject.destroy();
            },
            write: (args) => {
                this.startDateObject = new DateTimePicker({
                    placeholder: "Select start date time...",
                    min: args.rowData.startDate ? args.rowData.startDate : new Date(),
                    value: args.rowData.startDate,
                    openOnFocus: true,
                    allowEdit: false,
                    change: ev => {
                        this.endDateObject.min = ev.value;
                    }
                });
                this.startDateObject.appendTo(this.startDate);
            }
        }
    }

    editEndDate() {
        return {
            create: () => {
                this.endDate = document.createElement('input');
                return this.endDate;
            },
            read: () => {
                if (this.endDateObject) {
                    return this.endDateObject.value;
                }
            },
            destroy: () => {
                this.endDateObject.destroy();
            },
            write: (args) => {
                this.endDateObject = new DateTimePicker({
                    placeholder: "Select end date time...",
                    min: this.startDateObject && this.startDateObject.value ? this.startDateObject.value : args.rowData.startDate || new Date(),
                    value: args.rowData.endDate,
                    openOnFocus: true,
                    allowEdit: false,
                    change: ev => {
                        if (this.startDateObject) {
                            this.startDateObject.max = ev.value;
                        }
                    }
                });
                this.endDateObject.appendTo(this.endDate);
            }
        }
    }

    editAssignment() {
        return {
            create: () => {
                this.assignment = document.createElement('input');
                return this.assignment;
            },
            read: () => {
                if (this.assignmentObject) {
                    return this.assignmentObject.value;
                }
            },
            destroy: () => {
                this.assignmentObject.destroy();
            },
            write: args => {
                this.assignmentObject = new DropDownList({
                    dataSource: this.assignments.filter(assignment => !assignment.isDeleted),
                    fields: { value: 'tempId', text: 'referenceActivityDescription' },
                    value: args.rowData.assignmentId,
                    allowFiltering: true,
                    filterType: 'Contains',
                    popupWidth: '250px',
                    itemTemplate: '<span title="${referenceActivityDescription}">${referenceActivityDescription}</span>',
                    change: event => {
                        this.USER.company === Companies.conway && this.updateCostTypeDataSource(event);
                        this.setTempValuesForTransaction(event.itemData);
                        if (args?.rowData.assignmentDetailId) { // To reset the row on this change
                            this.costTypeObject.value = null;
                        }
                        if (this.discountAmountObject) {
                            this.discountAmountObject.enabled = this.getRefActivityCategory(event.itemData.referenceActivityCode) === 'Billable Service';
                        }
                        this.iscDiscountAmountObject.enabled = this.isIscDiscountEnabled();
                        this.iscPriceObject.enabled = this.isIscPriceEnabled();
                        this.recalculations(args);
                    }
                });
                this.assignmentObject.appendTo(this.assignment);
            }
        }
    }

    recalculations(args) {
        // do all the recalculations based on the category of the transaction (Billable Service, Contract Service, Warranty Service or Other)

        const qty = this.quantityObject ? this.quantityObject.value : args.rowData.qty;
        const customerPrice = this.customerPriceObject ? +this.customerPriceObject.value.toFixed(2) : +this.customerPriceValue.toFixed(2);
        const iscPrice = this.iscPriceObject ? this.iscPriceObject.value : args.rowData.price;
        let discount = this.discountAmountObject ? this.discountAmountObject.value : args.rowData.discountAmount;

        if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
            discount = customerPrice * qty;
            if (this.discountAmountObject) {
                this.discountAmountObject.value = customerPrice && qty ? customerPrice * qty : 0;
                this.discountAmountObject.max = customerPrice && qty ? customerPrice * qty : null;
            }
        } else {
            this.discountAmountObject.value = 0;
            discount = 0;
            if (this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Billable Service' && this.costTypeObject.value === CostTypes.material) {
                const itemCode = this.itemCodeObject.value ?? args.rowData.itemCode;
                const unit = this.unitObject?.value ?? args.rowData?.unit;
                const qty = this.quantityObject?.value ?? args.rowData.qty;
                this.getItemPrice(itemCode, unit, args.rowData, qty);
            }
        }
        const tax = this.getTaxRate(this.costTypeObject.value);
        const warranty = this.quantityObject.value * (this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0) || 0;
        const contract = this.quantityObject.value * (this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0) || 0;

        if (this.warrantyAmountObject) {
            this.warrantyAmountObject.value = warranty;
        }
        if (this.contractAmountObject) {
            this.contractAmountObject.value = contract;
        }
        this.calculateTotalAmount({ qty, customerPrice, iscPrice, discount, tax, warranty, contract }, args.rowData);
    }

    checkWarrantyContractCoverage(coverages) {
        let costTypes = this.costTypes;
        const createdDate = new Date(this.data?.orderDateLN ? this.data?.orderDateLN : this.data?.soCreatedDate).setHours(0,0,0,0);
        coverages.forEach(x => {
            if (x.costType === 'all' && new Date(x.expiryDate) < new Date(createdDate)) {
                costTypes = [];
            } else if (x.costType.toLowerCase() === 'material' && new Date(x.expiryDate) < new Date(createdDate)) {
                costTypes = costTypes.filter(x => x.value !== IscCostTypes.material);
            } else if (x.costType.toLowerCase() === 'labor' && new Date(x.expiryDate) < new Date(createdDate)) {
                costTypes = costTypes.filter(x => x.value !== IscCostTypes.labor);
            } else if (x.costType.toLowerCase() === 'other' && new Date(x.expiryDate) < new Date(createdDate)) { // Update the data (Instead of travel it should be callOut from the API.)
                costTypes = costTypes.filter(x => x.value !== IscCostTypes.other);
            }
        });
        if (coverages.findIndex(x => x.costType.toLowerCase() === 'material') === -1) {
            costTypes = costTypes.filter(x => x.value !== IscCostTypes.material);
        }
        if (coverages.findIndex(x => x.costType.toLowerCase() === 'labor') === -1) {
            costTypes = costTypes.filter(x => x.value !== IscCostTypes.labor);
        }
        if (coverages.findIndex(x => x.costType.toLowerCase() === 'other') === -1) {
            costTypes = costTypes.filter(x => x.value !== IscCostTypes.other);
        }
        return costTypes;
    }

    updateCostTypeDataSource(args) {
        if (args.itemData.referenceActivityDescription === 'Warranty Service' && this.equipmentInfoData) {
            this.costTypeObject.dataSource = this.checkWarrantyContractCoverage(this.equipmentInfoData.warrantyDescriptionObj)
        } else if (args.itemData.referenceActivityDescription === 'Contract Service' && this.equipmentInfoData) {
            this.costTypeObject.dataSource = this.checkWarrantyContractCoverage(this.equipmentInfoData.serviceContractDescriptionObj);
        }
    }

    editAssignmentDetailReferenceActivity() {
        return {
            create: () => {
                this.assignmentDetailReferenceActivity = document.createElement('input');
                return this.assignmentDetailReferenceActivity;
            },
            read: () => {
                if (this.assignmentDetailReferenceActivityObject) {
                    return this.assignmentDetailReferenceActivityObject.value;
                }
            },
            destroy: () => {
                this.assignmentDetailReferenceActivityObject.destroy();
            },
            write: (args) => {
                this.assignmentDetailReferenceActivityObject = new TextBox({
                    value: args.rowData.referenceActivityCode,
                    enabled: false
                });
                this.assignmentDetailReferenceActivityObject.appendTo(this.assignmentDetailReferenceActivity);
            }
        }
    }

    editAssignmentDetailServiceType() {
        return {
            create: () => {
                this.assignmentDetailServiceType = document.createElement('input');
                return this.assignmentDetailServiceType;
            },
            read: () => {
                if (this.assignmentDetailServiceTypeObject) {
                    return this.assignmentDetailServiceTypeObject.value;
                }
            },
            destroy: () => {
                this.assignmentDetailServiceTypeObject.destroy();
            },
            write: (args) => {
                this.assignmentDetailServiceTypeObject = new TextBox({
                    value: args.rowData.serviceTypeDescription,
                    enabled: false
                });
                this.assignmentDetailServiceTypeObject.appendTo(this.assignmentDetailServiceType);
            }
        }
    }

    editCostType() {
        return {
            create: () => {
                this.costType = document.createElement('input');
                return this.costType;
            },
            read: () => {
                if (this.costTypeObject) {
                    return this.costTypeObject.value;
                }
            },
            destroy: () => {
                this.costTypeObject.destroy();
            },
            write: (args) => {
                this.costTypeObject = new DropDownList({
                    dataSource: this.costTypes,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.costType,
                    popupWidth: '150px',
                    change: event => {
                        this.setTransactionValuesOnCostTypeChange(event.value, args.rowData);
                        this.iscDiscountAmountObject.enabled = this.isIscDiscountEnabled();
                        this.iscPriceObject.enabled = this.isIscPriceEnabled();
                        // if (event.value !== IscCostTypes.material) {
                        //     delete this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'];
                        // }
                        this.setItemCodeValidation(event.value);

                        this.tempUnit = this.getUnit(event.value);
                        const tooltipElement = document.getElementById('qty_Error');
                        if (tooltipElement) {
                            tooltipElement.style.display = 'none';
                        }
                    }
                });
                this.costTypeObject.appendTo(this.costType);
            }
        }
    }

    setItemCodeValidation(costType) {
        this.assignmentDetailsGrid.getColumnByField('itemCode').validationRules['required'] = costType === IscCostTypes.material;
        costType !== IscCostTypes.material && document.getElementById('itemCode_Error')?.remove();
    }

    setCustomerPrice(price, rowData, sourceFxn?) {
        this.customerPriceValue = price;
        const isWarranty: boolean = this.assignmentObject?.itemData && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service';
        const isContract: boolean = this.assignmentObject?.itemData && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service';
        if (this.customerPriceObject && !isWarranty && !isContract) {
            this.customerPriceObject.value = price;
        } else {
            this.customerPriceObject && (this.customerPriceObject.value = price);
            this.onCustomerPriceChange(price, rowData, sourceFxn);
        }
    }

    setDiscountAmountValue(discount) {
        this.discountAmountObject.value = discount;
    }

    onCustomerPriceChange(price, rowData, sourceFxn?) {
        const qty = this.quantityObject?.value;
        const customerPrice = +price?.toFixed(2);
        const iscPrice = this.iscPriceObject ? this.iscPriceObject.value : rowData.price;
        !price && this.setDiscountAmountValue(0);
        let discount = this.discountAmountObject?.value ?? rowData.discountAmount;
        if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
            discount = customerPrice * qty;
            if (this.discountAmountObject) {
                this.discountAmountObject.value = customerPrice && qty ? customerPrice * qty : 0;
                this.discountAmountObject.max = customerPrice && qty ? customerPrice * qty : null;
            }
        } else {
            if (['Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
                this.discountAmountObject.value = 0;
                discount = 0;
            }
        }
        const tax = rowData.taxpercent;
        const warranty = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
        const contract = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

        if (this.warrantyAmountObject) {
            this.warrantyAmountObject.value = warranty;
        }
        if (this.contractAmountObject) {
            this.contractAmountObject.value = contract;
        }
        this.calculateTotalAmount({ qty, customerPrice, iscPrice, discount, tax, warranty, contract }, rowData);
    }

    setTransactionValuesOnCostTypeChange(costType: string, rowData) {
        const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
        this.subcostTypeObject.enabled = costType === IscCostTypes.other;
        this.itemCodeObject.enabled = costType === IscCostTypes.material;
        this.subcostTypeObject.value = null;
        this.itemCodeObject.value = null;
        this.itemDescriptionObject.value = null;
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
        if (costType === IscCostTypes.labor) {
            this.iscPriceObject.value = this.data?.isNationalAccount ? this.iscEmployeeDetails.t2LaborRate : (isBillable ? this.laborRate : this.iscEmployeeDetails.t1LaborRate);
            this.totalAmountObject.value = this.iscPriceObject.value;
            this.itemDescriptionObject.value = this.getItemDescription(costType);
            this.setCustomerPrice(this.laborRate, rowData, 'cost type labor');
            anchorElement.classList.add('d-none');
        } else if (costType === IscCostTypes.other) {
            this.discountAmountObject.value = 0;
            this.iscPriceObject.value = 0;
            this.totalAmountObject.value = 0;
            this.setCustomerPrice(0, rowData, 'cost type other');
            anchorElement.classList.add('d-none');
        } else if (costType === IscCostTypes.material) {
            this.discountAmountObject.value = 0;
            this.iscPriceObject.value = 0;
            this.totalAmountObject.value = 0;
            this.setCustomerPrice(0, rowData, 'cost type material');
            anchorElement.classList.remove('d-none');
        } else {
            this.discountAmountObject.value = 0;
            this.setCustomerPrice(0, rowData, 'no cost type selected');
            anchorElement.classList.add('d-none');
        }
        this.laborTypeObject && (this.laborTypeObject.value = this.getLaborType(costType));
        this.unitObject && (this.unitObject.value = this.getUnit(costType));
        this.costComponentObject && (this.costComponentObject.value = this.getCostComponent(costType));
        this.transactionWarehouseObject && (this.transactionWarehouseObject.value = this.getWarehouse(costType));
        this.taskObject && (this.taskObject.value = this.getTask(costType));
        this.quantityObject.value = 1;
        this.assignmentDetailStartTimeObject && (this.assignmentDetailStartTimeObject.value = this.assignmentDetailStartTimeValue);
        this.taxObject && (this.taxObject.value = 0);
        this.warrantyAmountObject && (this.warrantyAmountObject.value = 0);
        this.contractAmountObject && (this.contractAmountObject.value = 0);
        this.taxAmount = 0;
        this.taxPercentObject && (this.taxPercentObject.value = this.getTaxRate(costType));

        this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = false;
        this.commentsObject && (this.commentsObject.value = null);

        this.returnReasonObject && (this.returnReasonObject.enabled = costType === IscCostTypes.material);

        this.setQuantityFormatAndSteps(costType);
    }

    setQuantityFormatAndSteps(costType) {
        this.quantityObject.format = costType === IscCostTypes.material ? 'n' : 'n2';
        this.quantityObject.step = costType === IscCostTypes.material ? 1 : 0.1;
        this.quantityObject.decimals = costType === IscCostTypes.material ? 0 : 2;
    }

    editSubCostType() {
        return {
            create: () => {
                this.subcostType = document.createElement('input');
                return this.subcostType;
            },
            read: () => {
                if (this.subcostTypeObject) {
                    return this.subcostTypeObject.value;
                }
            },
            destroy: () => {
                if (this.subcostTypeObject) {
                    this.subcostTypeObject.destroy();
                }
            },
            write: (args) => {
                this.subcostTypeObject = new DropDownList({
                    dataSource: IscSubCostTypeList,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.subCostType || '',
                    popupWidth: '150px',
                    enabled: args.rowData.costType === IscCostTypes.other,
                    change: event => {
                        this.mileageObject.enabled = event.value === SubCostTypes.travelCalloutCharge;

                        if (event.value !== SubCostTypes.travelCalloutCharge)
                            this.mileageObject.value = null;
                        if (event.isInteracted)
                            this.itemDescriptionObject.value = event.itemData?.text || null;

                        const isTravelCallout_mileage = [SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(event.value);
                        this.iscPriceObject.enabled = this.isIscPriceEnabled();
                        this.quantityObject.enabled = this.costTypeObject.value === IscCostTypes.other ? event.value !== SubCostTypes.travelCalloutCharge : true;
                        this.iscDiscountAmountObject.enabled = this.isIscDiscountEnabled();
                        if (event.value && !isTravelCallout_mileage) {
                            this.discountAmountObject.value = 0
                            this.iscPriceObject.value = 0;
                        }
                        this.iscDiscountAmountObject.value = 0;
                        this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = event.value && event.value !== SubCostTypes.travelCalloutCharge;
                        this.assignmentDetailsGrid.getColumnByField('mileage').validationRules['required'] = event.value === SubCostTypes.travelCalloutCharge;
                        // this.assignmentDetailsGrid.getColumnByField('addtMileageCharged').validationRules['required'] = event.value === SubCostTypes.travelCalloutCharge;
                        this.subcostTypeWorkflow(event.value, args.rowData);
                        this.data?.isNationalAccount && this.checkIscRulesForSubcost(event.value, args.rowData, event.isInteracted);
                    }
                });
                this.subcostTypeObject.appendTo(this.subcostType);
            }
        }
    }

    subcostTypeWorkflow(subcostType, rowData) {
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';

        switch (subcostType) {
            case SubCostTypes.travelCalloutCharge:
                this.quantityObject.value = 1;
                this.setCustomerPrice(this.calloutRate, rowData, 'subcost type: travel callout charge');
                const mileage = this.mileageObject?.value;
                let mileageExceededPrice = mileage > this.iscEmployeeDetails.mileageThreshold ? mileage * this.iscEmployeeDetails.mileageRate : undefined;
                this.iscPriceObject.value = this.data?.isNationalAccount ? mileageExceededPrice ?? this.iscEmployeeDetails.t2CalloutRate
                    : isBillable ? this.calloutRate : (mileageExceededPrice ?? this.iscEmployeeDetails.t1CalloutRate);
                this.totalAmountObject.value = this.iscPriceObject.value * this.quantityObject.value;
                break;
            case SubCostTypes.mileage:
                this.setCustomerPrice(this.USER.mileageRate, rowData, 'subcost type: mileage');
                this.iscPriceObject.value = this.iscEmployeeDetails.mileageRate;
                this.totalAmountObject.value = this.iscPriceObject.value * this.quantityObject.value;
                break;
            case SubCostTypes.tax:
                this.quantityObject.value = 1;
                this.setCustomerPrice(this.getTotalTaxAmount(), rowData, 'subcost type: tax');
                this.totalAmountObject.value = this.getTotalTaxAmount();
                break;
            case null: break;
            default:
                this.quantityObject.value = 1;
                this.setCustomerPrice(0, rowData, 'subcost type: other than travel callout, mileage, tax');
                this.totalAmountObject.value = this.iscPriceObject.value * this.quantityObject.value;
                break;
        }
    }

    checkIscRulesForMileageItem(costType, rowData) {
        this.data?.isNationalAccount && costType === IscCostTypes.material && this.claimMileageItem();
        this.data?.isNationalAccount && costType === IscCostTypes.material && this.billMileageItem(rowData);
    }

    claimMileageItem() {
        !this.iscRulesForSO.addMileageItem && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
        !this.iscRulesForSO.addMileageItem && (this.totalAmountObject.value = 0);
    } // 

    billMileageItem(rowData) {
        const price = this.iscRulesForSO.billMileageItem ? this.iscPriceObject.value : 0;
        this.setCustomerPrice(price, rowData, 'isc rule for national account: bill mileage item');
    }

    checkIscRulesForSubcost(subcostType, rowData, subcostInteracted: boolean = false) {
        subcostType === SubCostTypes.travelCalloutCharge && this.claimMultipleTripCharge(rowData, subcostInteracted);
        subcostType === SubCostTypes.travelCalloutCharge && this.billMultipleTripCharge(rowData);
        subcostType === SubCostTypes.local && this.claimLocalParts(subcostInteracted);
        subcostType === SubCostTypes.local && this.billLocalParts(rowData);
        subcostType === SubCostTypes.mileage && this.claimAdditionalMileage(subcostInteracted);
        subcostType === SubCostTypes.mileage && this.billAdditionalMileage(rowData);
        subcostType === SubCostTypes.freight && this.claimFreightCharge(subcostInteracted);
        subcostType === SubCostTypes.freight && this.billFreightCharge(rowData);
        subcostType === SubCostTypes.tax && this.claimTax(subcostInteracted);
    }

    isAmountClaimable(rowData) {
        switch (this.subcostTypeObject.value) {
            case SubCostTypes.travelCalloutCharge:
                const travelCalloutChargeExists = (this.assignmentDetailsGrid?.dataSource as any)?.findIndex(x => x.rowId !== rowData.rowId && !x.isDeleted && x.subCostType === SubCostTypes.travelCalloutCharge);
                if (travelCalloutChargeExists > -1) {
                    return this.iscRulesForSO?.claimMultipleTripCharge ?? true;
                } else {
                    return true;
                }
            case SubCostTypes.local:
                return this.iscRulesForSO?.claimLocalPart ?? true;
            case SubCostTypes.mileage:
                return this.iscRulesForSO?.claimAddtMileage ?? true;
            case SubCostTypes.freight:
                return this.iscRulesForSO?.claimFrieght ?? true;
            case SubCostTypes.tax:
                return this.iscRulesForSO?.iscTax ?? true;
            default:
                return true;
        }
    }

    claimMultipleTripCharge(rowData, subcostInteracted) {
        const travelCalloutChargeExists = (this.assignmentDetailsGrid?.dataSource as any)?.findIndex((x, index) => x.rowId !== rowData.rowId && index < rowData.index && !x.isDeleted && x.subCostType === SubCostTypes.travelCalloutCharge);
        if (travelCalloutChargeExists > -1) {
            !this.iscRulesForSO.claimMultipleTripCharge && subcostInteracted && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
            !this.iscRulesForSO.claimMultipleTripCharge && subcostInteracted && (this.totalAmountObject.value = 0);
        }
    }

    billMultipleTripCharge(rowData) {
        const travelCalloutChargeExists = (this.assignmentDetailsGrid?.dataSource as any)?.findIndex((x, index) => x.rowId !== rowData.rowId && index < rowData.index && !x.isDeleted && x.subCostType === SubCostTypes.travelCalloutCharge);
        if (travelCalloutChargeExists > -1) {
            const price = this.iscRulesForSO.billMultipleTripCharge ? this.calloutRate : 0;
            this.setCustomerPrice(price, rowData, 'isc rule for national account: bill multiple trip charge');
        }
    }

    claimLocalParts(subcostInteracted) {
        !this.iscRulesForSO.claimLocalPart && subcostInteracted && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
        !this.iscRulesForSO.claimLocalPart && subcostInteracted && (this.totalAmountObject.value = 0);
    }

    billLocalParts(rowData) {
        const price = this.iscRulesForSO.billLocalPart ? this.iscPriceObject.value : 0;
        this.setCustomerPrice(price, rowData, 'isc rule for national account: bill local part');
    }

    claimAdditionalMileage(subcostInteracted) {
        !this.iscRulesForSO.claimAddtMileage && subcostInteracted && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
        !this.iscRulesForSO.claimAddtMileage && subcostInteracted && (this.totalAmountObject.value = 0);
    }

    billAdditionalMileage(rowData) {
        const price = this.iscRulesForSO.billAddtMileage ? this.iscPriceObject.value : 0;
        this.setCustomerPrice(price, rowData, 'isc rule for national account: bill additional mileage');
    }

    claimFreightCharge(subcostInteracted) {
        !this.iscRulesForSO.claimFrieght && subcostInteracted && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
        !this.iscRulesForSO.claimFrieght && subcostInteracted && (this.totalAmountObject.value = 0);
    }

    billFreightCharge(rowData) {
        const price = this.iscRulesForSO.billFrieght ? this.iscPriceObject.value : 0;
        this.setCustomerPrice(price, rowData, 'isc rule for national account: bill freight charge');
    }

    claimTax(subcostInteracted) {
        !this.iscRulesForSO.iscTax && subcostInteracted && this.commonService.showNotification('warning', CANNOT_CLAIM_TRANSACTION_MESSAGE, 'center', 5000);
        !this.iscRulesForSO.iscTax && subcostInteracted && (this.totalAmountObject.value = 0);
    }

    getTotalTaxAmount() {
        let tax = 0;
        (this.assignmentDetailsGrid?.dataSource as any)?.forEach(transaction => {
            if (!transaction.isDeleted)
                tax += transaction.taxAmount;
        });
        return +tax?.toFixed(2);
    }

    showItemPopup() {
        this.showItemAdvancedSearchPopup = true;
    }

    onItemAdvancedSearchPopupClose(data: any) {
        this.itemChangedFromAdvanceSearch = true;
        this.rowServiceItemGroup = data?.serviceItemGroup;
        if (this.assignmentDetailsGrid.isEdit) {
            this.serviceOrderService.popupLoader.next(true);
            if (data) {
                this.itemDescriptionObject.value = data?.itemDescription;
                this.itemCodeObject.dataSource = [{
                    text: `${data?.item} | ${data?.itemDescription}`,
                    value: data?.item,
                    ...data
                }];
                this.itemCodeObject.value = data?.item;
            }
            setTimeout(() => {
                this.showItemAdvancedSearchPopup = false;
                this.serviceOrderService.popupLoader.next(false);
            }, 400);
        } else {
            const rowIndex = this.assignmentDetailsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
            this.serviceOrderService.popupLoader.next(true);
            this.showItemAdvancedSearchPopup = false;
            setTimeout(() => {
                this.assignmentDetailsGrid.selectRow(rowIndex);
                this.skipBeginEditEvent = true;
                this.assignmentDetailsGrid.startEdit();
                if (data) {
                    setTimeout(() => {
                        this.serviceOrderService.popupLoader.next(false);
                        this.itemDescriptionObject.value = data?.itemDescription;
                        this.itemCodeObject.dataSource = [{
                            text: `${data?.item} | ${data?.itemDescription}`,
                            value: data?.item,
                            ...data
                        }];
                        this.itemCodeObject.value = data?.item;
                    }, 400);
                } else {
                    this.serviceOrderService.popupLoader.next(false);
                }
            }, 2000);
        }
    }

    addAdvancedSearchIcon(costType) {
        const itemAnchor = document.createElement('a');
        itemAnchor.classList.add('item-advance-search-icon');
        if (costType !== IscCostTypes.material) {
            itemAnchor.classList.add('d-none');
        }
        itemAnchor.setAttribute('href', 'javascript:');
        itemAnchor.setAttribute('title', 'Advanced Search');
        itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
        const icon = document.createElement('i');
        icon.classList.add('fas');
        icon.classList.add('fa-info');
        itemAnchor.appendChild(icon);
        this.itemCode.parentElement.parentElement.append(itemAnchor);
    }

    editItem() {
        return {
            create: args => {
                this.selectedRowId = args.data.rowId;
                this.itemCode = document.createElement('input');
                return this.itemCode;
            },
            read: () => {
                if (this.itemCodeObject) {
                    return this.itemCodeObject.value;
                }
            },
            destroy: () => {
                this.itemCodeObject.destroy();
            },
            write: (args) => {
                this.itemCodeObject = new DropDownList({
                    dataSource: this.itemsList,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.itemCode,
                    cssClass: 'generic-items-dropdown-list',
                    // itemTemplate: '<span title="${text}">${text}</span>',
                    allowFiltering: true,
                    showClearButton: true,
                    popupWidth: '350px',
                    filterType: 'Contains',
                    enabled: this.costTypeObject.value === IscCostTypes.material,
                    filtering: args => { this.onItemUpdate.next(args.text) },
                    focus: args => { this.itemCodeObject.showPopup() },
                    blur: args => {
                        if (this.itemCodeObject['isSelectCustom']) {
                            this.itemCodeObject.value = null;
                        }
                    },
                    change: event => {
                        if (event.value && event.item) {
                            this.getPriceForItem = event.isInteracted || this.itemChangedFromAdvanceSearch;
                            const unit = this.tempUnit;
                            if (event.itemData?.value !== 'LOCAL' && this.getPriceForItem) {
                                this.getItemPrice(event.itemData?.value, unit, args.rowData, this.quantityObject.value);
                            }
                            if (event.value === 'Mileage') {
                                this.checkIscRulesForMileageItem(event.value, args.rowData);
                            }
                            this.itemDescriptionObject.value = event.itemData?.itemDescription || null;
                            this.serviceItemGroup = event.itemData?.serviceItemGroup;
                            this.rowServiceItemGroup = event.itemData?.serviceItemGroup;
                            this.itemGroup = event.itemData?.itemGroup;
                            this.itemChangedFromAdvanceSearch = false;
                        }
                    }
                });
                this.itemCodeObject.appendTo(this.itemCode);
                this.addAdvancedSearchIcon(args.rowData.costType);
            }
        }
    }

    getItemPrice(itemCode, unit, rowData, quantity = 1) {
        const data = {
            company: this.USER.company,
            item: itemCode,
            unit: unit,
            quantity,
            configuration: this.data.customerCode
        };
        const iscData = {
            ...data,
            configuration: this.USER.iscCode
        }
        if (data.item && data.quantity) {
            this.showLoader = true;
            forkJoin([
                this.serviceOrderService.getPrice(data),
                this.serviceOrderService.getPrice(iscData),
            ]).subscribe((res: any) => {
                if (res[0] && res[0].isSuccessful && res[0].result) {
                    const price = res[0].result.bookPrice ? +res[0].result.bookPrice.bookPriceValue?.toFixed(2) : 0;
                    this.discountAmountObject.value = res[0].result?.discountAmount ? +res[0].result.discountAmount.discountAmountValue?.toFixed(2) : 0;
                    this.setCustomerPrice(price, rowData, 'on item changed');
                } else {
                    this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
                }
                if (res[1] && res[1].isSuccessful && res[1].result) {
                    const price = res[1].result.bookPrice ? +res[1].result.bookPrice.bookPriceValue?.toFixed(2) : 0;
                    this.iscPriceObject.value = price;
                    this.iscDiscountAmountObject.value = res[1].result?.discountAmount ? +res[1].result.discountAmount.discountAmountValue?.toFixed(2) : 0;
                    this.totalAmountObject.value = this.isAmountClaimable(rowData) ? price * this.quantityObject.value - this.iscDiscountAmountObject.value : 0;
                } else {
                    this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
                }
                this.showLoader = false;
            }, (error: HttpErrorResponse) => {
                this.discountAmountObject.value = 0;
                this.iscPriceObject.value = 0;
                this.setCustomerPrice(0, rowData, 'item change API fail');
                this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
                this.showLoader = false;
            });
        }
    }

    setCustomerTotalAmountValue(totalAmount) {
        this.customerTotalAmountObject && (this.customerTotalAmountObject.value = totalAmount);
        this.customerTotalAmountValue = totalAmount;
    }

    updateItemOnHandQuantity(itemCode, onHandQty, rowData) {
        let calculatedOnHand = onHandQty;
        this.loadedAssignmentItemDetails.forEach(x => {
            if (itemCode === x.itemCode) {
                calculatedOnHand += x.issuedQty;
            }
        });
        (this.assignmentDetailsGrid.dataSource as any).forEach(x => {
            if (itemCode === x.itemCode && !x.isDeleted) {
                if (!this.assignmentDetailsGrid.isEdit || rowData.rowId != x.rowId)
                    calculatedOnHand -= x.qty;
            }
        });
        return calculatedOnHand;
    }

    editItemDescription() {
        return {
            create: () => {
                this.itemDescription = document.createElement('input');
                return this.itemDescription;
            },
            read: () => {
                if (this.itemDescriptionObject) {
                    return this.itemDescriptionObject.value;
                }
            },
            destroy: () => {
                this.itemDescriptionObject.destroy();
                this.itemDescriptionObject = null;
            },
            write: (args) => {
                this.itemDescriptionObject = new TextBox({
                    value: args.rowData.itemDescription,
                    enabled: false
                });
                this.itemDescriptionObject.appendTo(this.itemDescription);
            }
        }
    }

    editComments() {
        return {
            create: () => {
                this.comments = document.createElement('input');
                return this.comments;
            },
            read: () => {
                if (this.commentsObject) {
                    return this.commentsObject.value;
                }
            },
            destroy: () => {
                this.commentsObject.destroy();
            },
            write: (args) => {
                this.commentsObject = new TextBox({
                    value: args.rowData.comments,
                    // enabled: false
                });
                this.commentsObject.appendTo(this.comments);
            }
        }
    }

    editMileage() {
        let tooltip;
        return {
            create: () => {
                this.mileage = document.createElement('input');
                return this.mileage;
            },
            read: () => {
                if (this.mileageObject) {
                    return this.mileageObject.value;
                }
            },
            destroy: () => {
                if (this.mileageObject) {
                    this.mileageObject.destroy();
                }
            },
            write: (args) => {
                tooltip = new Tooltip({
                    //Set tooltip content
                    content: 'Estimated Distance: ' + (this.serviceOrderService.estimatedMiles ?? 'No data found!'),
                    opensOn: 'Focus',
                    beforeOpen: args => {
                        // if (this.costTypeObject.value !== IscCostTypes.material || this.onHandQuantity === undefined) {
                        //     args.cancel = true;
                        // }
                        tooltip.content = 'Estimated Distance: ' + (this.serviceOrderService.estimatedMiles ?? 'Not data found!');
                    }
                });
                //Render initialized Tooltip component
                tooltip.appendTo(this.mileage);
                this.mileageObject = new NumericTextBox({
                    value: args.rowData.mileage,
                    showSpinButton: false,
                    format: 'n2',
                    min: 0,
                    decimals: 2,
                    enabled: args.rowData.subCostType && args.rowData.subCostType === SubCostTypes.travelCalloutCharge,
                    created: () => {
                        this.mileage.onkeyup = event => {
                            if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                                this.mileageObject.value = event.target.value;
                            }
                        }
                    },
                    change: (event) => {
                        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
                        if (event.value != null) {
                            if (isBillable && !this.data.isNationalAccount) { // regular account and billable service
                                this.onMileageChangeForBillableAndRegular(args);
                            } else {
                                this.calculateTotalForMileage(event.value, args.rowData);
                            }
                        }
                    }
                });
                this.mileageObject.appendTo(this.mileage);
            }
        }
    }

    // editAdditionalMileageCharge() {
    //     return {
    //         create: () => {
    //             this.additionalMileage = document.createElement('input');
    //             return this.additionalMileage;
    //         },
    //         read: () => {
    //             if (this.additionalMileageObject) {
    //                 return this.additionalMileageObject.value;
    //             }
    //         },
    //         destroy: () => {
    //             if ( this.additionalMileageObject)
    //                 this.additionalMileageObject.destroy();
    //         },
    //         write: (args) => {
    //             this.additionalMileageObject = new NumericTextBox({
    //                 value: args.rowData.addtMileageCharged ?? 0,
    //                 showSpinButton: false,
    //                 format: 'n2',
    //                 min: 0,
    //                 decimals: 2,
    //                 enabled: false,
    //             });
    //             this.additionalMileageObject.appendTo(this.additionalMileage);
    //         }
    //     }
    // }

    calculateTotalForMileage(mileage, rowData) {
        // const additionalMileage = mileage > this.iscEmployeeDetails.mileageThreshold ?  mileage - this.iscEmployeeDetails.mileageThreshold: 0;
        // this.additionalMileageObject.value = additionalMileage * this.iscEmployeeDetails.mileageRate;
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
        if (mileage) {
            let mileageExceededPrice = mileage > this.iscEmployeeDetails.mileageThreshold ? mileage * this.iscEmployeeDetails.mileageRate : undefined;
            this.iscPriceObject.value = this.data?.isNationalAccount ? mileageExceededPrice ?? this.iscEmployeeDetails.t2CalloutRate
                : isBillable ? this.calloutRate : (mileageExceededPrice ?? this.iscEmployeeDetails.t1CalloutRate);

            this.totalAmountObject.value = this.isAmountClaimable(rowData) ? this.quantityObject.value * this.iscPriceObject.value - this.iscDiscountAmountObject.value : 0;
            this.data?.isNationalAccount && this.checkIscRulesForSubcost(this.subcostTypeObject.value, rowData);
        }
    }

    editQuantity() {
        const unsubscribe$ = new Subject<void>();
        let tooltip;
        return {
            create: () => {
                this.quantity = document.createElement('input');
                return this.quantity;
            },
            read: () => {
                if (this.quantityObject) {
                    return this.quantityObject.value;
                }
            },
            destroy: () => {
                this.onHandQuantity = undefined;
                tooltip && tooltip.destroy();
                this.quantityObject.destroy();
                unsubscribe$.next();
                unsubscribe$.complete();
            },
            write: (args) => {
                tooltip = new Tooltip({
                    //Set tooltip content
                    content: 'On Hand:' + this.onHandQuantity,
                    opensOn: 'Focus',
                    beforeOpen: args => {
                        if (this.costTypeObject.value !== IscCostTypes.material || this.onHandQuantity === undefined) {
                            args.cancel = true;
                        }
                        tooltip.content = 'On Hand: ' + this.onHandQuantity;
                    }
                });
                //Render initialized Tooltip component
                tooltip.appendTo(this.quantity);
                this.quantityObject = new NumericTextBox({
                    value: args.rowData.qty,
                    showSpinButton: false,
                    format: this.costTypeObject.value === IscCostTypes.material ? 'n' : 'n2',
                    min: 0,
                    step: this.costTypeObject.value === IscCostTypes.material ? 1 : 0.1,
                    decimals: this.costTypeObject.value === IscCostTypes.material ? 0 : 2,
                    validateDecimalOnType: true,
                    enabled: this.costTypeObject.value === IscCostTypes.other ? this.subcostTypeObject.value !== SubCostTypes.travelCalloutCharge : true,
                    created: args => {

                        const keyupEvent$ = fromEvent(this.quantity, 'keyup');
                        keyupEvent$
                            .pipe(
                                debounceTime(200),
                                takeUntil(unsubscribe$)
                            )
                            .subscribe((event: any) => {
                                if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105 || ['Backspace', 'Delete'].includes(event.key)) {
                                    if (event.target.value != null)
                                        this.quantityObject.value = event.target.value;
                                }
                            });
                    },
                    change: event => {
                        const qty = +event.value?.toFixed(2) || 0;
                        const costType = this.costTypeObject.value;

                        if (costType === IscCostTypes.material && this.itemCodeObject.value && qty) {
                            this.tempUnit = this.getUnit(costType);
                            const data = {
                                company: this.USER.company,
                                item: this.itemCodeObject.value,
                                unit: this.tempUnit,
                                quantity: qty,
                                configuration: this.data.customerCode
                            };
                            const iscData = {
                                ...data,
                                configuration: this.USER.iscCode
                            }
                            this.showLoader = true;
                            forkJoin([
                                this.serviceOrderService.getPrice(data),
                                this.serviceOrderService.getPrice(iscData),
                            ]).subscribe((res: any) => {
                                if (res[0] && res[0].isSuccessful && res[0].result) {
                                    const price = res[0].result.bookPrice ? +res[0].result.bookPrice.bookPriceValue?.toFixed(2) : 0;
                                    this.discountAmountObject.value = res[0].result?.discountAmount ? +res[0].result.discountAmount.discountAmountValue?.toFixed(2) : 0;
                                    this.setCustomerPrice(price, args.rowData, 'on quantity change for item API success');
                                    this.onQuantityChange(args, qty);
                                } else {
                                    this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                                }
                                if (res[1] && res[1].isSuccessful && res[1].result) {
                                    const price = res[1].result.bookPrice ? +res[1].result.bookPrice.bookPriceValue?.toFixed(2) : 0;
                                    this.iscPriceObject.value = price;
                                    this.iscDiscountAmountObject.value = res[1].result?.discountAmount ? +res[1].result.discountAmount.discountAmountValue?.toFixed(2) : 0;
                                    this.totalAmountObject.value = this.isAmountClaimable(args.rowData) ? price * this.quantityObject.value - this.iscDiscountAmountObject.value : 0;
                                } else {
                                    this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                                }
                                this.showLoader = false;
                            }, (error: HttpErrorResponse) => {
                                this.setCustomerPrice(0, args.rowData, 'on quantity change for item API fail');
                                this.discountAmountObject.value = 0;
                                this.iscPriceObject.value = 0;
                                this.commonService.showNotification('error', "Error fetching price from Pricebook. Please contact support.");
                                this.showLoader = false;
                            });
                        } else {
                            const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
                            this.onQuantityChange(args, qty);
                            const regularAccount = !this.data.isNationalAccount;
                            if (!(isBillable && regularAccount)) { // non-billable || national account
                                const mileage = this.mileageObject.value;
                                this.calculateTotalForMileage(mileage, args.rowData);
                            }
                        }
                        this.subcostTypeObject?.value && this.data?.isNationalAccount && this.checkIscRulesForSubcost(this.subcostTypeObject.value, args.rowData);
                    }
                });
                this.quantityObject.appendTo(this.quantity);
            }
        }
    }

    onMileageChangeForBillableAndRegular(args) { // For time complexity I am just copying the code for this, it should be a common function for multiple things
        const qty = this.quantityObject?.value;
        const customerPrice = this.customerPriceObject ? +this.customerPriceObject.value.toFixed(2) : +this.customerPriceValue?.toFixed(2);
        const iscPrice = this.iscPriceObject ? this.iscPriceObject.value : args.rowData.price;
        let discount = this.discountAmountObject ? +this.discountAmountObject.value?.toFixed(2) : +args.rowData.discountAmount.toFixed(2);
        if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
            discount = customerPrice * qty;
            if (this.discountAmountObject) {
                this.discountAmountObject.value = customerPrice && qty ? customerPrice * qty : 0;
                this.discountAmountObject.max = customerPrice && qty ? customerPrice * qty : null;
            }
        } else {
            if (['Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
                this.discountAmountObject.value = 0;
                discount = 0;
            }
        }
        const tax = +args.rowData.taxpercent?.toFixed(2);
        const warranty = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
        const contract = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

        if (this.warrantyAmountObject) {
            this.warrantyAmountObject.value = warranty;
        }
        if (this.contractAmountObject) {
            this.contractAmountObject.value = contract;
        }
        this.calculateTotalAmount({ qty, customerPrice, iscPrice, discount, tax, warranty, contract }, args.rowData);
    }

    onQuantityChange(args, qty:  number = 0) {
        const customerPrice = this.customerPriceObject ? +this.customerPriceObject.value.toFixed(2) : +this.customerPriceValue?.toFixed(2);
        const iscPrice = this.iscPriceObject ? this.iscPriceObject.value : args.rowData.price;
        let discount = this.discountAmountObject ? +this.discountAmountObject.value?.toFixed(2) : +args.rowData.discountAmount.toFixed(2);
        if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
            discount = customerPrice * qty;
            if (this.discountAmountObject) {
                this.discountAmountObject.value = customerPrice && qty ? customerPrice * qty : 0;
                this.discountAmountObject.max = customerPrice && qty ? customerPrice * qty : null;
            }
        } else {
            if (['Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
                this.discountAmountObject.value = 0;
                discount = 0;
            }
        }
        const tax = +args.rowData.taxpercent?.toFixed(2);
        const warranty = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
        const contract = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

        if (this.warrantyAmountObject) {
            this.warrantyAmountObject.value = warranty;
        }
        if (this.contractAmountObject) {
            this.contractAmountObject.value = contract;
        }
        this.calculateTotalAmount({ qty, customerPrice, iscPrice, discount, tax, warranty, contract }, args.rowData);
    }

    getRefActivityCategory(refActivityCode) {
        const refActivity = this.referenceActivities.find(act => act.value === refActivityCode);
        return refActivity ? refActivity.category : '';
    }

    editCustomerPrice() {
        return {
            create: () => {
                this.customerPriceElem = document.createElement('input');
                return this.customerPriceElem;
            },
            read: () => {
                if (this.customerPriceObject) {
                    return this.customerPriceObject.value;
                }
            },
            destroy: () => {
                if (this.customerPriceObject)
                    this.customerPriceObject.destroy();
            },
            write: (args) => {
                this.customerPriceObject = new NumericTextBox({
                    value: args.rowData.customerPrice,
                    showSpinButton: false,
                    format: 'n2',
                    min: 0,
                    decimals: 2,
                    enabled: false,
                    change: (event) => {
                        this.onCustomerPriceChange(event.value, args.rowData, 'customer price change');
                    }
                });
                this.customerPriceObject.appendTo(this.customerPriceElem);
            }
        }
    }

    editPrice() {
        return {
            create: () => {
                this.iscPriceElem = document.createElement('input');
                return this.iscPriceElem;
            },
            read: () => {
                if (this.iscPriceObject) {
                    return this.iscPriceObject.value;
                }
            },
            destroy: () => {
                this.iscPriceObject.destroy();
            },
            write: (args) => {
                let keyPressed = false;
                this.iscPriceObject = new NumericTextBox({
                    value: args.rowData.price,
                    showSpinButton: false,
                    format: 'n2',
                    min: this.quantityObject?.value ? this.iscDiscountAmountObject?.value / this.quantityObject.value || null : null, // Price Field: If (Price * Qty) < Discount Then Show error message - Cannot be less than discount
                    decimals: 2,
                    enabled: this.isIscPriceEnabled(),
                    created: () => {
                        this.iscPriceElem.onkeyup = event => {
                            if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105 || ['Backspace', 'Delete'].includes(event.key)) {
                                if (event.target.value != null)
                                    this.iscPriceObject.value = event.target.value;
                                keyPressed = true;
                                // this.setCustomerPrice(event.target.value, args.rowData);
                            }
                        }
                    },
                    focus: args => {
                        this.iscPriceObject.min = this.quantityObject?.value ? this.iscDiscountAmountObject.value / this.quantityObject.value || null : null;
                    },
                    change: (event) => {
                        if (event.value != null) {
                            if (keyPressed || event.isInteracted) {
                                this.totalAmountObject.value = this.isAmountClaimable(args.rowData) ? event.value * this.quantityObject.value - this.iscDiscountAmountObject.value : 0;
                                this.setCustomerPrice(event.value, args.rowData, 'on isc price change');
                                keyPressed = false;
                            }
                            this.iscDiscountAmountObject.max = event.value * this.quantityObject?.value;
                            this.subcostTypeObject?.value && this.data?.isNationalAccount && this.checkIscRulesForSubcost(this.subcostTypeObject.value, args.rowData);
                        } else {
                            this.iscPriceObject.value = 0;
                        }
                    }
                });
                this.iscPriceObject.appendTo(this.iscPriceElem);
            }
        }
    }

    isIscPriceEnabled() {
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
        if (!this.data.isNationalAccount && isBillable) { return true } else {
            return this.subcostTypeObject?.value && ![SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value);
        }
    }

    calculateTotalAmount(data, rowData?) {
        this.customerTotalAmountValue = +(data.qty && (data.customerPrice * data.qty) - (data.discount || 0) - (data.warranty || 0) - (data.contract || 0))?.toFixed(2);
        this.taxAmount = +(this.customerTotalAmountValue * data.tax / 100)?.toFixed(2);
        this.setCustomerTotalAmountValue(this.customerTotalAmountValue);
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
        const regularAccount = !this.data.isNationalAccount;
        if (isBillable && regularAccount)
            this.totalAmountObject.value = +(data.iscPrice * data.qty - this.iscDiscountAmountObject.value).toFixed(2);
        else // non-billable || national-account
            this.totalAmountObject.value = this.isAmountClaimable(rowData) ? this.iscPriceObject.value * this.quantityObject.value - this.iscDiscountAmountObject.value : 0;

    }

    isIscDiscountEnabled() {
        const isBillable = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service';
        if (isBillable && !this.data.isNationalAccount) {
            return true;
        } else {
            const isCostTypeOther = this.costTypeObject?.value === IscCostTypes.other;
            const isTravelCallout_mileage = [SubCostTypes.travelCalloutCharge, SubCostTypes.mileage].includes(this.subcostTypeObject.value);
            return isCostTypeOther && this.subcostTypeObject?.value && !isTravelCallout_mileage;
        }
    }

    editIscDiscountAmount() {
        return {
            create: () => {
                this.iscDiscountAmount = document.createElement('input');
                return this.iscDiscountAmount;
            },
            read: () => {
                if (this.iscDiscountAmountObject) {
                    return this.iscDiscountAmountObject.value;
                }
            },
            destroy: () => {
                this.iscDiscountAmountObject.destroy();
            },
            write: (args) => {
                this.iscDiscountAmountObject = new NumericTextBox({
                    value: args.rowData.iscDiscountAmount ?? 0,
                    format: 'n2',
                    min: 0,
                    decimals: 2,
                    max: this.iscPriceObject?.value * this.quantityObject?.value || null,
                    enabled: this.isIscDiscountEnabled(),
                    showSpinButton: false,
                    created: args => {
                        this.iscDiscountAmount.onkeyup = event => {
                            if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105 || [8, 127].includes(event.keyCode)) {
                                if (event.target.value.includes('.0') && event.target.value.split('.')[1].length === 1) {
                                    return;
                                }
                                this.iscDiscountAmountObject.value = event.target.value;
                            }
                        }
                    },
                    focus: args => {
                        const price = this.iscPriceObject?.value,
                            qty = this.quantityObject?.value;
                        this.iscDiscountAmountObject.max = price * qty;
                    },
                    change: (event) => {
                        if (event.value != null) {
                            if (this.mileageObject.value) {
                                this.calculateTotalForMileage(this.mileageObject.value, args.rowData);
                            } else {
                                this.totalAmountObject.value = this.isAmountClaimable(args.rowData) ? this.quantityObject.value * this.iscPriceObject.value - this.iscDiscountAmountObject.value : 0;
                                this.data?.isNationalAccount && this.checkIscRulesForSubcost(this.subcostTypeObject.value, args.rowData);
                            }
                            this.iscPriceObject.min = this.quantityObject?.value && event.value ? event.value / this.quantityObject.value || null : null;
                        } else {
                            this.iscDiscountAmountObject.value = 0;
                        }
                    }
                });
                this.iscDiscountAmountObject.appendTo(this.iscDiscountAmount);
            }
        }
    }

    editDiscountAmount() {
        return {
            create: () => {
                this.discountAmount = document.createElement('input');
                return this.discountAmount;
            },
            read: () => {
                if (this.discountAmountObject) {
                    return this.discountAmountObject.value;
                }
            },
            destroy: () => {
                this.discountAmountObject.destroy();
            },
            write: (args) => {
                this.discountAmountObject = new NumericTextBox({
                    value: args.rowData.discountAmount ?? 0,
                    format: 'n2',
                    min: 0,
                    decimals: 2,
                    max: this.customerPriceValue && this.quantityObject.value && this.customerPriceValue * this.quantityObject.value || null,
                    enabled: this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service' || args.rowData.serviceItemGroup === '250SVC',
                    showSpinButton: false,
                    created: args => {
                        this.discountAmount.onkeyup = event => {
                            if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                                if (event.target.value.includes('.0') && event.target.value.split('.')[1].length === 1) {
                                    return;
                                }
                                this.discountAmountObject.value = event.target.value;
                            }
                        }
                    },
                    focus: args => {
                        const price = this.customerPriceObject?.value ?? this.customerPriceValue,
                            qty = this.quantityObject && this.quantityObject.value;
                        this.discountAmountObject.max = price && qty ? price * qty : null;
                    },
                    change: (event) => {
                        this.discountValue = +event.value?.toFixed(2);
                        const qty = this.quantityObject ? this.quantityObject.value : args.rowData.qty;
                        const customerPrice = this.customerPriceObject ? +this.customerPriceObject.value.toFixed(2) : +this.customerPriceValue.toFixed(2);
                        const iscPrice = this.iscPriceObject ? this.iscPriceObject.value : args.rowData.price;
                        let discount = +event.value?.toFixed(2);
                        if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
                            discount = customerPrice * qty;
                            if (this.discountAmountObject) {
                                this.discountAmountObject.value = customerPrice && qty ? customerPrice * qty : 0;
                                this.discountAmountObject.max = customerPrice && qty ? customerPrice * qty : null;
                            }
                        } else {
                            if (['Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
                                this.discountAmountObject.value = 0;
                                discount = 0;
                            }
                        }
                        const tax = args.rowData.taxpercent;

                        const warranty = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
                        const contract = this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

                        if (this.warrantyAmountObject) {
                            this.warrantyAmountObject.value = warranty;
                        }
                        if (this.contractAmountObject) {
                            this.contractAmountObject.value = contract;
                        }
                        this.calculateTotalAmount({ qty, customerPrice, iscPrice, discount, tax, warranty, contract }, args.rowData);
                    }
                });
                this.discountAmountObject.appendTo(this.discountAmount);
            }
        }
    }

    editContractAmount() {
        return {
            create: () => {
                this.contractAmount = document.createElement('input');
                return this.contractAmount;
            },
            read: () => {
                if (this.contractAmountObject) {
                    return this.contractAmountObject.value;
                }
            },
            destroy: () => {
                this.contractAmountObject.destroy();
            },
            write: (args) => {
                this.contractAmountObject = new NumericTextBox({
                    value: args.rowData.contractCoverage ?? 0,
                    min: 0,
                    enabled: false,
                    showSpinButton: false
                });
                this.contractAmountObject.appendTo(this.contractAmount);
            }
        }
    }

    editWarrantyAmount() {
        return {
            create: () => {
                this.warrantyAmount = document.createElement('input');
                return this.warrantyAmount;
            },
            read: () => {
                if (this.warrantyAmountObject) {
                    return this.warrantyAmountObject.value;
                }
            },
            destroy: () => {
                this.warrantyAmountObject.destroy();
            },
            write: (args) => {
                this.warrantyAmountObject = new NumericTextBox({
                    value: args.rowData.warrantyCoverage ?? 0,
                    min: 0,
                    enabled: false,
                    showSpinButton: false
                });
                this.warrantyAmountObject.appendTo(this.warrantyAmount);
            }
        }
    }

    editCustomerTotalAmount() {
        return {
            create: () => {
                this.customerTotalAmount = document.createElement('input');
                return this.customerTotalAmount;
            },
            read: () => {
                if (this.customerTotalAmountObject) {
                    return this.customerTotalAmountObject.value;
                }
            },
            destroy: () => {
                this.customerTotalAmountObject.destroy();
            },
            write: (args) => {
                this.customerTotalAmountObject = new NumericTextBox({
                    value: args.rowData.customerTotalAmount,
                    showSpinButton: false,
                    enabled: false,
                    min: 0,
                    change: (event) => {
                        this.taxAmount = +(this.customerTotalAmountObject.value * this.getTaxRate(this.costTypeObject.value) / 100)?.toFixed(2);
                        if (this.taxObject) {
                            this.taxObject.value = this.taxAmount;
                        }
                    }
                });
                this.customerTotalAmountObject.appendTo(this.customerTotalAmount);
            }
        }
    }

    editTotalAmount() {
        return {
            create: () => {
                this.totalAmount = document.createElement('input');
                return this.totalAmount;
            },
            read: () => {
                if (this.totalAmountObject) {
                    return this.totalAmountObject.value;
                }
            },
            destroy: () => {
                this.totalAmountObject.destroy();
            },
            write: (args) => {
                this.totalAmountObject = new NumericTextBox({
                    value: args.rowData.totalAmount,
                    showSpinButton: false,
                    enabled: false,
                    min: 0,
                });
                this.totalAmountObject.appendTo(this.totalAmount);
            }
        }
    }

    editUnit() {
        return {
            create: () => {
                this.unit = document.createElement('input');
                return this.unit;
            },
            read: () => {
                if (this.unitObject) {
                    return this.unitObject.value;
                }
            },
            destroy: () => {
                this.unitObject.destroy();
            },
            write: (args) => {
                this.unitObject = new DropDownList({
                    dataSource: this.units,
                    fields: { value: 'value', text: 'text' },
                    value: args.rowData.unit,
                    allowFiltering: true,
                    filterType: 'Contains',
                    enabled: false
                });
                this.unitObject.appendTo(this.unit);
            }
        }
    }

    editReturnReason() {
        return {
            create: () => {
                this.returnReason = document.createElement('input');
                return this.returnReason;
            },
            read: () => {
                if (this.returnReasonObject) {
                    return this.returnReasonObject.value;
                }
            },
            destroy: () => {
                this.returnReasonObject.destroy();
            },
            write: (args) => {
                this.returnReasonObject = new DropDownList({
                    dataSource: this.returnReasons,
                    fields: { value: 'value', text: 'text' },
                    itemTemplate: '<span title="${text}">${text}</span>',
                    popupWidth: '250px',
                    enabled: this.costTypeObject.value === IscCostTypes.material,
                    value: args.rowData.returnReasonCode
                });
                this.returnReasonObject.appendTo(this.returnReason);
            }
        }
    }

    editStartTime() {
        return {
            create: () => {
                this.assignmentDetailStartTime = document.createElement('input');
                return this.assignmentDetailStartTime;
            },
            read: () => {
                if (this.assignmentDetailStartTimeObject) {
                    return this.assignmentDetailStartTimeObject.value;
                }
            },
            destroy: () => {
                this.assignmentDetailStartTimeObject.destroy();
            },
            write: (args) => {
                this.assignmentDetailStartTimeObject = new DateTimePicker({
                    placeholder: "Select start date time...",
                    value: args.rowData.startDate,
                    min: args.rowData.startDate ? args.rowData.startDate : this.checkinTime,
                    format: 'd MMM, y h:mm a',
                    openOnFocus: true,
                    allowEdit: false,
                    change: ev => {
                        this.assignmentDetailStartTimeValue = ev.value;
                        if (this.assignmentDetailEndTimeObject) {
                            this.assignmentDetailEndTimeObject.min = ev.value;
                        }
                    }
                });
                this.assignmentDetailStartTimeObject.appendTo(this.assignmentDetailStartTime);
            }
        }
    }

    editEndTime() {
        return {
            create: () => {
                this.assignmentDetailEndTime = document.createElement('input');
                return this.assignmentDetailEndTime;
            },
            read: () => {
                if (this.assignmentDetailEndTimeObject) {
                    return this.assignmentDetailEndTimeObject.value;
                }
            },
            destroy: () => {
                this.assignmentDetailEndTimeObject.destroy();
            },
            write: (args) => {
                this.assignmentDetailEndTimeObject = new DateTimePicker({
                    placeholder: "Select end date time...",
                    value: args.rowData.endDate,
                    min: args.rowData.startDate || new Date(),
                    format: 'd MMM, y h:mm a',
                    openOnFocus: true,
                    allowEdit: false,
                    change: ev => {
                        if (this.assignmentDetailStartTimeObject) {
                            this.assignmentDetailStartTimeObject.max = ev.value;
                        }
                    }
                });
                this.assignmentDetailEndTimeObject.appendTo(this.assignmentDetailEndTime);
            }
        }
    }

    editTask() {
        return {
            create: () => {
                this.task = document.createElement('input');
                return this.task;
            },
            read: () => {
                if (this.taskObject) {
                    return this.taskObject.value;
                }
            },
            destroy: () => {
                this.taskObject.destroy();
            },
            write: (args) => {
                this.taskObject = new TextBox({
                    value: args.rowData.task,
                    enabled: false
                });
                this.taskObject.appendTo(this.task);
            }
        }
    }

    editTax() {
        return {
            create: () => {
                this.tax = document.createElement('input');
                return this.tax;
            },
            read: () => {
                if (this.taxObject) {
                    return this.taxObject.value;
                }
            },
            destroy: () => {
                this.taxObject.destroy();
            },
            write: (args) => {
                this.taxObject = new NumericTextBox({
                    value: args.rowData.taxAmount,
                    showSpinButton: false,
                    enabled: false,
                    change: args => {
                    }
                });
                this.taxObject.appendTo(this.tax);
            }
        }
    }

    editTaxPercent() {
        return {
            create: () => {
                this.taxPercent = document.createElement('input');
                return this.taxPercent;
            },
            read: () => {
                if (this.taxPercentObject) {
                    return this.taxPercentObject.value;
                }
            },
            destroy: () => {
                this.taxPercentObject.destroy();
            },
            write: (args) => {
                this.taxPercentObject = new NumericTextBox({
                    value: args.rowData.taxpercent,
                    showSpinButton: false,
                    enabled: false,
                    change: args => {
                    }
                });
                this.taxPercentObject.appendTo(this.taxPercent);
            }
        }
    }

    editCostComponent() {
        return {
            create: () => {
                this.costComponent = document.createElement('input');
                return this.costComponent;
            },
            read: () => {
                if (this.costComponentObject) {
                    return this.costComponentObject.value;
                }
            },
            destroy: () => {
                this.costComponentObject.destroy();
            },
            write: (args) => {
                this.costComponentObject = new TextBox({
                    value: args.rowData.costComponent,
                    enabled: false
                });
                this.costComponentObject.appendTo(this.costComponent);
            }
        }
    }

    editTransactionWarehouse() {
        return {
            create: () => {
                this.transactionWarehouse = document.createElement('input');
                return this.transactionWarehouse;
            },
            read: () => {
                if (this.transactionWarehouseObject) {
                    return this.transactionWarehouseObject.value;
                }
            },
            destroy: () => {
                this.transactionWarehouseObject.destroy();
            },
            write: (args) => {
                this.transactionWarehouseObject = new TextBox({
                    value: args.rowData.warehouse,
                    enabled: false
                });
                this.transactionWarehouseObject.appendTo(this.transactionWarehouse);
            }
        }
    }

    editLaborType() {
        return {
            create: () => {
                this.laborType = document.createElement('input');
                return this.laborType;
            },
            read: () => {
                if (this.laborTypeObject) {
                    return this.laborTypeObject.value;
                }
            },
            destroy: () => {
                this.laborTypeObject.destroy();
            },
            write: (args) => {
                this.laborTypeObject = new TextBox({
                    value: args.rowData.laborType,
                    enabled: false
                });
                this.laborTypeObject.appendTo(this.laborType);
            }
        }
    }

    ngOnDestroy() {
        this.loadedAssignmentItemDetails = null;
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
