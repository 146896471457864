import { Component, EventEmitter, OnInit, Output, ViewChild, Input } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomAdaptor, gridActionBeginForFiltering, gridActionsForFiltering, handleClearFilter, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { environment } from 'src/environments/environment';

const BASE_URL = environment.apiUrl;
@Component({
  selector: 'app-cycle-counts-resource-selection-popup',
  templateUrl: './cycle-counts-resource-selection-popup.component.html',
  styles: [
  ]
})
export class CycleCountsResourceSelectionPopupComponent implements OnInit {

  isExpanded: boolean = false;
  resourceList;
  gridColumns;
  gridToolbar;
  gridPageSettings;
  query: Query;

  @ViewChild('resourceGrid') resourceGrid;

  @Input() employeeId: string;

  @Output() onClose = new EventEmitter();
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.resourceList = new DataManager({
      url: `${BASE_URL}ServiceEmployee/GetEmployeeSearchView`,
      adaptor: new CustomAdaptor()
    });
    this.query = new Query().addParams('employeeId', this.employeeId).addParams('fromResourceSelectionPopup', '');
  }

  ngAfterViewInit(): void {
      this.setGrid();
  }

  setGrid() {
    this.gridColumns = [
      { field: 'employeeId', headerText: 'Employee Id', width: 120, visible: false, nonHideable: true, showInColumnChooser: false, type: 'string' },
      { field: 'employeeName', headerText: 'Name', visible: true, type: 'string' },
      { field: 'email', headerText: 'Email', visible: true, type: 'string' },
      { field: 'telephone', headerText: 'Telephone', visible: true, type: 'string' },
      { field: 'serviceDepartment', headerText: 'Service Department', visible: true, type: 'string' },
      { field: 'warehouse', headerText: 'Warehouse', visible: true, type: 'string' },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
        commands: [
            {
                title: 'Proceed',
                buttonOption: {
                    iconCss: `fas fa-arrow-right`,
                    cssClass: `e-flat action-button`
                }
            }
        ]
      }
    ];
    this.gridToolbar = ['Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
    this.gridPageSettings = { pageSize: 10 }

  }

  close() {
    this.onClose.emit();
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if ( args.item.id === 'clear-filter' ) {
      handleClearFilter(grid);
    }
  }

  onGridCreated(args) {
    updateFilterIcon({requestType: 'filtering'}, this.resourceGrid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid){
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Proceed') {
      args.rowData.technicianId = args.rowData.employeeId;
      args.rowData.technicianName = args.rowData.employeeName;
      this.onClose.emit(args.rowData);
    }
  }

}
