import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { GridComponent, PageService, SelectionService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CustomAdaptor, gridActionBeginForFiltering, gridActionsForFiltering, handleClearFilter, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { environment } from 'src/environments/environment';
import { UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-config-advanced-search-for-serial-number',
  templateUrl: './config-advanced-search-for-serial-number.component.html',
  styleUrls: ['./config-advanced-search-for-serial-number.component.scss'],
  providers: [
    ToolbarService,
    SortService,
    PageService,
    SelectionService
  ]
})

export class ConfigAdvancedSearchForSerialNumberComponent implements OnInit {
  isExpanded: boolean = true;
  validationApplied: boolean = false;

  data: any;
  mainGrid: any;
  filterSettings: any;
  query: Query;
  selectedRowData: any;

  userRoles;

  model: string = '';
  srNumber: string = '';

  @Input() gridType: any = 'config';

  @Input() serialNumbers;
  @Input() serviceDepartment;
  @Input() offlineOrder: boolean;

  @Output() onClose = new EventEmitter();
  @ViewChild('columnChooser') columnChooser;
  @ViewChild('serviceOrderGrid') serviceOrderGrid;
  USER: any;

  constructor(
    private commonService: CommonService
  ) {
    this.USER = commonService.USER;
  }

  ngOnInit(): void {
    this.USER = this.commonService.USER;
    this.userRoles = UserRoles;
    if (this.commonService.USER.role !== UserRoles.iscManager && this.commonService.USER.role !== UserRoles.IscSupervisor && this.commonService.USER.role !== UserRoles.isc) {
      this.getSerialNumbers(null, true);
    }
  }

  validate() {
    this.validationApplied = true;
    return this.model && this.srNumber;
  }

  getSerialNumbers(event, onInit?: boolean) {
    if (this.validate() || onInit) {
      const isIsc = this.commonService.USER.role === UserRoles.iscManager || this.commonService.USER.role === UserRoles.IscSupervisor || this.commonService.USER.role === UserRoles.isc;
      const api = isIsc ? 'EquipmentObject/GetISCSerialNumberSearchView' : 'EquipmentObject/GetSerialNumberSearchView';
      this.filterSettings = { type: 'Menu' };
      this.data = new DataManager({
        url: `${BASE_URL}${api}`,
        adaptor: new CustomAdaptor()
      });
      this.query = new Query().addParams('company', this.USER.company).addParams('serviceDepartment', this.serviceDepartment).addParams('offlineOrder', this.offlineOrder + '');
      if (isIsc) {
        this.query.addParams('serialSearch', 'true');
        this.model && this.query.addParams('model', this.model);
        this.srNumber && this.query.addParams('serialNumber', this.srNumber);
      }
    }
  }

  ngAfterViewInit(): void {
    this.setGrid();
  }

  setGrid() {
    const columns = [
      { field: 'serialNumber', headerText: 'Serial Number', visible: true, nonHideable: true, },
      { field: 'model', headerText: 'Model Number', visible: true },
      { field: 'modelDescription', headerText: 'Model Description', visible: true },
      { field: 'configuration', headerText: 'Configuration', visible: true },
      { field: 'customerId', headerText: 'Customer Id', visible: true },
      { field: 'customerName', headerText: 'Customer Description', visible: true },
      { field: 'isNationalAccount', headerText: 'National Account', visible: false, showInColumnChooser: true, type: 'boolean' },
      { field: 'nationalAccount', headerText: 'National Account', visible: true, type: 'string', filter: { type: 'Menu' } },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false, textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
        commands: [
          {
            title: 'Proceed',
            buttonOption: {
              iconCss: `fas fa-arrow-right`,
              cssClass: `e-flat action-button`
            }
          }
        ]
      }
    ]
    this.mainGrid = {
      columns,
      toolbar: ['Search',
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
      ],
      pageSettings: { pageSize: 10 }
    };
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    }
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.serviceOrderGrid);
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFiltering(args, grid);
  }

  onGridActionComplete(args, grid) {
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Proceed') {
      this.onClose.emit(args.rowData);
    }
  }

  close() {
    this.onClose.emit();
  }

  rowSelected(args) {
    this.selectedRowData = args.data;
  }

}
