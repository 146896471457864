import { Component, ViewChild, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiResponse, gridNames, PersistenceData, GridPersistRequests } from 'src/app/shared/models/app.model';
import { GridComponent, EditService, ExcelExportProperties, CommandClickEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { getExcelDataSource, gridActionBeginForFiltering, handleClearFilter, handleGridExcelExport, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { CycleCountService } from '../cycle-counts.service';
import { getValue, setValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-cycle-counts-exception-popup',
  templateUrl: './cycle-counts-exception-popup.component.html',
  styleUrls: ['./cycle-counts-exception-popup.component.scss']
})
export class CycleCountExceptionPopupComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  @Output() refreshMainGrid = new EventEmitter();

  @Input() showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountExceptionPopupGrid;
  columns: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 420;
  showPopup: boolean =false;
  object;
  query: Query;
  public pageSettings: Object
  rowCycleCountData = null
  selectedTab: string = 'details';
  startDateElement: HTMLInputElement;
  startDateObject: DatePicker;
  showAddPopup: boolean = false;
  isExpanded = true
  aggreagtes
  @Output() browseFile: EventEmitter<any> = new EventEmitter();
  @Input() exceptionPopupData;
  cycleCountId: string;
 
  public selectionOptions: SelectionSettingsModel;

  @ViewChild('cycleCountExceptionPopupGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  USER: any;
  preference;
  showWareHouseGrid: boolean = false;
  @Input() fileInvalid: boolean = false;

  constructor(
    private commonService : CommonService,
  ) {
    this.USER = this.commonService.USER;
  }

  ngOnInit(): void {
    this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
    this.grid = null;
    this.getColumns();
    this.showLoader = false;
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { field: 'warehouse', headerText: 'Warehouse', type: 'String', textAlign: 'Left', width: 100, isPrimaryKey: true },
        { field: 'technicianName', headerText: 'Technician Name', type: 'String', allowEditing: false, textAlign: 'Left', width: 100, visible: true },
        { field: 'cycleCountId', headerText: 'Cycle Count Id', type: 'String', textAlign: 'Left', width: 100 },
        { field: 'startDate', headerText: 'Cycle Count Date', type: 'date', format: { type: 'date', format: 'd MMM, y' }, textAlign: 'Left', width: 100 },
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 300, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
          commands: [
              { title: 'Open Exception', buttonOption: { iconCss: `fa fa-exclamation-triangle`, cssClass: 'e-flat' } },
          ]
        }
      ]};
  }

  setGridToolbar() {
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  expand() {
    this.isExpanded = !this.isExpanded;
    if(!this.isExpanded) {
      this.gridHeight = window.innerHeight - 220;
    } else {
      this.gridHeight = window.innerHeight - 420;
    }
  }

  onCommandClick(args: any) {
    switch (args.commandColumn.title) {
      case 'Open Exception':
        this.selectedTab = 'details';
        this.showPopup = true;
        this.cycleCountId = args?.rowData?.cycleCountId
        break;
  
      default:
        // Default action if none of the cases match.
        break;
    }
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'excel-export') {
      handleGridExcelExport(grid, 'Cycle count with exceptions');
    } else if (args.item.id === 'clear-filter') {
      handleClearFilter(grid);
    }  else if (args.item.id === 'refresh') {
      this.grid.refresh();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountExceptionPopupGrid, value: '' }).subscribe((response) => {
            handleClearFilter(grid);
            this.showLoader = false;
          });
        }
        else{
          this.showLoader = false;
          return
        }
      });
    }
  }

  onCycleCountClick(cycleCountId: string) {
    this.selectedTab = 'details';
    this.showPopup = true;
    this.cycleCountId = cycleCountId
  }

  close(){
    this.commonService.showConfirmation('Are you sure to close?')
          .then(result => {
              if (result.isConfirmed) {
                  this.onClose.emit();
              }
          })
  }

}
