export enum serviceOrderTypes {
    fst = 'FST',
    isc = 'ISC'
}

export const serviceOrderStatuses = {
    open: 'Open',
    inProgress: 'In Progress',
    completed: 'Completed',
    costed: 'Costed',
    awaitingPayment: 'Awaiting Payment',
    paused: 'Paused',
    enroute: 'En Route',
    interrupted: 'Interrupted',
    scheduled: 'Scheduled',
    cancelled: 'Canceled',
    pendingApproval: 'Pending Approval',
    estimateApproved: 'Estimate Approved',
    estimateRejected: 'Estimate Rejected',
    importPublishError: 'Import Publish Error',
    exportPublishError: 'Export Publish Error'
}

export interface GeneralSectionData {
    serviceDepartment: string;
    serviceOrderIdLN: string;
    serviceDepartmentDescription: string;
    configuration: string;
    customerCode: string;
    customerDescription: string;
    bpDescription:string;
    address: string;
    addressCode: string;
    city: string;
    email: string;
    elapsedTime: number;
    estimatedMiles: number;
    country: string;
    state: string;
    priority: string;
    priorityDescription: string;
    reasonCode?: string;
    reasonDescription?: string;
    reasonType?: string;
    object: string;
    offlineOrder?: boolean;
    serialNumber: string;
    zipCode?: string;
    customerPhoneNumber?: string;
    customerCurrency?: string;
    taxable?: boolean;
    customerEmail: string;
    signal: string;
    status?: string;
    businessPartnerStatus: string | number;
    termsOfPayment: string;
    termCodeDescription: string;
    // signalCode: string;
    // signalCodeDescription: string;
    checkInDate: Date;
    checkOutDate: Date;
    reference: string;
    startDate: Date;
    activityText: string,
    callGroup: string;
    mode: string;
    nteAmount: number;
    approvedAmount: number;
    isNationalAccount: boolean;
}

export interface CustomerInformation {
    name: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    address: string;
}

export interface CardDetails {
    creditCardNumber: string;
    expiryDate: Date,
    ccv: number;
}
export interface BillingInformation {
    cardHolderName: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    address: string;
    cardDetails: CardDetails
}

export interface GenerateInvoiceData {
    technicianId: string;
    technicianName: string;
    serviceOrderId: string;
    paymentType?: number,
    paymentTypeDescription?: string;
    paymentAmount: number;
    cardType: string;
    bankCreditCard?: any;
    checkAuthCode?: any;
    paymentAttachment?: null;

}

export interface CreditCardPaymentDetails {
    invoiceNumber: string;
    invoiceAmount: number;
    customerInfo: CustomerInformation,
    billingInfo: BillingInformation
}

export enum InvoicePopupOpenedFrom {
    popupModal = 'POPUP-MODAL',
    checklist = 'SHOW-CHECKLIST',
    paymentType = 'PAYMENT-TYPE'
}

export enum CallGroups {
    breakdown = "002",
    calibration = "001",
    commissionInstall = "003",
    initialCalibration = "006",
    other = "004",
    routineMaintenance = "005",
    ninetyDayServiceCoverage = "WSC",
    billableService = "WO",
    contractService = "CON",
    customerSatisfaction = "POL",
    engineeringChangeNotice = "ECN",
    installation = "INS",
    periodicMaintenance = "PM",
    policyAdjust = "PAD",
    repDemo = "DEM",
    training = "TRN",
    warrantyService = "WAR"
}

export enum CostTypes {
    labor = 'Labor',
    material = 'Material',
    other = 'CallOut',
    otherCost = 'CostItem'
}

export enum IscCostTypes {
    labor = 'Labor',
    material = 'Material',
    other = 'Other',
}

export enum SubCostTypes {
    travel = 'Travel',
    mileage = 'Mileage',
    tax = 'Tax',
    miscellaneous = 'Miscellaneous',
    install = 'Install',
    freight = 'Freight',
    travelCalloutCharge = 'Travel Callout Charge',
    local = 'Local',
    hotel = 'Hotel',
    rental = 'Rental',
    promo = 'Promo'
}

export const SubCostTypeList = [
    { text: 'Travel', value: SubCostTypes.travel },
    { text: 'Mileage', value: SubCostTypes.mileage },
    { text: 'Tax', value: SubCostTypes.tax },
    { text: 'Miscellaneous', value: SubCostTypes.miscellaneous },
    { text: 'Install', value: SubCostTypes.install },
    { text: 'Freight', value: SubCostTypes.freight }
];

export const IscSubCostTypeList = [
    { text: 'Travel Callout Charge', value: SubCostTypes.travelCalloutCharge},
    { text: 'Local Part', value: SubCostTypes.local},
    // { text: 'Mileage', value: SubCostTypes.mileage},
    { text: 'Install', value: SubCostTypes.install },
    { text: 'Miscellaneous', value: SubCostTypes.miscellaneous },
    { text: 'Hotel', value: SubCostTypes.hotel},
    { text: 'Rental', value: SubCostTypes.rental},
    { text: 'Freight', value: SubCostTypes.freight},
    { text: 'Travel', value: SubCostTypes.travel},
    { text: 'Promo', value: SubCostTypes.promo}
]

export const CostTypesList = [
    { text: 'Material', value: CostTypes.material },
    { text: 'Labor', value: CostTypes.labor },
    { text: 'Other', value: CostTypes.other },
]

export const IscCostTypesList = [
    { text: 'Material', value: IscCostTypes.material },
    { text: 'Labor', value: IscCostTypes.labor },
    { text: 'Other', value: IscCostTypes.other }
]

export type Mode = 'Estimate' | 'Actual';
export const ModeList = {
    estimate: 'Estimate',
    actual: 'Actual'
}

export interface IscRuleChecklistModel {
    id: number, //114,
    parentBP: string, //"200618564",
    checkListItem: string, //"ttyy",
    isDeleted: boolean, //false,
    createdDate: string, //"2023-09-14T12:33:25.667Z",
    createdBy: string, //"W692TFH",
    createdByName: string, //null,
    lastUpdatedDate: string, //"2023-09-14T12:33:25.667Z",
    lastUpdatedBy: string, //"W692TFH",
    lastUpdatedByName: string, //null
};

export interface IscRuleHistoryModel {
    historyId: number,//15349,
    primaryId: null,
    label: string, //"ISCRule-CheckListItem",
    newValue: string, //"True",
    oldValue: string, //"False",
    text: string, //"CheckListItem has been deleted (6567)",
    createdDate: string, // "2023-09-26T08:47:36.993Z",
    createdBy: string, //"W692CAD",
    createdByName: string, //"Hoffman Automotive Eq (Molly Hoffma"
};

export interface  IscRuleModel {
    checklist: IscRuleChecklistModel[],
    history: IscRuleHistoryModel[],
    id: number,
    iscRuleId: string,
    bpType: string,
    parentBP: string,
    bpDescription: string,
    claimMultipleTripCharge: boolean,
    billMultipleTripCharge: boolean,
    claimLocalPart: boolean,
    billLocalPart: boolean,
    claimAddtMileage: boolean,
    addMileageItem: boolean,
    billMileageItem: boolean,
    billAddtMileage: boolean,
    claimFrieght: boolean,
    billFrieght: boolean,
    iscTax: boolean 
};

export interface IscEmployeeModel {
    id: number,
    company: string,
    employeeId: string,
    employeeName: string,
    employeeType: string,
    firstDateOfEmployment: string,
    lastDateOfEmployment: string,
    telephone: string,
    telephone2: string,
    fax: string,
    email: string,
    serviceDepartment: string,
    maximumOvertimePerDay: number,
    serviceCar: string,
    serviceArea: string,
    foreman: string,
    pagerPhoneEnabled: number,
    language: string,
    calendarcode: string,
    billingRate: string,
    orderCurrency: string,
    role: string,
    iscSoldToBP: string,
    parentEmployeeId: string,
    iscCode: string,
    isDisabled: number,
    iscSupCode: string,
    t1LaborRate: number,
    t2LaborRate: number,
    mileageRate: number,
    t1CalloutRate: number,
    t2CalloutRate: number,
    mileageThreshold: number,
    payVia: string,
    city: string,
    state: string,
    country: string,
    isSignatureCreated: boolean,
    createdDate: string,
    createdBy: string,
    createdByName: string,
    lastUpdatedDate: string,
    lastUpdatedBy: string,
    lastUpdatedByName: string,
}