import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from './modules/auth/services/auth.service';
import { CommonService } from './shared/services/common.service';
import { MessagingService } from './shared/services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {

  loading = false;
  title = 'mobile-service';
  message;

  private navigationSubscription: Subscription;
  private refreshSubscription: Subscription;

  userActivity;
  userInactive: Subject<any> = new Subject();

  sessionExpiryTime: number = 55
  remainingMinutes: number = 0;
  remainingSeconds: number = 0;
  timerInterval: any;
  broadcastChannel: BroadcastChannel;

  constructor(private router: Router, private commonService: CommonService, private authService: AuthService, private messagingService: MessagingService) {
    this.navigationSubscription = this.commonService.isCallingApi$.subscribe(isCallingApi => {
      this.loading = isCallingApi;
    });
    this.refreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closePopup()
        this.broadcastChannel.postMessage('closePopup');
      }
    });
    this.startBroadcastChannel();
    this.setTimeout();
    this.userInactive.subscribe(() => {
      if (!this.commonService.isReminderShowing) {
        this.commonService.isReminderShowing = true;
        this.startTimer();
        Swal.fire({
          icon: 'warning',
          title: 'Session Timeout',
          text: 'You are being timed out due to inactivity. \nPlease choose to stay signed in or to sign out.\nOtherwise, you will be signed out automatically',
          confirmButtonColor: '#479e47',
          showDenyButton: true,
          reverseButtons: true,
          denyButtonColor: '#d33',
          confirmButtonText: this.updateButtonText(),
          denyButtonText: 'Sign Out',
          width: '400px'
        }).then((result) => {
          if (result.isDenied) {
            this.broadcastChannel.postMessage('signOut');
          } else {
            this.commonService.isReminderShowing = false;
            this.resetTimer();
            clearInterval(this.timerInterval);
          }
          this.closePopup()
          this.broadcastChannel.postMessage('closePopup');

        });
      }
    });
  }

  startBroadcastChannel() {
    this.broadcastChannel = new BroadcastChannel('userActivityChannel');
    this.broadcastChannel.onmessage = (event) => {
      if (event.data === 'userActive') {
        clearTimeout(this.userActivity);
        this.setTimeout();
      } else if (event.data === 'timerStarted') {
        this.saveTimerEndTime();
      } else if (event.data === 'closePopup') {
        this.closePopup();
      } else if (event.data === 'signOut') {
        this.authService.signOut();
      }
    };
  }

  saveTimerEndTime(): void {
    const currentDate = new Date();
    const updatedDate = new Date(currentDate.getTime() + 5 * 60000); // 5 minutes in milliseconds
    this.commonService.sessionTimeoutSavedDate = updatedDate;
  }

  startTimer() {
    this.saveTimerEndTime();
    
    if (!this.timerInterval) {
      this.timerInterval = setInterval(() => {
        if (this.commonService.sessionTimeoutSavedDate) {
          const currentDate = new Date();
          const difference = this.commonService.sessionTimeoutSavedDate.getTime() - currentDate.getTime();
  
          if (difference < 0) {
            this.broadcastChannel.postMessage('signOut');
            clearInterval(this.timerInterval); // Stop the timer when sign out
            this.timerInterval = null;
            return;
          }
  
          const differenceInSeconds = Math.floor(difference / 1000);
          const remainingMinutes = Math.floor(differenceInSeconds / 60);
          const remainingSeconds = differenceInSeconds % 60;
  
          // Update the UI only if there's a change in the remaining time
          if (this.remainingMinutes !== remainingMinutes || this.remainingSeconds !== remainingSeconds) {
            this.remainingMinutes = remainingMinutes;
            this.remainingSeconds = remainingSeconds;
            Swal.update({ confirmButtonText: this.updateButtonText() });
          }
        }
      }, 1000);
      
      // Broadcast a message when the timer starts
      this.broadcastChannel.postMessage('timerStarted');
    }
  }

  resetTimer() {
    this.remainingMinutes = 5;
    this.remainingSeconds = 0;
  }

  updateButtonText() {
    return `Stay Signed In (${this.remainingMinutes}:${this.remainingSeconds < 10 ? '0' : ''}${this.remainingSeconds})`;
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
      this.broadcastChannel.postMessage('userInactive');
    }, this.sessionExpiryTime * 60 * 1000);
  }

  @HostListener('window:mousemove')
  @HostListener('window:keypress')
  refreshUserState() {
    if (!this.commonService.isReminderShowing) {
      clearTimeout(this.userActivity);
      this.setTimeout();
      this.broadcastChannel.postMessage('userActive');
    }
  }

  ngOnInit() {
    // Initialize Broadcast Channel and other services
    this.startBroadcastChannel();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
    this.broadcastChannel.close(); // Close the broadcast channel when component is destroyed
  }

  closePopup() {
    Swal.close();
    clearInterval(this.timerInterval);
    this.timerInterval = null;
    this.commonService.sessionTimeoutSavedDate = null;
  }
}
